
import { defineComponent } from 'vue'
import {
  getTableProperties,
  getDefaultFilters,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import { getPayoutColumns } from './getColumns'
import { PayoutStatus } from '@/enums/PayoutStatus'
import Filters from '@/components/filters/Filters.vue'
import { FiltersType } from '@/utils/request'
import PayoutEditDialog from '@/components/dialogs/PayoutEditDialog.vue'
import { Currency } from '@/enums/Currency'
import IconWithName from '@/components/icons/IconWithName.vue'
import { exportToCsv } from '@/utils/export'
import { formatValue, showSuccessSnackBar } from '@/utils'
import { formatFiltersFromQuery } from '@/utils/filters'
import { FormatMoney } from 'format-money-js'
import PayoutLeadsDialog from '@/components/dialogs/PayoutLeadsDialog.vue'
import { mapMutations } from 'vuex'

export default defineComponent({
  name: 'Payouts',
  props: ['affiliateId'],
  components: { PayoutLeadsDialog, IconWithName, PayoutEditDialog, Filters },
  async mounted() {
    formatFiltersFromQuery(this)
    await this.onRequest({ pagination: this.pagination })
  },
  data: function() {
    const moneyFormatter = new FormatMoney({
      decimals: 2,
      separator: ' ',
    })
    return {
      ...getTableProperties('createdAt', 25),
      moneyFormatter,
      filters: {
        ...(this.affiliateId
          ? { affiliateId: this.affiliateId }
          : {
              status: [
                PayoutStatus.InReview,
                PayoutStatus.ReadyForPayment,
                PayoutStatus.Processing,
              ],
            }),
        ...getDefaultFilters(),
      },
      columns: getPayoutColumns(),
      payoutStatuses: PayoutStatus,
      showPayoutEditDialog: false,
      showPayoutLeadsDialog: false,
      currentPayout: {},
      currencies: Currency,
    }
  },
  watch: {
    async filters() {
      await this.onRequest({ pagination: this.pagination })
    },
  },
  methods: {
    ...mapMutations('payoutsModule', ['update']),
    async onRequest(props: TableRequestProps) {
      await request(props, this as Table, '/api/payouts/')
    },
    editPayout(row: any) {
      this.currentPayout = row
      this.showPayoutEditDialog = !this.showPayoutEditDialog
    },
    showPayoutLeads(row: any) {
      this.currentPayout = row
      this.showPayoutLeadsDialog = !this.showPayoutLeadsDialog
    },
    infoPayout(evt: any, row: any) {
      this.update({ ...row })
      this.$router.push('payout')
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        ...(this.affiliateId ? { affiliateId: this.affiliateId } : {}),
        [field]: value,
      }
    },
    resetFilters() {
      this.filters = {
        ...(this.affiliateId ? { affiliateId: this.affiliateId } : {}),
        ...getDefaultFilters(),
      } as any
    },
    changeAll(filters: FiltersType) {
      this.filters = {
        ...(this.affiliateId ? { affiliateId: this.affiliateId } : {}),
        ...filters,
      } as any
    },
    onExport() {
      exportToCsv(
        this.rows.map((r: any) => ({
          ...r,
          affiliateEmail: `#${r.affiliateId} ${r.affiliateEmail}`,
        })),
        this.columns,
      )
    },
    copy(text: string) {
      const element = document.createElement('textarea')
      document.body.appendChild(element)
      element.value = text
      element.select()
      document.execCommand('copy')
      document.body.removeChild(element)
      showSuccessSnackBar('Скопировано')
    },
    formatMoney(value: number) {
      return this.moneyFormatter.from(
        Number(formatValue(Number(value), 2, true)),
        {
          decimals: 2,
          separator: ' ',
        },
      )
    },
  },
})
