import { formatDate } from '@/utils'
import { Currency } from '@/enums/Currency'

export default function getColumns() {
  return [
    {
      name: 'createdAt',
      label: 'Дата',
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'affiliateEmail',
      label: 'Аффилейт',
      field: 'affiliateEmail',
      align: 'left',
      sortable: true,
    },
    {
      name: 'amount',
      label: 'Переведено на баланс',
      field: 'amount',
      align: 'right',
      format: (val: number, row: any) => `${val} ${Currency[row.currency]}`,
    },
    {
      name: 'comment',
      label: 'Комментарий',
      field: 'comment',
      align: 'left',
    },
  ]
}
