export enum RevShareHold {
  EveryMonday,
  EveryTuesday,
  EveryWednesday,
  EveryThursday,
  EveryFriday,
  EveryTwoWeeksOnMonday,
  EveryTwoWeeksOnTuesday,
  EveryTwoWeeksOnWednesday,
  EveryTwoWeeksOnThursday,
  EveryTwoWeeksOnFriday,
  Every1Date,
  Every15Date,
  Every30Date,
}
