
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'PostbackNoteDialog',
  props: ['open', 'close'],
  computed: {
    showDialog(): boolean {
      return this.open
    },
  },
})
