
import { defineComponent } from 'vue'
import { getTableProperties } from '@/utils/request'
import { getBalanceChangelogColumns } from '@/pages/affiliates/getColumns'
import { request, Table, TableRequestProps } from '@/utils/request'
import axios from 'axios'
import IconWithName from '@/components/icons/IconWithName.vue'

export default defineComponent({
  name: 'BalanceChangelog',
  components: { IconWithName },
  props: ['affiliateId'],
  async created() {
    const admins = (await axios.get('/api/admins')).data
    this.admins = admins.map((admin: any) => ({
      ...admin,
      email: `#${admin.id} ` + admin.credentials.email,
    }))
    await this.onRequest({ pagination: this.pagination })
  },
  data: function() {
    return {
      ...getTableProperties('createdAt', 50),
      columns: getBalanceChangelogColumns(),
      admins: [],
    }
  },
  methods: {
    async onRequest(props: TableRequestProps) {
      await request(
        props,
        this as Table,
        `/api/balances/changelog/${this.affiliateId}`,
      )
    },
    getAdminEmail(id: number) {
      return (this.admins.find((ad: any) => ad.id === id) as any)?.credentials
        ?.email
    },
  },
})
