
import { defineComponent } from 'vue'
import { getLeadLimitChangelogColumns } from './getColumns'
import {
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import IconWithName from '@/components/icons/IconWithName.vue'
import { mapState } from 'vuex'
import { formatDate } from '@/utils'

export default defineComponent({
  name: 'LeadLimitChangelogPopup',
  components: { IconWithName },
  props: ['leadLimitRow'],
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  computed: {
    ...mapState('authModule', ['user']),
  },
  watch: {
    async filters() {
      await this.onRequest({ pagination: this.pagination })
    },
  },
  data() {
    return {
      ...getTableProperties('id', 500),
      filters: {},
      columns: getLeadLimitChangelogColumns(),
    }
  },
  methods: {
    frmtDate(val: any) {
      return formatDate(val)
    },
    async onRequest(props: TableRequestProps) {
      await request(
        props,
        this as Table,
        `/api/lead-limits/changelogs/${this.leadLimitRow.id}`,
      )
    },
  },
})
