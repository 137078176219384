
import axios from 'axios'
import { defineComponent } from 'vue'
import { confirmDialog, formatDate, showErrorMessageSnackBar } from '@/utils'
import AffiliateCommentDialog from '@/components/dialogs/AffiliateCommentDialog.vue'
import IconWithName from '@/components/icons/IconWithName.vue'

export default defineComponent({
  name: 'AffiliateCommentsDialog',
  components: { IconWithName, AffiliateCommentDialog },
  props: ['open', 'close', 'refresh', 'affiliateId', 'affComments'],
  async created() {
    if (!this.affComments) {
      this.comments = (
        await axios.get(`/api/affiliates/comments/${this.affiliateId}`)
      ).data
    }
    const admins = (await axios.get('/api/admins')).data
    this.admins = admins.reduce(
      (obj: any, key: any) => ({
        ...obj,
        [key.id]:
          JSON.parse(localStorage.getItem('user') as any)?.showStatisticsOnly ||
          JSON.parse(localStorage.getItem('user') as any)?.notFullAccess
            ? ''
            : key.credentials.email,
      }),
      {},
    )
  },
  computed: {
    showDialog(): boolean {
      return this.open
    },
  },
  data: function() {
    return {
      admins: {},
      isSaving: false,
      showCommentDialog: false,
      comments: this.affComments || [],
      errors: {},
    }
  },
  methods: {
    onClose() {
      this.isSaving = false
      this.comments = []
      this.errors = {}
      this.showCommentDialog = false
      this.$emit('close')
    },
    refreshAndClose() {
      this.$emit('close')
      this.$emit('refresh')
    },
    format(val: string) {
      return formatDate(val)
    },
    async deleteComment(id: number) {
      confirmDialog(
        'Вы действительно хотите удалить этот комментарий?',
        async () => {
          try {
            await axios.delete('/api/affiliates/comment/' + id)
            this.comments = this.comments.filter((c: any) => c.id !== id)
          } catch (e) {
            showErrorMessageSnackBar(
              'Только суперадмины могут удалять комменты',
            )
          }
        },
      )
    },
  },
})
