
import { defineComponent } from 'vue'
import GeneralTab from './tabs/GeneralTab.vue'
import ConversionsTab from './tabs/ConversionsTab.vue'
import { FiltersType, getDefaultFilters } from '@/utils/request'

export default defineComponent({
  name: 'Statistics',
  components: { GeneralTab, ConversionsTab },
  computed: {
    showBackButton(): boolean {
      return (
        'tab' in this.filters &&
        (this.filters as any).target === 'conversions' &&
        !['general'].includes(this.statsTab)
      )
    },
  },
  created() {
    if (
      Object.keys(this.$route.params).length ||
      Object.keys(this.$route.query).length
    ) {
      const paramsFilters = Object.keys(this.$route.params).length
        ? this.$route.params
        : this.$route.query
      for (const [key, value] of Object.entries(paramsFilters)) {
        if (!value || value === 'undefined' || value === 'null') {
          continue
        }
        if (
          [
            'rewardTypes',
            'affiliateIds',
            'advertiserIds',
            'geoIds',
            'offerIds',
            'managerIds',
          ].includes(key)
        ) {
          paramsFilters[key] = String(value)
            .split(',')
            .map(e => Number(e)) as any
          continue
        }
        if (Number(value) || Number(value) === 0) {
          paramsFilters[key] = (Number(value) as unknown) as string
        }
      }
      this.filters = { ...this.filters, ...paramsFilters }
      for (const [key, value] of Object.entries(this.filters)) {
        if (
          value === undefined ||
          value === null ||
          value === '' ||
          value === 'undefined' ||
          value === 'null'
        ) {
          delete (this.filters as any)[key]
        }
      }
      this.statsTab = 'conversions'
    }
  },
  data: function() {
    return {
      statsTab: 'general',
      filters: { ...getDefaultFilters(), isDetailing: false },
    }
  },
  watch: {
    statsTab(val: string) {
      if (val === 'general') {
        this.filters = { ...getDefaultFilters(), isDetailing: false }
        window.history.pushState({}, document.title, '/statistics')
      }
    },
  },
  methods: {
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }

      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    changeAll(filters: FiltersType, isDetailing = false) {
      if (isDetailing) {
        this.statsTab = 'conversions'
      }
      this.filters = { ...filters } as any
    },
    resetFilters() {
      this.filters = {
        ...getDefaultFilters(),
        groupBy: 'date',
        isDetailing: false,
      }
    },
  },
})
