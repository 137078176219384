
import getColumns from './getColumns'
import { defineComponent } from 'vue'
import { getTableProperties, request, TableRequestProps } from '@/utils/request'
import { SupportTicketStatus } from '@/enums/SupportTicketStatus'
import { confirmDialog } from '@/utils'
import axios from 'axios'
import IconWithName from '@/components/icons/IconWithName.vue'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'SupportTickets',
  components: { IconWithName },
  computed: {
    ...mapState('authModule', ['user']),
  },
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  data() {
    return {
      ...getTableProperties('createdAt'),
      filters: { status: SupportTicketStatus.WaitingForAdmin },
      campaigns: [],
      tab: SupportTicketStatus.WaitingForAdmin,
      campaignStatusOptions: [
        { label: 'Активные', value: SupportTicketStatus.WaitingForAdmin },
        { label: 'Завершенные', value: SupportTicketStatus.Resolved },
      ],
      showCreateTicketDialog: false,
      ticketStatus: SupportTicketStatus,
      columns: getColumns(),
    }
  },
  watch: {
    async filters() {
      await this.onRequest({ pagination: this.pagination })
    },
    tab() {
      this.resetFilters(this.tab)
    },
  },
  methods: {
    getTicketStatusName(val: number) {
      switch (val) {
        case SupportTicketStatus.New:
          return 'Новый'
        case SupportTicketStatus.WaitingForAdmin:
          return 'Ожидает ответа администрации'
        case SupportTicketStatus.WaitingForAffiliate:
          return 'Ожидает ответа аффилейта'
        case SupportTicketStatus.Resolved:
          return 'Завершенный'
      }
    },
    getStatusClass(status: SupportTicketStatus) {
      switch (status) {
        case SupportTicketStatus.New:
          return 'notify-success q-pa-sm bordered q-ma-auto'
        case SupportTicketStatus.WaitingForAdmin:
          return 'notify-warning q-pa-sm bordered q-ma-auto'
        case SupportTicketStatus.WaitingForAffiliate:
          return 'notify-orange q-pa-sm bordered q-ma-auto'
        case SupportTicketStatus.Resolved:
          return 'notify-grey q-pa-sm bordered q-ma-auto'
      }
    },
    onRowClick(ev: string, row: any) {
      this.$router.push('/tickets/' + row.id)
    },
    closeTicket(id: number) {
      confirmDialog(
        `Вы действительно хотите закрыть тикет #${id}?`,
        async () => {
          this.loading = true
          await axios.post(`/api/tickets/${id}/close`)
          await this.onRequest({ pagination: this.pagination })
        },
      )
    },
    async onRequest(
      props: TableRequestProps | Omit<TableRequestProps, 'pagination'>,
    ) {
      const options = { pagination: this.pagination, ...props }
      await request(options, this as any, '/api/tickets/')
    },
    onCloseDialog() {
      this.showCreateTicketDialog = false
    },
    resetFilters(status: number) {
      this.pagination.page = 1
      this.filters = {
        status,
      }
    },
  },
})
