import { GroupByOptions } from '@/utils/request'

export const groupByOptions: GroupByOptions = [
  {
    id: 'date',
    name: 'День',
    field: 'date',
  },
  {
    id: 'week',
    name: 'Неделя',
    field: 'week',
  },
  {
    id: 'month',
    name: 'Месяц',
    field: 'month',
  },
  {
    id: 'offerId',
    name: 'Оффер',
    field: 'offerName',
  },
  {
    id: 'affiliateId',
    name: 'Аффилейт',
    field: 'affiliateEmail',
  },
  {
    id: 'advertiserId',
    name: 'Рекламодатель',
    field: 'advertiserName',
  },
  {
    id: 'managerId',
    name: 'Афф менеджер',
    field: 'managerEmail',
  },
  {
    id: 'geoId',
    name: 'Гео',
    field: 'geoName',
  },
]
