<template>
  <q-layout view="hHh Lpr lff">
    <router-view />
  </q-layout>
</template>

<script>
export default {
  name: 'AdminApp',
}
</script>
