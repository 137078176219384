
import axios from 'axios'
import { defineComponent } from 'vue'
import {
  confirmDialog,
  enumToArray,
  formatDate,
  showErrorMessageSnackBar,
} from '@/utils'
import { Messengers } from '@/enums/Messengers'
import { BalanceType } from '@/enums/BalanceType'
import Balances from './Balances.vue'
import { Balance } from '@/store/types'
import { lookupGeos, lookupManagers } from '@/utils/lookups'
import { payoutCurrencies } from '@/utils'
import AffiliateCommentDialog from '@/components/dialogs/AffiliateCommentDialog.vue'
import IconWithName from '@/components/icons/IconWithName.vue'
import AddAffiliateTagsDialog from '@/components/dialogs/AddAffiliateTagsDialog.vue'
import { TdsRule } from '@/store/types'
import { Currency } from '@/enums/Currency'

export default defineComponent({
  name: 'AffiliateEdit',
  components: {
    AddAffiliateTagsDialog,
    IconWithName,
    AffiliateCommentDialog,
    Balances,
  },
  async created(): Promise<void> {
    await this.onRequest()
  },
  data: function() {
    return {
      loading: true,
      isSaving: false,
      newPassword: null,
      holdBalances: [],
      availableBalances: [],
      refHoldBalances: [],
      refAvailableBalances: [],
      currencies: payoutCurrencies,
      showCommentDialog: false,
      showAddTagsDialog: false,
      selectedMarkerId: null,
      affiliate: {
        isOurAppsTraffic: false,
        credentials: {},
        managerIds: [],
        contacts: { telegram: null },
        image: '',
        balances: [],
        referralBalances: [],
        geo: { id: null },
        installValue: {},
        tdsRules: [],
      },
      geos: [],
      admins: [],
      errors: {},
      messengers: enumToArray(Messengers),
    }
  },
  methods: {
    async onRequest() {
      this.loading = true
      this.affiliate = (
        await axios.get('/api/affiliates/affiliate/' + this.$route.params.id)
      ).data
      if (!this.affiliate.geo) {
        this.affiliate.geo = { id: null }
      }
      if ((this.affiliate as any).managers?.length) {
        this.affiliate.managerIds = (this.affiliate as any).managers.map(
          (r: any) => r.id,
        )
      }
      this.affiliate.installValue = {
        ...this.affiliate.installValue,
        currency: Currency.USD,
      }
      this.geos = (await axios.get('/api/geos')).data
      const admins = (await axios.get('/api/admins/managers')).data
      this.admins = admins.map((admin: any) => ({
        ...admin,
        email: 'ID:' + admin.id + ' — ' + admin.credentials.email,
      }))
      this.holdBalances = this.affiliate.balances.filter(
        (b: Balance) => b.type === BalanceType.Hold && !b.isReferralBalance,
      )
      this.availableBalances = this.affiliate.balances.filter(
        (b: Balance) =>
          b.type === BalanceType.Available && !b.isReferralBalance,
      )
      this.refHoldBalances = this.affiliate.referralBalances.filter(
        (b: Balance) => b.type === BalanceType.Hold,
      )
      this.refAvailableBalances = this.affiliate.referralBalances.filter(
        (b: Balance) => b.type === BalanceType.Available,
      )
      this.loading = false
    },
    saveAffiliate() {
      this.isSaving = true
      this.affiliate.balances = [
        ...this.holdBalances,
        ...this.availableBalances,
      ]
      axios
        .put('/api/affiliates/' + this.$route.params.id, {
          ...this.affiliate,
          newPassword: this.newPassword,
          installValue: {
            ...this.affiliate.installValue,
            amount: (this.affiliate.installValue as any).amount || null,
          },
        })
        .then(() => {
          this.errors = {}
          this.$router.push('/affiliates/')
        })
        .catch(({ response }) => {
          this.errors = response?.data.errors
          showErrorMessageSnackBar(
            response?.data.errors.message || 'Произошла ошибка',
          )
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    updateHoldBalances(balances: Balance[]) {
      this.holdBalances = balances as never[]
    },
    updateAvailableBalances(balances: Balance[]) {
      this.availableBalances = balances as never[]
    },
    setAvatar(image: string) {
      this.affiliate.image = image
    },
    async getGeos(value: string, update: Function) {
      await lookupGeos(this, value, update)
    },
    async getManagers(value: string, update: Function) {
      await lookupManagers(this, value, update)
    },
    async deleteComment(id: number) {
      confirmDialog(
        'Вы действительно хотите удалить этот комментарий?',
        async () => {
          try {
            await axios.delete('/api/affiliates/comment/' + id)
            await this.onRequest()
          } catch (e) {
            showErrorMessageSnackBar(
              'Только суперадмины могут удалять комменты',
            )
          }
        },
      )
    },
    format(val: string) {
      return formatDate(val)
    },
    addMarker(markerId: number) {
      this.selectedMarkerId = markerId as any
      this.showAddTagsDialog = !this.showAddTagsDialog
    },
    async deleteMarker(affiliateId: number, markerId: number, name: string) {
      confirmDialog(
        `Вы действительно хотите удалить маркер ${name}? с аффилейта ID:${affiliateId}?`,
        async () => {
          await axios.post(`/api/markers/delete-from-aff`, {
            id: markerId,
            affiliateId,
          })
          await this.onRequest()
        },
      )
    },
    onCloseDialog() {
      this.showAddTagsDialog = false
      this.selectedMarkerId = null
    },
    clearFilter(selectedRef: string) {
      if (this.$refs[selectedRef] !== void 0) {
        ;(this.$refs[selectedRef] as any).updateInputValue('')
      }
    },
    addRule() {
      this.affiliate.tdsRules.push({} as never)
    },
    deleteRule(ruleIndex: number) {
      const rules = this.affiliate.tdsRules.filter(
        (rule: TdsRule, index: number) => index !== ruleIndex,
      )
      this.affiliate.tdsRules = [...rules]
    },
    getFullNaming(rule: TdsRule, index: number) {
      if (rule.naming) {
        return rule.naming
      }
      rule.naming =
        (this.affiliate as any).id +
        '-' +
        Math.random()
          .toString(16)
          .substr(2, 8) +
        '_sub1_sub2_sub3_sub4_sub5_sub6_sub7'
      this.updateField(rule, index)
    },
    updateField(rule: TdsRule, index: number) {
      this.affiliate.tdsRules[index] = rule as never
    },
    resetErrors() {
      this.errors = {}
    },
  },
})
