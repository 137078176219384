
import axios from 'axios'
import { defineComponent } from 'vue'
import { getTableProperties } from '@/utils/request'
import { getReferralsColumns } from '@/pages/affiliates/getColumns'
import { request, Table, TableRequestProps } from '@/utils/request'
import IconWithName from '@/components/icons/IconWithName.vue'
import {
  confirmDialog,
  showErrorMessageSnackBar,
  showSuccessSnackBar,
} from '@/utils'
import { Currency } from '@/enums/Currency'
import AddReferalDialog from '@/components/dialogs/AddReferalDialog.vue'
import { formatValue } from '@/utils'

export default defineComponent({
  name: 'Referrals',
  props: ['affiliateId', 'individual', 'onOff'],
  components: { AddReferalDialog, IconWithName },
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  data: function() {
    return {
      ...getTableProperties('createdAt', 50),
      columns: getReferralsColumns(),
      individualReferralPercent: this.individual || null,
      referralProgramOn: this.onOff || false,
      showReferralsDialog: false,
      showAddReferralDialog: false,
      currencies: Currency,
    }
  },
  watch: {
    individualReferralPercent() {
      this.rows = this.rows.map((r: any) => ({
        ...r,
        broughtByReferralPercent: this.individualReferralPercent,
      })) as never[]
    },
    referralProgramOn() {
      this.rows = this.rows.map((r: any) => ({
        ...r,
        broughtByReferralProgramOn: this.referralProgramOn,
      })) as never[]
    },
  },
  methods: {
    frmt(val: number) {
      return formatValue(val)
    },
    async deleteReferral(referralId: number): Promise<void> {
      confirmDialog(
        'Вы действительно хотите удалить данного веба из рефералов?',
        async () => {
          await axios.delete(`/api/referrals/?id=${referralId}`)
          await this.onRequest({ pagination: this.pagination })
        },
      )
    },
    async onRequest(props: TableRequestProps) {
      const options = props || { pagination: this.pagination }
      await request(
        options,
        this as Table,
        `/api/referrals/${this.affiliateId}`,
      )
    },
    onCloseDialog() {
      this.showReferralsDialog = false
      this.showAddReferralDialog = false
    },
    async save() {
      try {
        this.loading = true
        await axios.post(`/api/referrals/save/${this.affiliateId}`, {
          referralProgramOn: this.referralProgramOn,
          individualReferralPercent: this.individualReferralPercent || null,
          rows: this.rows,
        })
        await this.onRequest({ pagination: this.pagination })
        showSuccessSnackBar('Изменения сохранены')
      } catch (e) {
        showErrorMessageSnackBar('Упс! Произошла ошибка :(')
      } finally {
        this.loading = false
      }
    },
  },
})
