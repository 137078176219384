import { formatDate } from '@/utils'

export function getColumns() {
  return [
    {
      name: 'createdAt',
      label: 'Дата',
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'affiliateEmail',
      label: 'Аффилейт',
      field: 'affiliateEmail',
      align: 'left',
      sortable: true,
    },
    {
      name: 'offerName',
      label: 'Оффер',
      field: 'offerName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'managers',
      label: 'Аффилейт менеджер',
      field: 'managers',
      align: 'left',
    },
    { name: 'actions', label: 'Действия', field: 'actions', align: 'center' },
  ]
}

export function getLogColumns() {
  return [
    {
      name: 'affiliateRequestDate',
      label: 'Дата запроса аффилейта',
      field: 'affiliateRequestDate',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'createdAt',
      label: 'Дата действия',
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'affiliateEmail',
      label: 'Аффилейт',
      field: 'affiliateEmail',
      align: 'left',
      sortable: true,
    },
    {
      name: 'offerName',
      label: 'Оффер',
      field: 'offerName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'managerEmail',
      label: 'Аффилейт менеджер',
      field: 'managerEmail',
      align: 'left',
    },
    {
      name: 'status',
      label: 'Статус',
      field: 'status',
      align: 'center',
      sortable: true,
    },
    {
      name: 'updatedByEmail',
      label: 'Кем изменено',
      field: 'updatedByEmail',
      align: 'left',
    },
  ]
}
