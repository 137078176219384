import { formatValue, isDefined, RowValue } from '@/utils'

export default function getColumns(groupBy: string) {
  const arr: any = [
    {
      name: 'groupByField',
      label: 'Дата',
      fullLabel: 'Дата',
      field: 'groupByField',
      align: 'left',
      sortable: true,
    },
    {
      name: 'registrations',
      label: 'Регистрации',
      fullLabel: 'Регистрации',
      field: 'registrations',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'conversions',
      label: 'Conv Count',
      fullLabel: 'Conv Count',
      field: 'conversions',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'paySum',
      label: 'PAY SUM',
      fullLabel: 'PAY SUM',
      field: 'paySum',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'usdApprovedSum',
      label: 'Pay USD SUM',
      fullLabel: 'Pay USD SUM',
      field: 'usdPaySum',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'eurPaySum',
      label: 'Pay EUR SUM',
      fullLabel: 'Pay EUR SUM',
      field: 'eurPaySum',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'uahPaySum',
      label: 'Pay UAH SUM',
      fullLabel: 'Pay UAH SUM',
      field: 'uahPaySum',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
  ]

  if (groupBy === 'date' || groupBy === 'week' || groupBy === 'month') {
    arr.push({
      name: 'partners',
      label: groupBy === 'date' ? 'DAU' : groupBy === 'month' ? 'MAU' : 'WAU',
      fullLabel:
        groupBy === 'date' ? 'DAU' : groupBy === 'month' ? 'MAU' : 'WAU',
      field: 'partners',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => isDefined(val),
    })
  }

  arr.push(
    {
      name: 'approvedSum',
      label: 'Approve SUM',
      fullLabel: 'Approve SUM',
      field: 'approvedSum',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'usdApprovedSum',
      label: 'Approve USD SUM',
      fullLabel: 'Approve USD SUM',
      field: 'usdApprovedSum',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'eurApprovedSum',
      label: 'Approve EUR SUM',
      fullLabel: 'Approve EUR SUM',
      field: 'eurApprovedSum',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'uahApprovedSum',
      label: 'Approve UAH SUM',
      fullLabel: 'Approve UAH SUM',
      field: 'uahApprovedSum',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'holdSum',
      label: 'Hold SUM',
      fullLabel: 'Hold SUM',
      field: 'holdSum',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'usdHoldSum',
      label: 'Hold USD SUM',
      fullLabel: 'Hold USD SUM',
      field: 'usdHoldSum',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'eurHoldSum',
      label: 'Hold EUR SUM',
      fullLabel: 'Hold EUR SUM',
      field: 'eurHoldSum',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'uahHoldSum',
      label: 'Hold UAH SUM',
      fullLabel: 'Hold UAH SUM',
      field: 'uahHoldSum',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'rejectedSum',
      label: 'Reject SUM',
      fullLabel: 'Reject SUM',
      field: 'rejectedSum',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'revenue',
      label: 'Profit SUM',
      fullLabel: 'Profit SUM',
      field: 'revenue',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'usdRevenue',
      label: 'Profit USD SUM',
      fullLabel: 'Profit USD SUM',
      field: 'usdRevenue',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'eurRevenue',
      label: 'Profit EUR SUM',
      fullLabel: 'Profit EUR SUM',
      field: 'eurRevenue',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'uahRevenue',
      label: 'Profit UAH SUM',
      fullLabel: 'Profit UAH SUM',
      field: 'uahRevenue',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'revenueHold',
      label: 'Profit SUM HOLD',
      fullLabel: 'Profit SUM HOLD',
      field: 'revenueHold',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'usdRevenueHold',
      label: 'Profit USD SUM HOLD',
      fullLabel: 'Profit USD SUM HOLD',
      field: 'usdRevenueHold',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'eurRevenueHold',
      label: 'Profit EUR SUM HOLD',
      fullLabel: 'Profit EUR SUM HOLD',
      field: 'eurRevenueHold',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'uahRevenueHold',
      label: 'Profit UAH SUM HOLD',
      fullLabel: 'Profit UAH SUM HOLD',
      field: 'uahRevenueHold',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'revenuePercent',
      label: 'Profit, %',
      fullLabel: 'Profit, %',
      field: 'revenuePercent',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'advHold',
      label: 'Adv Hold',
      fullLabel: 'Adv Hold',
      field: 'advHold',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'advApprove',
      label: 'Adv Approve',
      fullLabel: 'Adv Approve',
      field: 'advApprove',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'revenueTotal',
      label: 'Revenue Total',
      fullLabel: 'Revenue Total',
      field: 'revenueTotal',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
  )
  return arr
}
