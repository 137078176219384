
import { defineComponent } from 'vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import { getFiltersData, initFilterOptions } from '@/utils/appsFilters'
import {
  lookupAffiliates,
  lookupByField,
  FieldOption,
  lookupTrafficSourcesForApps,
} from '@/utils/lookups'
import { getDefaultFilters } from '@/utils/request'

export default defineComponent({
  name: 'FiltersDialog',
  components: { DatePicker },
  props: ['open', 'close', 'reset', 'currentFilters', 'changeFilters', 'tab'],
  async created() {
    await initFilterOptions(this)
  },
  computed: {
    showDialog(): boolean {
      return this.open
    },
    hideForAffiliates(): boolean {
      return !this.$route.path.includes('affiliates')
    },
    hideForStatistics(): boolean {
      return !this.$route.path.includes('statistics')
    },
    hideForBalances(): boolean {
      return !this.$route.path.includes('balances')
    },
    hideForPostbackLogs(): boolean {
      return !this.$route.path.includes('postback-logs')
    },
    hideForPushNotifications(): boolean {
      return !this.$route.path.includes('push-notifications')
    },
    showForPushGroups(): boolean {
      return (
        this.$route.path.includes('push-notifications') &&
        this.tab === 'push-groups'
      )
    },
    showForPostbackBlackListTab(): boolean {
      return (
        !this.$route.path.includes('postback-logs') ||
        (this.$route.path.includes('postback-logs') &&
          this.tab === 'black-list-logs') ||
        (this.$route.path.includes('postback-logs') && this.tab === 'installs')
      )
    },
    hideForMobileAppsPage(): boolean {
      return !this.$route.path.includes('mobile-apps')
    },
  },
  data: function() {
    return {
      filters: { ...this.currentFilters },
      ...getFiltersData(this.currentFilters.name),
    }
  },
  watch: {
    search() {
      this.onChangeFilters('name', this.search)
    },
    currentFilters() {
      this.filters = { ...this.currentFilters }
      this.search = this.currentFilters.name
    },
  },
  methods: {
    onChangeDate(date: { startDate: string; endDate: string }) {
      this.filters.dateFrom = date.startDate
      this.filters.dateTo = date.endDate
    },
    addFilters() {
      this.$emit('changeFilters', this.filters)
      this.$emit('close')
    },
    resetFilters() {
      this.search = null
      this.filters = getDefaultFilters()
      this.$emit('changeFilters', this.filters)
    },
    onChangeFilters(field: string, value: string | null) {
      if (field === 'vertical') {
        this.filters.offerCategoryId = null
      }
      this.filters[field] = value
    },
    async getOptionsByField(
      value: string,
      update: Function,
      field: FieldOption,
    ) {
      await lookupByField(this, value, update, field)
    },
    async getAffiliates(value: string, update: Function) {
      await lookupAffiliates(this, value, update)
    },
    async getTrafficSources(value: string, update: Function) {
      await lookupTrafficSourcesForApps(this, value, update)
    },
  },
})
