
import { defineComponent } from 'vue'
import { mapMutations, mapState } from 'vuex'
import { Promo, TrafficSource } from '@/store/types'
import { MarkupType } from '@/enums/MarkupType'
import { SemanticType } from '@/enums/SemanticType'

export default defineComponent({
  name: 'Promos',
  props: ['defaultOptions'],
  computed: {
    ...mapState('campaignsModule', ['campaign', 'errors']),
    landings: function(): Promo[] {
      return (
        this.defaultOptions.promos?.filter(
          (promo: Promo) =>
            promo.geos.find(g => g.id == this.campaign.geoId) &&
            (promo.trafficSources?.length
              ? promo.trafficSources.find(
                  (s: TrafficSource) => s.id === this.campaign.trafficSourceId,
                )
              : true) &&
            promo.semanticType === SemanticType.Landing,
        ) || []
      )
    },
    preLandings(): Promo[] {
      return (
        this.defaultOptions.promos?.filter(
          (promo: Promo) =>
            promo.geos.find(g => g.id == this.campaign.geoId) &&
            (promo.trafficSources?.length
              ? promo.trafficSources.find(
                  (s: TrafficSource) => s.id === this.campaign.trafficSourceId,
                )
              : true) &&
            promo.semanticType === SemanticType.Prelanding,
        ) || []
      )
    },
  },
  data: function() {
    return {
      markupTypes: MarkupType,
    }
  },
  methods: {
    ...mapMutations('campaignsModule', ['update']),
    updatePromos(targetPromo: Promo) {
      this.defaultOptions.promos?.forEach((p: Promo) => {
        if (
          p.id !== targetPromo.id &&
          p.semanticType === targetPromo.semanticType
        ) {
          p.isActive = false
        }
      })

      const activeIds = this.defaultOptions.promos
        ?.filter((p: Promo) => p.isActive)
        .map((p: Promo) => p.id)

      this.campaign.targetPromoIds = activeIds
      this.update({
        targetPromoIds: activeIds,
      })
    },
    landingRedirect(url: string) {
      window.open(url)
    },
  },
})
