
import { defineComponent } from 'vue'
import DatePicker from '../date-picker/DatePicker.vue'
import {
  FieldOption,
  lookupAdvertisers,
  lookupAffiliates,
  lookupByField,
  lookupCampaigns,
  lookupGeos,
  lookupManagers,
  lookupAdvertiserManagers,
  lookupMarkers,
  lookupOfferCategories,
  lookupOffers,
  lookupPresets,
  lookupTrafficSourceNames,
} from '@/utils/lookups'
import {
  getFiltersData,
  getIncludeOptions,
  initFilterOptions,
  initOptionsByRouteParams,
} from '@/utils/filters'
import FiltersDialog from '../dialogs/FiltersDialog.vue'
import { FiltersType, getPrevPeriods } from '@/utils/request'
import SavePresetDialog from '@/components/dialogs/SavePresetDialog.vue'
import axios from 'axios'
import { groupByOptions as financesGroupBy } from '@/pages/finances/config/groupByOptions'
import {
  groupByOptions as statsGroupBy,
  groupByOptions2 as statsGroupBy2,
  groupByReferralOptions,
  groupByReferralOptions2,
  groupByReferralOptions3,
} from '@/pages/statistics2/config/groupByOptions'
import {enumToArray, confirmDialog, showInfoSnackBar} from '@/utils'
import { PayoutStatus } from '@/enums/PayoutStatus'
import IconWithName from '@/components/icons/IconWithName.vue'
import { mapState } from 'vuex'
import { omit } from 'lodash'

export default defineComponent({
  name: 'Filters',
  components: { IconWithName, SavePresetDialog, DatePicker, FiltersDialog },
  props: ['reset', 'currentFilters', 'change', 'tab', 'export', 'exportAll'],
  async created() {
    await initOptionsByRouteParams(this)
    if (
      !this.$route.path.includes('statistics') ||
      this.tab === 'conversions'
    ) {
      await initFilterOptions(this)
    }
    // фильтры с линки не применяются для офферов и аффилейтов из за конфликта с localStorage фильтрами
    if (
      Object.keys(this.$route.query || {}).length &&
      !this.$route.path.includes('offers') &&
      !this.$route.path.includes('affiliates')
    ) {
      const opts: any = { ...this.$route.query }
      for (const [key, value] of Object.entries(opts)) {
        if (value === 'false') {
          opts[key] = false
        }
        if (value === 'true') {
          opts[key] = true
        }
      }
      this.filters = {
        ...this.filters,
        ...opts,
      }
    }
  },
  computed: {
    ...mapState('authModule', ['user']),
    groupByOptions(): any {
      return this.$route.path.includes('finances')
        ? financesGroupBy
        : this.tab === 'referral-stats'
        ? groupByReferralOptions
        : statsGroupBy
    },
    groupByOptions2(): any {
      return this.$route.path.includes('finances')
        ? financesGroupBy
        : this.tab === 'referral-stats'
        ? groupByReferralOptions2
        : statsGroupBy2
    },
    groupByOptions3(): any {
      return this.tab === 'referral-stats'
        ? groupByReferralOptions3
        : statsGroupBy2
    },
    hideForConversions(): boolean {
      return this.tab !== 'conversions'
    },
    hideForClickLogs(): boolean {
      return this.tab !== 'clicks'
    },
    hideForOffers(): boolean {
      return !this.$route.path.includes('offers')
    },
    hideForAffiliates(): boolean {
      return !this.$route.path.includes('affiliates')
    },
    hideForCampaigns(): boolean {
      return !this.$route.path.includes('campaigns')
    },
    hideForAdvertisers(): boolean {
      return !this.$route.path.includes('advertisers')
    },
    hideForPayouts(): boolean {
      return !this.$route.path.includes('payouts')
    },
    hideForFinances(): boolean {
      return !this.$route.path.includes('finances')
    },
    hideForStatistics(): boolean {
      return !this.$route.path.includes('statistics')
    },
    hideForLogs(): boolean {
      return !this.$route.path.includes('logs')
    },
  },
  data: function() {
    return {
      expLoading: false,
      expAllLoading: false,
      showFiltersDialog: false,
      showPresetDialog: false,
      presetId: this.currentFilters.presetId || null,
      filters: { ...this.currentFilters },
      payoutStatuses: enumToArray(PayoutStatus),
      ...getFiltersData(),
    }
  },
  watch: {
    presetId() {
      if (!this.presetId) {
        return
      }
      const presetFilters =
        (this.presets.find((p: any) => p.id === this.presetId) as any)
          ?.filters || {}
      this.filters = {
        dateFrom: this.filters.dateFrom,
        dateTo: this.filters.dateTo,
        ...presetFilters,
      }
      const includedOpts = getIncludeOptions()
      for (const [key, value] of Object.entries(includedOpts)) {
        if (value !== false && presetFilters[key]) {
          delete presetFilters[key]
        }
      }
      this.$router.push({
        path: this.$route.fullPath?.replace('/', ''),
        query: omit(presetFilters, ['pagination']),
      })
      this.filters.presetId = this.presetId
    },
    search() {
      this.onChangeFilters('name', this.search)
    },
    conversionAmount() {
      this.onChangeFilters('conversionAmount', this.conversionAmount)
    },
    conversionAdvAmount() {
      this.onChangeFilters('conversionAdvAmount', this.conversionAdvAmount)
    },
    redirectEventIdSearch() {
      this.onChangeFilters('redirectEventIds', this.redirectEventIdSearch)
    },
    currentFilters() {
      this.filters = { ...this.currentFilters }
      this.search = this.currentFilters.name
      this.redirectEventIdSearch = this.currentFilters.redirectEventIds
      this.conversionAmount = this.currentFilters.conversionAmount
      this.conversionAdvAmount = this.currentFilters.conversionAdvAmount
    },
  },
  methods: {
    exportAllFn() {
      showInfoSnackBar()
      this.$emit('exportAll')
      this.expAllLoading = true
      setTimeout(() => {
        this.expAllLoading = false
      }, 5000); // 5 секунд
    },
    startLoading() {
      this.$emit('export')
      this.expLoading = true
      setTimeout(() => {
        this.expLoading = false
      }, 5000); // 5 секунд
    },
    async deletePreset(presetId: number) {
      await axios.delete('/api/presets', { params: { id: presetId } })
    },
    async deleteMarker(markerId: number) {
      confirmDialog(
        `Вы действительно хотите удалить маркет ID:${markerId}?`,
        async () => {
          await axios.delete('/api/markers', { params: { id: markerId } })
          this.submitAll()
        },
      )
    },
    async onChangeFilters(field: string, value: string | null) {
      this.clearFilter(field)
      if (field === 'vertical') {
        this.filters.offerCategoryId = null
      }
      this.presetId = null
      this.filters[field] = value
      await this.addFiltersToQuery(field, value)
    },
    async resetFilters() {
      this.search = null
      this.redirectEventIdSearch = null
      this.conversionAmount = null
      this.conversionAdvAmount = null
      this.presetId = null
      await this.$router.push({
        path: this.$route.fullPath?.replace('/', ''),
        query: {},
      })
      this.$emit('reset')
    },
    async getOptionsByField(
      value: string,
      update: Function,
      field: FieldOption,
    ) {
      await lookupByField(this, value, update, field)
    },
    async getAdvertisers(value: string, update: Function) {
      await lookupAdvertisers(this, value, update)
    },
    async getMarkers(value: string, update: Function) {
      await lookupMarkers(this, value, update)
    },
    async getTrafficSources(value: string, update: Function) {
      await lookupTrafficSourceNames(this, value, update)
    },
    async getGeos(value: string, update: Function) {
      await lookupGeos(this, value, update)
    },
    async getOffers(value: string, update: Function) {
      await lookupOffers(this, value, update)
    },
    async getManagers(value: string, update: Function) {
      await lookupManagers(this, value, update, true)
    },
    async getAdvertiserManagers(value: string, update: Function) {
      await lookupAdvertiserManagers(this, value, update, false)
    },
    async getPresets(value: string, update: Function) {
      await lookupPresets(this, value, update)
    },
    async getCategories(value: string, update: Function) {
      await lookupOfferCategories(this, value, update, [], {
        vertical: this.filters.vertical,
      })
    },
    async getAffiliates(value: string, update: Function, extraParams = {}) {
      await lookupAffiliates(this, value, update, extraParams)
    },
    async getCampaigns(value: string, update: Function) {
      await lookupCampaigns(this, value, update)
    },
    async onChangeDate(date: { startDate: string; endDate: string }) {
      this.filters.dateFrom = date.startDate
      this.filters.dateTo = date.endDate
      const { prevDateFrom, prevDateTo } = getPrevPeriods(
        this.filters.dateFrom,
        this.filters.dateTo,
      )
      this.filters.prevDateFrom = prevDateFrom
      this.filters.prevDateTo = prevDateTo

      await this.addFiltersToQuery('dateFrom', this.filters.dateFrom)
      await this.addFiltersToQuery('dateTo', this.filters.dateTo)
      await this.addFiltersToQuery('prevDateFrom', this.filters.prevDateFrom)
      await this.addFiltersToQuery('prevDateTo', this.filters.prevDateTo)
    },
    async onChangePrevDate(date: { startDate: string; endDate: string }) {
      this.filters.prevDateFrom = date.startDate
      this.filters.prevDateTo = date.endDate

      await this.addFiltersToQuery('prevDateFrom', this.filters.prevDateFrom)
      await this.addFiltersToQuery('prevDateTo', this.filters.prevDateTo)
    },
    async onChangeConversionDate(date: { startDate: string; endDate: string }) {
      this.filters.conversionDateFrom = date.startDate
      this.filters.conversionDateTo = date.endDate

      await this.addFiltersToQuery(
        'conversionDateFrom',
        this.filters.conversionDateFrom,
      )
      await this.addFiltersToQuery(
        'conversionDateTo',
        this.filters.conversionDateTo,
      )
    },
    async addFiltersToQuery(field: string, value: any) {
      if (value === null || value === undefined) {
        await this.$router.push({
          path: this.$route.fullPath?.replace('/', ''),
          query: {
            ...omit(this.$route.query, field),
          },
        })
      } else {
        await this.$router.push({
          path: this.$route.fullPath?.replace('/', ''),
          query: {
            ...this.$route.query,
            [field]: value,
          },
        })
      }
    },
    submitAll() {
      if (this.presetId) {
        this.filters = {
          ...this.filters,
          ...(this.presets.find((p: any) => p.id === this.presetId) as any)
            .filters,
        }
      }
      this.$emit('changeAll', this.filters)
    },
    submitMobileAll(filters: FiltersType) {
      this.$emit('changeAll', filters)
    },
    async getOfferCategories(value: string, update: Function) {
      await lookupOfferCategories(this, value, update)
    },
    clearFilter(selectedRef: string) {
      if (this.$refs[selectedRef] !== void 0) {
        ;(this.$refs[selectedRef] as any).updateInputValue('')
      }
    },
  },
})
