import { formatValue, isDefined, RowValue } from '@/utils'

export default function getColumns() {
  return [
    {
      name: 'groupByField',
      label: 'Дата',
      fullLabel: 'Дата',
      field: 'groupByField',
      align: 'left',
      sortable: true,
    },
    {
      name: 'installs',
      label: 'Инсталлы',
      fullLabel: 'Инсталлы',
      field: 'installs',
      align: 'right',
      classes: 'monospaced',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'registrationCount',
      label: 'Количество, шт',
      fullLabel: 'Регистрации',
      field: 'registrationCount',
      align: 'right',
      classes: 'monospaced',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'firstDepositCount',
      label: 'Количество, шт',
      fullLabel: 'Депозиты',
      field: 'firstDepositCount',
      align: 'right',
      classes: 'monospaced',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'firstDepositSum',
      label: 'Выплата, $',
      fullLabel: 'Выплата, $',
      field: 'firstDepositSum',
      align: 'right',
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'firstDepositSumEur',
      label: 'Выплата, €',
      fullLabel: 'Выплата, €',
      field: 'firstDepositSumEur',
      align: 'right',
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'crReg',
      label: 'CR Reg',
      fullLabel: 'CR Reg',
      field: 'crReg',
      align: 'right',
      classes: 'monospaced',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'crFtd',
      label: 'CR FTD',
      fullLabel: 'CR FTD',
      field: 'crFtd',
      align: 'right',
      classes: 'monospaced',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'crFtdReg',
      label: 'CR FTD / Reg',
      fullLabel: 'CR FTD / Reg',
      field: 'crFtdReg',
      align: 'right',
      classes: 'monospaced',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'installSpend',
      label: 'Расход за инсталлы, $',
      fullLabel: 'Расход за инсталлы, $',
      field: 'installSpend',
      align: 'right',
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val),
    },
  ]
}
