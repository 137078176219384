import { formatDate } from '@/utils'
import { Row } from '@/utils/request'

export default function getColumns() {
  return [
    { name: 'id', label: 'ID', field: 'id', align: 'center' },
    {
      name: 'credentials',
      label: 'Email',
      field: 'credentials',
      align: 'left',
      format: (val: Row) => val?.email,
    },
    {
      name: 'createdAt',
      label: 'Дата добавления',
      field: 'createdAt',
      align: 'left',
      format: (val: string) => formatDate(val),
    },
    {
      name: 'contacts',
      label: 'Телеграм',
      field: 'contacts',
      align: 'left',
      format: (val: Row) => (val?.telegram ? '@' + val?.telegram : '—'),
    },
    {
      name: 'externalId',
      label: 'Ключ к сервисному Telegram боту',
      field: 'externalId',
      align: 'left',
    },
    {
      name: 'isTdsAdmin',
      label: 'Тип',
      field: 'isTdsAdmin',
      align: 'left',
    },
    { name: 'actions', label: '', field: 'actions', align: 'center' },
  ]
}
