import { createWebHistory, createRouter } from 'vue-router'
import routes from './routes'
import store from '@/store'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = store.state.authModule.status?.loggedIn

  if (authRequired && !loggedIn) {
    return next('/login')
  }

  if (
    JSON.parse(localStorage.getItem('user') as any)?.isAppsAdmin &&
    !to.path.includes('apps')
  ) {
    next('/mobile-apps')
  }

  if (
    JSON.parse(localStorage.getItem('user') as any)?.showStatisticsOnly &&
    !to.path.includes('statistics')
  ) {
    next('/statistics')
  }

  if (
    JSON.parse(localStorage.getItem('user') as any)?.notFullAccess &&
    (to.path.includes('admins') ||
      to.path.includes('affiliates') ||
      to.path.includes('payouts'))
  ) {
    next('/statistics')
  }

  if (
    JSON.parse(localStorage.getItem('user') as any)?.isLimitedAccess &&
    (to.path.includes('admins') ||
      to.path.includes('apps'))
  ) {
    next('/statistics')
  }

  if (
    JSON.parse(localStorage.getItem('user') as any)?.isLightLimitedAccess &&
    (to.path.includes('admins') ||
      to.path.includes('apps'))
  ) {
    next('/statistics')
  }

  next()
})

export default router
