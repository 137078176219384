
import { defineComponent } from 'vue'
import {
  getDefaultFilters,
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import getColumns from './getColumns'
import RefillBalanceDialog from '@/components/dialogs/RefillBalanceDialog.vue'
import { FiltersType } from '@/utils/request'

export default defineComponent({
  name: 'Transactions',
  components: { RefillBalanceDialog },
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  data: function() {
    return {
      ...getTableProperties('createdAt'),
      filters: { ...getDefaultFilters() },
      columns: getColumns(),
      showRefillBalanceDialog: false,
    }
  },
  methods: {
    async onRequest(
      props: TableRequestProps | Omit<TableRequestProps, 'pagination'>,
    ) {
      const options = { pagination: this.pagination, ...props }
      await request(options, this as Table, '/api/balances/')
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    resetFilters() {
      this.filters = { ...getDefaultFilters() }
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    openCampaignDialog() {
      this.showRefillBalanceDialog = true
    },
    onCloseDialog() {
      this.showRefillBalanceDialog = false
    },
  },
})
