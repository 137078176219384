
import { defineComponent } from 'vue'
import { enumToArray } from '@/utils'
import { Currency } from '@/enums/Currency'
import { Balance } from '@/store/types'

export default defineComponent({
  name: 'Balances',
  props: ['balances', 'update'],
  data: function() {
    return {
      currentBalances: [...this.balances],
      currencies: enumToArray(Currency),
    }
  },
  methods: {
    changeBalance(position: number, operation: 'increase' | 'decrease') {
      this.currentBalances = this.currentBalances.map(
        (balance: Balance, index: number) => {
          if (position === index) {
            return {
              ...balance,
              value: {
                ...balance.value,
                amount:
                  operation === 'increase'
                    ? ++(balance.value.amount as any)
                    : --(balance.value.amount as any),
              },
            }
          }
          return balance
        },
      )
      this.$emit('update', this.currentBalances)
    },
  },
})
