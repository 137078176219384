
import { defineComponent } from 'vue'
import GeneralTab from './tabs/GeneralTab.vue'
import ConversionsTab from './tabs/ConversionsTab.vue'
import { FiltersType, getDefaultFilters } from '@/utils/request'
import { formatFiltersFromQuery } from '@/utils/filters'
import ReferralsTab from '@/pages/statistics2/tabs/ReferralsTab.vue'

export default defineComponent({
  name: 'Statistics',
  components: { ReferralsTab, GeneralTab, ConversionsTab },
  computed: {
    showBackButton(): boolean {
      return (
        'tab' in this.filters &&
        (this.filters as any).target === 'conversions' &&
        !['general'].includes(this.statsTab)
      )
    },
  },
  created() {
    formatFiltersFromQuery(this)
  },
  data: function() {
    return {
      statsTab: 'general',
      filters: { ...getDefaultFilters(), isDetailing: false },
    }
  },
  watch: {
    statsTab(val: string, prevVal: string) {
      if (prevVal === 'referrals') {
        this.$router.replace(`/statistics?target=${val}`)
      }
      if (val === 'general') {
        this.filters = { ...getDefaultFilters(), isDetailing: false }
        window.history.pushState({}, document.title, '/statistics')
      }
      if (val === 'consumers') {
        this.filters = { ...getDefaultFilters(), isDetailing: false }
        window.history.pushState(
          {},
          document.title,
          '/statistics?target=consumers',
        )
      }
      if (val === 'referrals') {
        this.filters = {
          ...getDefaultFilters(),
          groupBy: 'id',
          groupBy2: 'affiliateId',
          groupBy3: 'offerId',
          isDetailing: false,
        } as any
        window.history.pushState(
          {},
          document.title,
          `/statistics?target=referrals`,
        )
      }
      if (
        val === 'general' &&
        [undefined, null].includes(this.filters.factualStats as any)
      ) {
        this.resetFilters()
      }
      if (val === 'conversions') {
        this.$router.push({
          path: this.$route.fullPath?.replace('/', ''),
          query: {
            ...(this.$route.query || {}),
            target: 'conversions',
          },
        })
      }
    },
  },
  methods: {
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }

      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    changeAll(filters: FiltersType, isDetailing = false) {
      if (isDetailing) {
        this.statsTab = 'conversions'
      }
      this.filters = { ...filters } as any
    },
    resetFilters() {
      if (this.statsTab === 'referrals') {
        this.filters = {
          ...getDefaultFilters(),
          groupBy: 'id',
          groupBy2: 'affiliateId',
          groupBy3: 'offerId',
          isDetailing: false,
        } as any
      } else {
        this.filters = {
          ...getDefaultFilters(),
          groupBy: 'date',
          isDetailing: false,
        }
      }
      this.$router.push({
        path: this.$route.fullPath?.replace('/', ''),
        query: {},
      })
    },
  },
})
