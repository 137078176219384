import { isDefined, RowValue } from '@/utils'
import { OfferStatus } from '@/enums/OfferStatus'
import { Vertical } from '@/enums/Vertical'

export default function getColumns() {
  return [
    { name: 'id', label: 'ID', field: 'id', align: 'center', sortable: true },
    {
      name: 'name',
      label: 'Название',
      field: 'name',
      align: 'left',
      sortable: true,
    },
    {
      name: 'advertiserManagerId',
      label: 'Менеджер',
      field: 'advertiserManagerId',
      align: 'left',
    },
    {
      name: 'clicks',
      label: 'Clicks',
      field: 'clicks',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
    },
    {
      name: 'clicksDifference',
      label: 'Δ Clicks',
      field: 'clicksDifference',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
    },
    {
      name: 'registrationCount',
      label: 'Regs',
      field: 'registrationCount',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
    },
    {
      name: 'regsDifference',
      label: 'Δ Regs',
      field: 'regsDifference',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
    },
    {
      name: 'firstDepositCount',
      label: 'FTD',
      field: 'firstDepositCount',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
    },
    {
      name: 'ftdDifference',
      label: 'Δ FTD',
      field: 'ftdDifference',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
    },
    {
      name: 'advertiserName',
      label: 'Рекламодатель',
      field: 'advertiserName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'status',
      label: 'Статус',
      field: 'status',
      align: 'center',
      format: (val: number) => OfferStatus[val],
      sortable: true,
    },
    {
      name: 'vertical',
      label: 'Вертикаль',
      field: 'vertical',
      align: 'left',
      format: (val: number, row: any) => {
        if (row.categories?.length) {
          const categories = row.categories.map((c: any) => c.name)
          return Vertical[val] + ' (' + categories.join(', ') + ')'
        }
        return Vertical[val]
      },
    },
    {
      name: 'rewardType',
      label: 'Тип конверсии',
      field: 'rewardType',
      align: 'center',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'trafficSources',
      label: 'Источники',
      field: 'trafficSources',
      align: 'left',
    },
    {
      name: 'rewards',
      label: 'Ставка вебу',
      field: 'rewards',
      align: 'left',
    },
    {
      name: 'advertiserRewards',
      label: 'Ставка рекла',
      field: 'advertiserRewards',
      align: 'left',
    },
    {
      name: 'comment',
      label: 'Комментарий',
      field: 'comment',
      align: 'center',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'affiliates',
      label: 'Аффилейты',
      field: 'affiliates',
      align: 'center',
      classes: 'monospaced',
    },
    {
      name: 'campaignsCount',
      label: 'Потоки',
      field: 'campaignsCount',
      align: 'right',
      classes: 'monospaced',
    },
    {
      name: 'landingsCount',
      label: 'Лендинги',
      field: 'landingsCount',
      align: 'right',
      classes: 'monospaced',
    },
    { name: 'actions', label: 'Действия', field: 'actions', align: 'center' },
  ]
}
