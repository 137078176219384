import { GroupByOptions } from '@/utils/request'

export const groupByOptions: GroupByOptions = [
  {
    id: 'date',
    name: 'Дата',
    field: 'date',
  },
  {
    id: 'mobileAppId',
    name: 'Приложение',
    field: 'mobileAppName',
  },
  {
    id: 'geoId',
    name: 'Гео',
    field: 'geoName',
  },
  {
    id: 'affiliateId',
    name: 'Аффилейт',
    field: 'affiliateEmail',
  },
  {
    id: 'trafficSourceId',
    name: 'Источник',
    field: 'trafficSourceName',
  },
]
