import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_CampaignsList = _resolveComponent("CampaignsList")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_LeadLimitsList = _resolveComponent("LeadLimitsList")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-ml-xl q-my-xl" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_tabs, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
        "inline-label": "",
        breakpoint: 0,
        align: "justify",
        "no-caps": "",
        class: "shadow-2 q-mb-md q-ml-lg q-mt-xl",
        style: {"max-width":"500px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab, {
            name: "campaigns",
            label: "Потоки"
          }),
          _createVNode(_component_q_tab, {
            name: "limits",
            label: "Капы"
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_q_tab_panels, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event)),
        animated: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab_panel, { name: "campaigns" }, {
            default: _withCtx(() => [
              _createVNode(_component_CampaignsList, { "affiliate-id": _ctx.affiliateId }, null, 8, ["affiliate-id"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panel, { name: "limits" }, {
            default: _withCtx(() => [
              _createVNode(_component_LeadLimitsList, { "affiliate-id": _ctx.affiliateId }, null, 8, ["affiliate-id"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}