
import { defineComponent } from 'vue'
import {
  getDefaultFilters,
  getGroupByField,
  getPagination,
  getTableProperties,
  request,
  Row,
  Table,
  TableRequestProps,
} from '@/utils/request'
import Filters from '@/components/filters/AppsFilters.vue'
import getColumns from './config/columns'
import { FiltersType } from '@/utils/request'
import { getData, getTotalValues } from '@/utils/getData'
import { financeGroupByOptions } from './config/groupByOptions'
import LineChart from '@/components/charts/LineChart.vue'
import { formatValue } from '@/utils'
import { startOfYear, format, getISOWeek } from 'date-fns'
import { FormatMoney } from 'format-money-js'
import { Currency } from '@/enums/Currency'
import PieChart from '@/components/charts/PieChart.vue'

export default defineComponent({
  name: 'Finances',
  components: { PieChart, Filters, LineChart },
  created() {
    this.onRequest({ pagination: this.pagination })
    this.onRequestChartData()
  },
  data() {
    const moneyFormatter = new FormatMoney({
      decimals: 2,
      separator: ' ',
    })
    return {
      ...getTableProperties('groupByField', 50),
      pagination: {
        ...getPagination('groupByField', 50),
        selectedColumns: ['paidInstalls', 'freeInstalls', 'paidInstallSum'],
      },
      isRowClick: false,
      filters: getDefaultFilters(),
      columns: getColumns(),
      chartData: [],
      chartLoading: true,
      ...this.getCheckboxes(),
      paidInstalls: true,
      freeInstalls: true,
      paidInstallSum: true,
      moneyFormatter,
      currencies: Currency,
    }
  },
  watch: {
    filters() {
      if (this.filters.groupBy !== 'date' && this.filters.groupBy !== 'week') {
        this.pagination.selectedColumns = this.pagination.selectedColumns.length
          ? [this.pagination.selectedColumns[0]]
          : []
        this.paidInstalls = false
        this.freeInstalls = false
        this.ppInstalls = false
        this.paidInstallSum = false
        this.ppInstallSum = false
        this.ppInstallSumHold = false
        this.profit = false
        if (this.pagination.selectedColumns.length) {
          this[this.pagination.selectedColumns[0]] = true
        }
      }
      this.pagination.page = 1
      this.onRequest({ pagination: this.pagination })
      this.onRequestChartData()
    },
  },
  methods: {
    getISOWeekName(val: string) {
      return (
        'week #' +
        getISOWeek(new Date(val)) +
        `  (${new Date(val).getFullYear()})`
      )
    },
    getCurrencyFromCookie(): string {
      return ' USD'
    },
    async onRequestChartData() {
      this.chartLoading = true
      const orderBy = {
        [String(this.filters?.groupBy)]: this.pagination.descending
          ? 'DESC'
          : 'ASC',
      }
      this.chartData = await getData(
        { filters: this.filters || {}, limit: 0, offset: 0, orderBy },
        '/api/finances/apps',
      )
      this.chartLoading = false
    },
    async onRequest(props: TableRequestProps) {
      await request(props, this as Table, '/api/finances/apps', true)
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    resetFilters() {
      this.filters = getDefaultFilters()
    },
    formatMoney(value: number) {
      return this.moneyFormatter.from(
        Number(formatValue(Number(value), 2, true)),
      )
    },
    total(field: string, operation: 'sum' | 'avg'): number | string {
      return getTotalValues(this.chartData, field, operation)
    },
    getGroupedField(field: 'id' | 'name' | 'field', row: Row) {
      return getGroupByField(field, row, financeGroupByOptions, this.filters)
    },
    changeSelected(column: string) {
      if (this.pagination.selectedColumns.includes(column)) {
        this.pagination.selectedColumns = this.pagination.selectedColumns.filter(
          (c: string) => c !== column,
        )
      } else {
        this.pagination.selectedColumns = [
          ...this.pagination.selectedColumns,
          column,
        ]
      }
    },
    getCheckboxes() {
      return {
        paidInstalls: false,
        freeInstalls: false,
        ppInstalls: false,
        paidInstallSum: false,
        ppInstallSum: false,
        ppInstallSumHold: false,
        profit: false,
      }
    },
    getGroupByArray() {
      return financeGroupByOptions
    },
    onRowClick(evt: any, row: any) {
      if (this.filters.groupBy === 'mobileAppId') {
        const dateFrom = format(startOfYear(new Date()), 'yyyy/MM/dd')
        const dateTo = format(new Date(), 'yyyy/MM/dd')
        this.changeAll({
          mobileAppId: row.mobileAppId,
          groupBy: 'date',
          dateFrom,
          dateTo,
        })
      }
    },
  },
})
