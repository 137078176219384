
import { defineComponent } from 'vue'
import { mapMutations, mapState } from 'vuex'
import store from '@/store'
import { AffiliateReward, TrafficSource } from '@/store/types'
import {
  enumToArray,
  getRewardTypesByVertical,
  showSuccessSnackBar,
} from '@/utils'
import { RewardType } from '@/enums/RewardType'
import { lookupAffiliates, lookupGeos } from '@/utils/lookups'
import { Vertical } from '@/enums/Vertical'
import { payoutCurrencies } from '@/utils'
import IconWithName from '@/components/icons/IconWithName.vue'
import { LeadLimitPeriod } from '@/enums/LeadLimitPeriod'
import axios from 'axios'

export default defineComponent({
  name: 'Affiliates',
  components: { IconWithName },
  props: ['defaultOptions'],
  created(): void {
    const rewards = store.state.offersModule.offer.affiliateRewards
    if (!rewards.length) {
      this.update({
        affiliateRewards: [...rewards, { value: {}, affiliateIds: [] }],
      })
    }
  },
  computed: {
    ...mapState('offersModule', ['errors', 'offer']),
    rewards: () => store.state.offersModule.offer.affiliateRewards,
  },
  data: function() {
    return {
      geos: [...(this.defaultOptions.geos || [])],
      affiliates: [...(this.defaultOptions.affiliates || [])],
      rewardTypesEnum: RewardType,
      rewardTypes: getRewardTypesByVertical(
        store.state.offersModule.offer.vertical,
      ),
      leadLimitPeriods: enumToArray(LeadLimitPeriod),
      leadLimitIsReseting: false,
      currencies: payoutCurrencies,
      verticalsEnum: Vertical,
      trafficSources:
        store.state.offersModule.offer.trafficSources.map(s => s.name) || [],
    }
  },
  methods: {
    ...mapMutations('offersModule', ['update']),
    addReward() {
      this.update({
        affiliateRewards: [
          ...store.state.offersModule.offer.affiliateRewards,
          { value: {}, affiliateIds: [] },
        ],
      })
    },
    deleteReward(rewardIndex: number) {
      const affiliateRewards = this.rewards.filter(
        (reward: AffiliateReward, index: number) => index !== rewardIndex,
      )
      this.update({ affiliateRewards })
    },
    updateField(reward: AffiliateReward, index: number) {
      if (reward.type === RewardType.RevShare) {
        reward = { ...reward, value: { ...reward.value, amount: null } }
      }
      const affiliateRewards = this.rewards
      affiliateRewards[index] = reward
      this.update({ affiliateRewards })
    },
    clearFilter(selectedRef: string) {
      if (this.$refs[selectedRef] !== void 0) {
        ;(this.$refs[selectedRef] as any).updateInputValue('')
      }
    },
    async resetLeadLimit(reward: AffiliateReward) {
      this.leadLimitIsReseting = true
      await axios.post('/api/lead-limits/reset', {
        offerId: this.offer.id,
        geoIds: reward.geoIds,
        affiliateIds: reward.affiliateIds,
        trafficSourceId: reward.trafficSource?.id || null,
      })
      showSuccessSnackBar(
        `Капа обновлена для aff ID: ${reward.affiliateIds.join(', ')}`,
      )
      this.leadLimitIsReseting = false
    },
    async getGeos(value: string, update: Function) {
      await lookupGeos(this, value, update)
    },
    async getAffiliates(value: string, update: Function) {
      await lookupAffiliates(this, value, update)
    },
    getTrafficSources(value: string, update: Function) {
      const trafficSources = this.trafficSources.filter((s: any) =>
        s.toLowerCase().includes(value.toLowerCase()),
      )
      update(() => {
        if (!value) {
          this.trafficSources = store.state.offersModule.offer.trafficSources.map(
            s => s.name,
          )
          return
        }
        this.trafficSources = trafficSources
      })
    },
    updateTrafficSource(reward: AffiliateReward, name: string, index: number) {
      const trafficSource = this.offer.trafficSources.find(
        (s: TrafficSource) => name === s.name,
      )
      this.updateField(
        {
          ...reward,
          trafficSource,
        },
        index,
      )
    },
  },
})
