
import { defineComponent } from 'vue'
import VueApexCharts from 'vue3-apexcharts'
import { groupByOptions } from '@/pages/statistics/config/groupByOptions'
import { Vertical } from '@/enums/Vertical'
import { RewardType } from '@/enums/RewardType'
import { formatValue } from '@/utils'
import ApexCharts from 'apexcharts'

export default defineComponent({
  name: 'PieChart',
  props: ['rows', 'groupBy', 'metric', 'loading', 'lists'],
  components: {
    apexchart: VueApexCharts,
  },
  watch: {
    groupBy() {
      const { series, labels } = this.getSeriesAndLabels()
      this.series = series
      ApexCharts.exec('pieChart', 'updateOptions', {
        series,
        labels,
      })
    },
    metric() {
      const { series, labels } = this.getSeriesAndLabels()
      this.series = series
      ApexCharts.exec('pieChart', 'updateOptions', {
        series,
        labels,
      })
    },
    rows: {
      handler: function() {
        const { series, labels } = this.getSeriesAndLabels()
        this.series = series
        this.chartOptions.labels = labels
      },
      deep: true,
    },
  },
  data: function() {
    const { series, labels } = this.getSeriesAndLabels()
    return {
      series,
      chartOptions: {
        noData: {
          text: 'NO DATA',
          style: {
            color: '#000000',
            fontSize: '18px',
            fontFamily: 'Helvetica',
            fontWeight: 'bold',
          },
        },
        chart: {
          id: 'pieChart',
          width: 480,
          type: 'pie',
        },
        labels,
        legend: {
          show: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
    }
  },
  methods: {
    getSeriesAndLabels() {
      const series = []
      const labels = []
      const groupByLabel = groupByOptions.find(g => g.id === this.groupBy)
        ?.field
      const rows = [...this.rows].sort(
        (r1: any, r2: any) => Number(r1[this.metric]) - Number(r2[this.metric]),
      )
      for (const row of rows) {
        if (
          [
            'cr',
            'epc',
            'crFtd',
            'crReg',
            'crFtdReg',
            'approvedPercent',
          ].includes(this.metric)
        ) {
          const val =
            Number(Number(formatValue(row[this.metric]))?.toFixed(2)) || 0
          if (val === 0) {
            continue
          }
          series.push(val)
        } else {
          const val = Number(row[this.metric]) || 0
          if (val === 0) {
            continue
          }
          series.push(val)
        }
        if (this.groupBy === 'vertical') {
          labels.push(Vertical[row[groupByLabel as string]] || 'Неопределено')
          continue
        }
        if (this.groupBy === 'rewardType') {
          labels.push(RewardType[row[groupByLabel as string]] || 'Неопределено')
          continue
        }
        if (this.groupBy === 'offerId') {
          labels.push(
            `${row[groupByLabel as string] ||
              this.lists?.offers[row[this.groupBy]]} [ID:${row[this.groupBy]}]`,
          )
          continue
        }
        if (this.groupBy === 'mobileAppId') {
          labels.push(
            `${row[groupByLabel as string]} [ID:${row[this.groupBy]}]`,
          )
          continue
        }
        if (this.groupBy === 'affiliateId') {
          labels.push(
            `${row[groupByLabel as string] ||
              this.lists?.affiliates[row[this.groupBy]]} [ID:${
              row[this.groupBy]
            }]`,
          )
          continue
        }
        if (this.groupBy === 'advertiserId') {
          labels.push(
            `${row[groupByLabel as string] ||
              this.lists?.advertisers[row[this.groupBy]]} [ID:${
              row[this.groupBy]
            }]`,
          )
          continue
        }
        if (this.groupBy === 'geoId') {
          labels.push(
            `${row[groupByLabel as string] ||
              this.lists?.geos[row[this.groupBy]]} [ID:${row[this.groupBy]}]`,
          )
          continue
        }
        if (this.groupBy === 'campaignId') {
          labels.push(
            `${row[groupByLabel as string] ||
              this.lists?.campaigns[row[this.groupBy]]} [ID:${
              row[this.groupBy]
            }]`,
          )
          continue
        }
        if (this.groupBy === 'landingId') {
          labels.push(
            `${row[groupByLabel as string] ||
              this.lists?.landings[row[this.groupBy]]} [ID:${
              row[this.groupBy]
            }]`,
          )
          continue
        }
        labels.push(row[groupByLabel as string] || 'Неопределено')
      }
      return { series, labels }
    },
  },
})
