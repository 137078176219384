
import { defineComponent } from 'vue'
import { mapMutations, mapState } from 'vuex'
import store from '@/store'
import { PromoTranslation } from '@/store/types'
import { lookupLanguages } from '@/utils/lookups'

export default defineComponent({
  name: 'PromoTranslationsDialog',
  props: ['open', 'close', 'promoIndex', 'defaultOptions'],
  computed: {
    ...mapState('offersModule', ['errors', 'offer']),
    translations(): PromoTranslation[] {
      const promo = store.state.offersModule.offer.promos[this.promoIndex]
      return promo?.translations || []
    },
    showDialog(): boolean {
      return this.open
    },
  },
  data: function() {
    return {
      languages: [...(this.defaultOptions.languages || [])],
    }
  },
  methods: {
    ...mapMutations('offersModule', ['update']),
    onClose() {
      const promos = this.offer.promos
      const translations = promos[this.promoIndex].translations.filter(
        (ts: PromoTranslation) => ts.name && ts.languageId,
      )
      promos[this.promoIndex] = { ...promos[this.promoIndex], translations }
      this.update({ promos })
      this.$emit('close')
    },
    addT() {
      const promos = this.offer.promos
      const translations = [
        ...(this.offer.promos[this.promoIndex].translations || []),
        {},
      ]
      promos[this.promoIndex] = { ...promos[this.promoIndex], translations }
      this.update({
        promos,
      })
    },
    deleteT(i: number) {
      const promos = this.offer.promos
      const translations = promos[this.promoIndex].translations.filter(
        (ts: PromoTranslation, index: number) => index !== i,
      )
      promos[this.promoIndex] = { ...promos[this.promoIndex], translations }
      this.update({ promos })
    },
    updateField(ts: PromoTranslation, index: number) {
      const promos = this.offer.promos
      const translations = promos[this.promoIndex].translations
      translations[index] = ts
      promos[this.promoIndex] = { ...promos[this.promoIndex], translations }
      this.update({ promos })
    },
    async getLanguages(value: string, update: Function) {
      await lookupLanguages(this, value, update)
    },
    clearFilter(selectedRef: string) {
      if (this.$refs[selectedRef] !== void 0) {
        ;(this.$refs[selectedRef] as any).updateInputValue('')
      }
    },
  },
})
