import { formatDate, formatValue, RowValue } from '@/utils'

export function getPushGroupsColumns() {
  return [
    {
      name: 'createdAt',
      label: 'Дата создания',
      field: 'createdAt',
      align: 'center',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'title',
      label: 'Заголовок',
      field: 'title',
      align: 'center',
    },
    {
      name: 'message',
      label: 'Описание',
      field: 'message',
      align: 'center',
    },
    {
      name: 'languages',
      label: 'Языки',
      field: 'languages',
      align: 'center',
    },
    {
      name: 'isTurnedOn',
      label: 'Вкл / Выкл',
      field: 'isTurnedOn',
      align: 'center',
    },
    { name: 'actions', label: 'Действия', field: 'actions', align: 'center' },
  ]
}

export function getPushAnalyticsColumns() {
  return [
    {
      name: 'id',
      label: 'ID',
      field: 'id',
      align: 'center',
    },
    {
      name: 'createdAt',
      label: 'Дата отправки',
      field: 'createdAt',
      align: 'center',
      format: (val: string) => formatDate(val),
    },
    {
      name: 'title',
      label: 'Заголовок',
      field: 'title',
      align: 'center',
    },
    {
      name: 'message',
      label: 'Описание',
      field: 'message',
      align: 'center',
    },
    {
      name: 'mobileAppName',
      label: 'Приложение',
      field: 'mobileAppName',
      align: 'center',
    },
    {
      name: 'languageCode',
      label: 'Язык',
      field: 'languageCode',
      align: 'center',
    },
    {
      name: 'successful',
      label: 'Отправлено успешно',
      field: 'successful',
      align: 'center',
    },
    {
      name: 'failed',
      label: 'Отправлено с ошибкой',
      field: 'failed',
      align: 'center',
    },
    {
      name: 'converted',
      label: 'Открыто',
      field: 'converted',
      align: 'center',
    },
    {
      name: 'cr',
      label: 'CR (Отправленные / Открытые), %',
      field: 'cr',
      align: 'center',
      format: (val: RowValue) => formatValue(val),
    },
  ]
}
