<template>
  <div className="flex cursor-pointer" style="width: 237px">
    <input
      type="text"
      :id="'daterange' + id"
      name="daterange"
      value="01/01/2018 - 01/15/2018"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  isMonday,
  previousMonday,
  startOfMonth,
  startOfYear,
  sub,
} from 'date-fns'

export default defineComponent({
  name: 'DatePicker',
  props: ['from', 'to', 'changeDate', 'id'],
  mounted() {
    const ref = this
    window.$('#daterange' + this.id).daterangepicker(
      {
        showDropdowns: true,
        minYear: 2021,
        maxYear: 2040,
        showISOWeekNumbers: true,
        locale: {
          format: 'YYYY/MM/DD',
          firstDay: 1,
        },
        showCustomRangeLabel: false,
        linkedCalendars: false,
        alwaysShowCalendars: true,
        startDate: this.dateValue.startDate,
        endDate: this.dateValue.endDate,
        drops: 'auto',
        applyButtonClasses: 'daterange-apply-button text-white',
        cancelButtonClasses: 'daterange-cancel-button text-white',
        ranges: {
          Сегодня: [new Date(), new Date()],
          Вчера: (function() {
            const date = sub(new Date(), { days: 1 })
            return [date, date]
          })(),
          'Текущая неделя': (function() {
            const date = new Date()
            if (isMonday(date)) {
              return [date, date]
            }
            return [previousMonday(date), date]
          })(),
          'Прошлая неделя': (function() {
            const date = new Date()
            if (isMonday(date)) {
              return [sub(date, { days: 7 }), sub(date, { days: 1 })]
            }
            const dayOnPrevWeek = sub(date, { weeks: 1 })
            if (isMonday(dayOnPrevWeek)) {
              return [
                dayOnPrevWeek,
                endOfWeek(dayOnPrevWeek, { weekStartsOn: 1 }),
              ]
            }
            return [
              previousMonday(dayOnPrevWeek),
              endOfWeek(dayOnPrevWeek, { weekStartsOn: 1 }),
            ]
          })(),
          'Текущий месяц': (function() {
            const date = new Date()
            return [startOfMonth(date), date]
          })(),
          'Прошлый месяц': (function() {
            const date = sub(new Date(), { months: 1 })
            return [startOfMonth(date), endOfMonth(date)]
          })(),
          'Позапрошлый месяц': (function() {
            const date = sub(new Date(), { months: 2 })
            return [startOfMonth(date), endOfMonth(date)]
          })(),
          'Текущий год': (function() {
            const date = new Date()
            return [startOfYear(date), date]
          })(),
          'Прошлый год': (function() {
            const date = sub(new Date(), { years: 1 })
            return [startOfYear(date), endOfYear(date)]
          })(),
          'Все время': [new Date('2021-02-01'), new Date()],
        },
      },
      function(start, end) {
        ref.dateValue = {
          startDate: start.format('YYYY/MM/DD'),
          endDate: end.format('YYYY/MM/DD'),
        }
      },
    )
  },
  data: function() {
    window.$('#daterange' + this.id).remove()
    const dateValue = {
      startDate: this.from || new Date('2021/02/01'),
      endDate: this.to || new Date(),
    }
    return {
      dateValue,
    }
  },
  watch: {
    from() {
      this.dateValue.startDate = this.from
      window
        .$('#daterange' + this.id)
        .data('daterangepicker')
        .setStartDate(this.from)
    },
    to() {
      this.dateValue.endDate = this.to
      window
        .$('#daterange' + this.id)
        .data('daterangepicker')
        .setEndDate(this.to)
    },
    dateValue() {
      this.$emit('changeDate', this.dateValue)
    },
  },
})
</script>
