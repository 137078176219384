
import { defineComponent } from 'vue'
import CampaignsList from '@/pages/campaigns/CampaignsList.vue'
import LeadLimitsList from '@/pages/campaigns/LeadLimitsList.vue'
export default defineComponent({
  name: 'CampaignsPage',
  props: ['affiliateId'],
  components: { LeadLimitsList, CampaignsList },
  created() {
    const params = this.$router.currentRoute.value?.query || {}
    if ('tab' in params) {
      this.tab = params.tab as any
    }
  },
  data: function() {
    return {
      tab: 'campaigns',
    }
  },
})
