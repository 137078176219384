import { Column, Row } from '@/utils/request'
import exportFile from 'quasar/src/utils/export-file.js';import useQuasar from 'quasar/src/composables/use-quasar.js';
import { getCorrectExportValue } from '@/utils/index'

function wrapCsvValue(val: any, row?: Row, formatFn?: Function) {
  let formatted = formatFn !== void 0 ? formatFn(val, row) : val
  formatted =
    formatted === void 0 || formatted === null ? '' : String(formatted)

  formatted = formatted.split('"').join('""')
  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}

export function exportToCsv(
  rows: Row[],
  columns: Column[],
  totalRow?: Row,
  getGroupedFieldFn?: Function,
  groupBy1?: null, // для одной группировки
  getGroupedFieldFn2?: Function,
  groupBy2?: null, // для двойной группировки
  getGroupedFieldFn3?: Function,
  groupBy3?: null, // для тройной группировки
) {
  const $q = useQuasar()
  const rowsWithTotal = [...rows]
  if (rowsWithTotal.length && totalRow) {
    rowsWithTotal.push(totalRow)
  }
  const newColumns = [...columns]
  if (groupBy2 && !groupBy3) {
    newColumns[0] = {
      field: 'groupByField2',
      name: 'groupByField2',
      label: groupBy2,
    }
    newColumns.splice(0, 0, {
      field: 'groupByField1',
      name: 'groupByField1',
      label: groupBy1,
    })
  }
  if (groupBy2 && groupBy3) {
    newColumns[0] = {
      field: 'groupByField3',
      name: 'groupByField3',
      label: groupBy3,
    }
    newColumns.splice(
      0,
      0,
      {
        field: 'groupByField1',
        name: 'groupByField1',
        label: groupBy1,
      },
      {
        field: 'groupByField2',
        name: 'groupByField2',
        label: groupBy2,
      },
    )
  }

  const content = [
    newColumns
      .map(col => {
        if (col.field === 'groupByField' && getGroupedFieldFn) {
          return wrapCsvValue(getGroupedFieldFn('name'))
        }
        return wrapCsvValue(col.label)
      })
      .join(';'),
  ]
    .concat(
      rowsWithTotal.map(row =>
        newColumns
          .map(col => {
            if (col.field === 'groupByField3' && groupBy3) {
              return wrapCsvValue(
                getCorrectExportValue(row, groupBy3),
                row,
                col.format,
              )
            }
            if (col.field === 'groupByField2' && groupBy2) {
              return wrapCsvValue(
                getCorrectExportValue(row, groupBy2),
                row,
                col.format,
              )
            }
            if (col.field === 'groupByField1' && groupBy1) {
              return wrapCsvValue(
                getCorrectExportValue(row, groupBy1),
                row,
                col.format,
              )
            }
            const field = col.field === void 0 ? col.name : col.field
            let value =
              typeof col.field === 'function' ? col.field(row) : row[field]
            if (field === 'groupByField' && getGroupedFieldFn && groupBy1) {
              value = row[groupBy1]
            }
            return wrapCsvValue(value, row, col.format)
          })
          .join(';'),
      ),
    )
    .join('\r\n')

  const status = exportFile(
    'stats.csv',
    '\ufeff' + content,
    'text/csv;charset=UTF-8;',
  )

  if (status !== true) {
    $q.notify({
      message: 'Browser denied file download...',
      color: 'negative',
      icon: 'warning',
    })
  }
}
