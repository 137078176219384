import { intersection } from 'lodash'
import { TdsRule } from '@/store/types'

export default function validateTdsRules(affiliateR: TdsRule[]): boolean {
  for (const [i, v] of affiliateR.entries()) {
    for (const [inx, val] of affiliateR.entries()) {
      if (i === inx) {
        continue
      }
      if (intersection(v.geoIds, val.geoIds).length) {
        return false
      }
    }
  }
  return true
}
