import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Filters = _resolveComponent("Filters")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Filters, {
      onReset: _ctx.resetFilters,
      onChange: _ctx.onChangeFilters,
      onChangeAll: _ctx.changeAll,
      "current-filters": _ctx.filters,
      onExport: _ctx.onExport
    }, null, 8, ["onReset", "onChange", "onChangeAll", "current-filters", "onExport"]),
    _createElementVNode("div", null, [
      _createVNode(_component_q_table, {
        dense: "",
        rows: _ctx.rows,
        columns: _ctx.columns,
        "row-key": "id",
        separator: "cell",
        pagination: _ctx.pagination,
        "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
        loading: _ctx.loading,
        onRequest: _ctx.onRequest,
        "binary-state-sort": ""
      }, null, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
    ])
  ], 64))
}