import getDefaultData from '../../pages/payouts/getDefaultData'
import { Errors, PayoutData, PayoutState } from '@/store/types'

const payoutsModule = {
  state: getDefaultData(),
  mutations: {
    update(state: PayoutState, value: PayoutData): void {
      state.payoutData = { ...state.payoutData, ...value }
      state.errors = {}
    },
    reset(state: PayoutState): void {
      const { payoutData, errors } = getDefaultData()
      state.payoutData = payoutData
      state.errors = errors
    },
    setErrors(state: PayoutState, value: Errors): void {
      state.errors = value
    },
  },
  namespaced: true,
}

export default payoutsModule
