
import { defineComponent } from 'vue'
import { PushNotificationEventType } from '@/enums/PushNotificationEventType'
import axios from 'axios'
import { PushNotificationRule } from '@/store/types'
import { Day } from '@/enums/Day'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { getDays, getPushNotificationEventTypes } from '@/utils'

export default defineComponent({
  name: 'PushSettings',
  setup() {
    const $q = useQuasar()
    return {
      showSuccessSnackbar() {
        $q.notify({
          type: 'positive',
          message: `Настройки сохранены.`,
          position: 'top-right',
          timeout: 2500,
        })
      },
    }
  },
  async created() {
    this.rules = (await axios.get('/api/push-notifications/rules')).data
    if (!this.rules.length) {
      this.addRule()
    }
    this.loading = false
  },
  data: function() {
    return {
      loading: true,
      errors: {},
      rules: [],
      isSaving: false,
      days: getDays(),
      types: getPushNotificationEventTypes(),
    }
  },
  methods: {
    addRule() {
      this.rules.push({
        days: [
          Day.Monday,
          Day.Tuesday,
          Day.Wednesday,
          Day.Thursday,
          Day.Friday,
          Day.Saturday,
          Day.Sunday,
        ],
        types: [
          PushNotificationEventType.Install,
          PushNotificationEventType.Registration,
          PushNotificationEventType.Deposit,
        ],
      } as never)
    },
    updateField(rule: PushNotificationRule, index: number) {
      this.rules[index] = rule as never
    },
    deleteRule(ruleIndex: number) {
      const rules = this.rules.filter(
        (rule: PushNotificationRule, index: number) => index !== ruleIndex,
      )
      this.rules = [...rules]
    },
    onSubmit: async function() {
      this.isSaving = true
      const sendingRules = this.rules.filter(
        (r: PushNotificationRule) => r.time,
      )
      try {
        await axios.put('/api/push-notifications/rules', { sendingRules })
        this.setErrors({})
        if (sendingRules.length) {
          this.showSuccessSnackbar()
        }
      } catch (error) {
        this.setErrors((error as any)?.response?.data?.errors)
        if (Number((error as any)?.response?.status) !== 400) {
          alert(
            'Не удалось сохранить настройки рассылки. Проверьте настройки и попробуйте еще раз.',
          )
        }
      } finally {
        this.isSaving = false
      }
    },
    setErrors(errors: any) {
      this.errors = errors
    },
  },
})
