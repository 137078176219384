import {
  Errors,
  AdvertiserData,
  AdvertiserState,
  RootModule,
} from '@/store/types'
import { ActionContext } from 'vuex'
import axios from 'axios'
import router from '@/router'

function getDefaultData(): AdvertiserState {
  return {
    errors: {},
    advertiser: {
      name: null,
      token: null,
      redirectUrlTemplate: null,
    },
  }
}

const advertisersModule = {
  state: getDefaultData(),
  mutations: {
    update(state: AdvertiserState, value: AdvertiserData): void {
      state.advertiser = { ...state.advertiser, ...value }
      state.errors = {}
    },
    reset(state: AdvertiserState): void {
      const { advertiser, errors } = getDefaultData()
      state.advertiser = advertiser
      state.errors = errors
    },
    setErrors(state: AdvertiserState, value: Errors): void {
      state.errors = value
    },
  },
  actions: {
    async getData(
      { commit }: ActionContext<AdvertiserState, RootModule>,
      advertiserId: string,
    ) {
      try {
        const { data } = await axios.get('/api/advertisers/' + advertiserId)
        commit('update', data)
        commit('setErrors', {})
      } catch (err) {
        await router.push('/advertisers/new')
        throw new Error(err as string)
      }
    },
  },
  namespaced: true,
}

export default advertisersModule
