
import axios from 'axios'
import { defineComponent } from 'vue'
import { lookupAffiliates } from '@/utils/lookups'
import { Currency } from '@/enums/Currency'
export default defineComponent({
  name: 'RefillBalanceDialog',
  props: ['open', 'close', 'refresh'],
  computed: {
    showDialog(): boolean {
      return this.open
    },
  },
  data: function() {
    return {
      balance: { affiliateId: null, value: {}, comment: null },
      affiliates: [],
      loading: false,
      errors: {},
    }
  },
  methods: {
    async refillBalance() {
      this.loading = true
      axios
        .post(`/api/balances`, {
          ...this.balance,
          value: {
            currency: Currency.USD,
            amount: Number((this.balance.value as any).amount),
          },
        })
        .then(() => {
          this.$emit('close')
          this.loading = false
          this.balance = { affiliateId: null, value: {}, comment: null }
          this.$emit('refresh')
        })
        .catch(error => {
          this.errors = error.response.data.errors
          this.loading = false
        })
    },
    async getAffiliates(value: string, update: Function) {
      await lookupAffiliates(this, value, update)
    },
  },
})
