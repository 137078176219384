import { Currency } from '@/enums/Currency'
import { formatDate, formatValue, RowValue } from '@/utils'

export default function getColumns() {
  return [
    { name: 'id', label: 'ID', field: 'id', align: 'center' },
    {
      name: 'currency',
      label: 'Валюта',
      field: 'currency',
      align: 'center',
      format: (val: number) => Currency[val],
    },
    {
      name: 'value',
      label: 'Курс (1 USD)',
      field: 'value',
      align: 'center',
      format: (val: RowValue) => formatValue(val, 4),
    },
    {
      name: 'correctedValue',
      label: 'Откорректированное значение',
      field: 'correctedValue',
      align: 'center',
      format: (val: RowValue) => formatValue(val, 4),
    },
    {
      name: 'type',
      label: 'Тип обновления',
      field: 'type',
      align: 'center',
    },
    {
      name: 'updatedAt',
      label: 'Дата обновления',
      field: 'updatedAt',
      align: 'center',
      format: (val: string) => formatDate(val),
    },
    {
      name: 'isDefault',
      label: 'Является базовой валютой',
      field: 'isDefault',
      align: 'center',
    },
  ]
}
