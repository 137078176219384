
import { mapState, mapMutations } from 'vuex'
import { defineComponent } from 'vue'
import { enumToArray, getFilteredVerticals } from '@/utils'
import { MobileAppStatus } from '@/enums/MobileAppStatus'
import { lookupGeos } from '@/utils/lookups'
import axios from 'axios'
import {AppMarket} from "@/enums/AppMarket";

export default defineComponent({
  name: 'General',
  computed: mapState('mobileAppsModule', ['mobileApp', 'errors']),
  async created() {
    this.geos = (await axios.get('/api/geos')).data
  },
  data: function() {
    return {
      geos: [],
      statuses: enumToArray(MobileAppStatus),
      appMarkets: enumToArray(AppMarket),
      verticals: getFilteredVerticals(),
      storageUrl: process.env.CDN_STORAGE_URL,
    }
  },
  methods: {
    ...mapMutations('mobileAppsModule', ['update']),
    clearFilter(selectedRef: string) {
      if (this.$refs[selectedRef] !== void 0) {
        ;(this.$refs[selectedRef] as any).updateInputValue('')
      }
    },
    async getGeos(value: string, update: Function) {
      await lookupGeos(this, value, update)
    },
  },
})
