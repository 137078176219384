import { Affiliate, OfferData } from '@/store/types'
import axios from 'axios'

export async function getInitialOptions(offer: OfferData): Promise<object> {
  const defaultOptions = {
    offerCategories: [],
    advertisers: [],
    advertiserManagers: [],
    geos: [],
    affiliates: [],
    redirectDomains: [],
    languages: [],
  }

  defaultOptions.redirectDomains = (
    await axios.get('/api/redirect-domains')
  ).data

  defaultOptions.offerCategories = (
    await axios.get(
      '/api/offer-categories',
      offer.categoryIds.length
        ? {
            params: { ids: offer.categoryIds },
          }
        : {},
    )
  ).data

  defaultOptions.advertisers = await (
    await axios.get(
      '/api/advertisers',
      offer.advertiserId
        ? {
            params: { ids: [offer.advertiserId] },
          }
        : {},
    )
  ).data

  const advertiserManagers = await (
    await axios.get(
      '/api/admins/advertiser-managers',
      offer.advertiserManagerId
        ? {
          params: { ids: [offer.advertiserManagerId] },
        }
        : {},
    )
  ).data
  defaultOptions.advertiserManagers = advertiserManagers.map((affiliate: Affiliate) => ({
    ...affiliate,
    email: `#${affiliate.id} 👤 ` + affiliate.credentials.email,
  }))

  defaultOptions.geos = (await axios.get('/api/geos?isOffersPage=true')).data
  defaultOptions.languages = (await axios.get('/api/languages')).data

  const affiliates = (await axios.get('/api/affiliates/list')).data
  defaultOptions.affiliates = affiliates.map((affiliate: Affiliate) => ({
    ...affiliate,
    email: `#${affiliate.id} 👤 ` + affiliate.credentials.email,
  }))

  return defaultOptions
}
