
import { defineComponent } from 'vue'
import axios from 'axios'
import {
  getDefaultFilters,
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import { mapMutations, mapState } from 'vuex'

export default defineComponent({
  name: 'Navigation',
  props: ['drawer', 'mouseover', 'mouseout', 'openMenu', 'miniState'],
  async created() {
    if (this.user?.isAppsAdmin) {
      this.isAppsExpanded = true
    }
    try {
      const [data1, data2, data3] = await Promise.all([
        axios.get('/api/payouts/count'),
        axios.get('/api/tickets/count'),
        axios.get('/api/affiliates/count-new'),
        this.onRequest({ pagination: this.pagination }),
      ])
      this.payoutsBadges = data1.data
      this.ticketsBadges = data2.data
      this.affiliatesBadges = data3.data
    } catch (err) {
      if (
        (err as any).response.status === 401 ||
        (err as any).response.status === 403
      ) {
        this.logout()
      }
    }
    this.offersAccessCount = (this.rows?.[0] as any)?.total
  },
  computed: {
    ...mapState('authModule', ['user']),
    sumPayoutBadges(): number {
      let count = 0
      for (const [, value] of Object.entries(this.payoutsBadges)) {
        count += value as number
      }
      return count
    },
  },
  data: function() {
    let link = ''
    if (this.$route.path.includes('statistics')) {
      link = 'stats'
    }
    if (this.$route.path.includes('offers')) {
      link = 'offers'
    }
    if (this.$route.path.includes('advertisers')) {
      link = 'advertisers'
    }
    if (this.$route.path.includes('affiliates')) {
      link = 'affiliates'
    }
    if (this.$route.path.includes('campaigns')) {
      link = 'campaigns'
    }
    if (this.$route.path.includes('access')) {
      link = 'access'
    }
    if (this.$route.path.includes('admins')) {
      link = 'admins'
    }
    if (this.$route.path.includes('logs')) {
      link = 'logs'
    }
    if (this.$route.path.includes('tickets')) {
      link = 'tickets'
    }
    if (
      this.$route.path.includes('payouts') ||
      this.$route.path.includes('courses') ||
      this.$route.path.includes('finances')
    ) {
      link = 'fin'
    }
    if (
      this.$route.path.includes('apps/balances') ||
      this.$route.path.includes('mobile-apps') ||
      this.$route.path.includes('push-notifications') ||
      this.$route.path.includes('apps/statistics') ||
      this.$route.path.includes('apps/postback-logs') ||
      this.$route.path.includes('apps/finances') ||
      this.$route.path.includes('apps/access')
    ) {
      link = 'apps'
    }
    return {
      ...getTableProperties('createdAt'),
      filters: { ...getDefaultFilters() },
      drawerModel: this.drawer,
      isAppsExpanded: false,
      link,
      payoutsBadges: {},
      ticketsBadges: {},
      affiliatesBadges: {},
      offersAccessCount: 0,
    }
  },
  watch: {
    drawerModel() {
      this.$emit('openMenu', this.drawerModel)
    },
    drawer() {
      if (this.$q.screen.width < 900) {
        this.drawerModel = this.drawer
      }
    },
  },
  methods: {
    ...mapMutations('authModule', ['logout']),
    async onRequest(props: TableRequestProps) {
      await request(props, this as Table, '/api/offers-access')
    },
    onClickItem(link: string, route: string) {
      this.link = link
      this.$router.push('/' + route)
    },
  },
})
