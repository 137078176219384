
import { defineComponent } from 'vue'
import { getReferralColumns } from '../config/columns'
import {
  request,
  Table,
  Row,
  TableRequestProps,
  getTableProperties,
  getGroupByField,
  getGroupBy2Field,
  getGroupBy3Field,
} from '@/utils/request'
import {
  groupByReferralOptions,
  groupByReferralOptions2,
  groupByReferralOptions3,
} from '@/pages/statistics2/config/groupByOptions'
import { getData, getTotalValues, regroupRowsData } from '@/utils/getData'
import { exportToCsv } from '@/utils/export'
import { omit } from 'lodash'
import LineChart from '@/components/charts/LineChart.vue'
import { formatValue, showErrorMessageSnackBar } from '@/utils'
import { getISOWeek } from 'date-fns'
import moment from 'moment'
import IconWithName from '@/components/icons/IconWithName.vue'
import axios from 'axios'
import { FormatMoney } from 'format-money-js'
import { Currency } from '@/enums/Currency'

export default defineComponent({
  name: 'ReferralsTable',
  components: { IconWithName, LineChart },
  props: [
    'filters',
    'exportCsv',
    'detail',
    'currentPagination',
    'changePagination',
  ],
  async mounted() {
    await Promise.all([
      this.lookupLists(),
      this.onRequest({ pagination: this.pagination }),
      this.onRequestChartData(),
    ])
    this.expandedIds.push(...this.rows.map((r: any) => r.expandedId))
  },
  data: function() {
    const moneyFormatter = new FormatMoney({
      decimals: 2,
      separator: ' ',
    })
    return {
      ...getTableProperties('groupByField', 50),
      pagination: this.currentPagination,
      selectedColumns: this.currentPagination.selectedColumns,
      visibleColumns: this.currentPagination.visibleColumns,
      columns: getReferralColumns(),
      moneyFormatter,
      chartData: [],
      nestedRows: [],
      nestedChildRows: [],
      expandedIds: [],
      chartLoading: true,
      ...this.getCheckboxes(),
      revenue: true,
      revenueHold: true,
      offers: {},
      affiliates: {},
      advertisers: {},
      landings: {},
      campaigns: {},
      geos: {},
      geosNames: {},
    }
  },
  watch: {
    async filters() {
      this.pagination.page = 1
      this.expandedIds = []
      await Promise.all([
        this.onRequest({ pagination: this.pagination }),
        this.onRequestChartData(),
      ])
      this.expandedIds.push(...this.rows.map((r: any) => r.expandedId))
    },
    exportCsv() {
      this.exportTable()
    },
    visibleColumns() {
      this.pagination.visibleColumns = this.visibleColumns
    },
    pagination: {
      handler() {
        this.$emit('changePagination', this.pagination)
      },
      deep: true,
    },
  },
  methods: {
    formatMoney(value: number) {
      return this.moneyFormatter.from(
        Number(formatValue(Number(value), 2, true)),
      )
    },
    getCurrencyUsd(): string {
      return ' USD'
    },
    getCurrencyFromCookie(): string {
      return ' ' + Currency[Number(this.$cookies.get('baseCurrency'))]
    },
    onExpandRow(row: any) {
      if (this.expandedIds.includes(row.expandedId)) {
        this.expandedIds = this.expandedIds.filter(
          (id: number) => id !== row.expandedId,
        )
      } else {
        this.expandedIds.push(row.expandedId)
      }
    },
    formatDateLikeChart(val: string) {
      return moment(new Date(val)).format('DD MMM YYYY')
    },
    getISOWeekName(val: string) {
      return (
        'week #' +
        getISOWeek(new Date(val)) +
        `  (${new Date(val).getFullYear()})`
      )
    },
    getISOMonthName(val: string) {
      return (
        'month #' +
        (new Date(val).getMonth() + 1) +
        `  (${new Date(val).getFullYear()})`
      )
    },
    total(field: string, operation: 'sum' | 'avg'): number | string {
      return getTotalValues(this.chartData, field, operation)
    },
    getGroupedField(field: 'id' | 'name' | 'field', row: Row) {
      return getGroupByField(field, row, groupByReferralOptions, this.filters)
    },
    getGroupedField2(field: 'id' | 'name' | 'field', row: Row) {
      return getGroupBy2Field(field, row, groupByReferralOptions2, this.filters)
    },
    getGroupedField3(field: 'id' | 'name' | 'field', row: Row) {
      return getGroupBy3Field(field, row, groupByReferralOptions3, this.filters)
    },
    async onRequestChartData() {
      if (
        this.filters.groupBy2 &&
        this.filters.groupBy2 === this.filters.groupBy
      ) {
        showErrorMessageSnackBar('Нельзя выбирать одинаковые группировки')
        this.loading = false
        return
      }

      this.chartLoading = true
      const orderBy = {
        [String(this.filters?.groupBy)]: this.pagination.descending
          ? 'DESC'
          : 'ASC',
      }
      this.chartData = await getData(
        {
          filters: omit(this.filters, ['groupBy2', 'groupBy3']) || {},
          limit: 0,
          offset: 0,
          orderBy,
        },
        '/api/referrals/statistics',
      )
      this.chartLoading = false
    },
    async onRequest(props: TableRequestProps) {
      this.expandedIds = []
      this.loading = true
      if (this.filters.groupBy2) {
        if (this.filters.groupBy2 === this.filters.groupBy) {
          showErrorMessageSnackBar('Нельзя выбирать одинаковые группировки')
          this.loading = false
          return
        }
        const filters = omit(this.filters, ['groupBy2', 'groupBy3'])
        if (!this.filters.groupBy3) {
          const [rows, nestedRows] = await Promise.all([
            request(
              props,
              { ...this, filters, pagination: props.pagination } as Table,
              '/api/referrals/statistics',
            ),
            this.onRequestNested(),
          ])
          this.regroupData(rows, nestedRows)
        } else {
          const [rows, nestedRows, nestedChildRows] = await Promise.all([
            request(
              props,
              { ...this, filters, pagination: props.pagination } as Table,
              '/api/referrals/statistics',
            ),
            this.onRequestNested(),
            this.onRequestNestedChildren(),
          ])
          this.regroupData(rows, nestedRows, nestedChildRows)
          this.expandedIds.push(...this.rows.map((r: any) => r.expandedId))
        }
      } else {
        await request(props, this as Table, '/api/referrals/statistics')
        this.expandedIds.push(...this.rows.map((r: any) => r.expandedId))
      }
      this.loading = false
    },
    async onRequestNested() {
      const orderBy = {
        [String(this.filters?.groupBy)]: this.pagination.descending
          ? 'DESC'
          : 'ASC',
      }
      this.nestedRows = await getData(
        {
          filters: omit(this.filters, 'groupBy3') || {},
          limit: 0,
          offset: 0,
          orderBy,
        },
        '/api/referrals/statistics',
      )
      return this.nestedRows
    },
    async onRequestNestedChildren() {
      const orderBy = {
        [String(this.filters?.groupBy)]: this.pagination.descending
          ? 'DESC'
          : 'ASC',
      }
      this.nestedChildRows = await getData(
        { filters: this.filters || {}, limit: 0, offset: 0, orderBy },
        '/api/referrals/statistics',
      )
      return this.nestedChildRows
    },
    regroupData(rows: any, nestedRows: any, nestedChildRows = []) {
      regroupRowsData(rows, nestedRows, nestedChildRows, this)
    },
    exportTable() {
      const totalRow = {
        groupByField: 'Total:',
        registrations: this.total('registrations', 'sum'),
        conversions: this.total('conversions', 'sum'),
        revenue: this.total('revenue', 'sum'),
        usdRevenue: this.total('usdRevenue', 'sum'),
        eurRevenue: this.total('eurRevenue', 'sum'),
        uahRevenue: this.total('uahRevenue', 'sum'),
        revenueHold: this.total('revenueHold', 'sum'),
        usdRevenueHold: this.total('usdRevenueHold', 'sum'),
        eurRevenueHold: this.total('eurRevenueHold', 'sum'),
        uahRevenueHold: this.total('uahRevenueHold', 'sum'),
      }
      const exportedRows = this.filters.groupBy2
        ? this.filters.groupBy3
          ? this.nestedChildRows
          : this.nestedRows
        : this.rows
      exportToCsv(
        exportedRows,
        this.columns,
        totalRow as any,
        this.getGroupedField,
        this.filters.groupBy,
        this.getGroupedField2,
        this.filters.groupBy2,
        this.getGroupedField3,
        this.filters.groupBy3,
      )
    },
    changeSelected(column: string) {
      if (this.selectedColumns.includes(column)) {
        this.selectedColumns = this.selectedColumns.filter(
          (c: string) => c !== column,
        )
      } else {
        this.selectedColumns = [...this.selectedColumns, column]
      }
      this.pagination.selectedColumns = this.selectedColumns
    },
    getCheckboxes() {
      return {
        registrations: false,
        conversions: false,
        revenue: false,
        usdRevenue: false,
        eurRevenue: false,
        uahRevenue: false,
        revenueHold: false,
        usdRevenueHold: false,
        eurRevenueHold: false,
        uahRevenueHold: false,
      }
    },
    resetColumns() {
      this.selectedColumns = []
      this.visibleColumns = getReferralColumns().map((c: any) => c.name)
      const checkboxes = this.getCheckboxes()
      for (const c of Object.keys(checkboxes)) {
        this[c] = false
      }
    },
    getGroupByArray() {
      return groupByReferralOptions
    },
    formatColumnValue(val: any) {
      return formatValue(val)
    },
    async lookupLists() {
      const data = (await axios.get('/api/statistics/lists')).data
      this.offers = data.offers.reduce(
        (obj: any, key: any) => ({ ...obj, [key.id]: key.name }),
        {},
      )
      this.advertisers = data.advertisers.reduce(
        (obj: any, key: any) => ({ ...obj, [key.id]: key.name }),
        {},
      )
      this.affiliates = data.affiliates.reduce(
        (obj: any, key: any) => ({
          ...obj,
          [key.id]:
            JSON.parse(localStorage.getItem('user') as any)
              ?.showStatisticsOnly ||
            JSON.parse(localStorage.getItem('user') as any)?.notFullAccess
              ? ''
              : key.credentials_email,
        }),
        {},
      )
      this.campaigns = data.campaigns.reduce(
        (obj: any, key: any) => ({ ...obj, [key.id]: key.name }),
        {},
      )
      this.landings = data.promos.reduce(
        (obj: any, key: any) => ({ ...obj, [key.id]: key.name }),
        {},
      )
      this.geos = data.geos.reduce(
        (obj: any, key: any) => ({ ...obj, [key.id]: key.iso_code }),
        {},
      )
      this.geosNames = data.geos.reduce(
        (obj: any, key: any) => ({ ...obj, [key.id]: key.name }),
        {},
      )
    },
  },
})
