export default function getColorByColumn(col: string): string {
  switch (col) {
    case 'clicks':
    case 'revenue':
      return '#00E396'
    case 'paidInstalls':
      return '#008FFB'
    case 'uniqueClicks':
    case 'ppInstalls':
      return '#bb7aff'
    case 'registrationCount':
    case 'registrations':
    case 'freeInstalls':
      return '#faaf16'
    case 'firstDepositCount':
    case 'conversions':
    case 'paidInstallSum':
      return '#7545e6'
    case 'firstDepositSum':
      return '#321c5c'
    case 'approvedLeads':
      return '#00E396'
    case 'holdLeads':
      return '#858585'
    case 'trashLeads':
      return '#000'
    case 'rejectedLeads':
      return '#e60b0b'
    case 'cr':
      return '#5e490d'
    case 'epc':
    case 'ppInstallSum':
      return '#00E396'
    case 'ppInstallSumHold':
      return '#858585'
    case 'crReg':
      return '#134722'
    case 'crUniqueReg':
      return '#9fba9f'
    case 'crFtd':
      return '#1a0afc'
    case 'crFtdReg':
      return '#fc0a8b'
    case 'approvedPercent':
      return '#61a8c9'
    case 'approvedSum':
      return '#60cf44'
    case 'holdSum':
      return '#6e6867'
    case 'rejectedSum':
      return '#ba5343'
    case 'depositCount':
    case 'partners':
      return '#d66c00'
    case 'depositSum':
      return '#f500f5'
    case 'revenueHold':
      return '#858585'
    case 'uniqueDepositCount':
      return '#423ad4'
    case 'usdRevenue':
    case 'usdProfitApprove':
    case 'eurProfitApprove':
    case 'uahProfitApprove':
      return '#00E396'
    case 'usdRevenueHold':
    case 'usdProfitHold':
    case 'eurProfitHold':
    case 'uahProfitHold':
      return '#858585'
    case 'eurRevenue':
      return '#134722'
    case 'eurRevenueHold':
      return '#9fba9f'
    case 'uahRevenue':
      return '#1a0afc'
    case 'uahRevenueHold':
      return '#858585'
    case 'revShareRevenueSum':
    default:
      return '#000'
  }
}
