
import { defineComponent } from 'vue'
import PushAnalytics from './PushAnalytics.vue'
import PushGroupsList from './PushGroupsList.vue'
import PushSettings from './PushSettings.vue'
export default defineComponent({
  name: 'PushNotifications',
  components: {
    PushAnalytics,
    PushGroupsList,
    PushSettings,
  },
  data: function() {
    return {
      tab: 'push-groups',
    }
  },
})
