import getDefaultData from '@/pages/offers/config/getDefaultData'
import { Errors, OfferData, OfferState, RootModule } from '@/store/types'
import { ActionContext } from 'vuex'
import axios from 'axios'
import router from '@/router'

const offersModule = {
  state: getDefaultData(),
  mutations: {
    update(state: OfferState, value: OfferData): void {
      state.offer = { ...state.offer, ...value }
      state.errors = {}
    },
    reset(state: OfferState): void {
      const { offer, errors } = getDefaultData(state)
      state.offer = offer
      state.errors = errors
    },
    setErrors(state: OfferState, value: Errors): void {
      state.errors = value
    },
  },
  actions: {
    async getData(
      { commit }: ActionContext<OfferState, RootModule>,
      offerId: string,
    ) {
      try {
        const { data } = await axios.get('/api/offers/' + offerId)
        commit('update', data)
        commit('setErrors', {})
      } catch (err) {
        await router.push('/offers/new')
        throw new Error(err as string)
      }
    },
  },
  namespaced: true,
}

export default offersModule
