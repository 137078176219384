
import { defineComponent } from 'vue'
import {
  getDefaultFilters,
  getGroupBy2Field,
  getGroupBy3Field,
  getGroupByField,
  getPagination,
  getTableProperties,
  request,
  Row,
  Table,
  TableRequestProps,
} from '@/utils/request'
import Filters from '@/components/filters/Filters.vue'
import getColumns from './config/columns'
import { FiltersType } from '@/utils/request'
import { getData, getTotalValues, regroupRowsData } from '@/utils/getData'
import { exportToCsv } from '@/utils/export'
import { groupByOptions } from './config/groupByOptions'
import LineChart from '@/components/charts/LineChart.vue'
import { formatValue, showErrorMessageSnackBar } from '@/utils'
import { startOfYear, format, getISOWeek } from 'date-fns'
import { FormatMoney } from 'format-money-js'
import { Currency } from '@/enums/Currency'
import IconWithName from '@/components/icons/IconWithName.vue'
import moment from 'moment'
import { mapState } from 'vuex'
import { omit } from 'lodash'
import { formatFiltersFromQuery } from '@/utils/filters'

export default defineComponent({
  name: 'Finances',
  components: { IconWithName, Filters, LineChart },
  mounted() {
    formatFiltersFromQuery(this)
    this.onRequest({ pagination: this.pagination })
    this.onRequestChartData()
  },
  computed: {
    ...mapState('authModule', ['user']),
    complexFilters(): any {
      return { ...this.filters, pagination: this.pagination }
    },
  },
  data() {
    const moneyFormatter = new FormatMoney({
      decimals: 2,
      separator: ' ',
    })
    const paginationL =
      this.$storage.getStorageSync('financePaginationGmbt') || {}
    const pagination = {
      ...getPagination('groupByField', 25),
      visibleColumns: getColumns('date')
        .filter(
          (c: any) =>
            ![
              'usdPaySum',
              'eurPaySum',
              'uahPaySum',
              'usdApprovedSum',
              'eurApprovedSum',
              'uahApprovedSum',
              'usdHoldSum',
              'eurHoldSum',
              'uahHoldSum',
              'usdRevenue',
              'eurRevenue',
              'uahRevenue',
              'usdRevenueHold',
              'eurRevenueHold',
              'uahRevenueHold',
            ].includes(c.name),
        )
        .map((c: any) => c.name),
      selectedColumns: ['approvedSum', 'holdSum', 'rejectedSum'],
      ...paginationL,
    }
    const trueCheckboxes: any = {}
    for (const l of pagination.selectedColumns) {
      trueCheckboxes[l] = true
    }
    return {
      ...getTableProperties('groupByField', 25),
      pagination,
      isRowClick: false,
      filters: getDefaultFilters(),
      columns: getColumns('date'),
      chartData: [],
      nestedRows: [],
      nestedChildRows: [],
      expandedIds: [],
      chartLoading: true,
      ...this.getCheckboxes(),
      ...trueCheckboxes,
      moneyFormatter,
      currencies: Currency,
    }
  },
  watch: {
    pagination: {
      handler() {
        this.$storage.setStorageSync('financePaginationGmbt', this.pagination)
      },
      deep: true,
    },
    filters() {
      this.$storage.removeStorageSync('financePaginationGmbt')
      this.pagination.page = 1
      if (this.filters.presetId) {
        this.pagination = {
          ...this.pagination,
          ...this.complexFilters.pagination,
        }
        this.pagination.selectedColumns = this.complexFilters.pagination.selectedColumns
        const checkboxes = this.getCheckboxes()
        for (const c of Object.keys(checkboxes)) {
          this[c] = false
        }
        for (const c of this.paginationselectedColumns) {
          this[c] = true
        }
      }

      if (
        this.filters.groupBy !== 'date' &&
        this.filters.groupBy !== 'week' &&
        this.filters.groupBy !== 'month'
      ) {
        this.partners = false
        this.pagination.selectedColumns = this.pagination.selectedColumns.filter(
          (r: string) => r !== 'partners',
        )
      }
      this.columns = getColumns(this.filters.groupBy)
      this.onRequest({ pagination: this.pagination })
      this.onRequestChartData()
    },
  },
  methods: {
    regroupData(rows: any, nestedRows: any, nestedChildRows = []) {
      regroupRowsData(rows, nestedRows, nestedChildRows, this)
    },
    onExpandRow(row: any) {
      if (this.expandedIds.includes(row.id)) {
        this.expandedIds = this.expandedIds.filter(
          (id: number) => id !== row.id,
        )
      } else {
        this.expandedIds.push(row.id)
      }
    },
    formatDateLikeChart(val: string) {
      return moment(new Date(val)).format('DD MMM YYYY')
    },
    getISOWeekName(val: string) {
      return (
        'week #' +
        getISOWeek(new Date(val)) +
        `  (${new Date(val).getFullYear()})`
      )
    },
    getISOMonthName(val: string) {
      return (
        'month #' +
        (new Date(val).getMonth() + 1) +
        `  (${new Date(val).getFullYear()})`
      )
    },
    getCurrencyFromCookie(): string {
      return ' ' + Currency[Number(this.$cookies.get('baseCurrency'))]
    },
    async onRequestChartData() {
      if (
        this.filters.groupBy2 &&
        this.filters.groupBy2 === this.filters.groupBy
      ) {
        showErrorMessageSnackBar('Нельзя выбирать одинаковые группировки')
        this.loading = false
        return
      }

      this.chartLoading = true
      const orderBy = {
        [String(this.filters?.groupBy)]: this.pagination.descending
          ? 'DESC'
          : 'ASC',
      }
      this.chartData = await getData(
        {
          filters: omit(this.filters, ['groupBy2', 'groupBy3']) || {},
          limit: 0,
          offset: 0,
          orderBy,
        },
        '/api/finances',
      )
      this.chartLoading = false
    },
    async onRequest(props: TableRequestProps) {
      this.loading = true
      const options = props || { pagination: this.pagination }
      if (this.filters.groupBy2) {
        if (this.filters.groupBy2 === this.filters.groupBy) {
          showErrorMessageSnackBar('Нельзя выбирать одинаковые группировки')
          this.loading = false
          return
        }
        const filters = omit(this.filters, ['groupBy2', 'groupBy3'])
        if (!this.filters.groupBy3) {
          const [rows, nestedRows] = await Promise.all([
            request(
              options,
              { ...this, filters, pagination: options.pagination } as Table,
              '/api/finances',
              true,
            ),
            this.onRequestNested(),
          ])
          this.regroupData(rows, nestedRows)
        } else {
          const [rows, nestedRows, nestedChildRows] = await Promise.all([
            request(
              options,
              { ...this, filters, pagination: options.pagination } as Table,
              '/api/finances',
              true,
            ),
            this.onRequestNested(),
            this.onRequestNestedChildren(),
          ])
          this.regroupData(rows, nestedRows, nestedChildRows)
        }
      } else {
        await request(options, this as Table, '/api/finances', true)
      }
      this.loading = false
    },
    async onRequestNested() {
      const orderBy = {
        [String(this.filters?.groupBy)]: this.pagination.descending
          ? 'DESC'
          : 'ASC',
      }
      this.nestedRows = await getData(
        {
          filters: omit(this.filters, 'groupBy3') || {},
          limit: 0,
          offset: 0,
          orderBy,
        },
        '/api/finances',
      )
      return this.nestedRows
    },
    async onRequestNestedChildren() {
      const orderBy = {
        [String(this.filters?.groupBy)]: this.pagination.descending
          ? 'DESC'
          : 'ASC',
      }
      this.nestedChildRows = await getData(
        { filters: this.filters || {}, limit: 0, offset: 0, orderBy },
        '/api/finances',
      )
      return this.nestedChildRows
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    resetFilters() {
      this.filters = getDefaultFilters()
    },
    formatMoney(value: number) {
      return this.moneyFormatter.from(
        Number(formatValue(Number(value), 2, true)),
      )
    },
    onExport() {
      const vc = this.pagination.visibleColumns
      const totalRow: any = {
        groupByField: 'Total:',
        partners: this.total('partners', 'avg'),
        registrations: this.total('registrations', 'sum'),
        conversions: this.total('conversions', 'sum'),
        approvedSum: this.total('approvedSum', 'sum'),
        paySum: this.total('paySum', 'sum'),
        usdPaySum: this.total('usdPaySum', 'sum'),
        eurPaySum: this.total('eurPaySum', 'sum'),
        uahPaySum: this.total('uahPaySum', 'sum'),
        usdApprovedSum: this.total('usdApprovedSum', 'sum'),
        eurApprovedSum: this.total('eurApprovedSum', 'sum'),
        uahApprovedSum: this.total('uahApprovedSum', 'sum'),
        holdSum: this.total('holdSum', 'sum'),
        usdHoldSum: this.total('usdHoldSum', 'sum'),
        eurHoldSum: this.total('eurHoldSum', 'sum'),
        uahHoldSum: this.total('uahHoldSum', 'sum'),
        rejectedSum: this.total('rejectedSum', 'sum'),
        revenue: this.total('revenue', 'sum'),
        revenueTotal: this.total('revenueTotal', 'sum'),
        advHold: this.total('advHold', 'sum'),
        advApprove: this.total('advApprove', 'sum'),
        usdRevenue: this.total('usdRevenue', 'sum'),
        eurRevenue: this.total('eurRevenue', 'sum'),
        uahRevenue: this.total('uahRevenue', 'sum'),
        revenueHold: this.total('revenueHold', 'sum'),
        usdRevenueHold: this.total('usdRevenueHold', 'sum'),
        eurRevenueHold: this.total('eurRevenueHold', 'sum'),
        uahRevenueHold: this.total('uahRevenueHold', 'sum'),
        revenuePercent: (
          (Number(this.total('revenue', 'sum')) /
            (Number(this.total('approvedSum', 'sum')) +
              Number(this.total('revenue', 'sum')))) *
          100
        ).toFixed(2),
      }
      for (const k of Object.keys(totalRow)) {
        if (!vc.includes(k)) {
          delete totalRow[k]
        }
      }
      // for (const key in totalRow) {
      //   totalRow[key] = String(totalRow[key]).replace('.', ',')
      //   totalRow[key] = isNaN(Number(totalRow[key])) ? totalRow[key] : Number(totalRow[key])
      // }
      const exportedRows = this.filters.groupBy2
        ? this.filters.groupBy3
          ? this.nestedChildRows
          : this.nestedRows
        : this.rows
      // exportedRows = exportedRows.map((r: any) => {
      //   for (const key in r) {
      //     r[key] = String(r[key]).replace('.', ',')
      //     r[key] = isNaN(Number(r[key])) ? r[key] : Number(r[key])
      //   }
      //   return r
      // })

      exportToCsv(
        exportedRows,
        this.columns.filter((c: any) => vc.includes(c.name)),
        totalRow as any,
        this.getGroupedField,
        this.filters.groupBy,
        this.getGroupedField2,
        this.filters.groupBy2,
        this.getGroupedField3,
        this.filters.groupBy3,
      )
    },
    total(field: string, operation: 'sum' | 'avg'): number | string {
      return getTotalValues(this.chartData, field, operation)
    },
    getGroupedField(field: 'id' | 'name' | 'field', row: Row) {
      return getGroupByField(field, row, groupByOptions, this.filters)
    },
    getGroupedField2(field: 'id' | 'name' | 'field', row: Row) {
      return getGroupBy2Field(field, row, groupByOptions, this.filters)
    },
    getGroupedField3(field: 'id' | 'name' | 'field', row: Row) {
      return getGroupBy3Field(field, row, groupByOptions, this.filters)
    },
    changeSelected(column: string) {
      if (this.pagination.selectedColumns.includes(column)) {
        this.pagination.selectedColumns = this.pagination.selectedColumns.filter(
          (c: string) => c !== column,
        )
      } else {
        this.pagination.selectedColumns = [
          ...this.pagination.selectedColumns,
          column,
        ]
      }
    },
    getCheckboxes() {
      return {
        registrations: false,
        conversions: false,
        paySum: false,
        usdPaySum: false,
        eurPaySum: false,
        uahPaySum: false,
        approvedSum: false,
        usdApprovedSum: false,
        eurApprovedSum: false,
        uahApprovedSum: false,
        holdSum: false,
        usdHoldSum: false,
        eurHoldSum: false,
        uahHoldSum: false,
        rejectedSum: false,
        revenue: false,
        revenueTotal: false,
        advHold: false,
        advApprove: false,
        usdRevenue: false,
        eurRevenue: false,
        uahRevenue: false,
        revenueHold: false,
        usdRevenueHold: false,
        eurRevenueHold: false,
        uahRevenueHold: false,
        revenuePercent: false,
        partners: false,
      }
    },
    getGroupByArray() {
      return groupByOptions
    },
    format(val: string) {
      return formatValue(val)
    },
    resetColumns() {
      this.pagination.selectedColumns = []
      this.pagination.visibleColumns = getColumns(this.filters.groupBy)
        .filter(
          (c: any) =>
            ![
              'usdPaySum',
              'eurPaySum',
              'uahPaySum',
              'usdApprovedSum',
              'eurApprovedSum',
              'uahApprovedSum',
              'usdHoldSum',
              'eurHoldSum',
              'uahHoldSum',
              'usdRevenue',
              'eurRevenue',
              'uahRevenue',
              'usdRevenueHold',
              'eurRevenueHold',
              'uahRevenueHold',
            ].includes(c.name),
        )
        .map((c: any) => c.name)
      const checkboxes = this.getCheckboxes()
      for (const c of Object.keys(checkboxes)) {
        this[c] = false
      }
    },
    onRowClick(evt: any, row: any) {
      if (this.filters.groupBy === 'offerId') {
        const dateFrom = format(startOfYear(new Date()), 'yyyy/MM/dd')
        const dateTo = format(new Date(), 'yyyy/MM/dd')
        this.changeAll({
          offerId: row.offerId,
          groupBy: 'date',
          dateFrom,
          dateTo,
        })
      }
    },
  },
})
