import { CampaignStatus } from '@/enums/CampaignStatus'
import { Geo } from '@/store/types'
import { formatDate, isDefined } from '@/utils'
import { LeadLimitPeriod } from '@/enums/LeadLimitPeriod'

export function getCampaignColumns() {
  return [
    { name: 'id', label: 'ID', field: 'id', align: 'center', sortable: true },
    { name: 'actions', label: 'Действия', field: 'actions', align: 'center' },
    {
      name: 'createdAt',
      label: 'Дата создания',
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'name',
      label: 'Название',
      field: 'name',
      align: 'left',
      sortable: true,
    },
    {
      name: 'affiliateEmail',
      label: 'Аффилейт',
      field: 'affiliateEmail',
      align: 'left',
      sortable: true,
    },
    {
      name: 'offerName',
      label: 'Оффер',
      field: 'offerName',
      align: 'left',
      sortable: true,
    },
    { name: 'url', label: 'Ссылка потока', field: 'url', align: 'left' },
    {
      name: 'status',
      label: 'Статус',
      field: 'status',
      align: 'left',
      sortable: true,
      format: (val: number) => CampaignStatus[val],
    },
    {
      name: 'geos',
      label: 'Гео',
      field: 'geos',
      align: 'left',
      format: (val: Geo[]) => val.map((g: Geo) => g.name).join(', '),
    },
    {
      name: 'trafficSourceName',
      label: 'Источник трафика',
      field: 'trafficSourceName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'regsCount',
      label: 'Регистрации',
      field: 'regsCount',
      align: 'right',
      classes: 'monospaced',
      sortable: true,
    },
    {
      name: 'depsCount',
      label: 'Депозиты',
      field: 'depsCount',
      align: 'right',
      classes: 'monospaced',
      sortable: true,
    },
  ]
}

export function getLeadLimitColumns() {
  return [
    { name: 'id', label: 'ID', field: 'id', align: 'center', sortable: true },
    {
      name: 'createdAt',
      label: 'Дата выдачи капы',
      field: 'createdAt',
      align: 'center',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'leadStartDate',
      label: 'Дата старта пролива',
      field: 'leadStartDate',
      align: 'right',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'lastFtdDate',
      label: 'Дата последнего FTD',
      field: 'lastFtdDate',
      align: 'right',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'affiliateEmail',
      label: 'Аффилейт',
      field: 'affiliateEmail',
      align: 'left',
      sortable: true,
    },
    {
      name: 'adminEmail',
      label: 'Афф. менеджер',
      field: 'adminEmail',
      align: 'left',
    },
    {
      name: 'offerName',
      label: 'Оффер',
      field: 'offerName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'geoCode',
      label: 'Гео',
      field: 'geoCode',
      align: 'left',
    },
    {
      name: 'trafficSourceName',
      label: 'Источник',
      field: 'trafficSourceName',
      align: 'left',
      format: (val: any) => isDefined(val),
    },
    {
      name: 'status',
      label: 'Статус',
      field: 'status',
      align: 'left',
      sortable: true,
    },
    {
      name: 'maxValue',
      label: 'Выданная капа',
      field: 'maxValue',
      align: 'right',
      classes: 'monospaced',
    },
    {
      name: 'value',
      label: 'Пролитая капа',
      field: 'value',
      align: 'right',
      classes: 'monospaced',
      format: (val: any) => val || 0,
    },
    {
      name: 'predictableReturn',
      label: 'Прогнозируемый оборот',
      field: 'predictableReturn',
      align: 'right',
      classes: 'monospaced',
    },
    {
      name: 'predictableProfit',
      label: 'Прогнозируемый профит',
      field: 'predictableProfit',
      align: 'right',
      classes: 'monospaced',
    },
    {
      name: 'realReturn',
      label: 'Реальный оборот',
      field: 'realReturn',
      align: 'right',
      classes: 'monospaced',
    },
    {
      name: 'realProfit',
      label: 'Реальный профит',
      field: 'realProfit',
      align: 'right',
      classes: 'monospaced',
    },
    {
      name: 'period',
      label: 'Период',
      field: 'period',
      align: 'right',
      format: (val: any) => LeadLimitPeriod[val],
    },
    {
      name: 'comments',
      label: 'Комментарий',
      field: 'comments',
      align: 'left',
    },
    { name: 'actions', label: '', field: 'actions', align: 'center' },
  ]
}
