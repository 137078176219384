
import { defineComponent } from 'vue'
import Filters from '@/components/filters/Filters.vue'
import { getDefaultFilters, getTableProperties } from '@/utils/request'
import {
  getAdvertiserPostbackColumns,
  getPostbackChartColumns,
} from './columns'
import { request, Table, TableRequestProps } from '@/utils/request'
import { FiltersType } from '@/utils/request'
import LineChart from '@/components/charts/LineChart.vue'
import { getData } from '@/utils/getData'
import { groupByOptions } from '@/pages/statistics/config/groupByOptions'
import { exportToCsv } from '@/utils/export'
import IconWithName from '@/components/icons/IconWithName.vue'
import { mapState } from 'vuex'
export default defineComponent({
  name: 'AdvertiserLogs',
  components: { IconWithName, LineChart, Filters },
  computed: {
    ...mapState('authModule', ['user']),
  },
  created() {
    this.onRequestChartData()
    this.onRequest({ pagination: this.pagination })
  },
  watch: {
    filters() {
      this.onRequestChartData()
      this.onRequest({ pagination: this.pagination })
    },
  },
  data: function() {
    return {
      ...getTableProperties('createdAt', 50),
      filters: getDefaultFilters(),
      columns: getAdvertiserPostbackColumns(),
      chartColumns: getPostbackChartColumns(),
      chartData: [],
      selectedColumns: ['registrationCount', 'firstDepositCount'],
      chartLoading: false,
    }
  },
  methods: {
    async onRequest(
      props: TableRequestProps | Omit<TableRequestProps, 'pagination'>,
    ) {
      const options = { pagination: this.pagination, ...props }
      await request(options, this as Table, '/api/logs/advertiser')
    },
    async onRequestChartData() {
      this.chartLoading = true
      const orderBy = {
        ['createdAt']: 'DESC',
      }
      this.chartData = await getData(
        { filters: this.filters || {}, limit: 0, offset: 0, orderBy },
        '/api/logs/postbacks-chart',
      )
      this.chartLoading = false
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    resetFilters() {
      this.pagination.page = 1
      this.filters = getDefaultFilters()
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    getGroupByArray() {
      return groupByOptions
    },
    onExport() {
      exportToCsv(this.rows, this.columns)
    },
  },
})
