
import { defineComponent } from 'vue'
import { getIntersectionsColumns, getConsumersColumns } from './columns'
import {
  request,
  getTableProperties,
  Table,
  TableRequestProps,
} from '@/utils/request'
import { exportToCsv } from '@/utils/export'
import IconWithName from '@/components/icons/IconWithName.vue'
import { mapState } from 'vuex'
import axios from 'axios'
import { formatDate } from '@/utils'

export default defineComponent({
  name: 'ConversionsTable',
  props: ['filters', 'exportCsv'],
  components: {
    IconWithName,
  },
  mounted() {
    this.lookupLists()
    this.onRequest({ pagination: this.pagination })
  },
  computed: {
    ...mapState('authModule', ['user']),
  },
  data: function() {
    return {
      ...getTableProperties('affiliateId', 500),
      columns: getIntersectionsColumns() as any,
      tab: 'intersections',
      offers: [],
      affiliates: [],
      advertisers: [],
      geos: [],
      geosNames: [],
    }
  },
  watch: {
    tab() {
      if (this.tab === 'consumers') {
        this.columns = getConsumersColumns()
      } else {
        this.columns = getIntersectionsColumns()
      }
      this.pagination.page = 1
      this.onRequest({ pagination: this.pagination })
    },
    filters() {
      this.pagination.page = 1
      this.onRequest({ pagination: this.pagination })
    },
    exportCsv() {
      exportToCsv(this.rows, this.columns)
    },
  },
  methods: {
    async onRequest(
      props: TableRequestProps | Omit<TableRequestProps, 'pagination'> = {},
    ) {
      const options = { pagination: this.pagination, ...props }
      await request(options, this as Table, `/api/logs/${this.tab}`)
    },
    getClickIds(rows: string[], takeFirst = false) {
      if (!rows?.length) {
        return []
      }
      if (takeFirst) {
        if (rows.length >= 1 && rows.length <= 3) {
          return rows
        }
        return [rows[0], rows[1]]
      }
      return rows
    },
    async lookupLists() {
      const data = (await axios.get('/api/statistics/lists')).data
      this.offers = data.offers.reduce(
        (obj: any, key: any) => ({ ...obj, [key.id]: key.name }),
        {},
      )
      this.advertisers = data.advertisers.reduce(
        (obj: any, key: any) => ({ ...obj, [key.id]: key.name }),
        {},
      )
      this.affiliates = data.affiliates.reduce(
        (obj: any, key: any) => ({
          ...obj,
          [key.id]:
            JSON.parse(localStorage.getItem('user') as any)
              ?.showStatisticsOnly ||
            JSON.parse(localStorage.getItem('user') as any)?.notFullAccess
              ? ''
              : key.credentials_email,
        }),
        {},
      )
      this.geos = data.geos.reduce(
        (obj: any, key: any) => ({ ...obj, [key.id]: key.iso_code }),
        {},
      )
      this.geosNames = data.geos.reduce(
        (obj: any, key: any) => ({ ...obj, [key.id]: key.name }),
        {},
      )
    },
    frmtDate(val: string) {
      return formatDate(val)
    },
  },
})
