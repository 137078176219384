import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "q-mb-lg" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "flex row items-center no-wrap" }
const _hoisted_4 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Filters = _resolveComponent("Filters")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_CampaignEditDialog = _resolveComponent("CampaignEditDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Filters, {
        onReset: _ctx.resetFilters,
        onChange: _ctx.onChangeFilters,
        onChangeAll: _ctx.changeAll,
        "current-filters": _ctx.filters
      }, null, 8, ["onReset", "onChange", "onChangeAll", "current-filters"]),
      _createVNode(_component_q_table, {
        dense: "",
        class: "q-ml-md",
        rows: _ctx.rows,
        columns: _ctx.columns,
        "row-key": "id",
        separator: "cell",
        pagination: _ctx.pagination,
        "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pagination) = $event)),
        loading: _ctx.loading,
        onRequest: _ctx.onRequest,
        "binary-state-sort": "",
        "column-sort-order": "da"
      }, {
        top: _withCtx(() => [
          _createVNode(_component_q_btn, {
            size: "sm",
            outline: "",
            "no-caps": "",
            icon: "add",
            label: "Создать",
            color: "primary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openCampaignDialog(null)))
          })
        ]),
        "body-cell-actions": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                dense: "",
                round: "",
                flat: "",
                class: "q-mr-sm",
                color: "primary",
                size: "sm",
                onClick: _withModifiers(($event: any) => (_ctx.openCampaignDialog(props.row.id)), ["stop"]),
                icon: "edit"
              }, null, 8, ["onClick"]),
              _createVNode(_component_q_btn, {
                dense: "",
                round: "",
                flat: "",
                class: "q-mr-sm",
                color: "positive",
                size: "sm",
                onClick: _withModifiers(($event: any) => (_ctx.copyRow(props)), ["stop"]),
                icon: "content_copy"
              }, null, 8, ["onClick"]),
              _createVNode(_component_q_btn, {
                dense: "",
                round: "",
                flat: "",
                color: "negative",
                size: "sm",
                onClick: _withModifiers(($event: any) => (_ctx.deleteRow(props)), ["stop"]),
                icon: "delete"
              }, null, 8, ["onClick"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-affiliateEmail": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_IconWithName, {
                id: 
              props.row.fantomAffiliateId
                ? `${props.row.affiliateId} (${props.row.fantomAffiliateId})`
                : props.row.affiliateId
            ,
                name: _ctx.user?.notFullAccess ? '' : props.row.affiliateEmail,
                size: "20px",
                color: "secondary",
                icon: "account_box",
                entity: "affiliate"
              }, null, 8, ["id", "name"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-offerName": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_IconWithName, {
                id: props.row.offerId,
                name: props.row.offerName,
                size: "20px",
                color: "orange-7",
                icon: "description",
                entity: "offer"
              }, null, 8, ["id", "name"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-url": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "cursor-pointer",
                onClick: _withModifiers(($event: any) => (_ctx.copy(props.row.url)), ["stop"])
              }, _toDisplayString(props.row.url), 9, _hoisted_2)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-geos": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.geos, (geo, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  style: {"width":"max-content"}
                }, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("img", {
                      class: "q-mr-sm",
                      height: "10",
                      width: "15",
                      alt: props.row.geo,
                      src: 
                  `http://purecatamphetamine.github.io/country-flag-icons/3x2/${geo.isoCode}.svg`
                
                    }, null, 8, _hoisted_4),
                    _createTextVNode(" " + _toDisplayString(geo.name), 1)
                  ])
                ]))
              }), 128))
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-trafficSource": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.row.trafficSource?.name), 1)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 1
      }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
    ]),
    (_ctx.showCampaignEditDialog)
      ? (_openBlock(), _createBlock(_component_CampaignEditDialog, {
          key: 0,
          open: _ctx.showCampaignEditDialog,
          onClose: _ctx.onCloseDialog,
          onRefresh: _ctx.onRequest,
          campaignId: _ctx.selectedCampaignId
        }, null, 8, ["open", "onClose", "onRefresh", "campaignId"]))
      : _createCommentVNode("", true)
  ], 64))
}