import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "q-mt-lg" }
const _hoisted_2 = { class: "flex full-width justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_drawer = _resolveComponent("q-drawer")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createBlock(_component_q_drawer, {
    modelValue: _ctx.drawerModel,
    "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.drawerModel) = $event)),
    "show-if-above": "",
    mini: _ctx.miniState,
    width: 240,
    behavior: _ctx.$q.screen.width < 900 ? 'mobile' : 'default',
    breakpoint: 50,
    bordered: "",
    "content-class": "bg-grey-3"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_q_list, { class: "text-white" }, {
          default: _withCtx(() => [
            (!_ctx.user?.isAppsAdmin)
              ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                  key: 0,
                  clickable: "",
                  active: _ctx.link === 'stats',
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickItem('stats', 'statistics'))),
                  "active-class": "custom-menu-link"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: "/statistics",
                      class: "row text-white"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, { avatar: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_icon, { name: "bar_chart" })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_q_item_section, { class: "flex justify-center" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Статистика ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["active"])), [
                  [_directive_ripple]
                ])
              : _createCommentVNode("", true),
            (
            !_ctx.user?.showStatisticsOnly &&
              !_ctx.user?.notFullAccess &&
              !_ctx.user.isAppsAdmin
          )
              ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                  key: 1,
                  clickable: "",
                  active: _ctx.link === 'affiliates',
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickItem('affiliates', 'affiliates'))),
                  "active-class": "custom-menu-link"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: "/affiliates",
                      class: "row text-white"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, { avatar: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_icon, {
                              name: "account_box",
                              color: "secondary"
                            }),
                            (Number(_ctx.affiliatesBadges.new_affiliates_count))
                              ? (_openBlock(), _createBlock(_component_q_badge, {
                                  key: 0,
                                  class: "q-mr-xs",
                                  color: "red",
                                  floating: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.affiliatesBadges.new_affiliates_count), 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_q_item_section, { class: "flex justify-center" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Аффилейты ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["active"])), [
                  [_directive_ripple]
                ])
              : _createCommentVNode("", true),
            (!_ctx.user?.showStatisticsOnly && !_ctx.user.isAppsAdmin)
              ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                  key: 2,
                  clickable: "",
                  active: _ctx.link === 'offers',
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onClickItem('offers', 'offers'))),
                  "active-class": "custom-menu-link"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: "/offers",
                      class: "row text-white"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, { avatar: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_icon, {
                              name: "description",
                              color: "orange-5"
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_q_item_section, { class: "flex justify-center" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Офферы ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["active"])), [
                  [_directive_ripple]
                ])
              : _createCommentVNode("", true),
            (!_ctx.user?.showStatisticsOnly && !_ctx.user.isAppsAdmin)
              ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                  key: 3,
                  clickable: "",
                  active: _ctx.link === 'campaigns',
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onClickItem('campaigns', 'campaigns'))),
                  "active-class": "custom-menu-link"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: "/campaigns",
                      class: "row text-white"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, { avatar: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_icon, {
                              name: "alt_route",
                              color: "positive"
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_q_item_section, { class: "flex justify-center" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Потоки / Капы ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["active"])), [
                  [_directive_ripple]
                ])
              : _createCommentVNode("", true),
            (!_ctx.user?.showStatisticsOnly && !_ctx.user.isAppsAdmin)
              ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                  key: 4,
                  clickable: "",
                  active: _ctx.link === 'access',
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onClickItem('access', 'offers/access'))),
                  "active-class": "custom-menu-link"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: "/offers/access",
                      class: "row text-white"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, { avatar: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_icon, { name: "lock" }),
                            (_ctx.offersAccessCount)
                              ? (_openBlock(), _createBlock(_component_q_badge, {
                                  key: 0,
                                  class: "q-mr-xs",
                                  color: "red",
                                  floating: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.offersAccessCount), 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_q_item_section, { class: "flex justify-center" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Доступы ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["active"])), [
                  [_directive_ripple]
                ])
              : _createCommentVNode("", true),
            (!_ctx.user?.showStatisticsOnly && !_ctx.user.isAppsAdmin)
              ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                  key: 5,
                  clickable: "",
                  active: _ctx.link === 'advertisers',
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onClickItem('advertisers', 'advertisers'))),
                  "active-class": "custom-menu-link"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: "/advertisers",
                      class: "row text-white"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, { avatar: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_icon, {
                              name: "people",
                              style: {"color":"#5bb3ff !important"}
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_q_item_section, { class: "flex justify-center" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Рекламодатели ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["active"])), [
                  [_directive_ripple]
                ])
              : _createCommentVNode("", true),
            (
            !_ctx.user?.showStatisticsOnly &&
              !_ctx.user?.notFullAccess &&
              !_ctx.user?.isAppsAdmin &&
              !_ctx.user?.isLimitedAccess &&
              !_ctx.user?.isLightLimitedAccess
          )
              ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                  key: 6,
                  clickable: "",
                  active: _ctx.link === 'admins',
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onClickItem('admins', 'admins'))),
                  "active-class": "custom-menu-link"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: "/admins",
                      class: "row text-white"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, { avatar: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_icon, {
                              name: "manage_accounts",
                              style: {"color":"#5bb3ff !important"}
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_q_item_section, { class: "flex justify-center" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Админы ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["active"])), [
                  [_directive_ripple]
                ])
              : _createCommentVNode("", true),
            (!_ctx.user?.showStatisticsOnly && !_ctx.user?.isLimitedAccess && !_ctx.user?.isLightLimitedAccess)
              ? (_openBlock(), _createBlock(_component_q_expansion_item, {
                  key: 7,
                  modelValue: _ctx.isAppsExpanded,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.isAppsExpanded) = $event)),
                  "expand-separator": "",
                  icon: "phone_iphone",
                  label: "APPS",
                  "header-class": _ctx.link === 'apps' ? 'custom-menu-link' : ''
                }, {
                  default: _withCtx(() => [
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                      clickable: "",
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onClickItem('apps', 'mobile-apps')))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          to: "/mobile-apps",
                          class: "row text-white"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_section, { avatar: "" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_icon, {
                                  style: {"color":"#ffd000 !important"},
                                  name: "casino"
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_q_item_section, { class: "flex justify-center items-center" }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Все приложения ")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })), [
                      [_directive_ripple]
                    ]),
                    (!_ctx.user?.isAppsAdmin)
                      ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                          key: 0,
                          clickable: "",
                          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onClickItem('apps', 'apps/statistics')))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_router_link, {
                              to: "/apps/statistics",
                              class: "row text-white"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_item_section, { avatar: "" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                      style: {"color":"#ffd000 !important"},
                                      name: "bar_chart"
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_q_item_section, { class: "flex justify-center items-center" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Статистика ")
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })), [
                          [_directive_ripple]
                        ])
                      : _createCommentVNode("", true),
                    (!_ctx.user?.isAppsAdmin)
                      ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                          key: 1,
                          clickable: "",
                          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onClickItem('apps', 'apps/finances')))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_router_link, {
                              to: "/apps/finances",
                              class: "row text-white"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_item_section, { avatar: "" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                      style: {"color":"#ffd000 !important"},
                                      name: "paid"
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_q_item_section, { class: "flex justify-center items-center" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" FIN TOTAL ")
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })), [
                          [_directive_ripple]
                        ])
                      : _createCommentVNode("", true),
                    (!_ctx.user?.isAppsAdmin)
                      ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                          key: 2,
                          clickable: "",
                          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.onClickItem('apps', 'apps/access')))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_router_link, {
                              to: "/apps/access",
                              class: "row text-white"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_item_section, { avatar: "" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                      style: {"color":"#ffd000 !important"},
                                      name: "lock"
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_q_item_section, { class: "flex justify-center items-center" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Доступы ")
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })), [
                          [_directive_ripple]
                        ])
                      : _createCommentVNode("", true),
                    (!_ctx.user?.isAppsAdmin)
                      ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                          key: 3,
                          clickable: "",
                          onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.onClickItem('apps', 'push-notifications')))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_router_link, {
                              to: "/push-notifications",
                              class: "row text-white"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_item_section, { avatar: "" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                      style: {"color":"#ffd000 !important"},
                                      name: "send_to_mobile"
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_q_item_section, { class: "flex justify-center items-center" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Пуши ")
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })), [
                          [_directive_ripple]
                        ])
                      : _createCommentVNode("", true),
                    (!_ctx.user?.isAppsAdmin)
                      ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                          key: 4,
                          clickable: "",
                          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.onClickItem('apps', 'apps/balances')))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_router_link, {
                              to: "/apps/balances",
                              class: "row text-white"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_item_section, { avatar: "" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                      style: {"color":"#ffd000 !important"},
                                      name: "money"
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_q_item_section, { class: "flex justify-center items-center" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Транзакции ")
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })), [
                          [_directive_ripple]
                        ])
                      : _createCommentVNode("", true),
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                      clickable: "",
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.onClickItem('apps', 'apps/postback-logs')))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          to: "/apps/postback-logs",
                          class: "row text-white"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_section, { avatar: "" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_icon, {
                                  style: {"color":"#ffd000 !important"},
                                  name: "storage"
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_q_item_section, { class: "flex justify-center items-center" }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Логи ")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })), [
                      [_directive_ripple]
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "header-class"]))
              : _createCommentVNode("", true),
            (!_ctx.user?.showStatisticsOnly && !_ctx.user.isAppsAdmin)
              ? (_openBlock(), _createBlock(_component_q_expansion_item, {
                  key: 8,
                  "expand-separator": "",
                  icon: "paid",
                  label: "FIN",
                  "header-class": _ctx.link === 'fin' ? 'custom-menu-link' : ''
                }, {
                  header: _withCtx(() => [
                    _createVNode(_component_q_item_section, { avatar: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_icon, {
                          name: "paid",
                          color: "secondary"
                        }),
                        (_ctx.sumPayoutBadges)
                          ? (_openBlock(), _createBlock(_component_q_badge, {
                              key: 0,
                              class: "q-mr-xs",
                              color: "red",
                              floating: ""
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.sumPayoutBadges), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_item_section, null, {
                      default: _withCtx(() => [
                        _createTextVNode(" FIN ")
                      ]),
                      _: 1
                    })
                  ]),
                  default: _withCtx(() => [
                    (!_ctx.user?.notFullAccess)
                      ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                          key: 0,
                          clickable: "",
                          onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.onClickItem('fin', 'payouts')))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_section, { class: "flex justify-center items-center column" }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_2, [
                                  _createTextVNode(" Выплаты "),
                                  _createElementVNode("span", null, [
                                    (_ctx.payoutsBadges.readyForPaymentCount)
                                      ? (_openBlock(), _createBlock(_component_q_badge, {
                                          key: 0,
                                          class: "q-mr-xs",
                                          color: "red"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.payoutsBadges.readyForPaymentCount), 1)
                                          ]),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true),
                                    (_ctx.payoutsBadges.processingCount)
                                      ? (_openBlock(), _createBlock(_component_q_badge, {
                                          key: 1,
                                          class: "q-mr-xs",
                                          color: "primary"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.payoutsBadges.processingCount), 1)
                                          ]),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true),
                                    (_ctx.payoutsBadges.inReviewCount)
                                      ? (_openBlock(), _createBlock(_component_q_badge, {
                                          key: 2,
                                          color: "orange"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.payoutsBadges.inReviewCount), 1)
                                          ]),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true)
                                  ])
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })), [
                          [_directive_ripple]
                        ])
                      : _createCommentVNode("", true),
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                      clickable: "",
                      onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.onClickItem('fin', 'finances')))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          to: "/finances",
                          class: "row text-white"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_section, { class: "flex justify-center items-center" }, {
                              default: _withCtx(() => [
                                _createTextVNode(" FIN TOTAL ")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })), [
                      [_directive_ripple]
                    ]),
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                      clickable: "",
                      onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.onClickItem('courses', 'currency-courses')))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          to: "/currency-courses",
                          class: "row text-white"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_section, { class: "flex justify-center items-center" }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Курсы валют ")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })), [
                      [_directive_ripple]
                    ])
                  ]),
                  _: 1
                }, 8, ["header-class"]))
              : _createCommentVNode("", true),
            (!_ctx.user?.showStatisticsOnly && !_ctx.user.isAppsAdmin)
              ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                  key: 9,
                  clickable: "",
                  active: _ctx.link === 'tickets',
                  onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.onClickItem('tickets', 'tickets'))),
                  "active-class": "custom-menu-link"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: "/tickets",
                      class: "row text-white"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, { avatar: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_icon, { name: "chat" }),
                            (
                  _ctx.ticketsBadges.new_tickets_count ||
                    _ctx.ticketsBadges.waiting_tickets_count
                )
                              ? (_openBlock(), _createBlock(_component_q_badge, {
                                  key: 0,
                                  class: "q-mr-xs",
                                  color: "red",
                                  floating: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.ticketsBadges.new_tickets_count +
                    _ctx.ticketsBadges.waiting_tickets_count), 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_q_item_section, { class: "flex justify-center" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Тикеты ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["active"])), [
                  [_directive_ripple]
                ])
              : _createCommentVNode("", true),
            (!_ctx.user?.showStatisticsOnly && !_ctx.user.isAppsAdmin)
              ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                  key: 10,
                  clickable: "",
                  active: _ctx.link === 'logs',
                  onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.onClickItem('logs', 'logs'))),
                  "active-class": "custom-menu-link"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: "/logs",
                      class: "row text-white"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, { avatar: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_icon, { name: "search" })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_q_item_section, { class: "flex justify-center" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Трафик ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["active"])), [
                  [_directive_ripple]
                ])
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "mini", "behavior"]))
}