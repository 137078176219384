
import { defineComponent } from 'vue'
import { mapMutations, mapState } from 'vuex'
import { getOptions } from '@/utils'
import AddDomainDialog from '@/components/dialogs/AddDomainDialog.vue'
import { lookupRedirectDomains } from '@/utils/lookups'

export default defineComponent({
  name: 'RedirectDomain',
  props: ['defaultOptions'],
  components: { AddDomainDialog },
  computed: mapState('campaignsModule', ['campaign', 'errors']),
  data: function() {
    return {
      showAddDomainDialog: false,
      redirectDomains: [...(this.defaultOptions.redirectDomains || [])],
    }
  },
  async mounted() {
    if (!this.campaign.redirectDomainId) {
      const redirectDomains = await getOptions(
        this.redirectDomains as [],
        '',
        '/api/redirect-domains',
      )
      // filter tracker.traffic.church and tracker.moniacc.com
      this.redirectDomains = redirectDomains.filter(
        (d: any) => d.id !== 1 && d.id !== 5,
      )
      this.campaign.redirectDomainId = (redirectDomains as any)[2]?.id // tracker.gambeat.io
      const gambeatTrackerDomain = this.redirectDomains.find(d => d.id === 15)
      if (gambeatTrackerDomain) {
        this.campaign.redirectDomainId = gambeatTrackerDomain.id
      }
    }
  },
  methods: {
    ...mapMutations('campaignsModule', ['update']),
    async getRedirectDomains(value: string, update: Function) {
      await lookupRedirectDomains(this, value, update, [], {
        affiliateId: this.campaign.affiliateId,
      })
    },
  },
})
