import { enumToArray, getFilteredVerticals } from '@/utils'
import { groupByOptions } from '@/pages/tds-statistics/config/groupByOptions'
import { financeGroupByOptions } from '@/pages/apps-finances/config/groupByOptions'
import { AffiliateStatus } from '@/enums/AffiliateStatus'
import axios from 'axios'
import { Affiliate } from '@/store/types'
import { MobileAppStatus } from '@/enums/MobileAppStatus'
import {AppMarket} from "@/enums/AppMarket";

export function getFiltersData(search = null, isFinances = false) {
  return {
    affiliateStatuses: enumToArray(AffiliateStatus),
    mobileAppStatuses: enumToArray(MobileAppStatus),
    appMarkets: enumToArray(AppMarket),
    admins: [],
    apps: [],
    geos: [],
    affiliates: [],
    search,
    trafficSources: [],
    verticals: getFilteredVerticals(),
    groupByOptions: isFinances ? financeGroupByOptions : groupByOptions,
  }
}

export async function initOptionsByRouteParams(ref: any) {
  if (ref.$route.params.mobileAppId) {
    ref.apps = (await axios.get('/api/apps/')).data
  }
  if (ref.$route.params.geoId) {
    ref.geos = (await axios.get('/api/geos/')).data
  }
  if (ref.$route.params.trafficSourceId) {
    ref.trafficSources = (await axios.get('/api/traffic-sources/')).data
  }
  if (ref.$route.params.affiliateId) {
    const affiliates = (await axios.get('/api/affiliates/list')).data
    ref.affiliates = affiliates.map((affiliate: Affiliate) => ({
      ...affiliate,
      email: affiliate.credentials.email,
    }))
  }
}

export async function initFilterOptions(ref: any) {
  ref.apps = (await axios.get('/api/apps/')).data
  ref.geos = (await axios.get('/api/geos/')).data
  ref.trafficSources = (await axios.get('/api/traffic-sources/')).data
  const affiliates = (await axios.get('/api/affiliates/list')).data
  ref.affiliates = affiliates.map((affiliate: Affiliate) => ({
    ...affiliate,
    email: affiliate.credentials.email,
  }))
}
