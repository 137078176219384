
import { defineComponent } from 'vue'
import {
  enumToArray,
  formatDate,
  payoutCurrencies as currencies,
} from '@/utils'
import { PaymentSystem } from '@/enums/PaymentSystem'
import { PayoutStatus } from '@/enums/PayoutStatus'
import { PaymentSystemNetwork } from '@/enums/PaymentSystemNetwork'
import IconWithName from '@/components/icons/IconWithName.vue'
import PayoutLeads from '@/pages/payouts/PayoutLeads.vue'
import { mapState } from 'vuex'
import store from '@/store'

export default defineComponent({
  name: 'PayoutInfo',
  components: { PayoutLeads, IconWithName },
  computed: {
    ...mapState('payoutsModule', ['payoutData']),
  },
  watch: {
    currentPayout() {
      this.payout = {
        ...this.currentPayout,
        paidValueCurrency:
          this.currentPayout.paidValueCurrency === undefined ||
          this.currentPayout.paidValueCurrency === null
            ? this.currentPayout.payoutCurrency
            : this.currentPayout.paidValueCurrency,
        paidValueAmount:
          this.currentPayout.paidValueAmount === undefined ||
          this.currentPayout.paidValueAmount === null
            ? this.currentPayout.payoutAmount
            : this.currentPayout.paidValueAmount,
      }
      this.createdAt = formatDate(this.currentPayout.createdAt)
      this.updatedAt = formatDate(this.currentPayout.updatedAt)
      this.affiliateEmail =
        this.currentPayout.affiliateEmail +
        ' [ ID#' +
        this.currentPayout.affiliateId +
        ' ]'
    },
  },
  data: function() {
    if (!store.state.payoutsModule.payoutData.id) {
      this.$router.push('/payouts')
      return {} as any
    }
    const payout: any = store.state.payoutsModule.payoutData
    return {
      paymentSystemNetworks: enumToArray(PaymentSystemNetwork),
      accounts: [],
      currentPayout: payout,
      createdAt: formatDate(payout.createdAt),
      updatedAt: formatDate(payout.updatedAt),
      affiliateEmail:
        payout.affiliateEmail + ' [ ID#' + payout.affiliateId + ' ]',
      payout: {
        ...payout,
        paidValueCurrency:
          payout.paidValueCurrency === undefined ||
          payout.paidValueCurrency === null
            ? payout.payoutCurrency
            : payout.paidValueCurrency,
        paidValueAmount:
          payout.paidValueAmount === undefined ||
          payout.paidValueAmount === null
            ? payout.payoutAmount
            : payout.paidValueAmount,
      },
      currencies,
      payoutStatusesEnum: PayoutStatus,
      payoutStatuses: enumToArray(PayoutStatus),
      paymentSystems: enumToArray(PaymentSystem).filter(
        e => (e.id as any) !== PaymentSystem.QIWI,
      ),
    }
  },
  methods: {
    frmtDate(date: Date) {
      return formatDate(date)
    },
  },
})
