import { Balance } from '@/store/types'
import { BalanceType } from '@/enums/BalanceType'
import { Currency } from '@/enums/Currency'
import axios from 'axios'
import { formatValue } from '@/utils'

export function getAffiliateBalances(
  affiliateBalances: Balance[],
  isReferralsBalances = false,
) {
  const balances: Balance[] = affiliateBalances
    .filter(
      (b: Balance) =>
        !b.isRentAppsBalance &&
        (isReferralsBalances ? b.isReferralBalance : !b.isReferralBalance),
    )
    .sort((b1: Balance, b2: Balance) =>
      String(b1.value.currency).localeCompare(String(b2.value.currency)),
    )
  const available = balances.filter(
    (b: Balance) => b.type === BalanceType.Available,
  )
  const hold = balances.filter((b: Balance) => b.type === BalanceType.Hold)
  const formattedBalances = []
  for (let i = 0; i < available.length; i++) {
    if (
      isReferralsBalances &&
      !Number(available[i].value.amount) &&
      !Number(hold[i].value.amount)
    ) {
      continue
    }
    formattedBalances.push(
      `${formatValue(available[i].value.amount)} [${formatValue(
        hold[i].value.amount,
      )}] ${Currency[available[i].value.currency]}  ${
        available[i].offer?.name
          ? '(оффер - ' + available[i].offer.name + ')'
          : ''
      } ${
        (available[i] as any).affiliateId
          ? '(ref #' + (hold[i] as any).affiliateId + ')'
          : ''
      }`,
    )
  }
  return formattedBalances.join('\n')
}

export function getAffiliateBalancesRaw(
  affiliateBalances: Balance[],
  referralBalances = [],
) {
  const balances: any[] = affiliateBalances.sort((b1: any, b2: any) =>
    String(b1.valueCurrency).localeCompare(String(b2.valueCurrency)),
  )
  const available = balances.filter(
    (b: Balance) => b.type === BalanceType.Available,
  )
  const hold = balances.filter((b: Balance) => b.type === BalanceType.Hold)
  const formattedBalances = []
  for (let i = 0; i < available.length; i++) {
    formattedBalances.push(
      `${formatValue(available[i].valueAmount)} [${formatValue(
        hold[i].valueAmount,
      )}] <small>${Currency[available[i].valueCurrency]}</small>  ${
        available[i].offerId ? '(оффер #' + available[i].offerId + ')' : ''
      }`,
    )
  }

  const availableRef: any[] = referralBalances.filter(
    (b: Balance) => b.type === BalanceType.Available,
  )
  const holdRef: any[] = referralBalances.filter(
    (b: Balance) => b.type === BalanceType.Hold,
  )
  for (let i = 0; i < availableRef.length; i++) {
    if (
      !Number(availableRef[i].valueAmount) &&
      !Number(holdRef[i].valueAmount)
    ) {
      continue
    }
    formattedBalances.push(
      `${formatValue(availableRef[i].valueAmount)} [${formatValue(
        holdRef[i].valueAmount,
      )}] <small>${Currency[availableRef[i].valueCurrency]}</small>  ${
        availableRef[i].affiliateId
          ? '(ref #' + availableRef[i].affiliateId + ')'
          : ''
      }`,
    )
  }
  return formattedBalances.join('<br/>')
}

export async function loginAsAffiliate(id: string) {
  const {
    data: { key },
  } = await axios.post('/api/affiliates/login', { id })
  if (location.hostname === 'localhost') {
    const port = location.host === 'localhost:8080' ? '8081' : '8080'
    window.open(`http://localhost:${port}/login?key=${key}`)
  } else {
    const host = location.hostname.replace('admin.', 'affiliate.')
    window.open(`https://${host}/login?key=${key}`)
  }
}
