
import axios from 'axios'
import { defineComponent } from 'vue'
import {
  getDefaultFilters,
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import { mapState } from 'vuex'
import getColumns from './getColumns'
import { Row } from '@/utils/request'
import { confirmDialog, showSuccessSnackBar } from '@/utils'

export default defineComponent({
  name: 'AdminsList',
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  computed: {
    ...mapState('adminsModule', ['admin', 'errors']),
  },
  data() {
    return {
      admins: [],
      ...getTableProperties('id', 100),
      filters: getDefaultFilters(),
      columns: getColumns(),
    }
  },
  methods: {
    onRowClick(evt: string, row: Row) {
      this.$router.push('/admins/' + row.id)
    },
    async deleteRow(props: TableRequestProps) {
      confirmDialog('Вы действительно хотите удалить админа?', async () => {
        this.loading = true
        await axios.delete('/api/admins', {
          params: { id: props.row?.id },
        })
        await this.onRequest({ pagination: this.pagination })
      })
    },
    async onRequest(props: TableRequestProps) {
      await request(props, this as Table, '/api/admins/')
    },
    copyKey(text: string) {
      const element = document.createElement('textarea')
      document.body.appendChild(element)
      element.value = text
      element.select()
      document.execCommand('copy')
      document.body.removeChild(element)
      showSuccessSnackBar('Ключ скопирован')
    },
  },
})
