import axios, { AxiosError } from 'axios'
import { RewardType } from '@/enums/RewardType'
import { Vertical } from '@/enums/Vertical'
import { isNaN } from 'lodash'
import { Currency } from '@/enums/Currency'
import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';
import { PushNotificationEventType } from '@/enums/PushNotificationEventType'
import { Day } from '@/enums/Day'
import { Timezone } from '@/enums/Timezone'
import { LeadLimitStatus } from '@/enums/LeadLimitStatus'
import {LeadStatus} from "@/enums/LeadStatus";

const StringIsNumber = (value: string) => !isNaN(Number(value))
export interface EnumItem<E> {
  id: keyof E
  name: E
}

export type RowValue = string | null | number | undefined
export function enumToArray<E>(enumObject: {
  [key: string]: E
}): EnumItem<E>[] {
  return Object.keys(enumObject)
    .filter(StringIsNumber)
    .map(key => ({ id: Number(key), name: enumObject[key] } as EnumItem<E>))
}

export const getGamblingRewardTypes = () => {
  const rewardTypes = enumToArray(RewardType)
  // rewardTypes.push({id: 99, name: 'Registration'} as any)
  return rewardTypes.filter(({ name }) =>
    ['FTD', 'BL', 'RevShare'].includes(name as string),
  )
}

export const getRewardTypesByVertical = (vertical: number | null) => {
  const rewardTypes = enumToArray(RewardType)
  switch (vertical) {
    case Vertical.Gambling:
      return rewardTypes.filter(({ name }) =>
        ['FTD', 'RevShare', 'BL'].includes(name as string),
      )
    case Vertical.Dating:
      return rewardTypes.filter(({ name }) =>
        ['CPS', 'SOI', 'DOI'].includes(name as string),
      )
    case Vertical.Crypto:
      return rewardTypes.filter(({ name }) =>
        ['CPL', 'CPA'].includes(name as string),
      )
    default:
      return rewardTypes
  }
}

export function isDefined(value: RowValue) {
  if (value === null || value === undefined) {
    return '—'
  }
  return value
}

export function formatValue(value: RowValue, round = 2, isMoney = false) {
  if (JSON.parse(localStorage.getItem('user') as any)?.hideFinances) {
    return '**'
  }
  const formattedValue = isDefined(value)
  if (!isNaN(Number(formattedValue)) && isMoney) {
    return (+formattedValue).toFixed(round)
  }
  if (Number.isInteger(+formattedValue)) {
    return Number.parseInt(String(formattedValue))
  }
  if (formattedValue === '—') {
    return formattedValue
  }
  return (+formattedValue).toFixed(round)
}

export function formatDate(isoDate: string | Date): string {
  if (!isoDate) {
    return '—'
  }
  const date = new Date(isoDate)
  if (!date) {
    return '—'
  }
  return (
    date
      .toLocaleDateString()
      .split('/')
      .reverse()
      .join('/') +
    ' ' +
    date.toLocaleTimeString()
  )
}

export async function getOptions(
  options: any[],
  value: string,
  url: string,
  ids: number[] = [],
  extraParams: any = {},
  notIncludeIds: number[] = [],
): Promise<[]> {
  const selectedIds = (ids || []).filter(Number)
  const notSelectedIds = (notIncludeIds || []).filter(Number)
  if (
    (!options.length || value === '') &&
    (selectedIds.length || notSelectedIds.length)
  ) {
    return (
      await axios.get(url, {
        params: {
          ...(extraParams || {}),
          ids: selectedIds,
          notIncludeIds: notSelectedIds,
          ...(value === '' ? {} : { search: value }),
        },
      })
    ).data
  }

  if (!options.length || value === '') {
    if (value) {
      const query: { search: string } = { search: value }
      return (
        await axios.get(url, { params: { ...query, ...(extraParams || {}) } })
      ).data
    }
    return (await axios.get(url, { params: extraParams })).data
  }

  const params: any = { search: value }
  if (selectedIds.length) {
    params.ids = selectedIds
  }
  if (notSelectedIds.length) {
    params.notIncludeIds = notSelectedIds
  }

  if (value !== '') {
    return (
      await axios.get(url, { params: { ...params, ...(extraParams || {}) } })
    ).data
  }

  return options as []
}

export function isNumberArraysEqual(
  firstArr: number[],
  secondArr: number[],
): boolean {
  if (!Array.isArray(firstArr) || !Array.isArray(secondArr)) {
    return false
  }
  return (
    JSON.stringify(firstArr.sort((a: number, b: number) => a - b)) ==
    JSON.stringify(secondArr.sort((a: number, b: number) => a - b))
  )
}

export function getPushNotificationEventTypes() {
  return [
    { label: 'Инсталл', value: PushNotificationEventType.Install },
    { label: 'Регистрация', value: PushNotificationEventType.Registration },
    { label: 'Депозит', value: PushNotificationEventType.Deposit },
  ]
}

export function getDays() {
  return [
    { label: 'Пн', value: Day.Monday },
    { label: 'Вт', value: Day.Tuesday },
    { label: 'Ср', value: Day.Wednesday },
    { label: 'Чт', value: Day.Thursday },
    { label: 'Пт', value: Day.Friday },
    { label: 'Сб', value: Day.Saturday },
    { label: 'Вс', value: Day.Sunday },
  ]
}

export function getFilteredVerticals() {
  const arr = enumToArray(Vertical)
  return arr.filter(
    (r: any) => r.id !== Vertical.Dating && r.id !== Vertical.Crypto,
  )
}

export const payoutCurrencies = enumToArray(Currency).filter(c =>
  [Currency.USD, Currency.EUR, Currency.UAH, Currency.PLN].includes(
    (c.id as unknown) as number,
  ),
)

export function showInternalErrorSnackBar(err: AxiosError) {
  if (err.response?.status === 500) {
    Notify.create({
      type: 'negative',
      timeout: 3000,
      position: 'top-right',
      message: `Internal Server Error: ${err.message}`,
    })
  }
}

export function showInfoSnackBar() {
  Notify.create({
    type: 'info',
    message: `Подождите, идет загрузка. Это может занять некоторое время...`,
    position: 'top-right',
    timeout: 4000,
  })
}

export function showErrorMessageSnackBar(message: string) {
  Notify.create({
    type: 'negative',
    timeout: 3000,
    position: 'top-right',
    message,
  })
}

export function showSuccessSnackBar(message: string) {
  Notify.create({
    type: 'positive',
    timeout: 3000,
    position: 'top-right',
    message,
  })
}

export function confirmDialog(message: string, okFn: any) {
  Dialog.create({
    title: 'Подтвердите действие',
    message,
    cancel: true,
    persistent: true,
  }).onOk(okFn)
}

export function randomString(length: number) {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function getCorrectExportValue(row: any, groupBy: string) {
  switch (groupBy) {
    case 'affiliateId':
      return row[groupBy] ? '#' + row[groupBy] + ` ${row.affiliateEmail}` : ''
    case 'advertiserId':
      return row[groupBy] ? '#' + row[groupBy] + ` ${row.advertiserName}` : ''
    case 'offerId':
      return row[groupBy] ? '#' + row[groupBy] + ` ${row.offerName}` : ''
    case 'geoId':
      return row[groupBy] ? `${row.geoCode}` : ''
    case 'managerId':
      return row[groupBy] ? '#' + row[groupBy] + ` ${row.managerEmail}` : ''
    default:
      return row[groupBy] ? row[groupBy] : ''
  }
}

export function getTimezones() {
  return [
    {
      id: Timezone.InternationalWest,
      name: '(GMT-12:00) International Date Line West',
      value: -12,
    },
    {
      id: Timezone.Samoa,
      name: '(GMT-11:00) Midway Island, Samoa',
      value: -11,
    },
    { id: Timezone.Hawaii, name: '(GMT-10:00) Hawaii', value: -10 },
    { id: Timezone.Alaska, name: '(GMT-09:00) Alaska', value: -9 },
    {
      id: Timezone.USAMountain,
      name: '(GMT-08:00) Pacific Time (US & Canada)',
      value: -8,
    },
    {
      id: Timezone.Arizona,
      name: '(GMT-07:00) Mountain Time (US & Canada), Arizona',
      value: -7,
    },
    {
      id: Timezone.CentralAmerica,
      name: '(GMT-06:00) Central America',
      value: -6,
    },
    {
      id: Timezone.EasternAmerica,
      name: '(GMT-05:00) Eastern Time (US & Canada), Indiana (East)',
      value: -5,
    },
    {
      id: Timezone.AtlanticTime,
      name: '(GMT-04:00) Atlantic Time (Canada), Santiago',
      value: -4,
    },
    {
      id: Timezone.Brasilia,
      name: '(GMT-03:00) Brasilia, Greenland, Montevideo',
      value: -3,
    },
    { id: Timezone.MidAtlantic, name: '(GMT-02:00) Mid-Atlantic', value: -2 },
    { id: Timezone.CapeVerde, name: '(GMT-01:00) Cape Verde Is.', value: -1 },
    {
      id: Timezone.UTC,
      name: '(GMT+00:00) Greenwich Mean Time: Dublin, Lisbon, London',
      value: 0,
    },
    {
      id: Timezone.Berlin,
      name: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
      value: 1,
    },
    {
      id: Timezone.Kyiv,
      name: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
      value: 2,
    },
    {
      id: Timezone.Moscow,
      name: '(GMT+03:00) Moscow, St. Petersburg, Kuwait, Riyadh, Baghdad',
      value: 3,
    },
    {
      id: Timezone.Yerevan,
      name: '(GMT+04:00) Abu Dhabi, Muscat, Yerevan, Kabul',
      value: 4,
    },
    {
      id: Timezone.Islamabad,
      name: '(GMT+05:00) Islamabad, Karachi, Tashkent',
      value: 5,
    },
    {
      id: Timezone.Novosibirsk,
      name: '(GMT+06:00) Almaty, Novosibirsk',
      value: 6,
    },
    {
      id: Timezone.Jakarta,
      name: '(GMT+07:00) Bangkok, Hanoi, Jakarta',
      value: 7,
    },
    {
      id: Timezone.Singapore,
      name: '(GMT+08:00) Kuala Lumpur, Singapore, Hong Kong',
      value: 8,
    },
    {
      id: Timezone.Tokyo,
      name: '(GMT+09:00) Osaka, Sapporo, Tokyo, Seoul, Yakutsk',
      value: 9,
    },
    {
      id: Timezone.Sydney,
      name: '(GMT+10:00) Canberra, Melbourne, Sydney',
      value: 10,
    },
    {
      id: Timezone.NewCaledonia,
      name: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
      value: 11,
    },
    {
      id: Timezone.Fiji,
      name: '(GMT+12:00) Fiji, Kamchatka, Marshall Is., Wellington',
      value: 12,
    },
  ]
}

export function getLeadLimitStatuses() {
  return [
    { id: LeadLimitStatus.InProgress, name: 'В процессе' },
    { id: LeadLimitStatus.Finished, name: 'Пролито' },
    { id: LeadLimitStatus.OverLimited, name: 'Перелив' },
    { id: LeadLimitStatus.Stopped, name: 'Перестал лить' },
    { id: LeadLimitStatus.NotDefined, name: 'Не выставлена' },
  ]
}

export function getLeadStatuses() {
  return [
    { id: LeadStatus.Pending, name: 'Pending' },
    { id: LeadStatus.Hold, name: 'Hold (Все)' },
    { id: 5, name: 'Hold (Превышен Постклик)' },
    { id: 6, name: 'Hold (Отключен Постбек)' },
    { id: LeadStatus.Approved, name: 'Approved (Все)' },
    { id: 7, name: 'Approved (Превышен Постклик)' },
    { id: 8, name: 'Approved (Отключен Постбек)' },
    { id: 11, name: 'Approved (Paid)' },
    { id: LeadStatus.Rejected, name: 'Rejected (Все)' },
    { id: 9, name: 'Rejected (Превышен Постклик)' },
    { id: 10, name: 'Rejected (Отключен Постбек)' },
    { id: LeadStatus.Trash, name: 'Trash' },
  ]
}
