
import { defineComponent } from 'vue'
import {
  getAffiliateRewardsColumns,
  getCommonRewardsColumns,
} from './getColumns'
import {
  getPagination,
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import IconWithName from '@/components/icons/IconWithName.vue'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'RewardsPopup',
  components: { IconWithName },
  props: ['offerId', 'offerName'],
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  computed: {
    ...mapState('authModule', ['user']),
  },
  watch: {
    async filters() {
      await this.onRequest({ pagination: this.pagination })
    },
  },
  data() {
    return {
      ...getTableProperties('id', 500),
      affRewardsPagination: getPagination('id', 500),
      commonRewardsPagination: getPagination('id', 500),
      filters: {},
      affiliateRewardsColumns: getAffiliateRewardsColumns(),
      commonRewardsColumns: getCommonRewardsColumns(),
    }
  },
  methods: {
    async onRequest(props: TableRequestProps) {
      await request(
        props,
        this as Table,
        `/api/offers-access/affiliates/${this.offerId}`,
      )
      this.affRewardsPagination = {
        ...this.pagination,
        rowsNumber: (this.rows as any).affiliateRewards.length,
      }
      this.commonRewardsPagination = {
        ...this.pagination,
        rowsNumber: (this.rows as any).commonRewards.length,
      }
    },
    editRewards(tab: 'affiliates' | 'targeting') {
      if (location.hostname === 'localhost') {
        const port = '8080'
        window.open(
          `http://localhost:${port}/offers/${this.offerId}?tab=${tab}`,
        )
      } else {
        window.open(
          `https://${location.hostname}/offers/${this.offerId}?tab=${tab}`,
        )
      }
    },
  },
})
