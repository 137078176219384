
import { defineComponent } from 'vue'
import VueApexCharts from 'vue3-apexcharts'
import ApexCharts from 'apexcharts'
import { formatValue } from '@/utils'
import getColorByColumn from '@/components/charts/getColors'
import { intersection, maxBy, sum } from 'lodash'
import { getISOWeek } from 'date-fns'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'LineChart',
  props: [
    'rows',
    'columns',
    'loading',
    'allColumns',
    'groupBy',
    'groupByOptions',
  ],
  components: {
    apexchart: VueApexCharts,
  },
  beforeUnmount() {
    ApexCharts.exec('lineChart', 'destroy')
  },
  computed: {
    ...mapState('authModule', ['user']),
  },
  watch: {
    columns() {
      const {
        series,
        xaxis,
        yaxis,
        stroke,
        fill,
        stacked,
        colors,
        annotations,
      } = this.getData()
      ApexCharts.exec('lineChart', 'clearAnnotations')
      ApexCharts.exec(
        'lineChart',
        'updateOptions',
        {
          chart: {
            stacked,
          },
          series,
          annotations,
          stroke,
          fill,
          colors,
          xaxis,
          yaxis,
        },
        false,
        false,
      )
    },
    rows: {
      handler: function() {
        const {
          series,
          xaxis,
          yaxis,
          stroke,
          fill,
          stacked,
          colors,
          annotations,
        } = this.getData()
        this.chartOptions.xaxis = xaxis
        this.chartOptions.yaxis = yaxis
        this.chartOptions.stroke = stroke
        this.chartOptions.fill = fill
        this.chartOptions.colors = colors
        this.chartOptions.annotations = annotations
        this.chartOptions.chart.stacked = stacked
        this.series = series
      },
      deep: true,
    },
  },
  data: function() {
    const {
      series,
      xaxis,
      yaxis,
      stroke,
      fill,
      stacked,
      colors,
      annotations,
    } = this.getData()
    return {
      series,
      chartOptions: {
        chart: {
          id: 'lineChart',
          type: 'line',
          height: 350,
          stacked,
        },
        annotations,
        dataLabels: {
          enabled: false,
        },
        stroke,
        fill,
        xaxis,
        yaxis,
        legend: {
          show: true,
          onItemClick: {
            toggleDataSeries: true,
          },
          position: 'top',
        },
        responsive: [
          {
            breakpoint: 1500,
            options: {
              chart: {
                width: 1000,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 480,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        colors,
        noData: {
          text: 'NO DATA',
          style: {
            color: '#000000',
            fontSize: '18px',
            fontFamily: 'Helvetica',
            fontWeight: 'bold',
          },
        },
      },
    }
  },
  methods: {
    getData() {
      const allColumns = this.allColumns
      const colors = []
      const minDayRevenue = 1050
      const minWeekRevenue = minDayRevenue * 7
      const minMonthRevenue = minDayRevenue * 7 * 4
      const minDayYellowRevenue = 1200
      const minWeekYellowRevenue = minDayYellowRevenue * 7
      const minMonthYellowRevenue = minDayYellowRevenue * 30
      const minDayGreenRevenue = 1700
      const minWeekGreenRevenue = minDayGreenRevenue * 7
      const minMonthGreenRevenue = minDayGreenRevenue * 30
      const profitColumns = [
        'revenue',
        'revenueHold',
        'usdRevenue',
        'eurRevenue',
        'uahRevenue',
        'usdRevenueHold',
        'eurRevenueHold',
        'uahRevenueHold',
      ]
      let sortedRows = [...this.rows]
      if (this.groupBy === 'week') {
        sortedRows = [...this.rows].sort(
          (r1, r2) => new Date(r1.week).getTime() - new Date(r2.week).getTime(),
        )
      }
      if (this.groupBy === 'month') {
        sortedRows = [...this.rows].sort(
          (r1, r2) =>
            new Date(r1.month).getTime() - new Date(r2.month).getTime(),
        )
      }
      for (const col of this.columns) {
        colors.push(getColorByColumn(col))
      }
      const series = []
      let stackedCount = 0
      let stackedCount2 = 0
      let stackedCount3 = 0
      const stroke = {
        width: [],
        curve: 'smooth',
      }
      const fill = {
        opacity: [],
      }
      const yaxis = []
      let xaxis: any = {}
      if (
        this.groupBy === 'date' ||
        this.groupBy === 'week' ||
        this.groupBy === 'month'
      ) {
        if (this.groupBy === 'date') {
          xaxis = {
            type: 'datetime',
            min: Date.now(),
            max: Date.now(),
            categories: [],
            labels: {
              datetimeUTC: false,
              format: 'dd MMM yyyy',
            },
          }
        } else {
          xaxis = {
            type: 'category',
            categories: [],
            labels: {
              datetimeUTC: false,
              format:
                this.groupBy === 'week'
                  ? getISOWeek(new Date())
                  : new Date().getMonth() + 1,
            },
          }
        }

        if (this.groupBy === 'date') {
          xaxis.labels = {
            datetimeUTC: false,
            format: 'dd MMM yyyy',
          }
        }
        for (const row of sortedRows) {
          const value =
            this.groupBy === 'date'
              ? row.date
              : this.groupBy === 'week'
              ? row.week
              : row.month
          if (this.groupBy === 'week') {
            xaxis.categories.push(
              'week #' +
                getISOWeek(new Date(value)) +
                `  (${new Date(value).getFullYear()})`,
            )
          } else if (this.groupBy === 'month') {
            xaxis.categories.push(
              'month #' +
                (new Date(value).getMonth() + 1) +
                `  (${new Date(value).getFullYear()})`,
            )
          } else {
            xaxis.categories.push(new Date(value).getTime())
          }
        }
        if (this.groupBy === 'date') {
          const min = Math.min.apply(null, xaxis.categories)
          const max = Math.max.apply(null, xaxis.categories)
          xaxis.min = min === Infinity ? new Date().getTime() : min
          xaxis.max = max === -Infinity ? new Date().getTime() : max
        }
      } else {
        sortedRows.sort((r1: any, r2: any) => {
          let sum1 = 0
          let sum2 = 0
          for (const col of this.columns) {
            sum1 += Number(r1[col])
            sum2 += Number(r2[col])
          }
          if (sum1 > sum2) {
            return -1
          }
          if (sum1 < sum2) {
            return 1
          }
          return 0
        })
        xaxis = {
          categories: [],
        }
        const field = this.groupByOptions.find(
          (r: any) => r.id === this.groupBy,
        )?.field
        for (const row of sortedRows) {
          const id =
            this.groupBy === 'offerId' ||
            this.groupBy === 'affiliateId' ||
            this.groupBy === 'id' ||
            this.groupBy === 'mobileAppId'
              ? ` [ID #${row[this.groupBy]}]`
              : ''
          xaxis.categories.push(
            this.user?.notFullAccess && this.groupBy === 'affiliateId'
              ? id
              : row[field] + id,
          )
        }
      }

      const {
        stackedYMaxConversions,
        stackedYMaxFinances,
        stackedYMaxRevenue,
      } = this.getStackedY()
      for (const [i, col] of this.columns.entries()) {
        const isConversions = [
          'approvedLeads',
          'holdLeads',
          'trashLeads',
          'rejectedLeads',
        ].includes(col)
        const isFinances = ['approvedSum', 'holdSum', 'rejectedSum'].includes(
          col,
        )
        const isRevenue = profitColumns.includes(col)
        if (isConversions) {
          stackedCount++
        }
        if (isFinances) {
          stackedCount2++
        }
        if (isRevenue) {
          stackedCount3++
        }
        const data = []
        const name = allColumns.find((r: any) => r.name === col)
          ?.fullLabel as string
        for (const row of sortedRows) {
          data.push(formatValue(Number(row[col])))
        }
        series.push({
          name,
          data,
          type: [
            'firstDepositCount',
            'depositCount',
            'uniqueDepositCount',
            'approvedLeads',
            'holdLeads',
            'trashLeads',
            'rejectedLeads',
            'approvedSum',
            'holdSum',
            'rejectedSum',
            'paidInstallSum',
            'ppInstallSum',
            'ppInstallSumHold',
            'revenue',
            'revenueHold',
            'usdRevenue',
            'eurRevenue',
            'uahRevenue',
            'usdRevenueHold',
            'eurRevenueHold',
            'uahRevenueHold',
          ].includes(col)
            ? 'column'
            : 'area',
        })
        if (
          (!isConversions && !isFinances && !isRevenue) ||
          (isConversions && stackedCount == 1) ||
          (isFinances && stackedCount2 == 1) ||
          (isRevenue && stackedCount3 == 1)
        ) {
          yaxis.push({
            min: 0,
            max:
              !isConversions && !isFinances && !isRevenue
                ? Math.max.apply(null, data as number[]) + 1
                : isConversions
                ? stackedYMaxConversions
                : isFinances
                ? stackedYMaxFinances
                : this.getMinPeriodRevenue(
                    stackedYMaxRevenue,
                    minDayGreenRevenue,
                    minWeekGreenRevenue,
                    minMonthGreenRevenue,
                  ),
            opposite: i !== 0,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: colors[i],
            },
            labels: {
              style: {
                colors: colors[i],
              },
            },
            title: {
              text: isConversions
                ? 'Конверсии'
                : isFinances
                ? 'Финансы'
                : isRevenue
                ? 'Profit'
                : name,
              style: {
                color: colors[i],
              },
            },
            tooltip: {
              enabled: true,
            },
          })
        }
      }
      for (const serie of series) {
        if (serie.type === 'area') {
          stroke.width.push(4 as never)
          fill.opacity.push(0.25 as never)
        } else {
          stroke.width.push(2 as never)
          fill.opacity.push(1 as never)
        }
      }
      const stacked = stackedCount > 1 || stackedCount2 > 1 || stackedCount3 > 1
      const showRedLine =
        intersection(Object.values(this.columns), profitColumns).length &&
        ['date', 'week', 'month'].includes(this.groupBy) &&
        !this.$route.path.includes('statistics')
      const redLineValue =
        this.groupBy === 'date'
          ? minDayRevenue
          : this.groupBy === 'week'
          ? minWeekRevenue
          : this.groupBy === 'month'
          ? minMonthRevenue
          : 1
      const yellowLineValue =
        this.groupBy === 'date'
          ? minDayYellowRevenue
          : this.groupBy === 'week'
          ? minWeekYellowRevenue
          : this.groupBy === 'month'
          ? minMonthYellowRevenue
          : 1
      const greenLineValue =
        this.groupBy === 'date'
          ? minDayGreenRevenue
          : this.groupBy === 'week'
          ? minWeekGreenRevenue
          : this.groupBy === 'month'
          ? minMonthGreenRevenue
          : 1
      const annotations = {
        position: showRedLine ? 'front' : 'back',
        yaxis: [
          // {
          //   y: redLineValue,
          //   strokeDashArray: 0,
          //   opacity: showRedLine ? 1 : 0,
          //   borderColor: showRedLine ? '#d1001f' : '#fff',
          //   label: {
          //     position: 'left',
          //     offsetX: 50,
          //     offsetY: -2,
          //     borderColor: showRedLine ? '#d1001f' : '#fff',
          //     style: {
          //       color: '#fff',
          //       background: showRedLine ? '#d1001f' : '#fff',
          //       fontWeight: 'bold',
          //     },
          //     text: showRedLine ? `${redLineValue} USD` : '',
          //   },
          // },
          // {
          //   y: yellowLineValue,
          //   strokeDashArray: 0,
          //   opacity: showRedLine ? 1 : 0,
          //   borderColor: showRedLine ? '#e6b400' : '#fff',
          //   label: {
          //     position: 'left',
          //     offsetX: 50,
          //     offsetY: -2,
          //     borderColor: showRedLine ? '#e6b400' : '#fff',
          //     style: {
          //       color: '#fff',
          //       background: showRedLine ? '#e6b400' : '#fff',
          //       fontWeight: 'bold',
          //     },
          //     text: showRedLine ? `${yellowLineValue} USD` : '',
          //   },
          // },
          // {
          //   y: greenLineValue,
          //   strokeDashArray: 0,
          //   opacity: showRedLine ? 1 : 0,
          //   borderColor: showRedLine ? '#4caf50' : '#fff',
          //   label: {
          //     position: 'left',
          //     offsetX: 50,
          //     offsetY: -2,
          //     borderColor: showRedLine ? '#4caf50' : '#fff',
          //     style: {
          //       color: '#fff',
          //       background: showRedLine ? '#4caf50' : '#fff',
          //       fontWeight: 'bold',
          //     },
          //     text: showRedLine ? `${greenLineValue} USD` : '',
          //   },
          // },
        ],
      }
      return {
        series,
        xaxis,
        yaxis,
        stroke,
        fill,
        stacked,
        colors,
        annotations,
      }
    },
    getStackedY() {
      if (!this.rows.length) {
        return {
          stackedYMaxConversions: 0,
          stackedYMaxFinances: 0,
          stackedYMaxRevenue: 0,
        }
      }
      const convFields = intersection(this.columns, [
        'approvedLeads',
        'holdLeads',
        'trashLeads',
        'rejectedLeads',
      ])
      const financeFields = intersection(this.columns, [
        'approvedSum',
        'holdSum',
        'rejectedSum',
      ])
      const revenueFields = intersection(this.columns, [
        'revenue',
        'revenueHold',
        'usdRevenue',
        'eurRevenue',
        'uahRevenue',
        'usdRevenueHold',
        'eurRevenueHold',
        'uahRevenueHold',
      ])
      const maxConvData = []
      const maxFinanceData = []
      const maxRevenueData = []
      for (const col of convFields) {
        maxConvData.push((maxBy(this.rows, col) as any)[col])
      }
      for (const col of financeFields) {
        maxFinanceData.push((maxBy(this.rows, col) as any)[col])
      }
      for (const col of revenueFields) {
        maxRevenueData.push((maxBy(this.rows, col) as any)[col])
      }
      return {
        stackedYMaxConversions: sum(maxConvData.map(e => Number(e))),
        stackedYMaxFinances: sum(maxFinanceData.map(e => Number(e))),
        stackedYMaxRevenue: sum(maxRevenueData.map(e => Number(e))),
      }
    },
    getMinPeriodRevenue(
      stackedYMaxRevenue: number,
      dayRevenue: number,
      weekRevenue: number,
      monthRevenue: number,
    ) {
      if (this.groupBy === 'date' && stackedYMaxRevenue < dayRevenue) {
        return dayRevenue + 100
      }
      if (this.groupBy === 'week' && stackedYMaxRevenue < weekRevenue) {
        return weekRevenue + 500
      }
      if (this.groupBy === 'month' && stackedYMaxRevenue < monthRevenue) {
        return monthRevenue + 500
      }
      return stackedYMaxRevenue
    },
  },
})
