
import axios from 'axios'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ConversionHoldEditDialog',
  props: ['open', 'close', 'change', 'lead', 'selected'],
  computed: {
    showDialog(): boolean {
      return this.open
    },
  },
  data: function() {
    return {
      holdInDays: this.lead?.holdInDays,
      loading: false,
    }
  },
  methods: {
    async changeHold() {
      if (!this.holdInDays) {
        this.$emit('close')
        return
      }
      this.loading = true
      if (this.selected.length) {
        const data = this.selected.map((r: any) => ({
          id: r.id,
          status: r.status,
          holdInDays: this.holdInDays || null,
        }))
        await axios.put(`/api/conversions/change-hold`, data)
      } else {
        await axios.put(`/api/conversions/${this.lead?.id}/change-hold`, {
          holdInDays: this.holdInDays || null,
        })
      }
      this.$emit('change')
      this.$emit('close')
      this.loading = false
    },
  },
})
