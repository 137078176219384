export enum Timezone {
  InternationalWest,
  Samoa,
  Hawaii,
  Alaska,
  USAMountain,
  Arizona,
  CentralAmerica,
  EasternAmerica,
  AtlanticTime,
  Brasilia,
  MidAtlantic,
  CapeVerde,
  UTC,
  Berlin,
  Kyiv,
  Moscow,
  Yerevan,
  Islamabad,
  Novosibirsk,
  Jakarta,
  Singapore,
  Tokyo,
  Sydney,
  NewCaledonia,
  Fiji,
  Nukualofa,
}
