
import axios from 'axios'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ChangeInstallPriceDialog',
  props: ['open', 'close'],
  async created() {
    this.price = (await axios.get('/api/apps/install-price')).data?.amount || 0
  },
  computed: {
    showDialog(): boolean {
      return this.open
    },
  },
  data: function() {
    return {
      price: 0,
      loading: false,
    }
  },
  methods: {
    async save() {
      this.loading = true
      axios
        .post(`/api/apps/change-install-price`, { value: this.price })
        .then(() => {
          this.$emit('close')
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
})
