import { formatValue, isDefined, RowValue } from '@/utils'

export default function getColumns() {
  return [
    {
      name: 'groupByField',
      label: 'Дата',
      fullLabel: 'Дата',
      field: 'groupByField',
      align: 'left',
      sortable: true,
    },
    {
      name: 'paidInstalls',
      label: 'Платные инсталлы',
      fullLabel: 'Платные инсталлы, шт.',
      field: 'paidInstalls',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'freeInstalls',
      label: 'Бесплатные инсталлы',
      fullLabel: 'Бесплатные инсталлы, шт.',
      field: 'freeInstalls',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'ppInstalls',
      label: 'Инсталлы на офферы пп',
      fullLabel: 'Инсталлы на офферы пп, шт.',
      field: 'ppInstalls',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'paidInstallSum',
      label: 'Платные инсталлы, $',
      fullLabel: 'Платные инсталлы, $',
      field: 'paidInstallSum',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'ppInstallSum',
      label: 'Инсталлы на офферы пп APPROVE, $',
      fullLabel: 'Инсталлы на офферы пп APPROVE, $',
      field: 'ppInstallSum',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'ppInstallSumHold',
      label: 'Инсталлы на офферы пп HOLD, $',
      fullLabel: 'Инсталлы на офферы пп HOLD, $',
      field: 'ppInstallSumHold',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'profit',
      label: 'Профит с одного инсталла, $',
      fullLabel: 'Профит с одного инсталла, $',
      field: 'profit',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
      format: (val: RowValue) => formatValue(val, 2, true),
    },
  ]
}
