
import { defineComponent } from 'vue'
import Filters from '@/components/filters/Filters.vue'
import { getPagination } from '@/utils/request'
import { getReferralColumns } from '@/pages/statistics2/config/columns'
import ReferralsTable from '@/pages/statistics2/tables/ReferralsTable.vue'
export default defineComponent({
  name: 'ReferralsTab',
  props: ['refresh', 'change', 'changeAll', 'filters'],
  components: { ReferralsTable, Filters },
  computed: {
    complexFilters(): any {
      return { ...this.filters, pagination: this.pagination }
    },
  },
  data: function() {
    return {
      exportCsv: false,
      pagination: {
        ...getPagination('groupByField', 50),
        selectedColumns: ['revenue', 'revenueHold'],
        visibleColumns: getReferralColumns().map((c: any) => c.name),
      },
    }
  },
  methods: {
    onExport() {
      this.exportCsv = !this.exportCsv
    },
    onDetail(filters: any) {
      this.$emit('changeAll', filters, true)
    },
    changePagination(pagination: any) {
      this.pagination = pagination
    },
  },
})
