
import { mapActions, mapMutations, mapState } from 'vuex'
import axios from 'axios'
import { defineComponent } from 'vue'
import General from './steps/General.vue'
import Rules from './steps/Rules.vue'
import Metrics from './steps/Metrics.vue'
import TrafficSources from './steps/TrafficSources.vue'
import { getInitialOptions } from './config/getInitialOptions'
import { AffiliateReward, TdsRule } from '@/store/types'
import Affiliates from './steps/Affiliates.vue'
import { Currency } from '@/enums/Currency'
import { showErrorMessageSnackBar } from '@/utils'
import validateTdsRules from './config/validateTdsRules'

export default defineComponent({
  name: 'MobileAppEdit',
  components: {
    Affiliates,
    TrafficSources,
    General,
    Rules,
    Metrics,
  },
  async created() {
    if (!this.$route.params.id) {
      this.reset()
    } else {
      await this.getData(this.$route.params.id)
    }

    const options = await getInitialOptions()
    this.defaultOptions = Object.assign({}, this.defaultOptions, options)
    this.loading = false
  },
  computed: mapState('mobileAppsModule', ['errors', 'mobileApp']),
  data() {
    return {
      step: 'main',
      defaultOptions: [],
      loading: true,
      isSaving: false,
    }
  },
  methods: {
    ...mapActions('mobileAppsModule', ['getData']),
    ...mapMutations('mobileAppsModule', ['update', 'reset', 'setErrors']),
    onSubmit: async function() {
      if (!this.mobileApp.image) {
        this.setErrors({ image: 'Please, select image' })
        return
      }
      this.isSaving = true
      const rules = this.mobileApp.rules.filter(
        (r: TdsRule) => Object.keys(r).length,
      )
      const affiliateRewards = this.mobileApp.affiliateRewards
        .filter((r: AffiliateReward) => r.affiliateIds?.length)
        .map((r: AffiliateReward) => {
          if (!Number(r.value.amount) && r.value.amount !== 0) {
            return { ...r, value: null }
          }
          return { ...r, value: { ...r.value, currency: Currency.USD } }
        })

      const data = {
        ...this.mobileApp,
        rules,
        affiliateRewards,
        defaultInstallValue: {
          currency: Currency.USD,
          amount: Number(this.mobileApp.defaultInstallValue.amount) || 0,
        },
      }
      const method = !this.$route.params.id ? 'post' : 'put'
      try {
        if (!validateTdsRules(rules)) {
          showErrorMessageSnackBar(
            'Ошибка: дублирование заданных ГЕО под органику',
          )
          return
        }
        const response: any = await axios[method]('/api/apps', data, {
          params: { id: this.$route.params.id },
        })
        this.setErrors({})

        const mobileAppId = response.data.id
        await this.postFormData(
          data.image,
          'image',
          `/api/apps/${mobileAppId}/image`,
        )
        await this.postFormData(
          data.playMarketImage,
          'playMarketImage',
          `/api/apps/${mobileAppId}/playMarketImage`,
        )

        this.$router.push('/mobile-apps')
      } catch (error) {
        this.setErrors((error as any).response?.data.errors)
        showErrorMessageSnackBar((error as any).response?.data.errors?.message)
      } finally {
        this.isSaving = false
      }
    },
    async postFormData(data: string, field: string, url: string) {
      const form = new FormData()
      form.append(field, data)
      await axios.post(url, form)
    },
    onReset: function() {
      this.reset()
      this.$router.push('/mobile-apps')
    },
    getErrors(step: string): boolean {
      if (!this.errors) {
        return false
      }
      switch (step) {
        case 'main':
          return (
            this.errors.name ||
            this.errors.status ||
            this.errors.vertical ||
            this.errors.unknownTrafficRedirectUrl ||
            this.errors.image ||
            this.errors.description
          )
        case 'rules':
          return this.errors.rules
        case 'affiliates':
          return this.errors.affiliateRewards
        case 'metrics':
          return (
            this.errors.oneSignalApiKey ||
            this.errors.oneSignalAppId ||
            this.errors.oneSignalAppPackage ||
            this.errors.facebookAppId ||
            this.errors.yandexApiKey ||
            this.errors.yandexAppId ||
            this.errors.appsflyerKey ||
            this.errors.developerId
          )
        case 'sources':
          return this.errors.trafficSources
        default:
          return false
      }
    },
  },
})
