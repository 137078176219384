
import axios from 'axios'
import { defineComponent } from 'vue'
import {
  getDefaultFilters,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import { getTableProperties } from '@/utils/request'
import getColumns from './config/getColumns'
import Filters from '@/components/filters/AppsFilters.vue'
import { MobileAppStatus } from '@/enums/MobileAppStatus'
import { LocationQueryValue } from 'vue-router'
import { FiltersType } from '@/utils/request'
import AttributionLinkDialog from '@/components/dialogs/AttributionLinkDialog.vue'
import {
  showSuccessSnackBar,
  showErrorMessageSnackBar,
  confirmDialog,
} from '@/utils'
import ChangeInstallPriceDialog from '@/components/dialogs/ChangeInstallPriceDialog.vue'
import { getIncludeOptions } from '@/utils/filters'

export default defineComponent({
  name: 'MobileAppsList',
  components: { ChangeInstallPriceDialog, AttributionLinkDialog, Filters },
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  data() {
    const queryFilters = this.$route.query
    for (const [filter, value] of Object.entries(queryFilters)) {
      queryFilters[filter] = (Number(value) as unknown) as LocationQueryValue
    }
    return {
      ...getTableProperties('id', 50),
      filters: { ...queryFilters, ...getIncludeOptions() },
      columns: getColumns(),
      status: MobileAppStatus,
      showAttributionLinkDialog: false,
      attributionSource: null,
      appPackage: null,
      showInstallPriceDialog: false,
      isSendingEvents: {} as any,
      storageUrl: process.env.CDN_STORAGE_URL,
    }
  },
  watch: {
    async filters() {
      await this.onRequest({ pagination: this.pagination })
    },
  },
  methods: {
    copyTdsRedirectUrl(id: number) {
      const copyText = document.getElementById(`tds_redirect_url_${id}`) as any
      const textArea = document.createElement('textarea')
      textArea.value = copyText.textContent
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      textArea.remove()
    },
    async sendEvents(appId: number) {
      this.isSendingEvents[appId] = true
      try {
        await axios.post('/api/apps/events/' + appId)
        showSuccessSnackBar('События отправлены')
      } catch (e) {
        showErrorMessageSnackBar('Произошла ошибка отправки')
      } finally {
        this.isSendingEvents[appId] = false
      }
    },
    async banApp(appId: number) {
      confirmDialog(
        'Вы действительно хотите забанить это приложение?',
        async () => {
          await axios.put('/api/apps/ban/' + appId)
          this.loading = true
          await this.onRequest({ pagination: this.pagination })
        },
      )
    },
    async onDelete(appId: number) {
      confirmDialog(
        'Вы действительно хотите удалить это приложение?',
        async () => {
          await axios.delete('/api/apps?id=' + appId)
          this.loading = true
          await this.onRequest({ pagination: this.pagination })
        },
      )
    },
    async publishApp(app: any, isInvite = false) {
      if (!app.playMarketImage || !app.oneSignalAppPackage) {
        showErrorMessageSnackBar(
          'Ошибка: загрузите скрин с Play Market и название бандла',
        )
        return
      }
      if (
        app.status !== MobileAppStatus.Active &&
        app.status !== MobileAppStatus.Private &&
        app.status !== MobileAppStatus.Draft
      ) {
        showErrorMessageSnackBar(
          'Ошибка: приложение должно быть активным (Active, Draft либо Private status)',
        )
        return
      }
      confirmDialog(
        isInvite
          ? 'Отправить инвайт на добавление приложения для пошарки?'
          : 'Вы действительно хотите опубликовать это приложение в Telegram бот Gambeat Apps?',
        async () => {
          if (isInvite) {
            await axios.put('/api/apps/invite/' + app.id)
            showSuccessSnackBar(
              'Инвайт на добавление приложения для пошарки отправлен',
            )
          } else {
            await axios.put('/api/apps/publish/' + app.id)
            showSuccessSnackBar('Опубликовано в Telegram бот')
          }
          this.loading = true
          await this.onRequest({ pagination: this.pagination })
        },
      )
    },
    async markedByFb(app: any) {
      confirmDialog(
        'Вы действительно хотите пометить это приложение в Telegram боте Gambeat Apps как приложение с меткой Facebook?',
        async () => {
          await axios.put('/api/apps/mark-fb/' + app.id)
          showSuccessSnackBar('Приложение помечено в Facebook')
          this.loading = true
          await this.onRequest({ pagination: this.pagination })
        },
      )
    },
    async onRequest(
      props: TableRequestProps | Omit<TableRequestProps, 'pagination'>,
    ) {
      const options = { pagination: this.pagination, ...props }
      await request(options, this as Table, '/api/apps/list/')
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    resetFilters() {
      this.pagination.page = 1
      this.filters = getDefaultFilters() as any
    },
    openAttributionDialog(source: 'tt' | 'unity', appPackage: string) {
      this.showAttributionLinkDialog = !this.showAttributionLinkDialog
      this.attributionSource = source as any
      this.appPackage = appPackage as any
    },
    onCloseDialog() {
      this.showAttributionLinkDialog = false
      this.showInstallPriceDialog = false
      this.attributionSource = null
      this.appPackage = null
    },
    changeInstallPrice() {
      this.showInstallPriceDialog = true
    },
    async turnFbPostback(sendFbPostback: boolean) {
      await axios.post('/api/apps/on-off-fb-postback', {
        sendFbPostback,
      })
      await this.onRequest({ pagination: this.pagination })
    },
  },
})
