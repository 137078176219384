
import Header from '../components/header/Header.vue'
import Navigation from '../components/navigation/Navigation.vue'
import { defineComponent } from 'vue'
import { mapMutations } from 'vuex'

export default defineComponent({
  name: 'DashboardView',
  components: { Navigation, Header },
  data: function() {
    return {
      drawer: false,
      miniState: true,
    }
  },
  methods: {
    ...mapMutations('authModule', ['logout']),
    changeDrawer(drawerModel: boolean) {
      this.drawer = drawerModel
      this.miniState = drawerModel
    },
  },
})
