
import { defineComponent } from 'vue'
import Filters from '@/components/filters/Filters.vue'
import { getDefaultFilters, getTableProperties } from '@/utils/request'
import { getClicksChartColumns, getRedirectEventsColumns } from './columns'
import { request, Table, TableRequestProps } from '@/utils/request'
import { FiltersType } from '@/utils/request'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { groupByOptions } from '@/pages/statistics/config/groupByOptions'
import { getData } from '@/utils/getData'
import LineChart from '@/components/charts/LineChart.vue'
import { exportToCsv } from '@/utils/export'
import IconWithName from '@/components/icons/IconWithName.vue'
import { mapState } from 'vuex'
import { formatFiltersFromQuery } from '@/utils/filters'
export default defineComponent({
  name: 'RedirectEvents',
  components: { IconWithName, LineChart, Filters },
  computed: {
    ...mapState('authModule', ['user']),
  },
  created() {
    if (
      Object.keys(this.$route.params).length ||
      Object.keys(this.$route.query).length
    ) {
      formatFiltersFromQuery(this)
    } else {
      this.onRequestChartData()
      this.onRequest({ pagination: this.pagination })
    }
  },
  watch: {
    filters() {
      this.onRequestChartData()
      this.onRequest({ pagination: this.pagination })
    },
  },
  data: function() {
    return {
      ...getTableProperties('createdAt', 50),
      filters: getDefaultFilters(),
      columns: getRedirectEventsColumns(),
      chartColumns: getClicksChartColumns(),
      chartData: [],
      selectedColumns: ['clicks'],
      chartLoading: false,
    }
  },
  setup() {
    const $q = useQuasar()
    return {
      showErrorSnackbar(err: any) {
        const error = new Error(err)
        $q.notify({
          type: 'negative',
          message: `Internal Server Error: ${error.message}`,
          position: 'top-right',
          timeout: 4000,
        })
      },
    }
  },
  methods: {
    async onRequest(
      props: TableRequestProps | Omit<TableRequestProps, 'pagination'>,
    ) {
      try {
        const options = { pagination: this.pagination, ...props }
        await request(options, this as Table, '/api/logs/clicks')
      } catch (err) {
        this.showErrorSnackbar(err)
      }
    },
    async onRequestChartData() {
      this.chartLoading = true
      const orderBy = {
        ['createdAt']: 'DESC',
      }
      this.chartData = await getData(
        { filters: this.filters || {}, limit: 0, offset: 0, orderBy },
        '/api/logs/clicks-chart',
      )
      this.chartLoading = false
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    resetFilters() {
      this.pagination.page = 1
      this.filters = getDefaultFilters()
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    getGroupByArray() {
      return groupByOptions
    },
    onExport() {
      exportToCsv(this.rows, this.columns)
    },
  },
})
