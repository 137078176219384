
import { defineComponent } from 'vue'
import axios from 'axios'
import { getConversionsColumns } from '../config/columns'
import {
  request,
  getTableProperties,
  Table,
  TableRequestProps,
} from '@/utils/request'
import ConversionEditDialog from '@/components/dialogs/ConversionEditDialog.vue'
import ConversionInfoDialog from '@/components/dialogs/ConversionInfoDialog.vue'
import ConversionHoldEditDialog from '@/components/dialogs/ConversionHoldEditDialog.vue'
import { LeadStatus } from '@/enums/LeadStatus'
import { RewardType } from '@/enums/RewardType'
import { exportToCsv } from '@/utils/export'
import { confirmDialog } from '@/utils'
import IconWithName from '@/components/icons/IconWithName.vue'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'ConversionsTable',
  props: ['filters', 'exportCsv'],
  components: {
    IconWithName,
    ConversionEditDialog,
    ConversionInfoDialog,
    ConversionHoldEditDialog,
  },
  mounted() {
    this.onRequest({ pagination: this.pagination })
  },
  computed: {
    ...mapState('authModule', ['user']),
  },
  data: function() {
    return {
      ...getTableProperties('datetime', 50),
      selected: [],
      loading: false,
      columns: getConversionsColumns(),
      showEditDialog: false,
      showInfoDialog: false,
      showHoldEditDialog: false,
      currentRow: null,
      leadStatuses: LeadStatus,
      rewardTypes: RewardType,
    }
  },
  watch: {
    filters() {
      this.pagination.page = 1
      this.onRequest({ pagination: this.pagination })
    },
    exportCsv() {
      exportToCsv(this.rows, this.columns)
    },
  },
  methods: {
    async onRequest(
      props: TableRequestProps | Omit<TableRequestProps, 'pagination'> = {},
    ) {
      const options = { pagination: this.pagination, ...props }
      this.columns = getConversionsColumns(this.filters.isDetailing)
      await request(options, this as Table, '/api/conversions')
      if (this.selected.length) {
        this.selected = this.rows.filter((item1: any) =>
          this.selected.some((item2: any) => item1.id === item2.id),
        )
      }
    },
    checkInfo(props: TableRequestProps) {
      this.currentRow = props.row as never
      this.showInfoDialog = !this.showInfoDialog
    },
    changeConversionHold(props: TableRequestProps) {
      this.currentRow = props?.row as never
      this.showHoldEditDialog = !this.showInfoDialog
    },
    changeEditDialog() {
      this.showEditDialog = !this.showEditDialog
    },
    async approveConversions(props: TableRequestProps) {
      confirmDialog('Апрув конверсий?', async () => {
        this.loading = true
        const data = this.selected.map((r: any) => ({
          id: r.id,
          status: r.status,
          tdsRedirectEventId: r.tdsRedirectEventId,
        }))
        await axios.put(`/api/conversions/approve`, data)
        await this.onRequest(props)
        this.loading = false
      })
    },
    async rejectConversions(props: TableRequestProps) {
      confirmDialog('Реджект конверсий?', async () => {
        this.loading = true
        const data = this.selected.map((r: any) => ({
          id: r.id,
          status: r.status,
        }))
        await axios.put(`/api/conversions/reject`, data)
        await this.onRequest(props)
        this.loading = false
      })
    },
  },
})
