<template>
  <q-header style="background: #1e1e1e;">
    <q-toolbar>
      <q-btn flat @click="$emit('openMenu')" round dense icon="menu" />
      <q-toolbar-title>
        <img
          width="110"
          height="22"
          src="../../../public/images/gambeat_logo_white_full.png"
          @click="$router.push('/statistics')"
          class="cursor-pointer"
        />
      </q-toolbar-title>
      <div class="row items-center q-mr-md">
        <div class="q-mr-sm">
          <q-avatar
            v-for="(admin, index) in displayedAdmins"
            :key="admin.id"
            size="40px"
            class="overlapping cursor-pointer"
            :style="`right: ${index * 15}px`"
          >
            <img v-if="getAdminAvatar(admin)" :src="getAdminAvatar(admin)">
            <span v-if="!getAdminAvatar(admin)">{{ admin.name?.charAt(0) || 'A' }}</span>
            <q-tooltip>{{ `Online (${admin.credentials_email})` }}</q-tooltip>
          </q-avatar>
        </div>
        <q-select
          color="black"
          bg-color="grey-3"
          class="q-mr-sm"
          v-model="customTimezone"
          outlined
          filled
          dense
          :options="timezones"
          option-value="id"
          option-label="name"
          emit-value
          map-options
          ><q-tooltip anchor="center left" self="center right"
            >Часовой пояс (таймзона)</q-tooltip
          ></q-select
        >
        <q-select
          class="q-mr-sm"
          color="black"
          bg-color="grey-3"
          v-model="baseCurrency"
          outlined
          filled
          dense
          :options="currencies"
          option-value="id"
          option-label="name"
          emit-value
          map-options
          ><q-tooltip anchor="center left" self="center right"
            >Базовая валюта аккаунта</q-tooltip
          ></q-select
        >
        <q-btn
          no-caps
          label="Обновить"
          @click="clearCache"
          outline
          color="white"
        />
      </div>
      <q-btn
        no-caps
        label="Выход"
        @click="logout"
        outline
        color="white"
        icon-right="logout"
      />
    </q-toolbar>
  </q-header>
</template>

<script>
import {mapActions} from 'vuex'
import {
  getTimezones,
  payoutCurrencies,
  showErrorMessageSnackBar,
  showSuccessSnackBar,
} from '@/utils'
import axios from 'axios'
import { Timezone } from '@/enums/Timezone'

export default {
  name: 'Header',
  props: ['openMenu'],
  mounted() {
    this.fetchAdmins()
    this.intervalId = setInterval(this.fetchAdmins, 60000);
  },
  methods: {
    ...mapActions('authModule', ['logout']),
    async clearCache() {
      await this.$router.push({
        path: this.$route.fullPath?.replace('/', ''),
        query: {},
      })
      this.$storage.removeStorageSync('affiliatePaginationGmbt')
      this.$storage.removeStorageSync('affiliateFiltersGmbt')
      this.$storage.removeStorageSync('offersPaginationGmbt')
      this.$storage.removeStorageSync('financePaginationGmbt')
      this.$storage.removeStorageSync('offersFiltersGmbt')
      this.$storage.removeStorageSync('adminTimezoneGmbt')
      window.location.reload(true)
    },
    async fetchAdmins() {
      this.admins = (await axios.get('/api/admins/online')).data
    },
    getAdminAvatar(admin) {
      const url = this.storageUrl + '/'
      switch (admin.id) {
        case 2: return url + 'brovyev.png'
        case 6: return url + 'buryyev.png'
        case 7: return url + 'salibog.jpg'
        case 10: return url + 'goncana.jpg'
        case 12: return url + 'kunddmy.jpg'
        case 13: return url + 'poznvla.png'
        case 15: return url + 'kovaden.png'
        case 17: return url + 'bluzbor.png'
        case 18: return url + 'melnimak.png'
        case 19: return url + 'vlasole.png'
        case 20: return url + 'lockheed.png'
        case 23: return url + 'ivansvi.png'
        case 24: return url + 'onysvik.jpg'
        case 25: return url + 'khozvik.png'
        case 26: return url + 'makakat.png'
        case 27: return url + 'shevole.png'
        case 29: return url + 'lukivol.png'
        case 30: return url + 'rubamax.png'
        case 32: return url + 'karenik.png'
        default: return false
      }
    },
  },
  watch: {
    baseCurrency() {
      const admin = JSON.parse(localStorage.getItem('user'))
      axios
        .post(
          '/api/admins/currency',
          { baseCurrency: this.baseCurrency },
          {
            params: { id: admin.id },
          },
        )
        .then(() => {
          showSuccessSnackBar('Валюта обновлена')
        })
        .catch(() => {
          showErrorMessageSnackBar('Произошла ошибка')
        })
    },
    customTimezone() {
      const admin = JSON.parse(localStorage.getItem('user'))
      const timezone = getTimezones().find(tz => tz.id === this.customTimezone)
        ?.value
      this.$storage.setStorageSync('adminTimezoneGmbt', timezone)
      axios
        .post(
          '/api/admins/timezone',
          { customTimezone: this.customTimezone },
          {
            params: { id: admin.id },
          },
        )
        .then(() => {
          showSuccessSnackBar('Часовой пояс обновляется...')
        })
        .catch(() => {
          showErrorMessageSnackBar('Произошла ошибка')
        })
        .finally(() => {
          window.location.reload(true)
        })
    },
  },
  computed: {
    displayedAdmins() {
      return this.admins
    },
  },
  data: function() {
    const customTimezone =
      this.$cookies.get('userTimezone') !== null &&
      this.$cookies.get('userTimezone') !== undefined
        ? Number(this.$cookies.get('userTimezone'))
        : Timezone.Kyiv
    return {
      admins: [],
      intervalId: null,
      timezones: getTimezones(),
      customTimezone,
      storageUrl: process.env.CDN_STORAGE_URL,
      baseCurrency: Number(this.$cookies.get('baseCurrency')),
      currencies: payoutCurrencies,
    }
  },
  beforeUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
}
</script>
