
import { defineComponent } from 'vue'
import Filters from '@/components/filters/AppsFilters.vue'
import { getDefaultFilters, getTableProperties } from '@/utils/request'
import { getRedirectEventsColumns } from './columns'
import { request, Table, TableRequestProps } from '@/utils/request'
import { exportToCsv } from '@/utils/export'
import { FiltersType } from '@/utils/request'
import useQuasar from 'quasar/src/composables/use-quasar.js';
export default defineComponent({
  name: 'Installs',
  components: { Filters },
  created() {
    if (Object.keys(this.$route.params).length) {
      const paramsFilters = this.$route.params
      for (const [key, value] of Object.entries(paramsFilters)) {
        if (Number(value)) {
          paramsFilters[key] = (Number(value) as unknown) as string
        }
      }
      this.filters = { ...this.filters, ...paramsFilters }
    } else {
      this.onRequest({ pagination: this.pagination })
    }
  },
  watch: {
    async filters() {
      await this.onRequest({ pagination: this.pagination })
    },
  },
  setup() {
    const $q = useQuasar()
    return {
      showErrorSnackbar(err: any) {
        const error = new Error(err)
        $q.notify({
          type: 'negative',
          message: `Internal Server Error: ${error.message}`,
          position: 'top-right',
          timeout: 4000,
        })
      },
    }
  },
  data: function() {
    return {
      ...getTableProperties('createdAt', 25),
      filters: { ...getDefaultFilters(), showBlockGeos: false, appMarket: null },
      columns: getRedirectEventsColumns(),
    }
  },
  methods: {
    async onRequest(
      props: TableRequestProps | Omit<TableRequestProps, 'pagination'>,
    ) {
      try {
        const options = { pagination: this.pagination, ...props }
        await request(options, this as Table, '/api/logs/installs')
      } catch (err) {
        this.showErrorSnackbar(err)
      }
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    resetFilters() {
      this.pagination.page = 1
      this.filters = { ...getDefaultFilters(), showBlockGeos: false, appMarket: null }
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    onExport() {
      exportToCsv(this.rows, this.columns)
    },
  },
})
