
import axios from 'axios'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AffiliateCommentDialog',
  props: ['open', 'close', 'affiliateId', 'refresh', 'leadLimitId'],
  computed: {
    showDialog(): boolean {
      return this.open
    },
  },
  data: function() {
    return {
      isSaving: false,
      comment: null,
      errors: {},
    }
  },
  methods: {
    save() {
      this.isSaving = true
      axios
        .post(`/api/affiliates/${this.affiliateId}/comment`, {
          comment: this.comment,
          leadLimitId: this.leadLimitId,
        })
        .then(() => {
          this.$emit('refresh')
          this.onClose()
        })
        .catch(error => {
          this.errors = error.response.data.errors
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    onClose() {
      this.isSaving = false
      this.comment = null
      this.errors = {}
      this.$emit('close')
    },
  },
})
