
import { defineComponent } from 'vue'
import axios from 'axios'
import { formatDate, showErrorMessageSnackBar } from '@/utils'
import IconWithName from '@/components/icons/IconWithName.vue'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'TicketMessages',
  components: { IconWithName },
  computed: {
    ...mapState('authModule', ['user']),
  },
  async created() {
    const id = this.$route.params.id
    this.messages = (await axios.get(`/api/tickets/${id}`)).data
    this.loading = false
    setTimeout(() => {
      const objDiv: any = document.getElementById('messages-scroll-area')
      objDiv.scrollTop = objDiv?.scrollHeight as any
    }, 400)
  },
  data() {
    return {
      messages: [],
      loading: true,
      isSaving: false,
      message: null,
      file: null,
    }
  },
  methods: {
    async sendMessage() {
      if (!this.message) {
        return
      }
      this.isSaving = true
      try {
        const id = this.$route.params.id
        const messageId = (
          await axios.post('/api/tickets/' + id + '/message', {
            text: this.message,
          })
        ).data
        if (this.file) {
          const form = new FormData()
          form.append('image', this.file as any)
          await axios.post(`/api/tickets/${messageId}/image`, form)
        }
        this.messages = (await axios.get(`/api/tickets/${id}`)).data
      } catch (e) {
        showErrorMessageSnackBar('Произошла ошибка.')
      } finally {
        this.message = null
        this.file = null
        this.isSaving = false
        setTimeout(() => {
          const objDiv: any = document.getElementById('messages-scroll-area')
          objDiv.scrollTop = objDiv?.scrollHeight as any
        }, 400)
      }
    },
    getFormatData(val: string) {
      return formatDate(val)
    },
    onLoad(index: number, done: any) {
      setTimeout(async () => {
        done()
      }, 1000)
    },
    downloadURI(name: string) {
      const uri = process.env.CDN_STORAGE_URL + '/' + name
      const link = document.createElement('a')
      link.download = name
      link.href = uri
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      link.remove()
    },
  },
})
