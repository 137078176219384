
import axios from 'axios'
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import IconWithName from '@/components/icons/IconWithName.vue'
import { lookupAffiliates } from '@/utils/lookups'
import { Affiliate } from '@/store/types'
import { showErrorMessageSnackBar } from '@/utils'

export default defineComponent({
  name: 'AddReferalDialog',
  components: { IconWithName },
  props: ['open', 'close', 'affiliateId', 'refresh'],
  computed: {
    ...mapState('authModule', ['user']),
    showDialog(): boolean {
      return this.open
    },
  },
  async created() {
    const affiliates = (await axios.get('/api/affiliates/list')).data
    this.affiliates = affiliates.map((affiliate: Affiliate) => ({
      ...affiliate,
      email:
        JSON.parse(localStorage.getItem('user') as any)?.showStatisticsOnly ||
        JSON.parse(localStorage.getItem('user') as any)?.notFullAccess
          ? ''
          : affiliate.credentials.email,
    }))
  },
  data: function() {
    return {
      isSaving: false,
      affiliates: [],
      affiliateIds: [],
      errors: {},
    }
  },
  methods: {
    addReferrals() {
      if (!this.affiliateIds?.length) {
        return
      }
      this.isSaving = true
      axios
        .post(`/api/referrals/add/${this.affiliateId}`, {
          affiliateIds: this.affiliateIds,
        })
        .then(() => {
          this.$emit('refresh')
          this.onClose()
        })
        .catch(error => {
          this.errors = error.response.data.errors
          showErrorMessageSnackBar(
            'Произошла ошибка. Обратитесь к администратору.',
          )
        })
      this.isSaving = false
    },
    onClose() {
      this.affiliateIds = []
      this.errors = {}
      this.$emit('close')
    },
    async getAffiliates(value: string, update: Function) {
      await lookupAffiliates(this, value, update)
    },
    clearFilter(selectedRef: string) {
      if (this.$refs[selectedRef] !== void 0) {
        ;(this.$refs[selectedRef] as any).updateInputValue('')
      }
    },
  },
})
