import getDefaultData from '../../pages/campaigns/getDefaultData'
import { Errors, CampaignData, CampaignState, RootModule } from '@/store/types'
import { ActionContext } from 'vuex'
import axios from 'axios'
import router from '@/router'

const campaignsModule = {
  state: getDefaultData(),
  mutations: {
    update(state: CampaignState, value: CampaignData): void {
      state.campaign = { ...state.campaign, ...value }
      state.errors = {}
    },
    reset(state: CampaignState): void {
      const { campaign, errors } = getDefaultData()
      state.campaign = campaign
      state.errors = errors
    },
    setErrors(state: CampaignState, value: Errors): void {
      state.errors = value
    },
  },
  actions: {
    async getData(
      { commit }: ActionContext<CampaignState, RootModule>,
      campaignId: string,
    ) {
      try {
        const { data } = await axios.get('/api/campaigns/' + campaignId)
        commit('update', data)
        commit('setErrors', {})
      } catch (err) {
        await router.push('/campaigns')
        throw new Error(err as string)
      }
    },
  },
  namespaced: true,
}

export default campaignsModule
