
import { defineComponent } from 'vue'
import axios from 'axios'
import { enumToArray } from '@/utils'
import { AffiliateAccessStatus } from '@/enums/AffiliateAccessStatus'
import { lookupAffiliates, lookupOffers } from '@/utils/lookups'
import {
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import { getColumns, getLogColumns } from './getColumns'
import { Row } from '@/utils/request'
import IconWithName from '@/components/icons/IconWithName.vue'
import { mapState } from 'vuex'
export default defineComponent({
  name: 'OffersAccess',
  components: { IconWithName },
  computed: {
    ...mapState('authModule', ['user']),
  },
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  data: function() {
    return {
      ...getTableProperties('createdAt', 50),
      filters: {},
      columns: getColumns(),
      errors: {},
      access: this.getEmptyAccess(),
      offers: [],
      affiliates: [],
      showLogs: false,
      status: AffiliateAccessStatus,
      statuses: enumToArray(AffiliateAccessStatus).filter(({ name }: any) =>
        ['Closed', 'Opened'].includes(name),
      ),
    }
  },
  watch: {
    async showLogs(next: boolean, prev: boolean) {
      if (next === prev) {
        return
      }
      this.pagination.sortBy = 'createdAt'
      this.pagination.descending = true
      if (this.showLogs) {
        this.columns = getLogColumns()
        await this.onRequestLogs({ pagination: this.pagination })
      } else {
        this.columns = getColumns()
        await this.onRequest({ pagination: this.pagination })
      }
    },
  },
  methods: {
    async onRequest(props: TableRequestProps) {
      await request(props, this as Table, '/api/offers-access')
    },
    async onRequestLogs(props: TableRequestProps) {
      await request(props, this as Table, '/api/offer-access-log')
    },
    async changeStatus(access: Row) {
      await axios.put('/api/offers-access', {
        status: access.status,
        offerId: access.offerId,
        affiliateId: access.affiliateId,
      })
      if (this.showLogs) {
        await this.onRequestLogs({ pagination: this.pagination })
      } else {
        await this.onRequest({ pagination: this.pagination })
      }
      this.access = this.getEmptyAccess()
    },
    getEmptyAccess() {
      return {
        offer: null,
        affiliate: null,
        status: undefined,
      }
    },
    async getOffers(value: string, update: Function) {
      await lookupOffers(this, value, update)
    },
    async getAffiliates(value: string, update: Function) {
      await lookupAffiliates(this, value, update)
    },
  },
})
