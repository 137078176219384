
import { defineComponent } from 'vue'
import Filters from '@/components/filters/Filters.vue'
import ConversionsTable from '../tables/ConversionsTable.vue'
export default defineComponent({
  name: 'ConversionsTab',
  props: ['refresh', 'change', 'changeAll', 'filters'],
  components: { Filters, ConversionsTable },
  data: function() {
    return {
      exportCsv: false,
    }
  },
  methods: {
    onExport() {
      this.exportCsv = !this.exportCsv
    },
  },
})
