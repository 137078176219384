import OffersEdit from '@/pages/offers/OffersEdit.vue'
import OffersList from '@/pages/offers/OffersList.vue'
import Login from '@/pages/login/Login.vue'
import OffersAccess from '@/pages/offers-access/OffersAccess.vue'
import DashboardView from '@/views/DashboardView.vue'
import Statistics from '@/pages/statistics/Statistics.vue'
import Statistics2 from '@/pages/statistics2/Statistics2.vue'
import AdvertisersList from '@/pages/advertisers/AdvertisersList.vue'
import AdvertisersEdit from '@/pages/advertisers/AdvertisersEdit.vue'
import AdminsList from '@/pages/admins/AdminsList.vue'
import AdminsEdit from '@/pages/admins/AdminsEdit.vue'
import AffiliatesList from '@/pages/affiliates/AffiliatesList.vue'
import AffiliateInfo from '@/pages/affiliates/AffiliateInfo.vue'
import AffiliateEdit from '@/pages/affiliates/AffiliateEdit.vue'
import CampaignEdit from '@/pages/campaigns/CampaignEdit.vue'
import Payouts from '@/pages/payouts/Payouts.vue'
import Logs from '@/pages/request-logs/Logs.vue'
import CampaignsPage from '@/pages/campaigns/CampaignsPage.vue'
import Finances from '@/pages/finances/Finances.vue'
import CurrencyCourses from '@/pages/currency-courses/CurrencyCourses.vue'
import SupportTickets from '@/pages/support/SupportTickets.vue'
import TicketMessages from '@/pages/support/TicketMessages.vue'
import PayoutInfo from '@/pages/payouts/PayoutInfo.vue'
import MobileAppsList from '@/pages/mobile-apps/MobileAppsList.vue'
import MobileAppEdit from '@/pages/mobile-apps/MobileAppEdit.vue'
import PushGroup from '@/pages/push-notifications/PushGroup.vue'
import PushNotifications from '@/pages/push-notifications/PushNotifications.vue'
import Balances from '@/pages/balances/Balances.vue'
import MobileAppsAccess from '@/pages/mobile-apps-access/MobileAppsAccess.vue'
import PostbackLogs from '@/pages/tds-logs/PostbackLogs.vue'
import TdsStatistics from '@/pages/tds-statistics/Statistics.vue'
import AppsFinances from '@/pages/apps-finances/Finances.vue'

const routes = [
  { path: '/login', component: Login },
  {
    path: '/',
    component: DashboardView,
    name: 'basic',
    meta: { requiresAuth: true },
    children: [
      { path: 'offers', component: OffersList },
      { path: 'offers/access', component: OffersAccess },
      { path: 'offers/new', component: OffersEdit },
      { path: 'offers/:id', component: OffersEdit, name: 'offer' },
      { path: 'statistics', component: Statistics2, name: 'statistics' },
      { path: 'statistics2', component: Statistics, name: 'statistics2' },
      { path: 'finances', component: Finances, name: 'finances' },
      { path: 'logs', component: Logs, name: 'logs' },
      { path: 'currency-courses', component: CurrencyCourses },
      { path: 'payouts', component: Payouts },
      {
        path: 'payout',
        component: PayoutInfo,
        name: 'payout',
      },
      { path: 'advertisers', component: AdvertisersList },
      { path: 'advertisers/new', component: AdvertisersEdit },
      {
        path: 'advertisers/:id',
        component: AdvertisersEdit,
        name: 'advertiser',
      },
      { path: 'admins', component: AdminsList },
      { path: 'admins/new', component: AdminsEdit },
      {
        path: 'admins/:id',
        component: AdminsEdit,
        name: 'admin',
      },
      { path: 'affiliates', component: AffiliatesList },
      {
        path: 'affiliates/edit/:id',
        component: AffiliateEdit,
      },
      {
        path: 'affiliates/:id',
        component: AffiliateInfo,
        name: 'affiliate',
      },
      {
        path: 'campaigns',
        component: CampaignsPage,
      },
      {
        path: 'campaigns/:id',
        component: CampaignEdit,
        name: 'campaign',
      },
      { path: 'tickets', component: SupportTickets },
      { path: 'tickets/:id', component: TicketMessages, name: 'ticket' },
      { path: 'mobile-apps', component: MobileAppsList },
      { path: 'mobile-apps/new', component: MobileAppEdit },
      { path: 'mobile-apps/:id', component: MobileAppEdit, name: 'mobile-app' },
      { path: 'push-notifications', component: PushNotifications },
      { path: 'push-notifications/new', component: PushGroup },
      {
        path: 'push-notifications/:id',
        component: PushGroup,
        name: 'push-notification',
      },
      {
        path: 'apps/statistics',
        component: TdsStatistics,
        name: 'tds-statistics',
      },
      { path: 'apps/finances', component: AppsFinances, name: 'tds-finances' },
      { path: 'apps/postback-logs', component: PostbackLogs, name: 'tds-logs' },
      { path: 'apps/balances', component: Balances },
      { path: 'apps/access', component: MobileAppsAccess },
      {
        path: '/:pathMatch(.*)*',
        redirect: '/statistics',
      },
    ],
  },
]

export default routes
