
import { getCampaignColumns } from './getColumns'
import { defineComponent } from 'vue'
import {
  getDefaultFilters,
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import Filters from '@/components/filters/Filters.vue'
import { LocationQueryValue } from 'vue-router'
import axios from 'axios'
import { FiltersType } from '@/utils/request'
import { confirmDialog, showSuccessSnackBar } from '@/utils'
import IconWithName from '@/components/icons/IconWithName.vue'
import { mapState } from 'vuex'
import { formatFiltersFromQuery } from '@/utils/filters'
import CampaignEditDialog from '@/components/dialogs/CampaignEditDialog.vue'

export default defineComponent({
  name: 'CampaignsList',
  components: { CampaignEditDialog, IconWithName, Filters },
  props: ['affiliateId'],
  async created() {
    formatFiltersFromQuery(this)
    await this.onRequest({ pagination: this.pagination })
  },
  computed: {
    ...mapState('authModule', ['user']),
  },
  data() {
    const queryFilters = this.$route.query
    for (const [filter, value] of Object.entries(queryFilters)) {
      queryFilters[filter] = (Number(value) as unknown) as LocationQueryValue
    }
    return {
      ...getTableProperties('id', 25),
      filters: {
        ...(this.affiliateId ? { affiliateId: this.affiliateId } : {}),
        ...getDefaultFilters(),
        fantomAffiliateOnly: false,
        ...queryFilters,
      },
      selectedCampaignId: null,
      showCampaignEditDialog: false,
      columns: getCampaignColumns(),
    }
  },
  watch: {
    async filters() {
      await this.onRequest({ pagination: this.pagination })
    },
  },
  methods: {
    async deleteRow(props: TableRequestProps) {
      confirmDialog('Вы действительно хотите удалить поток?', async () => {
        this.loading = true
        await axios.delete('/api/campaigns', { params: { id: props.row?.id } })
        await this.onRequest({ pagination: this.pagination })
      })
    },
    async onRequest(
      props: TableRequestProps | Omit<TableRequestProps, 'pagination'>,
    ) {
      const options = { pagination: this.pagination, ...props }
      await request(options, this as Table, '/api/campaigns/all')
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    async copyRow(props: TableRequestProps) {
      this.loading = true
      await axios.post('/api/campaigns/' + props.row?.id)
      await this.onRequest({ pagination: this.pagination })
      showSuccessSnackBar('Поток скопирован')
    },
    resetFilters() {
      this.filters = { ...getDefaultFilters(), fantomAffiliateOnly: false }
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    openCampaignDialog(campaignId: number) {
      this.selectedCampaignId = (campaignId as unknown) as null
      this.showCampaignEditDialog = true
    },
    onCloseDialog() {
      this.showCampaignEditDialog = false
      this.selectedCampaignId = null
    },
    copy(text: string) {
      if (!text) return
      const element = document.createElement('textarea')
      document.body.appendChild(element)
      element.value = text
      element.select()
      document.execCommand('copy')
      document.body.removeChild(element)
      showSuccessSnackBar('Ссылка скопирована')
    },
  },
})
