import { formatDate } from '@/utils'
import { MobileAppStatus } from '@/enums/MobileAppStatus'

export default function getColumns() {
  return [
    { name: 'id', label: 'ID', field: 'id', align: 'center', sortable: true },
    {
      name: 'createdAt',
      label: 'Дата',
      field: 'createdAt',
      align: 'center',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'name',
      label: 'Название приложениия',
      field: 'name',
      align: 'left',
      sortable: true,
    },
    {
      name: 'tdsRedirectUrl',
      label: 'Уникальная ссылка',
      field: 'tdsRedirectUrl',
      align: 'center',
    },
    {
      name: 'oneSignalAppPackage',
      label: 'Пакет (бандл)',
      field: 'oneSignalAppPackage',
      align: 'center',
    },
    {
      name: 'status',
      label: 'Статус',
      field: 'status',
      align: 'center',
      format: (val: number) => MobileAppStatus[val],
    },
    {
      name: 'affiliatesCount',
      label: 'Аффилейты',
      field: 'affiliatesCount',
      align: 'right',
    },
    {
      name: 'openGeos',
      label: 'Открытые гео',
      field: 'openGeos',
      align: 'center',
    },
    {
      name: 'organicGeos',
      label: 'Органика',
      field: 'organicGeos',
      align: 'center',
    },
    { name: 'actions', label: 'Действия', field: 'actions', align: 'center' },
    {
      name: 'attributionLinks',
      label: 'Attribution Links',
      field: 'actions',
      align: 'center',
    },
  ]
}
