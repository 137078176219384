import { getData } from '@/utils/getData'
import { sub, format, differenceInDays } from 'date-fns'
import { MarkupType } from '@/enums/MarkupType'
import { TrafficPropertyType } from '@/enums/TrafficPropertyType'
import { Vertical } from '@/enums/Vertical'
import { RewardType } from '@/enums/RewardType'
import { CampaignStatus } from '@/enums/CampaignStatus'
import { showErrorMessageSnackBar } from '@/utils'
import { getIncludeOptions } from '@/utils/filters'
import { useStorage } from 'vue3-storage'

export type ReadOptions = {
  filters: Filters
  groupBy?: GroupBy
  orderBy?: OrderBy
  limit?: number
  offset?: number
}

export type GroupByOptions = Array<{
  id: string
  name: string
  field: string
}>

export type FiltersType = {
  offerId?: number
  campaignId?: number
  landingId?: number
  preLandingId?: number
  geoId?: number
  status?: CampaignStatus
  markupType?: number
  dateFrom?: number | string
  dateTo?: number | string
  groupBy?: string
  groupBy2?: string
  groupBy3?: string
}

export type Filters = {
  affiliateId?: number
  offerId?: number
  campaignId?: number
  landingId?: number
  preLandingId?: number
  geoId?: number
  markupType?: MarkupType
  dateFrom?: number | string
  dateTo?: number | string
  trafficProperty?: TrafficPropertyType
  vertical?: Vertical
  advertiserId?: number
  categoryId?: number
  rewardType?: RewardType
  groupBy?: string
  groupBy2?: string
  groupBy3?: string
}

type GroupBy = string[]
type OrderBy = { [key: string]: string }
export type Row = { [key: string]: any }
export type Column = { [key: string]: any }

type Pagination = {
  page: number
  rowsNumber: number
  rowsPerPage: number
  sortBy: string
  descending: boolean
}
export type Table = {
  loading: boolean
  rows: string[]
  pagination: Pagination
  filters?: Filters
}
export type TableRequestProps = {
  pagination: Pagination
  row?: Row
}

export async function request(
  props: TableRequestProps,
  ref: any,
  url: string,
  withoutPagination = false,
) {
  const { page, rowsPerPage, sortBy, descending } = props.pagination
  ref.loading = true
  const limit = withoutPagination
    ? 0
    : rowsPerPage === 0
    ? ref.pagination.rowsNumber
    : rowsPerPage
  const offset = (page - 1) * rowsPerPage
  const orderBy = {
    [sortBy !== 'groupByField'
      ? sortBy
      : String(ref.filters?.groupBy)]: descending ? 'DESC' : 'ASC',
  }
  try {
    ref.rows = await getData(
      { filters: ref.filters || {}, limit, offset, orderBy },
      url,
    )
  } catch (e) {
    showErrorMessageSnackBar('Произошла ошибка запроса.')
  }
  ref.pagination.rowsNumber = (ref.rows.length
    ? ((ref.rows[0] as unknown) as Row).total
    : ref.pagination.rowsNumber) as number
  ref.pagination.page = page
  ref.pagination.rowsPerPage = withoutPagination
    ? ref.pagination.rowsNumber
    : rowsPerPage
  ref.pagination.sortBy = sortBy
  ref.pagination.descending = descending
  ref.loading = false
  return ref.rows
}

export function getPagination(
  sortBy: string,
  rowsPerPage: number,
  descending = true,
) {
  return {
    sortBy,
    descending,
    page: 1,
    rowsPerPage,
    rowsNumber: 100,
  }
}

export const getTableProperties = (
  sortBy: string,
  rowsPerPage = 10,
  descending = true,
) => ({
  rows: [],
  loading: true,
  pagination: getPagination(sortBy, rowsPerPage, descending),
})

export function getPrevPeriods(dateFrom: Date | string, dateTo: Date | string) {
  const differenceDays = Math.abs(
    differenceInDays(new Date(dateFrom), new Date(dateTo)),
  )
  const prevPeriodDateTo = sub(new Date(dateFrom), { days: 1 })
  const prevPeriodDateFrom = sub(new Date(prevPeriodDateTo), {
    days: differenceDays,
  })
  const prevDateFrom = format(prevPeriodDateFrom, 'yyyy/MM/dd')
  const prevDateTo = format(prevPeriodDateTo, 'yyyy/MM/dd')
  return {
    prevDateFrom,
    prevDateTo,
  }
}

export function getDefaultFilters() {
  const date = new Date()
  const lastMonth = sub(date, { months: 1 })
  const dateTo = format(date, 'yyyy/MM/dd')
  const dateFrom = format(lastMonth, 'yyyy/MM/dd')
  const timezone = useStorage().getStorageSync('adminTimezoneGmbt')
  return {
    ...getIncludeOptions(),
    factualStats: true,
    groupBy: 'date',
    dateFrom,
    dateTo,
    ...getPrevPeriods(dateFrom, dateTo),
    timezoneOffset:
      timezone !== null && timezone !== undefined
        ? timezone
        : -(new Date().getTimezoneOffset() / 60),
  }
}

export function getDefaultFiltersLast7Days() {
  const date = new Date()
  const lastWeek = sub(date, { weeks: 1 })
  const dateTo = format(date, 'yyyy/MM/dd')
  const dateFrom = format(lastWeek, 'yyyy/MM/dd')
  const timezone = useStorage().getStorageSync('adminTimezoneGmbt')
  return {
    ...getIncludeOptions(),
    factualStats: true,
    groupBy: 'date',
    dateFrom,
    dateTo,
    ...getPrevPeriods(dateFrom, dateTo),
    timezoneOffset:
      timezone !== null && timezone !== undefined
        ? timezone
        : -(new Date().getTimezoneOffset() / 60),
  }
}

export function getGroupByField(
  key: 'id' | 'name' | 'field',
  row: Row,
  groupByOptions: GroupByOptions,
  filters: Filters,
): string | undefined {
  for (const option of groupByOptions) {
    if (option.id !== filters.groupBy) {
      continue
    }
    const fieldName = option[key]
    if (key === 'field') {
      switch (fieldName) {
        case 'rewardType':
          return RewardType[Number(row[fieldName])]
        case 'vertical':
          return Vertical[Number(row[fieldName])]
        default:
          return row[fieldName]
      }
    }
    return fieldName
  }
}

export function getGroupBy2Field(
  key: 'id' | 'name' | 'field',
  row: Row,
  groupByOptions: GroupByOptions,
  filters: Filters,
): string | undefined {
  for (const option of groupByOptions) {
    if (option.id !== filters.groupBy2) {
      continue
    }
    const fieldName = option[key]
    if (key === 'field') {
      switch (fieldName) {
        case 'rewardType':
          return RewardType[Number(row[fieldName])]
        case 'vertical':
          return Vertical[Number(row[fieldName])]
        default:
          return row[fieldName]
      }
    }
    return fieldName
  }
}

export function getGroupBy3Field(
  key: 'id' | 'name' | 'field',
  row: Row,
  groupByOptions: GroupByOptions,
  filters: Filters,
): string | undefined {
  for (const option of groupByOptions) {
    if (option.id !== filters.groupBy3) {
      continue
    }
    const fieldName = option[key]
    if (key === 'field') {
      switch (fieldName) {
        case 'rewardType':
          return RewardType[Number(row[fieldName])]
        case 'vertical':
          return Vertical[Number(row[fieldName])]
        default:
          return row[fieldName]
      }
    }
    return fieldName
  }
}
