import { createStore } from 'vuex'
import authModule from './modules/auth.module'
import offersModule from './modules/offers.module'
import advertisersModule from './modules/advertisers.module'
import adminsModule from '@/store/modules/admins.module'
import campaignsModule from '@/store/modules/campaigns.module'
import mobileAppsModule from '@/store/modules/mobile-apps.module'
import payoutsModule from '@/store/modules/payouts.module'

const store = createStore({
  modules: {
    authModule,
    offersModule,
    advertisersModule,
    adminsModule,
    mobileAppsModule,
    campaignsModule,
    payoutsModule,
  },
})

export default store
