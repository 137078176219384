import { formatValue, isDefined, RowValue, formatDate } from '@/utils'
import { Row } from '@/utils/request'
import { Currency } from '@/enums/Currency'
import { LeadStatus } from '@/enums/LeadStatus'

export function getGeneralColumns() {
  const arr: any = [
    {
      name: 'groupByField',
      label: 'Дата',
      fullLabel: 'Дата',
      field: 'groupByField',
      align: 'left',
      sortable: true,
    },
    {
      name: 'clicks',
      label: 'Hit',
      fullLabel: 'Hit',
      field: 'clicks',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'uniqueClicks',
      label: 'Host',
      fullLabel: 'Host',
      field: 'uniqueClicks',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'registrationCount',
      label: 'Регистрации',
      fullLabel: 'Регистрации',
      field: 'registrationCount',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'firstDepositCount',
      label: 'Депозиты',
      fullLabel: 'Первые депозиты',
      field: 'firstDepositCount',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'firstDepositSum',
      label: 'Сумма первых депозитов, $',
      fullLabel: 'Сумма депозитов',
      field: 'firstDepositSum',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'depositCount',
      label: 'Количество, шт.',
      fullLabel: 'Повторные депозиты',
      field: 'depositCount',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'depositSum',
      label: 'Сумма, $',
      fullLabel: 'Сумма повторных депозитов, $',
      field: 'depositSum',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'uniqueDepositCount',
      label: 'Уникальный, шт.',
      fullLabel: 'Уникальные первые повторные депозиты',
      field: 'uniqueDepositCount',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'approvedLeads',
      label: 'Принято',
      fullLabel: 'Конверсии, принято',
      field: 'approvedLeads',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'holdLeads',
      label: 'Холд',
      fullLabel: 'Конверсии, холд',
      field: 'holdLeads',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'rejectedLeads',
      label: 'Отменено',
      fullLabel: 'Конверсии, отменено',
      field: 'rejectedLeads',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'epc',
      label: 'EPC',
      fullLabel: 'EPC',
      field: 'epc',
      align: 'right',
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'cr',
      label: 'Host/Dep',
      fullLabel: 'Host/Dep',
      field: 'cr',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'crUniqueReg',
      label: 'Host/Reg',
      fullLabel: 'Host/Reg',
      field: 'crUniqueReg',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'crFtdReg',
      label: 'Reg/Dep',
      fullLabel: 'Reg/Dep',
      field: 'crFtdReg',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'approvedPercent',
      label: 'Апрув, %',
      fullLabel: 'Процент апрува',
      field: 'approvedPercent',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => formatValue(val),
    },
  ]

  if (!JSON.parse(localStorage.getItem('user') as any)?.showStatisticsOnly) {
    arr.push(
      {
        name: 'approvedSum',
        label: 'Принято',
        fullLabel: 'Финансы, принято',
        field: 'approvedSum',
        align: 'right',
        format: (val: RowValue) => formatValue(val),
      },
      {
        name: 'holdSum',
        label: 'Холд',
        fullLabel: 'Финансы, холд',
        field: 'holdSum',
        align: 'right',
        format: (val: RowValue) => formatValue(val),
      },
      {
        name: 'rejectedSum',
        label: 'Отменено',
        fullLabel: 'Финансы, отменено',
        field: 'rejectedSum',
        align: 'right',
        format: (val: RowValue) => formatValue(val),
      },
    )
  }

  arr.push({
    name: 'revShareRevenueSum',
    label: 'Доход, $',
    fullLabel: 'RevShare',
    field: 'revShareRevenueSum',
    align: 'right',
    format: (val: RowValue) => formatValue(val),
  })
  return arr
}

export function getConversionsColumns(isDetailing = false) {
  const arr: any = [
    {
      name: 'datetime',
      label: 'Дата и время',
      field: 'datetime',
      align: 'center',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'conversionDate',
      label: 'Conversion date',
      field: 'conversionDate',
      align: 'center',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    { name: 'id', label: 'ID', field: 'id', align: 'center', sortable: true },
    {
      name: 'offerName',
      label: 'Оффер',
      field: 'offerName',
      align: 'center',
      sortable: true,
    },
    {
      name: 'ip',
      label: 'IP',
      field: 'ip',
      align: 'center',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'advertiserName',
      label: 'Рекламодатель',
      field: 'advertiserName',
      align: 'center',
      sortable: true,
    },
    {
      name: 'geo',
      label: 'Страна',
      field: 'geo',
      align: 'center',
      sortable: true,
    },
    {
      name: 'status',
      label: 'Статус',
      field: 'status',
      align: 'center',
      sortable: true,
      format: (val: number, row: Row) => {
        if (row.attributes?.advertiserStatus) {
          return LeadStatus[val] + ` ( ${row.attributes?.advertiserStatus} ) `
        }
        if (row.attributes?.over_limited) {
          return LeadStatus[val] + ` ( Превышена капа ) `
        }
        if (row.attributes?.disable_postback) {
          return LeadStatus[val] + ` ( Отключен постбек ) `
        }
        if (row.attributes?.over_postclick) {
          return LeadStatus[val] + ` ( Превышен постклик ) `
        }
        return LeadStatus[val]
      },
    },
    {
      name: 'rewardType',
      label: 'Тип конверсии',
      field: 'rewardType',
      align: 'center',
      format: (val: RowValue, row: Row) => {
        if (row.status === 'Pending') {
          return 'Registration'
        }
        return isDefined(val)
      },
    },
  ]
  if (!JSON.parse(localStorage.getItem('user') as any)?.showStatisticsOnly) {
    arr.push({
      name: 'amount',
      label: 'Выплата',
      field: 'amount',
      align: 'center',
      format: (val: RowValue, row: any) => {
        return formatValue(val, 3) + ' ' + (Currency[row.currency] ?? '')
      },
    })
  }
  arr.push(
    {
      name: 'affiliateEmail',
      label: 'Аффилейт',
      field: 'affiliateEmail',
      align: 'center',
    },
    {
      name: 'markupType',
      label: 'Тип трафика',
      field: 'markupType',
      align: 'center',
    },
    { name: 'actions', label: 'Действия', field: 'actions', align: 'center' },
    {
      name: 'redirectEventId',
      label: 'Клик ID',
      field: 'redirectEventId',
      align: 'center',
    },
  )
  if (isDetailing) {
    arr.push(
      {
        name: 'ftdSum',
        label: 'Сумма FTD, $',
        field: 'ftdSum',
        align: 'right',
        sortable: true,
        format: (val: RowValue) => formatValue(val),
      },
      {
        name: 'rdSum',
        label: 'Сумма RD, $',
        field: 'rdSum',
        align: 'right',
        sortable: true,
        format: (val: RowValue) => formatValue(val),
      },
      {
        name: 'rdCount',
        label: 'Количество RD',
        field: 'rdCount',
        align: 'right',
        sortable: true,
        format: (val: RowValue) => formatValue(val),
      },
      {
        name: 'depSum',
        label: 'Сумма Dep, $',
        field: 'depSum',
        align: 'right',
        sortable: true,
        format: (val: RowValue) => formatValue(val),
      },
    )
  }

  return arr
}
