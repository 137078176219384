import { AffiliateReward, CommonReward } from '@/store/types'
import { intersection } from 'lodash'
import { RewardType } from '@/enums/RewardType'
import { showErrorMessageSnackBar } from '@/utils'
import { Currency } from '@/enums/Currency'

export default function validateRewards(
  commonRewards: CommonReward[],
  affiliateRewards: AffiliateReward[],
): boolean {
  const commonR = commonRewards.filter(r => !r.upsaleNumber)
  const affiliateR = affiliateRewards.filter(r => !r.upsaleNumber)
  for (const [i, v] of commonR.entries()) {
    for (const [inx, val] of commonR.entries()) {
      if (i === inx) {
        continue
      }
      if (
        intersection(v.geoIds, val.geoIds).length &&
        (v.trafficSource?.name === val.trafficSource?.name ||
          (!v.trafficSource && !val.trafficSource))
      ) {
        return false
      }
    }
  }
  for (const [i, v] of affiliateR.entries()) {
    for (const [inx, val] of affiliateR.entries()) {
      if (i === inx) {
        continue
      }
      if (
        intersection(v.geoIds, val.geoIds).length &&
        intersection(v.affiliateIds, val.affiliateIds).length &&
        (v.trafficSource?.name === val.trafficSource?.name ||
          (!v.trafficSource && !val.trafficSource))
      ) {
        return false
      }
    }
  }
  return true
}

export function validateWithoutValueRewards(commonRewards: CommonReward[]) {
  for (const reward of commonRewards) {
    if (reward.withoutValue) {
      if (
        Number(reward.value?.amount) > 0 ||
        Number(reward.advertiserValueAmount) > 0
      ) {
        return false
      }
      reward.advertiserValueAmount = 0
      reward.advertiserValueCurrency =
        reward.advertiserValueCurrency || Currency.USD
      reward.value.amount = 0
      reward.value.currency = reward.value.currency || Currency.USD
      reward.type = reward.type || RewardType.FTD
    }
  }
  return true
}

export function validateRevShareRewards(
  commonRewards: CommonReward[],
  affiliateRewards: AffiliateReward[],
) {
  let isRevShare = false
  for (const reward of [...commonRewards, ...affiliateRewards]) {
    if (reward.type === RewardType.RevShare) {
      isRevShare = true
      reward.advertiserValueAmount = 0
      reward.value.amount = 0
      if (
        reward.value?.currency === null ||
        reward.value?.currency === undefined ||
        reward.advertiserValueCurrency === null ||
        reward.advertiserValueCurrency === undefined ||
        reward.value?.currency !== reward.advertiserValueCurrency
      ) {
        return false
      }
    }
  }
  if (isRevShare) {
    for (const reward of [...commonRewards, ...affiliateRewards]) {
      if (reward.type !== RewardType.RevShare) {
        showErrorMessageSnackBar(
          'В ревшарном оффере не должно быть других типов конверсии',
        )
        return false
      }
    }
  }

  return true
}
