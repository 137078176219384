
import { defineComponent } from 'vue'
import { getTableProperties } from '@/utils/request'
import { getLoginLogsColumns } from '@/pages/affiliates/getColumns'
import { request, Table, TableRequestProps } from '@/utils/request'

export default defineComponent({
  name: 'LoginLogs',
  props: ['affiliateId'],
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  data: function() {
    return {
      ...getTableProperties('createdAt', 50),
      columns: getLoginLogsColumns(),
    }
  },
  methods: {
    async onRequest(props: TableRequestProps) {
      await request(props, this as Table, `/api/login-logs/${this.affiliateId}`)
    },
  },
})
