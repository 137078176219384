
import axios from 'axios'
import { defineComponent } from 'vue'
import { omit } from 'lodash'
import { PresetPage } from '@/enums/PresetPage'

export default defineComponent({
  name: 'SavePresetDialog',
  props: ['open', 'close', 'filters'],
  computed: {
    showDialog(): boolean {
      return this.open
    },
  },
  data: function() {
    return {
      name: null,
      errors: {},
    }
  },
  methods: {
    savePreset() {
      if (!this.name) {
        return
      }
      const extraParams = this.$route.path.includes('offers')
        ? { page: PresetPage.Offers }
        : {}
      axios
        .post('/api/presets', {
          ...extraParams,
          name: this.name,
          filters: omit(this.filters, ['dateFrom', 'dateTo']),
        })
        .then(() => {
          this.onClose()
        })
        .catch(error => {
          this.errors = error.response.data.errors
        })
    },
    onClose() {
      this.name = null
      this.errors = {}
      this.$emit('close')
    },
  },
})
