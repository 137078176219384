<template>
  <span class="flex items-center" style="flex-wrap: nowrap">
    <span v-if="id">{{ `${prevText || ''}` }}#{{ id }}</span>
    <q-icon
      class="cursor-pointer"
      style="display: block"
      :size="size"
      :color="color"
      :name="icon"
      @click.stop="onRequest"
    >
      <q-menu style="border: 1px solid #000;">
        <div class="q-pa-md row" v-if="affiliate">
          <div class="q-pr-sm" style="border-right: 1px solid #ccc;">
            <div class="column items-center">
              <q-icon size="70px" name="account_box" color="secondary" />
              <div class="text-subtitle1 q-mb-md">
                <b>{{ affiliate.credentials.email }}</b>
              </div>
            </div>
            <div class="row q-mb-md justify-center">
              <q-btn
                class="q-mr-sm"
                outline
                no-caps
                @click.stop="$router.push('/affiliates/' + affiliate.id)"
                icon="account_circle"
                color="positive"
                label="Profile"
                size="sm"
              />
              <q-btn
                class="q-mr-sm"
                outline
                size="sm"
                color="primary"
                no-caps
                label="Edit"
                @click.stop="$router.push('/affiliates/edit/' + affiliate.id)"
                icon="edit"
              />
              <q-btn
                class="q-mr-sm"
                outline
                size="sm"
                no-caps
                label="Stats"
                color="secondary"
                @click.stop="
                  $router.push(`/statistics?affiliateId=${id}&fromPage=1`)
                "
                icon="bar_chart"
              />
              <q-btn
                outline
                no-caps
                @click.stop="loginAffiliate(id)"
                icon-right="play_circle_outline"
                color="primary"
                label="Login"
                size="sm"
              />
            </div>
            <div
              class="row q-mb-md justify-center"
              v-if="affiliate.markers?.length"
            >
              <span
                v-for="(marker, i) in affiliate.markers"
                :key="i"
                class="q-mr-sm"
                @click.stop="() => true"
              >
                <q-badge
                  class="cursor-pointer"
                  :style="`background: ${marker.color} !important;`"
                >
                  <b>{{ marker.name }}</b>
                  <q-popup-edit
                    class="bg-grey-9 text-white marker-popup"
                    anchor="bottom middle"
                  >
                    <div class="row justify-between items-center">
                      <span
                        ><b>Название:</b> <b>{{ marker.name }}</b></span
                      >
                      <span>
                        <q-btn
                          icon="edit"
                          size="xs"
                          round
                          flat
                          @click.stop="addMarker(marker.id)"
                        ></q-btn>
                        <q-btn
                          icon="delete"
                          size="xs"
                          round
                          flat
                          @click.stop="
                            deleteMarker(affiliate.id, marker.id, marker.name)
                          "
                        ></q-btn>
                      </span>
                    </div>
                    <div>
                      <b>Описание:</b> <span v-html="marker.reason"></span>
                    </div>
                    <div>
                      <b>Добавлено:</b> {{ frmtDate(marker.createdAt) }}
                    </div>
                    <div>
                      <b>Кто добавил:</b>
                      {{
                        marker.addedBySystem
                          ? 'Добавлено системой'
                          : marker.createdBy || 'Добавлено системой'
                      }}
                    </div>
                    <div
                      v-if="
                        frmtDate(marker.updatedAt) !==
                          frmtDate(marker.createdAt) &&
                          marker.updatedBy &&
                          marker.updatedAt
                      "
                    >
                      <b>Изменено:</b> {{ frmtDate(marker.updatedAt) }}
                    </div>
                    <div
                      v-if="
                        frmtDate(marker.updatedAt) !==
                          frmtDate(marker.createdAt) &&
                          marker.updatedBy &&
                          marker.updatedAt
                      "
                    >
                      <b>Кто изменил:</b>
                      {{ marker.updatedBy }}
                    </div>
                  </q-popup-edit>
                </q-badge>
              </span>
            </div>
            <div class="row q-mb-md">
              <div class="flex column justify-center items-center">
                <span><b>ID</b></span>
                <span>{{ affiliate.id }}</span>
              </div>
              <q-separator vertical inset class="q-mx-lg" />
              <div class="flex column justify-center items-center">
                <span><b>Last login</b></span>
                <span>{{ frmtDate(affiliate.lastLoginDate) }}</span>
              </div>
              <q-separator vertical inset class="q-mx-lg" />
              <div class="flex column justify-center items-center">
                <span><b>Registered</b></span>
                <span>{{ frmtDate(affiliate.createdAt) }}</span>
              </div>
            </div>
            <div class="row q-mb-md justify-between">
              <div class="flex column justify-center items-center">
                <span><b>Ref id</b></span>
                <span v-if="affiliate.broughtById">{{
                  affiliate.broughtById
                }}</span>
                <span v-else>-</span>
              </div>
              <div class="flex column justify-center items-center">
                <span><b>Manager</b></span>
                <span v-if="affiliate.managers?.length">
                  #{{ affiliate.managers[0].id }}
                  <q-icon size="20px" color="primary" name="manage_accounts" />
                  {{ affiliate.managers[0].credentials.email }}
                </span>
                <span v-else>-</span>
              </div>
            </div>
            <div class="row justify-center" v-if="affiliate.contacts.telegram">
              <a
                target="_blank"
                class="text-primary"
                style="text-decoration: underline"
                :href="getTelegramLink(affiliate.contacts.telegram)"
                >telegram: {{ affiliate.contacts.telegram }}</a
              >
            </div>
            <div class="row justify-center" v-if="affiliate.contacts.skype">
              <div>skype: {{ affiliate.contacts.skype }}</div>
            </div>
            <div v-if="affiliate.rewards?.length">
              <div class="q-my-md"><b>Ставки:</b></div>
              <div
                v-for="(reward, index) in affiliate.rewards"
                class="row selectable"
                :key="index"
              >
                <div class="flex column col-3 q-mr-sm overflow-hidden">
                  <b>Оффер</b>
                  <span class="no-wrap overflow-hidden text-ellipsis">{{
                    reward.offer_name
                  }}</span>
                </div>
                <div class="flex column col-1 q-mr-sm">
                  <b>Гео</b>
                  <div class="flex row items-center no-wrap">
                    <img
                      class="q-mr-xs"
                      height="10"
                      width="15"
                      :alt="reward.geo_code"
                      :src="
                        `http://purecatamphetamine.github.io/country-flag-icons/3x2/${reward.geo_code}.svg`
                      "
                    />
                    {{ reward.geo_code }}
                  </div>
                </div>
                <div class="flex column col-3 q-mr-sm">
                  <b>Ставка веба</b>
                  <span>{{
                    `${reward.value_amount} ${
                      currencies[reward.value_currency]
                    }`
                  }}</span>
                </div>
                <div class="flex column col-3 q-mr-sm">
                  <b>Ставка рекла</b>
                  <span>{{
                    `${reward.advertiser_value_amount} ${
                      currencies[reward.advertiser_value_currency]
                    }`
                  }}</span>
                </div>
                <div class="flex column col-1">
                  <b>Капа<q-tooltip>Информационная капа</q-tooltip></b>
                  <span>{{ reward.inform_lead_limit || '-' }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="q-ml-sm" style="zoom: 0.8;">
            <div
              v-for="(comment, index) in affiliate.comments"
              :key="index"
              class="flex row q-mb-xs q-pa-md relative-position"
              style="border-radius: 6px; border: 1px solid #ccc; width: 400px;"
            >
              <div class="flex column col-4">
                <span class="q-mr-md">{{ format(comment.createdAt) }}</span>
                <IconWithName
                  :id="comment.createdBy?.id || comment.createdById"
                  :name="''"
                  size="20px"
                  color="primary"
                  icon="manage_accounts"
                  entity="manager"
                />
              </div>
              <div class="flex column col-7">
                <span v-html="comment.comment"></span>
              </div>
              <div class="q-ml-sm absolute" style="right: 10px">
                <q-btn
                  size="xs"
                  round
                  icon="delete"
                  color="negative"
                  @click="deleteComment(comment.id)"
                />
              </div>
            </div>
            <div class="row">
              <q-editor
                style="max-height: 120px !important; flex: 1; overflow: scroll;"
                :class="[
                  'q-mb-md',
                  'editor',
                  { 'editor-error': !!errors?.comment },
                ]"
                v-model="comment"
                placeholder="Напишите комментарий..."
                autofocus
                :toolbar="[
                  ['bold', 'italic', 'underline'],
                  ['unordered', 'ordered'],
                  [
                    {
                      label: $q.lang.editor.formatting,
                      icon: $q.iconSet.editor.formatting,
                      list: 'no-icons',
                      options: ['p', 'h3', 'h4', 'h5', 'h6'],
                    },
                  ],
                ]"
              />
              <span>
                <q-btn
                  class="q-ml-md q-mt-xl"
                  size="sm"
                  no-caps
                  icon="send"
                  rounded
                  outline
                  color="primary"
                  @click="saveComment"
                />
              </span>
            </div>
          </div>
        </div>
        <div v-else-if="entity === 'affiliate' && !affiliate" class="q-pa-xl">
          <center>
            <q-spinner-pie style="color: #ffd000 !important;" size="lg" />
          </center>
        </div>
      </q-menu>
    </q-icon>
    <span class="q-ml-xs" v-if="name">{{
      name
        .replace(`#${id} 👤 `, '')
        .replace(`#${id} ⭐️ `, '')
        .replace(`#${id} 🔀 `, '')
    }}</span>
    <AddAffiliateTagsDialog
      v-if="showAddTagsDialog"
      :open="showAddTagsDialog"
      :affiliateId="id"
      :markerId="selectedMarkerId"
      @close="onCloseDialog"
      @refresh="false"
    />
    <AffiliateCommentsDialog
      v-if="showCommentsDialog"
      :open="showCommentsDialog"
      :affiliateId="id"
      :affComments="affiliate.comments"
      @close="onCloseDialog"
      @refresh="false"
    />
  </span>
</template>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'
import { confirmDialog, formatDate, showErrorMessageSnackBar } from '@/utils'
import { loginAsAffiliate } from '@/utils/affiliate'
import AffiliateCommentsDialog from '@/components/dialogs/AffiliateCommentsDialog'
import AddAffiliateTagsDialog from '@/components/dialogs/AddAffiliateTagsDialog'
import { Currency } from '@/enums/Currency'
export default defineComponent({
  name: 'IconWithName',
  components: { AddAffiliateTagsDialog, AffiliateCommentsDialog },
  props: ['id', 'name', 'color', 'icon', 'size', 'entity', 'prevText'],
  data: function() {
    return {
      comment: null,
      commentSaving: false,
      loading: false,
      affiliate: null,
      showCommentsDialog: false,
      selectedMarkerId: null,
      showAddTagsDialog: false,
      currencies: Currency,
    }
  },
  methods: {
    async onRequest() {
      if (this.entity !== 'affiliate') {
        return
      }
      this.loading = true
      this.affiliate = (
        await axios.get(
          '/api/affiliates/affiliate/' + String(this.id)?.split(' ')[0],
        )
      ).data
      this.loading = false
    },
    frmtDate(val) {
      return formatDate(val)
    },
    getTelegramLink(nickname) {
      return 'https://t.me/' + nickname.replace(/@/g, '')
    },
    async loginAffiliate(id) {
      await loginAsAffiliate(id)
    },
    onCloseDialog() {
      this.showCommentsDialog = false
      this.selectedMarkerId = null
      this.showAddTagsDialog = false
    },
    addMarker(markerId) {
      this.selectedMarkerId = markerId
      this.showAddTagsDialog = !this.showAddTagsDialog
    },
    async deleteMarker(affiliateId, markerId, name) {
      confirmDialog(
        `Вы действительно хотите удалить маркер ${name}? с аффилейта ID:${affiliateId}?`,
        async () => {
          await axios.post(`/api/markers/delete-from-aff`, {
            id: markerId,
            affiliateId,
          })
          this.affiliate.markers = this.affiliate.markers.filter(
            m => m.id === markerId,
          )
        },
      )
    },
    format(val) {
      return formatDate(val)
    },
    async deleteComment(id) {
      confirmDialog(
        'Вы действительно хотите удалить этот комментарий?',
        async () => {
          try {
            await axios.delete('/api/affiliates/comment/' + id)
            this.comments = this.comments.filter(c => c.id !== id)
          } catch (e) {
            showErrorMessageSnackBar(
              'Только суперадмины могут удалять комменты',
            )
          }
        },
      )
    },
    saveComment() {
      if (!this.comment?.trim()) {
        return
      }
      this.loading = true
      axios
        .post(`/api/affiliates/${this.affiliate.id}/comment`, {
          comment: this.comment,
        })
        .then(() => {
          this.comment = null
          this.affiliate = null
          this.onRequest()
        })
        .catch(error => {
          showErrorMessageSnackBar(error.toString())
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
})
</script>
