export enum RewardType {
  CPI,
  CPS,
  FTD,
  SOI,
  DOI,
  RevShare,
  CPA,
  CPL,
  BL,
}
