
import { defineComponent } from 'vue'
import { mapMutations, mapState } from 'vuex'
import { Geo, Promo, TrafficSource } from '@/store/types'
import { MarkupType } from '@/enums/MarkupType'
import store from '@/store'
import { enumToArray } from '@/utils'
import { SemanticType } from '@/enums/SemanticType'
import { lookupAffiliates, lookupGeos } from '@/utils/lookups'
import PromoTranslationsDialog from '@/components/dialogs/PromoTranslationsDialog.vue'
import IconWithName from '@/components/icons/IconWithName.vue'
import { omit } from 'lodash'

export default defineComponent({
  name: 'Landing',
  components: { IconWithName, PromoTranslationsDialog },
  props: ['defaultOptions'],
  created(): void {
    if (!store.state.offersModule.offer.promos.length) {
      this.update({
        promos: [
          {
            translations: [],
            markupType: MarkupType.Adaptive,
            semanticType: SemanticType.Landing,
          },
        ],
      })
    }
  },
  computed: {
    ...mapState('offersModule', ['errors', 'offer']),
    promos() {
      return store.state.offersModule.offer.promos
    },
  },
  data: function() {
    return {
      promoIndex: null,
      showTranslationsDialog: false,
      geos: [...(this.defaultOptions.geos || [])],
      trafficSources:
        store.state.offersModule.offer.trafficSources.map(s => s.name) || [],
      affiliates: [...(this.defaultOptions.affiliates || [])],
      markupTypes: enumToArray(MarkupType),
      semanticTypes: enumToArray(SemanticType),
    }
  },
  methods: {
    ...mapMutations('offersModule', ['update']),
    addPromo() {
      this.update({
        promos: [
          ...this.promos,
          {
            translations: [],
            markupType: MarkupType.Adaptive,
            semanticType: SemanticType.Landing,
          },
        ],
      })
    },
    deletePromo(promoIndex: number) {
      const promos = this.promos.filter(
        (promo: Promo, index: number) => index !== promoIndex,
      )
      this.update({ promos })
    },
    updateField(promo: Promo, index: number) {
      const promos = this.promos
      promos[index] = promo
      this.update({ promos })
    },
    updateTrafficSource(promo: Promo, names: string[], index: number) {
      const trafficSources = this.offer.trafficSources.filter(
        (s: TrafficSource) => names.includes(s.name as string),
      )
      this.updateField(
        {
          ...promo,
          trafficSources,
        },
        index,
      )
    },
    async getGeos(value: string, update: Function) {
      await lookupGeos(this, value, update)
      const rewardGeoIds = [
        ...new Set(
          [...this.offer.commonRewards, ...this.offer.affiliateRewards]
            .map(reward => reward.geoIds)
            .flat(),
        ),
      ]
      const geos = rewardGeoIds.length
        ? this.geos.filter((g: Geo) => rewardGeoIds.includes(g.id))
        : this.geos
      update(() => {
        this.geos = geos
      })
    },
    async getAffiliates(value: string, update: Function) {
      await lookupAffiliates(this, value, update)
    },
    getTrafficSources(value: string, update: Function) {
      const trafficSources = this.trafficSources.filter((s: any) =>
        s.toLowerCase().includes(value.toLowerCase()),
      )
      update(() => {
        if (!value) {
          this.trafficSources = store.state.offersModule.offer.trafficSources.map(
            s => s.name,
          )
          return
        }
        this.trafficSources = trafficSources
      })
    },
    clearFilter(selectedRef: string) {
      if (this.$refs[selectedRef] !== void 0) {
        ;(this.$refs[selectedRef] as any).updateInputValue('')
      }
    },
    changeTranslations(index: number) {
      this.promoIndex = index as any
      this.showTranslationsDialog = !this.showTranslationsDialog
    },
    copy(promoIndex: number) {
      const promo = this.promos.find(
        (promo: Promo, index: number) => index === promoIndex,
      )
      this.update({
        promos: [
          ...this.promos,
          omit(promo, [
            'id',
            'createdAt',
            'updatedAt',
            'createdBy',
            'updatedBy',
            'removedAt',
          ]),
        ],
      })
    },
  },
})
