import { PayoutState } from '@/store/types'

function getDefaultData(): PayoutState {
  return {
    payoutData: {} as any,
    errors: {},
  }
}

export default getDefaultData
