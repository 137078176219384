
import { defineComponent } from 'vue'
import { getGeneralColumns } from '../config/columns'
import {
  request,
  Table,
  Row,
  TableRequestProps,
  getTableProperties,
  getGroupByField,
  getGroupBy2Field,
  getGroupBy3Field,
} from '@/utils/request'
import {
  groupByOptions,
  groupByOptions2,
} from '@/pages/statistics/config/groupByOptions'
import { getData, getTotalValues } from '@/utils/getData'
import { exportToCsv } from '@/utils/export'
import { LeadStatus } from '@/enums/LeadStatus'
import { omitBy, omit, isNull, intersection } from 'lodash'
import { RewardType } from '@/enums/RewardType'
import LineChart from '@/components/charts/LineChart.vue'
import PieChart from '@/components/charts/PieChart.vue'
import { formatValue, showErrorMessageSnackBar } from '@/utils'
import { getISOWeek } from 'date-fns'
import moment from 'moment'
import IconWithName from '@/components/icons/IconWithName.vue'
import { PostbackEventType } from '@/enums/PostbackEventType'

export default defineComponent({
  name: 'GeneralTable',
  components: { IconWithName, PieChart, LineChart },
  props: [
    'filters',
    'exportCsv',
    'detail',
    'currentPagination',
    'changePagination',
  ],
  computed: {
    totalCrUniqueReg(): number | string {
      const value =
        Number(
          this.total('registrationCount', 'sum') /
            Number(this.total('uniqueClicks', 'sum')),
        ) * 100 || 0
      return formatValue(value === Infinity || value === -Infinity ? 0 : value)
    },
    totalCrFtdReg(): number | string {
      const value =
        Number(
          this.total('firstDepositCount', 'sum') /
            Number(this.total('registrationCount', 'sum')),
        ) * 100 || 0
      return formatValue(value)
    },
    isDateGroupingSelected(): boolean {
      return this.filters.groupBy === 'date' || this.filters.groupBy === 'week'
    },
    getDateSlot(): number {
      return intersection(this.visibleColumns, ['groupByField']).length
    },
    getTrafficSlot(): number {
      if (this.filters.groupBy === 'date' || this.filters.groupBy === 'week') {
        return intersection(this.visibleColumns, ['clicks', 'uniqueClicks'])
          .length
      }
      return intersection(this.visibleColumns, [
        'clicks',
        'uniqueClicks',
        'clicksDelta',
        'uniqueClicksDelta',
      ]).length
    },
    getRegsSlot(): number {
      return intersection(this.visibleColumns, ['registrationCount']).length
    },
    getDepositsSlot(): number {
      return intersection(this.visibleColumns, [
        'firstDepositCount',
        'firstDepositSum',
      ]).length
    },
    getDDepositsSlot(): number {
      return intersection(this.visibleColumns, [
        'depositCount',
        'depositSum',
        'uniqueDepositCount',
      ]).length
    },
    getConversionSlot(): number {
      return intersection(this.visibleColumns, [
        'approvedLeads',
        'holdLeads',
        'rejectedLeads',
      ]).length
    },
    getCrSlot(): number {
      return intersection(this.visibleColumns, [
        'cr',
        'epc',
        'crUniqueReg',
        'crFtdReg',
        'approvedPercent',
      ]).length
    },
    getFinancesSlot(): number {
      return intersection(this.visibleColumns, [
        'approvedSum',
        'holdSum',
        'rejectedSum',
      ]).length
    },
    getRevShareSlot(): number {
      return intersection(this.visibleColumns, ['revShareRevenueSum']).length
    },
  },
  mounted() {
    this.onRequest({ pagination: this.pagination })
    this.onRequestChartData()
  },
  data: function() {
    return {
      ...getTableProperties('groupByField', 25),
      pagination: this.currentPagination,
      selectedColumns: this.currentPagination.selectedColumns,
      visibleColumns: this.currentPagination.visibleColumns,
      columns: getGeneralColumns(),
      leadStatus: LeadStatus,
      rewardType: RewardType,
      chartData: [],
      nestedRows: [],
      nestedChildRows: [],
      expandedIds: [],
      chartLoading: true,
      ...this.getCheckboxes(),
      clicks: true,
      registrationCount: true,
      firstDepositCount: true,
      eventTypes: PostbackEventType,
    }
  },
  watch: {
    filters() {
      this.pagination.page = 1
      if (
        this.filters.groupBy !== 'date' &&
        this.filters.groupBy !== 'week' &&
        this.selectedColumns.length > 1 &&
        !this.filters.presetId
      ) {
        this.selectedColumns = ['clicks']
        const checkboxes = this.getCheckboxes()
        for (const c of Object.keys(checkboxes)) {
          this[c] = false
        }
        this.clicks = true
      }
      if (this.filters.presetId) {
        this.pagination = { ...this.pagination, ...this.filters.pagination }
        this.selectedColumns = this.filters.pagination.selectedColumns
        this.visibleColumns = this.filters.pagination.visibleColumns
        const checkboxes = this.getCheckboxes()
        for (const c of Object.keys(checkboxes)) {
          this[c] = false
        }
        for (const c of this.selectedColumns) {
          this[c] = true
        }
      }

      this.onRequest({ pagination: this.pagination })
      this.onRequestChartData()
    },
    exportCsv() {
      this.exportTable()
    },
    visibleColumns() {
      this.pagination.visibleColumns = this.visibleColumns
    },
    pagination: {
      handler() {
        this.$emit('changePagination', this.pagination)
      },
      deep: true,
    },
  },
  methods: {
    onExpandRow(row: any) {
      if (this.expandedIds.includes(row.id)) {
        this.expandedIds = this.expandedIds.filter(
          (id: number) => id !== row.id,
        )
      } else {
        this.expandedIds.push(row.id)
      }
    },
    formatDateLikeChart(val: string) {
      return moment(new Date(val)).format('DD MMM YYYY')
    },
    getISOWeekName(val: string) {
      return (
        'week #' +
        getISOWeek(new Date(val)) +
        `  (${new Date(val).getFullYear()})`
      )
    },
    total(field: string, operation: 'sum' | 'avg'): number | string {
      return getTotalValues(this.chartData, field, operation)
    },
    getGroupedField(field: 'id' | 'name' | 'field', row: Row) {
      return getGroupByField(field, row, groupByOptions, this.filters)
    },
    getGroupedField2(field: 'id' | 'name' | 'field', row: Row) {
      return getGroupBy2Field(field, row, groupByOptions2, this.filters)
    },
    getGroupedField3(field: 'id' | 'name' | 'field', row: Row) {
      return getGroupBy3Field(field, row, groupByOptions2, this.filters)
    },
    async onRequestChartData() {
      if (
        this.filters.groupBy2 &&
        this.filters.groupBy2 === this.filters.groupBy
      ) {
        showErrorMessageSnackBar('Нельзя выбирать одинаковые группировки')
        this.loading = false
        return
      }

      this.chartLoading = true
      const orderBy = {
        [String(this.filters?.groupBy)]: this.pagination.descending
          ? 'DESC'
          : 'ASC',
      }
      this.chartData = await getData(
        {
          filters: omit(this.filters, ['groupBy2', 'groupBy3']) || {},
          limit: 0,
          offset: 0,
          orderBy,
        },
        '/api/statistics/general',
      )
      this.chartLoading = false
    },
    async onRequest(props: TableRequestProps) {
      this.loading = true
      if (this.filters.groupBy2) {
        if (this.filters.groupBy2 === this.filters.groupBy) {
          showErrorMessageSnackBar('Нельзя выбирать одинаковые группировки')
          this.loading = false
          return
        }
        const filters = omit(this.filters, ['groupBy2', 'groupBy3'])
        if (!this.filters.groupBy3) {
          const [rows, nestedRows] = await Promise.all([
            request(
              props,
              { ...this, filters } as Table,
              '/api/statistics/general',
            ),
            this.onRequestNested(),
          ])
          this.regroupData(rows, nestedRows)
        } else {
          const [rows, nestedRows, nestedChildRows] = await Promise.all([
            request(
              props,
              { ...this, filters } as Table,
              '/api/statistics/general',
            ),
            this.onRequestNested(),
            this.onRequestNestedChildren(),
          ])
          this.regroupData(rows, nestedRows, nestedChildRows)
        }
      } else {
        await request(props, this as Table, '/api/statistics/general')
      }
      this.loading = false
    },
    async onRequestNested() {
      const orderBy = {
        [String(this.filters?.groupBy)]: this.pagination.descending
          ? 'DESC'
          : 'ASC',
      }
      this.nestedRows = await getData(
        {
          filters: omit(this.filters, 'groupBy3') || {},
          limit: 0,
          offset: 0,
          orderBy,
        },
        '/api/statistics/general',
      )
      return this.nestedRows
    },
    async onRequestNestedChildren() {
      const orderBy = {
        [String(this.filters?.groupBy)]: this.pagination.descending
          ? 'DESC'
          : 'ASC',
      }
      this.nestedChildRows = await getData(
        { filters: this.filters || {}, limit: 0, offset: 0, orderBy },
        '/api/statistics/general',
      )
      return this.nestedChildRows
    },
    regroupData(rows: any, nestedRows: any, nestedChildRows = []) {
      this.rows = [...rows]
      const { sortBy, descending } = this.pagination
      const orderByField =
        sortBy !== 'groupByField' ? sortBy : String(this.filters?.groupBy)
      if (!nestedChildRows?.length) {
        for (const row of this.rows) {
          const children = []
          const groupBy1Value = row[this.filters.groupBy]
          for (const child of nestedRows) {
            if (
              child[this.filters.groupBy] === groupBy1Value &&
              child[this.filters.groupBy] !== null &&
              child[this.filters.groupBy] !== undefined
            ) {
              children.push(child)
            }
          }
          row.children = children.sort((r1: any, r2: any) =>
            descending
              ? r2[orderByField] - r1[orderByField]
              : r1[orderByField] - r2[orderByField],
          )
        }
      } else {
        for (const row of this.rows) {
          const children = []
          const groupBy1Value = row[this.filters.groupBy]
          for (const child of nestedRows) {
            const nestedChildren: any = []
            const groupBy2Value = child[this.filters.groupBy2]
            if (
              child[this.filters.groupBy] === groupBy1Value &&
              child[this.filters.groupBy] !== null &&
              child[this.filters.groupBy] !== undefined
            ) {
              for (const child2 of nestedChildRows) {
                if (
                  child2[this.filters.groupBy] === groupBy1Value &&
                  child2[this.filters.groupBy] !== null &&
                  child2[this.filters.groupBy] !== undefined &&
                  child2[this.filters.groupBy2] === groupBy2Value &&
                  child2[this.filters.groupBy2] !== null &&
                  child2[this.filters.groupBy2] !== undefined
                ) {
                  nestedChildren.push(child2)
                }
              }
              child.children = nestedChildren.sort((r1: any, r2: any) =>
                descending
                  ? r2[orderByField] - r1[orderByField]
                  : r1[orderByField] - r2[orderByField],
              )
              children.push(child)
            }
          }
          row.children = children.sort((r1: any, r2: any) =>
            descending
              ? r2[orderByField] - r1[orderByField]
              : r1[orderByField] - r2[orderByField],
          )
        }
      }
    },
    exportTable() {
      const totalRow = {
        groupByField: 'Total:',
        clicks: this.total('clicks', 'sum'),
        uniqueClicks: this.total('uniqueClicks', 'sum'),
        registrationCount: this.total('registrationCount', 'sum'),
        firstDepositCount: this.total('firstDepositCount', 'sum'),
        firstDepositSum: this.total('firstDepositSum', 'sum'),
        depositCount: this.total('depositCount', 'sum'),
        depositSum: this.total('depositSum', 'sum'),
        uniqueDepositCount: this.total('uniqueDepositCount', 'sum'),
        approvedLeads: this.total('approvedLeads', 'sum'),
        holdLeads: this.total('holdLeads', 'sum'),
        rejectedLeads: this.total('rejectedLeads', 'sum'),
        epc: this.total('epc', 'avg'),
        cr: this.total('cr', 'avg'),
        crUniqueReg: this.total('crUniqueReg', 'avg'),
        crFtdReg: this.total('crFtdReg', 'avg'),
        approvedPercent: this.total('approvedPercent', 'avg'),
        approvedSum: this.total('approvedSum', 'sum'),
        holdSum: this.total('holdSum', 'sum'),
        rejectedSum: this.total('rejectedSum', 'sum'),
        revShareRevenueSum: this.total('revShareRevenueSum', 'sum'),
      }
      exportToCsv(
        this.rows,
        this.columns,
        totalRow as any,
        this.getGroupedField,
      )
    },
    detailClicks(params: any = {}) {
      const filters = omitBy(this.filters, isNull)
      const url = new URL(window.location.origin + '/logs')
      const newFilters = {
        ...filters,
        ...params,
      }
      for (const [k, v] of Object.entries(newFilters)) {
        url.searchParams.append(k, v as string)
      }
      window.open(url.toString(), '_blank')?.focus()
    },
    detailConversions(params: any = {}) {
      const ftdObj: any = {}
      if (
        params.eventType === PostbackEventType.FirstDeposit &&
        (this.filters.factualStats === true ||
          (this.filters as any).factualStats === 'true')
      ) {
        ftdObj.conversionDateFrom = params.week || this.filters.dateFrom
        ftdObj.conversionDateTo = params.week || this.filters.dateTo
      }
      if ('status' in params) {
        ftdObj.viewConversions = true
      }
      const url = new URL(window.location.href)
      const newFilters = {
        ...this.filters,
        ...params,
        ...ftdObj,
        tab: 'general',
        target: 'conversions',
      }
      for (const [k, v] of Object.entries(newFilters)) {
        url.searchParams.append(k, v as string)
      }
      window.open(url.toString(), '_blank')?.focus()
    },
    isDisabled(column: string): boolean {
      if (this.filters.groupBy !== 'date' && this.filters.groupBy !== 'week') {
        return (
          !this.selectedColumns.includes(column) &&
          this.selectedColumns.length >= 1
        )
      }
      return (
        !this.selectedColumns.includes(column) &&
        this.selectedColumns.length > 4
      )
    },
    isDisabled2(column: string): boolean {
      if (this.selectedColumns.includes(column)) {
        return false
      }
      const lineColumns = [
        'clicks',
        'uniqueClicks',
        'epc',
        'cr',
        'crUniqueReg',
        'registrationCount',
        'crFtdReg',
        'approvedPercent',
        'revShareRevenueSum',
      ]
      const conversionColumns = ['approvedLeads', 'holdLeads', 'rejectedLeads']
      const financesColumns = ['approvedSum', 'holdSum', 'rejectedSum']
      const isLineColumn = lineColumns.includes(column)
      const isStackedColumn =
        conversionColumns.includes(column) || financesColumns.includes(column)
      const lineColumnsSelected =
        intersection(this.selectedColumns, lineColumns).length > 0
      const stackedColumnsSelected =
        intersection(this.selectedColumns, conversionColumns).length > 0 ||
        intersection(this.selectedColumns, financesColumns).length > 0
      if (
        !this.selectedColumns.includes(column) &&
        isStackedColumn &&
        stackedColumnsSelected &&
        lineColumnsSelected
      ) {
        return true
      }
      return (
        (this.filters.groupBy === 'date' || this.filters.groupBy === 'week') &&
        (intersection(this.selectedColumns, conversionColumns).length > 1 ||
          intersection(this.selectedColumns, financesColumns).length > 1) &&
        isLineColumn
      )
    },
    changeSelected(column: string) {
      if (this.isDisabled(column) || this.isDisabled2(column)) {
        return
      }
      if (this.selectedColumns.includes(column)) {
        this.selectedColumns = this.selectedColumns.filter(
          (c: string) => c !== column,
        )
      } else {
        this.selectedColumns = [...this.selectedColumns, column]
      }
      this.pagination.selectedColumns = this.selectedColumns
    },
    getCheckboxes() {
      return {
        clicks: false,
        uniqueClicks: false,
        registrationCount: false,
        firstDepositCount: false,
        firstDepositSum: false,
        depositCount: false,
        depositSum: false,
        uniqueDepositCount: false,
        approvedLeads: false,
        holdLeads: false,
        rejectedLeads: false,
        trashLeads: false,
        epc: false,
        cr: false,
        crUniqueReg: false,
        crFtdReg: false,
        approvedPercent: false,
        approvedSum: false,
        holdSum: false,
        rejectedSum: false,
        revShareRevenueSum: false,
      }
    },
    resetColumns() {
      this.selectedColumns = []
      this.visibleColumns = getGeneralColumns().map((c: any) => c.name)
      const checkboxes = this.getCheckboxes()
      for (const c of Object.keys(checkboxes)) {
        this[c] = false
      }
    },
    getGroupByArray() {
      return groupByOptions
    },
    formatColumnValue(val: any) {
      return formatValue(val)
    },
  },
})
