
import { mapActions, mapMutations, mapState } from 'vuex'
import axios from 'axios'
import store from '@/store'
import { defineComponent } from 'vue'
import OfferInfo from './steps/OfferInfo.vue'
import Targeting from './steps/Targeting.vue'
import Affiliates from './steps/Affiliates.vue'
import Traffic from './steps/Traffic.vue'
import Promos from './steps/Promos.vue'
import { getInitialOptions } from './config/getInitialOptions'
import {
  AffiliateReward,
  CommonReward,
  OfferTranslation,
  Promo,
} from '@/store/types'
import { isNumberArraysEqual, showErrorMessageSnackBar } from '@/utils'
import { maxBy, isEqual, flattenDeep, intersection } from 'lodash'
import { Currency } from '@/enums/Currency'
import Translations from '@/pages/offers/steps/Translations.vue'
import validateRewards, {
  validateRevShareRewards,
  validateWithoutValueRewards,
} from '@/pages/offers/config/validateRewards'
import { LeadLimitPeriod } from '@/enums/LeadLimitPeriod'

export default defineComponent({
  name: 'OffersEdit',
  components: {
    Translations,
    Promos,
    Traffic,
    Affiliates,
    Targeting,
    OfferInfo,
  },
  async created() {
    if (!this.$route.params.id) {
      this.reset()
    } else {
      await this.getData(this.$route.params.id)
    }
    const params = this.$router.currentRoute.value?.query || {}
    if ('tab' in params) {
      this.step = params.tab as any
    }
    const options = await getInitialOptions(store.state.offersModule.offer)
    this.defaultOptions = Object.assign({}, this.defaultOptions, options)
    this.loading = false
  },
  computed: mapState('offersModule', ['errors', 'offer']),
  data() {
    return {
      step: 'main',
      defaultOptions: [],
      loading: true,
      isSaving: false,
    }
  },
  methods: {
    ...mapActions('offersModule', ['getData']),
    ...mapMutations('offersModule', ['update', 'reset', 'setErrors']),
    onSubmit: async function() {
      if (!this.offer.image) {
        this.setErrors({ image: 'Please, select image' })
        return
      }
      this.isSaving = true
      const promos = this.offer.promos.filter((promo: Promo) => promo.url)
      const translations = this.offer.translations.filter(
        (t: OfferTranslation) =>
          Object.keys(t)?.length && (t.name || t.description),
      )
      const commonRewards = this.offer.commonRewards.filter(
        (reward: CommonReward) =>
          Object.keys(reward)?.length &&
          (Object.keys(reward.value)?.length || reward.geoIds?.length),
      )
      const affiliateRewards = this.offer.affiliateRewards.filter(
        (reward: AffiliateReward) =>
          Object.keys(reward)?.length &&
          (Object.keys(reward.value)?.length ||
            reward.geoIds?.length ||
            reward.affiliateIds?.length),
      )

      const data = {
        ...this.offer,
        commonRewards,
        affiliateRewards,
        promos,
        translations,
      }
      const method = !this.$route.params.id ? 'post' : 'put'
      try {
        for (const rew of affiliateRewards) {
          if (
            (![undefined, null, ''].includes(rew.informLeadLimit) &&
              !LeadLimitPeriod[rew.informLeadLimitPeriod]) ||
            (![undefined, null, ''].includes(rew.leadLimitPerMonth) &&
              !LeadLimitPeriod[rew.leadLimitPeriod]) ||
            (![undefined, null, ''].includes(
              this.offer.defaultLeadLimitPerMonth,
            ) &&
              !LeadLimitPeriod[this.offer.defaultLeadLimitPeriod]) ||
            (![undefined, null, ''].includes(
              this.offer.defaultInformLeadLimit,
            ) &&
              !LeadLimitPeriod[this.offer.defaultInformLeadLimitPeriod])
          ) {
            showErrorMessageSnackBar(
              'Укажите периоды для всех проставленных информационных либо фактических кап',
            )
            return
          }
        }
        if (!validateRewards(commonRewards, affiliateRewards)) {
          showErrorMessageSnackBar(
            'Нельзя использовать разные модели выплат на одно гео. Проверьте Таргетинг и Аффилейты.',
          )
          return
        }
        if (!validateWithoutValueRewards(commonRewards)) {
          showErrorMessageSnackBar(
            'Ошибка! На странице Таргетинг в некоторых ставках стоит галочка Без ставки, но при этом указана ненулевая ставка.',
          )
          return
        }
        if (!validateRevShareRewards(commonRewards, affiliateRewards)) {
          showErrorMessageSnackBar(
            'Выберите валюты и ставку веба для ревшары. Валюты для веба и рекла должны совпадать. Проверьте Таргетинг и Аффилейты.',
          )
          return
        }
        if (
          !this.validateUpsales(commonRewards) ||
          !this.validateUpsales(affiliateRewards)
        ) {
          return
        } else {
          const geos = flattenDeep(
            commonRewards
              .filter((r: CommonReward) => !r.upsaleNumber)
              .map((r: CommonReward) => r.geoIds),
          )
          const upsaleGeos = flattenDeep(
            commonRewards
              .filter((r: CommonReward) => r.upsaleNumber)
              .map((r: CommonReward) => r.geoIds),
          )
          if (intersection(geos, upsaleGeos)?.length) {
            showErrorMessageSnackBar(
              'Ошибка: в таргетинге нельзя задавать правила с доп. продажами и без на одинаковые гео.',
            )
            return
          }
        }
        const response: any = await axios[method]('/api/offers', data, {
          params: { id: this.$route.params.id },
        })
        this.setErrors({})

        const offerId = response.data.id
        await this.postFormData(
          data.image,
          'image',
          `/api/offers/${offerId}/image`,
        )
        const uploads = []
        for (const promo of response.data.promos) {
          const clientPromo = promos.find(
            (p: Promo) =>
              p.name === promo.name &&
              p.url === promo.url &&
              p.markupType === promo.markupType &&
              p.semanticType === promo.semanticType &&
              this.isEqual(p.geoIds, promo.geoIds) &&
              (p.affiliateIds?.length
                ? this.isEqual(p.affiliateIds, promo.affiliateIds)
                : true),
          )
          if (clientPromo?.archive) {
            uploads.push(
              this.postFormData(
                clientPromo.archive,
                'archive',
                `/api/promos/${promo.id}/upload`,
              ),
            )
          }
        }
        const uploadingOperations =
          this.offer.uploadingOperations + uploads.length
        this.update({ uploadingOperations })
        Promise.all(uploads)
          .then(() => {
            this.update({
              uploadingOperations: this.offer.uploadingOperations -=
                uploads.length,
            })
          })
          .catch(err => {
            showErrorMessageSnackBar(
              `Не удалось загрузить промо-архивы для оффера ${response.data.name}: ${err}`,
            )
          })

        this.$router.push('/offers')
      } catch (error) {
        this.setErrors((error as any).response?.data?.errors)
        if (Number((error as any).response?.status) !== 400) {
          showErrorMessageSnackBar(
            'Не удалось сохранить оффер. Проверьте настройки и попробуйте еще раз.',
          )
        }
      } finally {
        this.isSaving = false
      }
    },
    async postFormData(data: string, field: string, url: string) {
      const form = new FormData()
      form.append(field, data)
      await axios.post(url, form)
    },
    isEqual(first: number[], second: number[]): boolean {
      return isNumberArraysEqual(first, second)
    },
    onReset: function() {
      this.reset()
      this.$router.push('/offers')
    },
    validateUpsales(rewards: AffiliateReward[]): boolean {
      const filteredRewards = rewards.filter(
        (r: AffiliateReward) => r.upsaleNumber,
      )
      const upsaleRewards = filteredRewards.filter(
        (r: AffiliateReward) =>
          isEqual(r.geoIds, filteredRewards[0].geoIds) &&
          (r.affiliateIds?.length
            ? isEqual(r.affiliateIds, filteredRewards[0].affiliateIds)
            : true),
      )
      const maxUpSaleReward = maxBy(upsaleRewards, 'upsaleNumber')
      if (!upsaleRewards.length || !maxUpSaleReward) {
        return true
      }
      if (upsaleRewards.length !== maxUpSaleReward.upsaleNumber) {
        showErrorMessageSnackBar(
          'Количество доп. продаж должно быть равно наибольшему порядковому номеру доп. продажи.',
        )
        return false
      }
      const currencies = upsaleRewards.map(
        (r: CommonReward) => r.value.currency,
      )
      const advertCurrencies = upsaleRewards.map(
        (r: CommonReward) => r.advertiserValueCurrency,
      )
      if (
        currencies.every((c: Currency) => c === currencies[0]) &&
        advertCurrencies.every((c: Currency) => c === advertCurrencies[0]) &&
        currencies[0] === advertCurrencies[0]
      ) {
        return true
      }
      showErrorMessageSnackBar(
        'Валюты для всех доп. продаж по одинаковому гео должны быть одинаковыми.',
      )
      return false
    },
    getErrors(step: string): boolean {
      if (!this.errors) {
        return false
      }
      switch (step) {
        case 'main':
          return (
            this.errors.name ||
            this.errors.advertiserId ||
            this.errors.vertical ||
            this.errors.categoryIds ||
            this.errors.defaultHoldInDays ||
            this.errors.image ||
            this.errors.leadLimitPerMonth ||
            this.errors.status ||
            this.errors.description
          )
        case 'targeting':
          return this.errors.commonRewards
        case 'affiliates':
          return this.errors.affiliateRewards
        case 'integration':
          return (
            this.errors.advertiserLink ||
            this.errors.postbackLink ||
            this.errors.redirectDomainId
          )
        case 'traffic':
          return this.errors.trafficSources
        case 'promos':
          return this.errors.promos
        case 'translations':
          return this.errors.translations
        default:
          return false
      }
    },
  },
})
