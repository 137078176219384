
import axios from 'axios'
import { defineComponent } from 'vue'
import { Geo, TrafficProperty } from '@/store/types'
import { TrafficPropertyType } from '@/enums/TrafficPropertyType'
import { formatDate, formatValue } from '@/utils'
import { Currency } from '@/enums/Currency'
import IconWithName from '@/components/icons/IconWithName.vue'
import { LeadStatus } from '@/enums/LeadStatus'
import { RewardType } from '@/enums/RewardType'
import { getLeadChangelogColumns } from '@/pages/statistics2/config/columns'
export default defineComponent({
  name: 'ConversionInfoDialog',
  components: { IconWithName },
  props: ['open', 'close', 'lead'],
  computed: {
    showDialog(): boolean {
      return this.open
    },
  },
  mounted() {
    this.load()
  },
  async created() {
    const admins = (await axios.get('/api/admins')).data
    this.geos = (await axios.get('/api/geos')).data
    this.admins = admins.map((admin: any) => ({
      ...admin,
      email: `#${admin.id} ` + admin.credentials.email,
    }))
  },
  watch: {
    lead() {
      this.load()
    },
    open() {
      this.load()
    },
  },
  data: function() {
    return {
      columns: getLeadChangelogColumns(),
      currencies: Currency,
      leadStatuses: LeadStatus,
      rewardTypes: RewardType,
      loading: true,
      campaignUrl: null,
      conversion: null,
      admins: [],
      geos: [],
    }
  },
  methods: {
    async load() {
      this.loading = true
      const lead = (await axios.get('/api/conversions/' + this.lead.id)).data
      lead.changelogs =
        lead.changelogs?.sort((r1: any, r2: any) => r2.id - r1.id) || []
      this.conversion = lead
      this.campaignUrl = (
        await axios.get('/api/campaigns/' + lead.campaignId + '/url')
      ).data
      this.loading = false
    },
    getSids(properties: TrafficProperty[]) {
      const types = [
        TrafficPropertyType.SID1,
        TrafficPropertyType.SID2,
        TrafficPropertyType.SID3,
        TrafficPropertyType.SID4,
        TrafficPropertyType.SID5,
      ]
      const sids: string[] = []
      for (const prop of properties) {
        if (types.includes(prop.type)) {
          sids.push(prop.value as string)
        }
      }
      return sids.length ? sids.join('; ') : '—'
    },
    onClose() {
      this.$emit('close')
    },
    format(val: string) {
      return formatValue(val, 2, true)
    },
    frmtDate(val: string) {
      return formatDate(val)
    },
    getAdminEmail(id: number) {
      return (this.admins.find((ad: any) => ad.id === id) as any)?.credentials
        ?.email
    },
    getGeoById(geoId: number) {
      return this.geos.find((g: Geo) => g.id === geoId)
    },
  },
})
