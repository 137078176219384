export enum PostbackEventType {
  Registration,
  FirstDeposit,
  Deposit,
  Approved,
  Rejected,
  Trash,
  RevShare,
  Baseline,
}
