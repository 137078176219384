import { PostbackType } from '@/enums/PostbackType'

export function getPostbackTypes() {
  return [
    { label: 'Подтвержденный лид', value: PostbackType.Approved },
    { label: 'Регистрация', value: PostbackType.Pending },
    { label: 'Депозит', value: PostbackType.Hold },
    { label: 'Ревшара', value: PostbackType.RevShare },
    { label: 'Отклоненный', value: PostbackType.Rejected },
    { label: 'Трэш', value: PostbackType.Trash },
  ]
}
