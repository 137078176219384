import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "row justify-between",
  style: {"flex":"1"}
}
const _hoisted_2 = { class: "row items-center" }
const _hoisted_3 = {
  key: 0,
  class: "row items-center"
}
const _hoisted_4 = {
  key: 0,
  class: "q-mr-md"
}
const _hoisted_5 = {
  key: 1,
  class: "q-mr-md"
}
const _hoisted_6 = {
  key: 2,
  class: "q-mr-md"
}
const _hoisted_7 = {
  key: 3,
  class: "q-mr-md"
}
const _hoisted_8 = {
  key: 4,
  class: "q-mr-md"
}
const _hoisted_9 = {
  key: 5,
  class: "q-mr-md"
}
const _hoisted_10 = { class: "flex row items-center" }
const _hoisted_11 = ["alt", "src"]
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_ConversionEditDialog = _resolveComponent("ConversionEditDialog")!
  const _component_ConversionInfoDialog = _resolveComponent("ConversionInfoDialog")!
  const _component_ConversionHoldEditDialog = _resolveComponent("ConversionHoldEditDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_q_table, {
        dense: "",
        "rows-per-page-options": [5, 10, 25, 50, 100, 200, 500, 1000, 1500, 2000, 0],
        rows: _ctx.rows,
        columns: _ctx.columns,
        "row-key": "id",
        separator: "cell",
        pagination: _ctx.pagination,
        "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
        selected: _ctx.selected,
        "onUpdate:selected": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected) = $event)),
        loading: _ctx.loading,
        selection: "multiple",
        onRequest: _ctx.onRequest,
        "binary-state-sort": "",
        "column-sort-order": "da"
      }, {
        top: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_q_btn, {
                dense: "",
                label: "Апрув",
                flat: "",
                class: "q-mr-md",
                "no-caps": "",
                color: "primary",
                disable: !_ctx.selected.length,
                onClick: _ctx.approveConversions,
                icon: "check"
              }, null, 8, ["disable", "onClick"]),
              _createVNode(_component_q_btn, {
                dense: "",
                label: "Реджект",
                "no-caps": "",
                flat: "",
                class: "q-mr-md",
                color: "primary",
                disable: !_ctx.selected.length,
                onClick: _ctx.rejectConversions,
                icon: "cancel"
              }, null, 8, ["disable", "onClick"]),
              _createVNode(_component_q_btn, {
                dense: "",
                "no-caps": "",
                label: "Перевести в Холд",
                flat: "",
                class: "q-mr-md",
                color: "primary",
                disable: !_ctx.selected.length,
                onClick: _ctx.changeConversionHold,
                icon: "hourglass_top"
              }, null, 8, ["disable", "onClick"]),
              _createVNode(_component_q_btn, {
                dense: "",
                "no-caps": "",
                flat: "",
                label: "Редактировать",
                color: "negative",
                disable: !_ctx.selected.length,
                onClick: _ctx.changeEditDialog,
                icon: "edit"
              }, null, 8, ["disable", "onClick"])
            ]),
            (_ctx.user?.isSuperAdmin || _ctx.user?.isFinanceManager)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (Number(_ctx.tableAmount.webUsdAmount))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, "WEB: " + _toDisplayString(_ctx.formatMoney(_ctx.tableAmount.webUsdAmount)) + "$", 1))
                    : _createCommentVNode("", true),
                  (Number(_ctx.tableAmount.advUsdAmount))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, "ADV: " + _toDisplayString(_ctx.formatMoney(_ctx.tableAmount.advUsdAmount)) + "$", 1))
                    : _createCommentVNode("", true),
                  (Number(_ctx.tableAmount.webEurAmount))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, "WEB: " + _toDisplayString(_ctx.formatMoney(_ctx.tableAmount.webEurAmount)) + "€", 1))
                    : _createCommentVNode("", true),
                  (Number(_ctx.tableAmount.advEurAmount))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, "ADV: " + _toDisplayString(_ctx.formatMoney(_ctx.tableAmount.advEurAmount)) + "€", 1))
                    : _createCommentVNode("", true),
                  (Number(_ctx.tableAmount.profitUsd))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, "Маржа $: " + _toDisplayString(_ctx.formatMoney(_ctx.tableAmount.profitUsd)), 1))
                    : _createCommentVNode("", true),
                  (Number(_ctx.tableAmount.profitEur))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, "Маржа €: " + _toDisplayString(_ctx.formatMoney(_ctx.tableAmount.profitEur)), 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        loading: _withCtx(() => [
          _createVNode(_component_q_inner_loading, {
            showing: "",
            color: "primary"
          })
        ]),
        "body-cell-offerName": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_IconWithName, {
                id: props.row.offerId,
                name: props.row.offerName,
                size: "20px",
                color: "orange-7",
                icon: "description",
                entity: "offer"
              }, null, 8, ["id", "name"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-advertiserName": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_IconWithName, {
                id: props.row.advertiserId,
                name: props.row.advertiserName,
                size: "20px",
                color: "primary",
                icon: "people",
                entity: "advertiser"
              }, null, 8, ["id", "name"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-geo": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("img", {
                  class: "q-mr-sm",
                  height: "10",
                  width: "15",
                  alt: props.row.geo,
                  src: 
                `http://purecatamphetamine.github.io/country-flag-icons/3x2/${props.row.geo}.svg`
              
                }, null, 8, _hoisted_11),
                _createTextVNode(" " + _toDisplayString(props.row.geo), 1)
              ])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-amount": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              (props.row.amount !== null && props.row.amount !== undefined)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.amount)) + " ", 1),
                    _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromRow(props.row.currency)), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString('—')))
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-advertiserAmount": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              (
              props.row.advertiserAmount !== null &&
                props.row.advertiserAmount !== undefined
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.advertiserAmount)) + " ", 1),
                    _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromRow(props.row.advertiserCurrency)), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString('—')))
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-affiliateEmail": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_IconWithName, {
                id: 
              props.row.fantomAffiliateId
                ? `${props.row.affiliateId} (${props.row.fantomAffiliateId})`
                : props.row.affiliateId
            ,
                name: 
              _ctx.user?.notFullAccess || _ctx.user?.showStatisticsOnly
                ? ''
                : props.row.affiliateEmail
            ,
                size: "20px",
                color: "secondary",
                icon: "account_box",
                entity: "affiliate"
              }, null, 8, ["id", "name"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-ftdSum": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              (props.row.ftdSum !== null)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.ftdSum)) + " ", 1),
                    _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromRow(0)), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString('—')))
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-rdSum": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              (props.row.rdSum !== null)
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.rdSum)) + " ", 1),
                    _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromRow(0)), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString('—')))
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-depSum": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              (props.row.depSum !== null)
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.depSum)) + " ", 1),
                    _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromRow(0)), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString('—')))
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-actions": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                dense: "",
                round: "",
                flat: "",
                color: "primary",
                onClick: ($event: any) => (_ctx.checkInfo(props)),
                icon: "info"
              }, null, 8, ["onClick"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 1
      }, 8, ["rows", "columns", "pagination", "selected", "loading", "onRequest"])
    ]),
    (_ctx.showEditDialog)
      ? (_openBlock(), _createBlock(_component_ConversionEditDialog, {
          key: 0,
          open: _ctx.showEditDialog,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showEditDialog = false)),
          onRefresh: _ctx.onRequest,
          leads: _ctx.selected
        }, null, 8, ["open", "onRefresh", "leads"]))
      : _createCommentVNode("", true),
    (_ctx.showInfoDialog)
      ? (_openBlock(), _createBlock(_component_ConversionInfoDialog, {
          key: 1,
          open: _ctx.showInfoDialog,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showInfoDialog = false)),
          lead: _ctx.currentRow
        }, null, 8, ["open", "lead"]))
      : _createCommentVNode("", true),
    (_ctx.showHoldEditDialog)
      ? (_openBlock(), _createBlock(_component_ConversionHoldEditDialog, {
          key: 2,
          open: _ctx.showHoldEditDialog,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showHoldEditDialog = false)),
          onChange: _ctx.onRequest,
          lead: _ctx.currentRow,
          selected: _ctx.selected
        }, null, 8, ["open", "onChange", "lead", "selected"]))
      : _createCommentVNode("", true)
  ], 64))
}