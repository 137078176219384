
import { defineComponent } from 'vue'
import DatePicker from '../date-picker/DatePicker.vue'
import {
  FieldOption,
  lookupByField,
  lookupAffiliates,
  lookupTrafficSourcesForApps,
} from '@/utils/lookups'
import { getFiltersData } from '@/utils/appsFilters'
import axios from 'axios'
import { Affiliate } from '@/store/types'
import { FiltersType } from '@/utils/request'
import { initFilterOptions } from '@/utils/appsFilters'
import FiltersDialog from '../dialogs/AppsFiltersDialog.vue'
import { initOptionsByRouteParams } from '@/utils/appsFilters'
import IconWithName from '@/components/icons/IconWithName.vue'

export default defineComponent({
  name: 'Filters',
  components: { IconWithName, DatePicker, FiltersDialog },
  props: ['reset', 'currentFilters', 'changeAll', 'change', 'tab', 'export'],
  async created() {
    if (this.$route.query.mobileAppId) {
      this.apps = (await axios.get('/api/apps')).data
    }
    if (this.$route.query.affiliateId) {
      this.affiliates = (await axios.get('/api/affiliates/list')).data?.map(
        (affiliate: Affiliate) => ({
          ...affiliate,
          email: affiliate.credentials.email,
        }),
      )
    }
    if (this.$q.screen.width < 600) {
      await initFilterOptions(this)
    } else {
      await initOptionsByRouteParams(this)
    }
  },
  computed: {
    hideForAffiliates(): boolean {
      return !this.$route.path.includes('affiliates')
    },
    hideForFinances(): boolean {
      return !this.$route.path.includes('finances')
    },
    hideForStatistics(): boolean {
      return !this.$route.path.includes('statistics')
    },
    hideForBalances(): boolean {
      return !this.$route.path.includes('balances')
    },
    hideForPostbackLogs(): boolean {
      return !this.$route.path.includes('postback-logs')
    },
    showForPushGroups(): boolean {
      return (
        this.$route.path.includes('push-notifications') &&
        this.tab === 'push-groups'
      )
    },
    showForPostbackBlackListTab(): boolean {
      return (
        !this.$route.path.includes('postback-logs') ||
        (this.$route.path.includes('postback-logs') &&
          this.tab === 'black-list-logs') ||
        (this.$route.path.includes('postback-logs') && this.tab === 'installs')
      )
    },
    hideForPushNotifications(): boolean {
      return !this.$route.path.includes('push-notifications')
    },
    hideForMobileAppsPage(): boolean {
      return !this.$route.path.includes('mobile-apps')
    },
  },
  data: function() {
    return {
      filters: { ...this.currentFilters },
      showFiltersDialog: false,
      ...getFiltersData(null, this.$route.path.includes('finances')),
    }
  },
  watch: {
    search() {
      this.onChangeFilters('name', this.search)
    },
    currentFilters() {
      this.filters = { ...this.currentFilters }
      this.search = this.currentFilters.name
    },
  },
  methods: {
    onChangeDate(date: { startDate: string; endDate: string }) {
      this.filters.dateFrom = date.startDate
      this.filters.dateTo = date.endDate
    },
    resetFilters() {
      this.search = null
      this.$emit('reset')
    },
    onChangeFilters(field: string, value: string | number | null) {
      this.clearFilter(field)
      this.filters[field] = value
    },
    async getOptionsByField(
      value: string,
      update: Function,
      field: FieldOption,
    ) {
      await lookupByField(this, value, update, field)
    },
    async getAffiliates(value: string, update: Function) {
      await lookupAffiliates(this, value, update)
    },
    async getTrafficSources(value: string, update: Function) {
      await lookupTrafficSourcesForApps(this, value, update)
    },
    submitAll() {
      this.$emit('changeAll', this.filters)
    },
    submitMobileAll(filters: FiltersType) {
      this.$emit('changeAll', filters)
    },
    clearFilter(selectedRef: string) {
      if (this.$refs[selectedRef] !== void 0) {
        ;(this.$refs[selectedRef] as any).updateInputValue('')
      }
    },
  },
})
