
import { defineComponent } from 'vue'
import {
  getDefaultFilters,
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import getColumns from './getColumns'
import {
  enumToArray,
  showInternalErrorSnackBar,
  showSuccessSnackBar,
} from '@/utils'
import { CurrencyUpdateType } from '@/enums/CurrencyUpdateType'
import { Currency } from '@/enums/Currency'
import axios from 'axios'

export default defineComponent({
  name: 'CurrencyCourses',
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  data() {
    return {
      admins: [],
      filteredRows: [],
      ...getTableProperties('currency', 1176, false),
      filters: getDefaultFilters(),
      columns: getColumns(),
      showMainCurrencies: false,
      selectedCurrencies: [],
      correctedPercent: null,
      isSaving: false,
      allRows: [],
      currencies: enumToArray(Currency),
      currencyCorrectTypes: enumToArray(CurrencyUpdateType),
      currencyCorrectTypesEnum: CurrencyUpdateType,
    }
  },
  watch: {
    selectedCurrencies() {
      if (
        this.selectedCurrencies === null ||
        this.selectedCurrencies === undefined ||
        !this.selectedCurrencies?.length
      ) {
        this.filteredRows = this.rows
        return
      }
      this.filteredRows = this.rows.filter((r: any) =>
        (this.selectedCurrencies as any).includes(r.currency),
      )
    },
  },
  methods: {
    async onRequest(props: TableRequestProps) {
      const options = props || { pagination: this.pagination }
      const url = this.showMainCurrencies
        ? '/api/currencies/?isDefault=true'
        : '/api/currencies/'
      await request(options, this as Table, url)
      this.filteredRows = this.rows
      if (this.rows.length) {
        this.correctedPercent = (this.rows[0] as any).correctedPercent || null
      }
    },
    onChangeAmount(value: number, row: any) {
      this.filteredRows = this.filteredRows.map((r: any) => {
        if (r.id === row.id) {
          return {
            ...r,
            value,
            paidValueAmount: value,
          }
        }
        return r
      }) as never[]
      this.onCorrectPercent(this.correctedPercent)
    },
    onCorrectPercent(val: number | null) {
      this.correctedPercent = val as any
      if (!val) {
        val = 0
      }
      this.filteredRows = this.filteredRows.map((r: any) => {
        r.correctedValue = (
          (Number(r.value) * (100 - Number(val))) /
          100
        ).toFixed(4)
        r.correctedPercent = val
        return r
      }) as never[]
    },
    save() {
      this.isSaving = true
      axios
        .post(`/api/currencies`, this.filteredRows)
        .then(() => {
          showSuccessSnackBar('Курсы успешно сохранены.')
        })
        .catch(err => {
          showInternalErrorSnackBar(err)
        })
        .finally(() => {
          this.isSaving = false
        })
    },
  },
})
