
import axios from 'axios'
import { defineComponent } from 'vue'
import {
  getDefaultFilters,
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import Filters from '@/components/filters/Filters.vue'
import { mapState } from 'vuex'
import getColumns from './getColumns'
import { Row } from '@/utils/request'
import { FiltersType } from '@/utils/request'
import { confirmDialog } from '@/utils'
import IconWithName from '@/components/icons/IconWithName.vue'

export default defineComponent({
  name: 'AdvertisersList',
  components: { IconWithName, Filters },
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  computed: {
    ...mapState('advertisersModule', ['advertiser', 'errors']),
  },
  data() {
    return {
      advertisers: [],
      ...getTableProperties('id', 50),
      filters: getDefaultFilters(),
      columns: getColumns(),
    }
  },
  watch: {
    async filters() {
      await this.onRequest({ pagination: this.pagination })
    },
  },
  methods: {
    onRowClick(evt: string, row: Row) {
      this.$router.push('/advertisers/' + row.id)
    },
    async deleteRow(props: TableRequestProps) {
      confirmDialog(
        'Вы действительно хотите удалить рекламодателя?',
        async () => {
          this.loading = true
          await axios.delete('/api/advertisers', {
            params: { id: props.row?.id },
          })
          await this.onRequest({ pagination: this.pagination })
        },
      )
    },
    async onRequest(props: TableRequestProps) {
      await request(props, this as Table, '/api/advertisers/all')
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    resetFilters() {
      this.filters = getDefaultFilters()
    },
  },
})
