
import { mapMutations, mapState } from 'vuex'
import { defineComponent } from 'vue'
import { TrafficSource } from '@/store/types'
import axios from 'axios'
import store from '@/store'

export default defineComponent({
  name: 'TrafficSources',
  created() {
    axios
      .get('/api/traffic-sources')
      .then(res => {
        const trafficSourcesIds = this.mobileApp.trafficSources.map(
          (source: TrafficSource) => source.id,
        )
        this.defaultTrafficSources = res.data.map(
          (defaultSource: TrafficSource) => ({
            ...defaultSource,
            isDefault: true,
            isActive: trafficSourcesIds.includes(defaultSource.id),
          }),
        )
      })
      .catch(error => {
        throw new Error(error)
      })
      .finally(() => {
        this.loading = false
      })
  },
  computed: {
    ...mapState('mobileAppsModule', ['mobileApp', 'errors']),
    trafficSources(): TrafficSource[] {
      const defaultSourcesIds = this.defaultTrafficSources.map(
        (source: TrafficSource) => source.id,
      )
      const customSources = store.state.mobileAppsModule.mobileApp.trafficSources
        .filter(
          (source: TrafficSource) => !defaultSourcesIds.includes(source.id),
        )
        .map((source: TrafficSource) => ({ ...source, isActive: true }))

      return [...this.defaultTrafficSources, ...customSources]
    },
  },
  data: function() {
    return {
      loading: true,
      defaultTrafficSources: [],
    }
  },
  methods: {
    ...mapMutations('mobileAppsModule', ['update']),
    addTrafficSource() {
      this.defaultTrafficSources.push({
        isDefault: false,
        isActive: false,
      } as never)
    },
    updateTrafficSources(index: number, name?: string) {
      const trafficSources = this.trafficSources
      if (name) {
        trafficSources[index].name = name
      }
      this.update({
        trafficSources: trafficSources.filter(source => source.isActive),
      })
    },
  },
})
