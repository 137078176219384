
import { defineComponent } from 'vue'
import {
  getTableProperties,
  getDefaultFilters,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import { getPayoutColumns } from './getColumns'
import { PayoutStatus } from '@/enums/PayoutStatus'
import { FiltersType } from '@/utils/request'
import PayoutEditDialog from '@/components/dialogs/PayoutEditDialog.vue'
import { Currency } from '@/enums/Currency'
import IconWithName from '@/components/icons/IconWithName.vue'
import { exportToCsv } from '@/utils/export'
import { enumToArray, showSuccessSnackBar } from '@/utils'
import { formatFiltersFromQuery } from '@/utils/filters'
import DatePicker from '@/components/date-picker/DatePicker.vue'

export default defineComponent({
  name: 'Payouts',
  props: ['affiliateId'],
  components: { DatePicker, IconWithName, PayoutEditDialog },
  async mounted() {
    formatFiltersFromQuery(this)
    await this.onRequest({ pagination: this.pagination })
  },
  data: function() {
    return {
      ...getTableProperties('createdAt', 25),
      filters: {
        ...(this.affiliateId
          ? { affiliateId: this.affiliateId }
          : {
              status: [
                PayoutStatus.InReview,
                PayoutStatus.ReadyForPayment,
                PayoutStatus.Processing,
              ],
            }),
        ...getDefaultFilters(),
      },
      columns: getPayoutColumns(),
      payoutStatuses: PayoutStatus,
      showPayoutEditDialog: false,
      currentPayout: {},
      currencies: Currency,
      payoutStatusesEnum: enumToArray(PayoutStatus),
    }
  },
  watch: {
    async filters() {
      await this.onRequest({ pagination: this.pagination })
    },
  },
  methods: {
    async onRequest(props: TableRequestProps) {
      await request(props, this as Table, '/api/payouts/')
    },
    editPayout(row: any) {
      this.currentPayout = row
      this.showPayoutEditDialog = !this.showPayoutEditDialog
    },
    infoPayout(evt: any, row: any) {
      this.$router.push({
        name: 'payout',
        params: {
          id: row.id,
          ...(this.affiliateId ? { affiliateId: this.affiliateId } : {}),
          payout: JSON.stringify(row),
        },
      })
    },
    clearFilter(selectedRef: string) {
      if (this.$refs[selectedRef] !== void 0) {
        ;(this.$refs[selectedRef] as any).updateInputValue('')
      }
    },
    onChangeFilters(field: string, value?: string) {
      this.clearFilter(field)
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        ...(this.affiliateId ? { affiliateId: this.affiliateId } : {}),
        [field]: value,
      }
    },
    resetFilters() {
      this.filters = {
        ...(this.affiliateId ? { affiliateId: this.affiliateId } : {}),
        ...getDefaultFilters(),
      } as any
    },
    changeAll(filters: FiltersType) {
      this.filters = {
        ...(this.affiliateId ? { affiliateId: this.affiliateId } : {}),
        ...filters,
      } as any
    },
    onExport() {
      exportToCsv(
        this.rows.map((r: any) => ({
          ...r,
          affiliateEmail: `#${r.affiliateId} ${r.affiliateEmail}`,
        })),
        this.columns,
      )
    },
    copy(text: string) {
      const element = document.createElement('textarea')
      document.body.appendChild(element)
      element.value = text
      element.select()
      document.execCommand('copy')
      document.body.removeChild(element)
      showSuccessSnackBar('Скопировано')
    },
    async onChangeDate(date: { startDate: string; endDate: string }) {
      this.filters.dateFrom = date.startDate
      this.filters.dateTo = date.endDate
      await this.onRequest({ pagination: this.pagination })
    },
  },
})
