import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_RedirectEvents = _resolveComponent("RedirectEvents")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_AdvertiserLogs = _resolveComponent("AdvertiserLogs")!
  const _component_AffiliateLogs = _resolveComponent("AffiliateLogs")!
  const _component_ConsumersTab = _resolveComponent("ConsumersTab")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-ml-xl q-my-xl" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_tabs, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
        "inline-label": "",
        breakpoint: 0,
        align: "justify",
        "no-caps": "",
        class: "shadow-2 q-mb-md q-ml-lg",
        style: {"max-width":"800px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab, {
            name: "clicks",
            label: "Клики"
          }),
          _createVNode(_component_q_tab, {
            name: "advertiser-logs",
            label: "Принятые постбеки"
          }),
          _createVNode(_component_q_tab, {
            name: "affiliate-logs",
            label: "Отправленные постбеки"
          }),
          _createVNode(_component_q_tab, {
            name: "consumers",
            label: "Consumers (пересечения)"
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_q_tab_panels, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event)),
        animated: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab_panel, { name: "clicks" }, {
            default: _withCtx(() => [
              _createVNode(_component_RedirectEvents)
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panel, { name: "advertiser-logs" }, {
            default: _withCtx(() => [
              _createVNode(_component_AdvertiserLogs)
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panel, { name: "affiliate-logs" }, {
            default: _withCtx(() => [
              _createVNode(_component_AffiliateLogs)
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panel, { name: "consumers" }, {
            default: _withCtx(() => [
              _createVNode(_component_ConsumersTab)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}