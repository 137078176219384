import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Filters = _resolveComponent("Filters")!
  const _component_ConsumersTable = _resolveComponent("ConsumersTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Filters, {
      tab: "consumers",
      onExport: _ctx.onExport,
      onReset: _ctx.resetFilters,
      onChangeAll: _ctx.changeAll,
      onChange: _ctx.onChangeFilters,
      "current-filters": _ctx.filters
    }, null, 8, ["onExport", "onReset", "onChangeAll", "onChange", "current-filters"]),
    _createVNode(_component_ConsumersTable, {
      filters: _ctx.filters,
      exportCsv: _ctx.exportCsv
    }, null, 8, ["filters", "exportCsv"])
  ]))
}