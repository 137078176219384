import { CommonReward } from '@/store/types'
import { RewardType } from '@/enums/RewardType'

export function getTypesForRewards(rewards: CommonReward[]) {
  const rewardTypes = [...new Set(rewards.map(reward => reward.type))]
  return rewardTypes.map(type => {
    let className = 'default-option'
    switch (type) {
      case RewardType.FTD:
        className = 'first-option'
        break
      case RewardType.RevShare:
        className = 'second-option'
        break
      case RewardType.CPL:
      case RewardType.CPA:
        className = 'third-option'
        break
      case RewardType.CPS:
      case RewardType.SOI:
      case RewardType.DOI:
      case RewardType.BL:
        className = 'fourth-option'
        break
    }
    if (type === RewardType.FTD) {
      return `<div class='option ${className}'>Миндеп</div>`
    }
    if (type === RewardType.BL) {
      return `<div class='option ${className}'>Бейс</div>`
    }
    return `<div class='option ${className}'>${RewardType[type]}</div>`
  })
}
