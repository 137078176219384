import { formatDate, formatValue, isDefined, RowValue } from '@/utils'
import { Currency } from '@/enums/Currency'
import { PayoutStatus } from '@/enums/PayoutStatus'
import { PaymentSystem } from '@/enums/PaymentSystem'
import { PaymentSystemNetwork } from '@/enums/PaymentSystemNetwork'
import { Row } from '@/utils/request'
import { LeadStatus } from '@/enums/LeadStatus'

export function getPayoutColumns() {
  return [
    { name: 'id', label: 'ID', field: 'id', align: 'center' },
    {
      name: 'createdAt',
      label: 'Дата',
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'affiliateEmail',
      label: 'Аффилейт',
      field: 'affiliateEmail',
      align: 'left',
      sortable: true,
    },
    {
      name: 'payoutAmount',
      label: 'Сумма',
      field: 'payoutAmount',
      align: 'right',
      classes: 'monospaced',
      format: (val: string, row: any) => {
        return (row.payoutAmount || 0).toFixed(2)
      },
    },
    {
      name: 'payoutCurrency',
      label: 'Валюта',
      field: 'payoutCurrency',
      align: 'right',
      classes: 'monospaced',
      format: (val: string, row: any) => {
        return Currency[row.payoutCurrency]
      },
    },
    {
      name: 'paymentSystem',
      label: 'Платежная система',
      field: 'paymentSystem',
      align: 'left',
      format: (val: string, row: any) =>
        PaymentSystem[row.paymentSystem] +
        (PaymentSystemNetwork[row.paymentSystemNetwork]
          ? ` (${PaymentSystemNetwork[row.paymentSystemNetwork].toLowerCase()})`
          : ''),
    },
    {
      name: 'account',
      label: 'Номер кошелька',
      field: 'account',
      align: 'left',
    },
    {
      name: 'status',
      label: 'Статус',
      field: 'status',
      align: 'center',
      sortable: true,
      format: (val: string, row: any) => PayoutStatus[row.status],
    },
    {
      name: 'feePercent',
      label: 'Коммисия, %',
      field: 'feePercent',
      align: 'right',
      classes: 'monospaced',
      format: (val: string) => isDefined(val),
    },
    {
      name: 'paidValueAmount',
      label: 'К оплате',
      field: 'paidValueAmount',
      align: 'right',
      classes: 'monospaced',
      format: (val: string, row: any) => {
        return (row.paidValueAmount || 0).toFixed(2)
      },
    },
    {
      name: 'paidValueCurrency',
      label: 'Валюта',
      field: 'paidValueCurrency',
      align: 'right',
      classes: 'monospaced',
      format: (val: string, row: any) => {
        return Currency[row.paidValueCurrency]
          ? Currency[row.paidValueCurrency]
          : Currency[row.payoutCurrency]
      },
    },
    { name: 'actions', label: 'Действия', field: 'actions', align: 'center' },
    {
      name: 'updatedAt',
      label: 'Дата обновления',
      field: 'updatedAt',
      align: 'left',
      format: (val: string) => formatDate(val),
    },
    {
      name: 'comment',
      label: 'Комментарий (хеш транзакции)',
      field: 'comment',
      align: 'left',
      format: (val: string) => isDefined(val),
    },
    {
      name: 'rejectComment',
      label: 'Причина реджекта',
      field: 'rejectComment',
      align: 'left',
      format: (val: string) => isDefined(val),
    },
  ]
}

export function getConversionsColumns() {
  const arr: any = [
    {
      name: 'createdAt',
      label: 'Дата регистрации',
      field: 'createdAt',
      align: 'center',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'conversionDate',
      label: 'Дата конверсии',
      field: 'conversionDate',
      align: 'center',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'offerName',
      label: 'Оффер',
      field: 'offerName',
      align: 'center',
      sortable: true,
    },
    {
      name: 'advertiserName',
      label: 'Рекламодатель',
      field: 'advertiserName',
      align: 'center',
      sortable: true,
    },
    {
      name: 'isoCode',
      label: 'Страна',
      field: 'isoCode',
      align: 'center',
      sortable: true,
    },
    {
      name: 'status',
      label: 'Статус',
      field: 'status',
      align: 'center',
      sortable: true,
      format: (val: number, row: Row) => {
        if (row.attributes?.advertiserStatus) {
          return LeadStatus[val] + ` ( ${row.attributes?.advertiserStatus} ) `
        }
        if (row.attributes?.disable_postback) {
          return LeadStatus[val] + ` ( Отключен постбек ) `
        }
        if (row.attributes?.over_limited) {
          return LeadStatus[val] + ` ( Превышена капа ) `
        }
        if (row.attributes?.over_postclick) {
          return LeadStatus[val] + ` ( Превышен постклик ) `
        }
        return LeadStatus[val]
      },
    },
  ]
  if (!JSON.parse(localStorage.getItem('user') as any)?.showStatisticsOnly) {
    arr.push({
      name: 'amount',
      label: 'Выплата вебу',
      field: 'amount',
      align: 'right',
      classes: 'monospaced',
      format: (val: RowValue, row: any) => {
        return formatValue(val, 3) + ' ' + (Currency[row.currency] ?? '')
      },
    })
    arr.push({
      name: 'advertiserAmount',
      label: 'Выплата рекла',
      field: 'advertiserAmount',
      align: 'right',
      classes: 'monospaced',
      format: (val: RowValue, row: any) => {
        return (
          formatValue(val, 3) + ' ' + (Currency[row.advertiserCurrency] ?? '')
        )
      },
    })
  }
  arr.push({
    name: 'id',
    label: 'ID',
    field: 'id',
    align: 'left',
    sortable: true,
  })

  return arr
}
