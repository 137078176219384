
import axios from 'axios'
import { defineComponent } from 'vue'
import { showSuccessSnackBar } from '@/utils'
export default defineComponent({
  name: 'ReferralPercentDialog',
  props: ['open', 'close'],
  async created() {
    const data = (await axios.get('/api/referrals/info')).data
    this.price = data?.generalReferralPercent || null
    this.referralProgramOn = data?.referralProgramOn || false
  },
  computed: {
    showDialog(): boolean {
      return this.open
    },
  },
  data: function() {
    return {
      price: 0,
      referralProgramOn: false,
      loading: false,
    }
  },
  methods: {
    async save() {
      this.loading = true
      axios
        .post(`/api/referrals/general`, {
          generalReferralPercent: this.price || null,
          individualReferralPercent: this.price || null,
          referralProgramOn: this.referralProgramOn || false,
          broughtByReferralProgramOn: this.referralProgramOn || false,
          broughtByReferralPercent: this.price || null,
        })
        .then(() => {
          this.$emit('close')
          showSuccessSnackBar('Изменения сохранены')
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
})
