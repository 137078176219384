import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_input, {
      class: "q-mr-sm",
      modelValue: _ctx.maxValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.maxValue) = $event)),
      type: "number",
      dense: "",
      style: {"max-width":"60px"}
    }, null, 8, ["modelValue"]),
    _createVNode(_component_q_btn, {
      onClick: _ctx.save,
      size: "xs",
      outline: "",
      "no-caps": "",
      label: "Сохранить",
      loading: _ctx.loading,
      disable: _ctx.loading
    }, null, 8, ["onClick", "loading", "disable"])
  ]))
}