import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "col-sm-12 col-lg-6 q-mr-lg q-mb-lg" }
const _hoisted_2 = { class: "row items-center" }
const _hoisted_3 = { class: "q-mx-md" }
const _hoisted_4 = { class: "flex column col-4" }
const _hoisted_5 = { class: "q-mr-md" }
const _hoisted_6 = { class: "flex column col-7" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  class: "q-ml-sm absolute",
  style: {"right":"10px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_AffiliateCommentDialog = _resolveComponent("AffiliateCommentDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.showDialog,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDialog) = $event)),
      onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.$emit('close')), ["esc"]))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, { style: {"min-width":"650px"} }, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, { class: "q-mt-lg" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("b", _hoisted_3, "Комментарии (aff ID #" + _toDisplayString(_ctx.affiliateId) + ")", 1),
                    _createVNode(_component_q_btn, {
                      size: "sm",
                      "no-caps": "",
                      rounded: "",
                      color: "primary",
                      label: "Добавить",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showCommentDialog = !_ctx.showCommentDialog))
                    })
                  ])
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comments, (comment, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "flex row q-mb-md q-pa-md relative-position",
                    style: {"border-radius":"6px","border":"1px solid #ccc"}
                  }, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.format(comment.createdAt)), 1),
                      _createVNode(_component_IconWithName, {
                        id: comment.createdBy?.id || comment.createdById,
                        name: 
                comment.createdBy?.credentials?.email ||
                  _ctx.admins[comment.createdById]
              ,
                        size: "20px",
                        color: "primary",
                        icon: "manage_accounts",
                        entity: "manager"
                      }, null, 8, ["id", "name"])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("span", {
                        innerHTML: comment.comment
                      }, null, 8, _hoisted_7)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_q_btn, {
                        size: "xs",
                        round: "",
                        icon: "delete",
                        color: "negative",
                        onClick: ($event: any) => (_ctx.deleteComment(comment.id))
                      }, null, 8, ["onClick"])
                    ])
                  ]))
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_actions, {
              align: "right",
              class: "text-primary"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  flat: "",
                  label: "Закрыть",
                  "no-caps": "",
                  onClick: _ctx.onClose
                }, null, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_AffiliateCommentDialog, {
      open: _ctx.showCommentDialog,
      onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showCommentDialog = false)),
      onRefresh: _ctx.refreshAndClose,
      affiliateId: _ctx.affiliateId
    }, null, 8, ["open", "onRefresh", "affiliateId"])
  ], 64))
}