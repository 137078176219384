import { GroupByOptions } from '@/utils/request'

export const groupByOptions: GroupByOptions = [
  {
    id: 'date',
    name: 'День',
    field: 'date',
  },
  {
    id: 'week',
    name: 'Неделя',
    field: 'week',
  },
  {
    id: 'month',
    name: 'Месяц',
    field: 'month',
  },
  {
    id: 'offerId',
    name: 'Оффер',
    field: 'offerName',
  },
  {
    id: 'affiliateId',
    name: 'Аффилейт',
    field: 'affiliateEmail',
  },
  {
    id: 'advertiserId',
    name: 'Рекламодатель',
    field: 'advertiserName',
  },
  {
    id: 'campaignId',
    name: 'Поток',
    field: 'campaignName',
  },
  {
    id: 'geoId',
    name: 'Гео',
    field: 'geoName',
  },
  {
    id: 'landingId',
    name: 'Лендинг',
    field: 'promoName',
  },
  {
    id: 'sid1',
    name: 'SUB 1',
    field: 'sid1',
  },
  {
    id: 'sid2',
    name: 'SUB 2',
    field: 'sid2',
  },
  {
    id: 'sid3',
    name: 'SUB 3',
    field: 'sid3',
  },
  {
    id: 'sid4',
    name: 'SUB 4',
    field: 'sid4',
  },
  {
    id: 'sid5',
    name: 'SUB 5',
    field: 'sid5',
  },
]

export const groupByOptions2: GroupByOptions = [
  {
    id: 'date',
    name: 'День',
    field: 'date',
  },
  {
    id: 'week',
    name: 'Неделя',
    field: 'week',
  },
  {
    id: 'month',
    name: 'Месяц',
    field: 'month',
  },
  {
    id: 'offerId',
    name: 'Оффер',
    field: 'offerName',
  },
  {
    id: 'affiliateId',
    name: 'Аффилейт',
    field: 'affiliateEmail',
  },
  {
    id: 'advertiserId',
    name: 'Рекламодатель',
    field: 'advertiserName',
  },
  {
    id: 'campaignId',
    name: 'Поток',
    field: 'campaignName',
  },
  {
    id: 'geoId',
    name: 'Гео',
    field: 'geoName',
  },
  {
    id: 'landingId',
    name: 'Лендинг',
    field: 'promoName',
  },
]

export const groupByReferralOptions: GroupByOptions = [
  {
    id: 'id',
    name: 'Аффилейт',
    field: 'affiliateEmail',
  },
]

export const groupByReferralOptions2: GroupByOptions = [
  {
    id: 'affiliateId',
    name: 'Реферал',
    field: 'affiliateEmail',
  },
]

export const groupByReferralOptions3: GroupByOptions = [
  {
    id: 'offerId',
    name: 'Оффер',
    field: 'offerName',
  },
  {
    id: 'geoId',
    name: 'Гео',
    field: 'geoName',
  },
]
