import { Affiliate } from '@/store/types'
import axios from 'axios'

export async function getInitialOptions(): Promise<object> {
  const defaultOptions = {
    geos: [],
    affiliates: [],
  }

  defaultOptions.geos = (await axios.get('/api/geos')).data

  const affiliates = (await axios.get('/api/affiliates/list')).data
  defaultOptions.affiliates = affiliates.map((affiliate: Affiliate) => ({
    ...affiliate,
    email: `#${affiliate.id} 👤 ` + affiliate.credentials.email,
  }))

  return defaultOptions
}
