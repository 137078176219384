
import { defineComponent } from 'vue'
import GeneralInfo from '@/pages/campaigns/steps/GeneralInfo.vue'
import Promos from '@/pages/campaigns/steps/Promos.vue'
import Postbacks from '@/pages/campaigns/steps/Postbacks.vue'
import RedirectDomain from '@/pages/campaigns/steps/RedirectDomain.vue'
import { mapState, mapActions, mapMutations } from 'vuex'
import axios from 'axios'
import {
  getInitialOptions,
  setOptionsByOfferId,
  DefaultOptions,
} from '@/pages/campaigns/getInitialOptions'
import { Postback } from '@/store/types'
import { showErrorMessageSnackBar } from '@/utils'

export default defineComponent({
  name: 'CampaignEditDialog',
  props: ['offerId', 'campaignId', 'open', 'close', 'refresh'],
  components: { GeneralInfo, Promos, Postbacks, RedirectDomain },
  computed: {
    ...mapState('campaignsModule', ['errors', 'campaign']),
    ...mapState('authModule', ['user']),
    showDialog(): boolean {
      return this.open
    },
  },
  async created() {
    if (!this.campaignId) {
      this.onReset()
      if (this.offerId) {
        this.update({ offerId: Number(this.offerId) })
        await this.selectOffer()
      }
      this.ready = true
      return
    }

    await this.getData(this.campaignId)
    this.defaultOptions = await getInitialOptions(this.campaign)
    this.ready = true
  },
  data() {
    return {
      defaultOptions: {},
      ready: false,
      loading: false,
    }
  },
  methods: {
    ...mapActions('campaignsModule', ['getData']),
    ...mapMutations('campaignsModule', ['update', 'reset', 'setErrors']),
    ...mapMutations('authModule', ['logout']),
    selectOffer: async function() {
      if (!this.campaign.offerId) {
        return
      }

      const options = await setOptionsByOfferId(
        this.campaign,
        this.defaultOptions as DefaultOptions,
      )
      this.defaultOptions = Object.assign({}, this.defaultOptions, options)
    },
    onSubmit: function() {
      this.loading = true
      const method = !this.campaignId ? 'post' : 'put'
      const postbacks = this.campaign.postbacks.filter(
        (postback: Postback) => postback.url,
      )
      if (!this.campaign.affiliateId) {
        showErrorMessageSnackBar('Выберите аффилейта')
        this.loading = false
        return
      }
      axios[method](
        '/api/campaigns',
        { ...this.campaign, postbacks },
        {
          params: { id: this.campaignId },
        },
      )
        .then(() => {
          this.setErrors({})
          this.$router.push('/campaigns')
          this.$emit('close')
          this.$emit('refresh')
        })
        .catch(({ response }) => {
          this.setErrors(response.data.errors)
        })
        .finally(() => {
          this.loading = false
          if (!Object.keys(this.errors).length) {
            return
          }
          const elements = document.getElementsByClassName('q-field__messages')
          if (elements.length) {
            for (const el of elements) {
              if (el.firstChild) {
                el.scrollIntoView()
                return
              }
            }
          }
        })
    },
    onReset: function() {
      this.reset()
    },
  },
})
