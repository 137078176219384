
import { getPushGroupsColumns } from './config/getColumns'
import { defineComponent } from 'vue'
import Filters from '@/components/filters/AppsFilters.vue'
import {
  FiltersType,
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import { PushNotification } from '@/store/types'
import axios from 'axios'

export default defineComponent({
  name: 'PushGroupsList',
  components: { Filters },
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  data() {
    return {
      ...getTableProperties('createdAt', 25),
      filters: {},
      columns: getPushGroupsColumns(),
      storageUrl: process.env.CDN_STORAGE_URL,
    }
  },
  watch: {
    async filters() {
      await this.onRequest({ pagination: this.pagination })
    },
  },
  methods: {
    editPush(id: number) {
      this.$router.push('/push/' + id)
    },
    async updatePush(push: PushNotification) {
      await axios.put('/api/push-notifications/turn', push, {
        params: { id: push.autoPushGroupId },
      })
      await this.onRequest({ pagination: this.pagination })
    },
    async deleteRow(groupId: string) {
      this.loading = true
      await axios.delete('/api/push-notifications', {
        params: { id: groupId },
      })
      await this.onRequest({ pagination: this.pagination })
    },
    async onRequest(props: TableRequestProps) {
      await request(props, this as Table, '/api/push-notifications/')
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    resetFilters() {
      this.filters = {}
    },
  },
})
