export enum TrafficPropertyType {
  IP,
  Referer,
  SID1,
  SID2,
  SID3,
  SID4,
  SID5,
  SID6,
  SID7,
}
