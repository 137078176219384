import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "text-h6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showDialog) = $event)),
    onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.$emit('close')), ["esc"]))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { style: {"min-width":"350px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, " Добавить рефералов для веба #" + _toDisplayString(_ctx.affiliateId), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_select, {
                dense: "",
                modelValue: _ctx.affiliateIds,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.affiliateIds) = $event)),
                onFilter: _ctx.getAffiliates,
                ref: "ref-affiliateIds",
                onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearFilter('ref-affiliateIds'))),
                outlined: "",
                multiple: "",
                "use-chips": "",
                class: "col-4 q-mr-lg",
                options: _ctx.affiliates,
                "option-value": "id",
                "option-label": "email",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, {
                option: _withCtx((scope) => [
                  _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_IconWithName, {
                            id: scope.opt.id,
                            name: scope.opt.email,
                            size: "20px",
                            color: "secondary",
                            icon: "account_box",
                            entity: "affiliate"
                          }, null, 8, ["id", "name"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1040)), [
                    [_directive_close_popup]
                  ])
                ]),
                _: 1
              }, 8, ["modelValue", "onFilter", "options"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_actions, {
            align: "right",
            class: "text-primary"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                "no-caps": "",
                flat: "",
                label: "Отменить",
                onClick: _ctx.onClose
              }, null, 8, ["onClick"]),
              _createVNode(_component_q_btn, {
                "no-caps": "",
                flat: "",
                label: "Добавить рефералов",
                onClick: _ctx.addReferrals,
                loading: _ctx.isSaving,
                disable: _ctx.isSaving
              }, null, 8, ["onClick", "loading", "disable"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}