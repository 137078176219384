
import { mapActions, mapMutations, mapState } from 'vuex'
import axios from 'axios'
import { defineComponent } from 'vue'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { payoutCurrencies } from '@/utils'
import { lookupAffiliates } from '@/utils/lookups'

export default defineComponent({
  name: 'AdminsEdit',
  computed: mapState('adminsModule', ['admin', 'errors']),
  async created() {
    if (!this.$route.params.id) {
      this.reset()
      this.loading = false
      return
    }

    await this.getData(this.$route.params.id)
    this.loading = false
  },
  setup(): any {
    const $q = useQuasar()
    return {
      showErrorSnackbar() {
        $q.notify({
          type: 'negative',
          message: this.errors?.message,
          position: 'top-right',
          timeout: 4000,
        })
      },
    }
  },
  data: function() {
    return {
      affiliates: [],
      loading: true,
      currencies: payoutCurrencies,
    }
  },
  methods: {
    ...mapActions('adminsModule', ['getData']),
    ...mapMutations('adminsModule', ['update', 'reset', 'setErrors']),
    onSubmit: function() {
      axios
        .post('/api/admins', this.admin, {
          params: { id: this.$route.params.id },
        })
        .then(() => {
          this.setErrors({})
          this.reset()
          this.$router.push('/admins')
        })
        .catch(({ response }) => {
          this.setErrors(response.data.errors)
          this.showErrorSnackbar()
        })
    },
    goBack: function() {
      this.reset()
      this.$router.push('/admins')
    },
    async getAffiliates(value: string, update: Function) {
      await lookupAffiliates(this, value, update)
    },
    clearFilter(selectedRef: string) {
      if (this.$refs[selectedRef] !== void 0) {
        ;(this.$refs[selectedRef] as any).updateInputValue('')
      }
    },
  },
})
