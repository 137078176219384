import { isDefined, RowValue, formatDate } from '@/utils'

export function getRedirectEventsColumns() {
  return [
    {
      name: 'createdAt',
      label: 'Дата / Время',
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'id',
      label: 'Клик ID',
      field: 'id',
      align: 'left',
    },
    {
      name: 'ip',
      label: 'IP',
      field: 'ip',
      align: 'left',
    },
    {
      name: 'geoName',
      label: 'Гео',
      field: 'geoName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'affiliateEmail',
      label: 'Аффилейт',
      field: 'affiliateEmail',
      align: 'left',
      sortable: true,
      format: (val: string, row: any) => `${val} [ ID #${row.affiliateId} ]`,
    },
    {
      name: 'offerName',
      label: 'Оффер',
      field: 'offerName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'campaignName',
      label: 'Поток',
      field: 'campaignName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'os',
      label: 'Операционная система',
      field: 'os',
      align: 'left',
      format: (val: any) => isDefined(val?.trim() || null),
    },
    {
      name: 'device',
      label: 'Устройство',
      field: 'device',
      align: 'left',
      format: (val: any) => isDefined(val?.trim() || null),
    },
    {
      name: 'browser',
      label: 'Браузер',
      field: 'browser',
      align: 'left',
      format: (val: any) => isDefined(val?.trim() || null),
    },
    {
      name: 'campaignUrl',
      label: 'Ссылка потока',
      field: 'campaignUrl',
      align: 'left',
    },
    {
      name: 'targetUrl',
      label: 'Редирект на оффер',
      field: 'targetUrl',
      align: 'left',
    },
    {
      name: 'tdsRedirectEventId',
      label: 'TDS Клик ID',
      field: 'tdsRedirectEventId',
      align: 'left',
    },
    {
      name: 'request',
      label: 'Запрос в трекер',
      field: 'request',
      align: 'left',
      format: (val: RowValue) => JSON.stringify(val),
    },
  ]
}

export function getAffiliatePostbackColumns() {
  return [
    {
      name: 'createdAt',
      label: 'Дата / Время',
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    { name: 'url', label: 'Ссылка', field: 'url', align: 'left' },
    {
      name: 'status',
      label: 'Статус',
      field: 'status',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'eventType',
      label: 'Событие',
      field: 'eventType',
      align: 'left',
      sortable: true,
    },
    {
      name: 'campaignName',
      label: 'Поток',
      field: 'campaignName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'offerName',
      label: 'Оффер',
      field: 'offerName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'affiliateEmail',
      label: 'Аффилейт',
      field: 'affiliateEmail',
      align: 'left',
      sortable: true,
      format: (val: string, row: any) => `${val} [ ID #${row.affiliateId} ]`,
    },
    {
      name: 'response',
      label: 'Ответ сервера',
      field: 'response',
      align: 'left',
      format: (val: RowValue) => JSON.stringify(val),
    },
  ]
}

export function getAdvertiserPostbackColumns() {
  return [
    {
      name: 'createdAt',
      label: 'Дата / Время',
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    { name: 'url', label: 'Ссылка', field: 'url', align: 'left' },
    {
      name: 'eventType',
      label: 'Событие',
      field: 'eventType',
      align: 'left',
      sortable: true,
    },
    {
      name: 'campaignName',
      label: 'Поток',
      field: 'campaignName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'offerName',
      label: 'Оффер',
      field: 'offerName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'affiliateEmail',
      label: 'Аффилейт',
      field: 'affiliateEmail',
      align: 'left',
      sortable: true,
      format: (val: string, row: any) => `${val} [ ID #${row.affiliateId} ]`,
    },
  ]
}

export function getClicksChartColumns() {
  return [
    {
      name: 'groupByField',
      label: 'Дата',
      fullLabel: 'Дата',
      field: 'groupByField',
      align: 'left',
      sortable: true,
    },
    {
      name: 'clicks',
      label: 'Клики',
      fullLabel: 'Клики',
      field: 'clicks',
      align: 'left',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
  ]
}

export function getPostbackChartColumns() {
  return [
    {
      name: 'groupByField',
      label: 'Дата',
      fullLabel: 'Дата',
      field: 'groupByField',
      align: 'left',
      sortable: true,
    },
    {
      name: 'registrationCount',
      label: 'Регистрации',
      fullLabel: 'Регистрации',
      field: 'registrationCount',
      align: 'left',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'firstDepositCount',
      label: 'Депозиты',
      fullLabel: 'Количество депозитов',
      field: 'firstDepositCount',
      align: 'left',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
  ]
}

export function getIntersectionsColumns() {
  return [
    {
      name: 'affiliateId',
      label: 'Аффилейт',
      field: 'affiliateId',
      align: 'center',
      sortable: true,
    },
    {
      name: 'intersections',
      label: 'Веб / Количество пересечений / Дата последнего пересечения',
      field: 'intersections',
      align: 'left',
    },
    {
      name: 'offerIds',
      label: 'Офферы',
      field: 'offerIds',
      align: 'left',
    },
    {
      name: 'geoIds',
      label: 'Гео',
      field: 'geoIds',
      align: 'left',
    },
  ]
}

export function getConsumersColumns() {
  return [
    {
      name: 'consumerId',
      label: 'ID консъюмера',
      field: 'consumerId',
      align: 'center',
    },
    {
      name: 'createdAt',
      label: 'Дата последнего пересечения',
      field: 'createdAt',
      align: 'center',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'affiliateIds',
      label: 'Аффилейты',
      field: 'affiliateIds',
      align: 'left',
    },
    {
      name: 'offerIds',
      label: 'Офферы',
      field: 'offerIds',
      align: 'left',
    },
    {
      name: 'geoIds',
      label: 'Гео',
      field: 'geoIds',
      align: 'left',
    },
    {
      name: 'advertiserIds',
      label: 'Рекламодатели',
      field: 'advertiserIds',
      align: 'left',
    },
    {
      name: 'clickIds',
      label: 'Клик ID',
      field: 'clickIds',
      align: 'left',
    },
  ]
}
