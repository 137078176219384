
import { defineComponent } from 'vue'
import GeneralInfo from './steps/GeneralInfo.vue'
import Promos from './steps/Promos.vue'
import Postbacks from '@/components/postbacks/Postbacks.vue'
import RedirectDomain from './steps/RedirectDomain.vue'
import { mapState, mapActions, mapMutations } from 'vuex'
import axios from 'axios'
import {
  getInitialOptions,
  setOptionsByOfferId,
  DefaultOptions,
} from './getInitialOptions'
import { Postback } from '@/store/types'

export default defineComponent({
  name: 'CampaignsEdit',
  props: ['offerId'],
  components: { GeneralInfo, Promos, Postbacks, RedirectDomain },
  computed: {
    ...mapState('campaignsModule', ['errors', 'campaign']),
    ...mapState('authModule', ['user']),
  },
  async created() {
    if (!this.$route.params.id) {
      this.onReset()
      if (this.offerId) {
        this.update({ offerId: Number(this.offerId) })
        await this.selectOffer()
      }
      this.loading = false
      return
    }

    await this.getData(this.$route.params.id)
    this.defaultOptions = await getInitialOptions(this.campaign)
    this.loading = false
  },
  data() {
    return {
      defaultOptions: {},
      loading: true,
    }
  },
  methods: {
    ...mapActions('campaignsModule', ['getData']),
    ...mapMutations('campaignsModule', ['update', 'reset', 'setErrors']),
    ...mapMutations('authModule', ['logout']),
    selectOffer: async function() {
      if (!this.campaign.offerId) {
        return
      }

      const options = await setOptionsByOfferId(
        this.campaign,
        this.defaultOptions as DefaultOptions,
      )
      this.defaultOptions = Object.assign({}, this.defaultOptions, options)
    },
    onSubmit: function() {
      const method = !this.$route.params.id ? 'post' : 'put'
      const postbacks = this.campaign.postbacks.filter(
        (postback: Postback) => postback.url,
      )
      axios[method](
        '/api/campaigns',
        { ...this.campaign, postbacks },
        {
          params: { id: this.$route.params.id },
        },
      )
        .then(() => {
          this.setErrors({})
          this.$router.push('/campaigns')
        })
        .catch(({ response }) => {
          this.setErrors(response.data.errors)
        })
    },
    onReset: function() {
      this.reset()
    },
  },
})
