import { RewardType } from '@/enums/RewardType'
import { Currency } from '@/enums/Currency'
import { formatDate } from '@/utils'

export function getAffiliateRewardsColumns() {
  return [
    {
      name: 'id',
      label: 'Аффилейт',
      field: 'id',
      align: 'left',
      sortable: true,
    },
    {
      name: 'geoCode',
      label: 'Гео',
      field: 'geoCode',
      align: 'left',
    },
    {
      name: 'type',
      label: 'Тип конверсии',
      field: 'type',
      align: 'right',
      format: (val: number) => (val !== undefined ? RewardType[val] : '-'),
    },
    {
      name: 'valueAmount',
      label: 'Ставка веба',
      field: 'valueAmount',
      align: 'right',
      format: (val: number, row: any) =>
        val !== undefined
          ? `${val || 0} ${Currency[row.valueCurrency || 0]}`
          : '-',
    },
    {
      name: 'advertiserValueAmount',
      label: 'Ставка рекла',
      field: 'advertiserValueAmount',
      align: 'right',
      format: (val: number, row: any) =>
        val !== undefined
          ? `${val || 0} ${Currency[row.advertiserValueCurrency || 0]}`
          : '-',
    },
    {
      name: 'informLeadLimit',
      label: 'Капа',
      field: 'informLeadLimit',
      align: 'right',
      format: (val: number) => val || '-',
    },
    {
      name: 'trafficSourceName',
      label: 'Источник',
      field: 'trafficSourceName',
      align: 'right',
      format: (val: string) => val || '-',
    },
  ]
}

export function getCommonRewardsColumns() {
  return [
    {
      name: 'geoCode',
      label: 'Гео',
      field: 'geoCode',
      align: 'left',
    },
    {
      name: 'type',
      label: 'Тип конверсии',
      field: 'type',
      align: 'right',
      format: (val: number) => RewardType[val],
    },
    {
      name: 'valueAmount',
      label: 'Ставка веба',
      field: 'valueAmount',
      align: 'right',
      format: (val: number, row: any) =>
        `${val || 0} ${Currency[row.valueCurrency || 0]}`,
    },
    {
      name: 'advertiserValueAmount',
      label: 'Ставка рекла',
      field: 'advertiserValueAmount',
      align: 'right',
      format: (val: number, row: any) =>
        `${val || 0} ${Currency[row.advertiserValueCurrency || 0]}`,
    },
    {
      name: 'trafficSourceName',
      label: 'Источник',
      field: 'trafficSourceName',
      align: 'right',
      format: (val: string) => val || '-',
    },
  ]
}

export function getLeadLimitChangelogColumns() {
  return [
    {
      name: 'createdAt',
      label: 'Дата обновления',
      field: 'createdAt',
      align: 'center',
      format: (val: string) => formatDate(val),
    },
    {
      name: 'createdById',
      label: 'Кем изменено',
      field: 'createdById',
      align: 'center',
    },
    {
      name: 'prevMaxValue',
      label: 'Предыдущее значение',
      field: 'prevMaxValue',
      align: 'right',
    },
    {
      name: 'maxValue',
      label: 'Новое значение',
      field: 'maxValue',
      align: 'right',
    },
    {
      name: 'value',
      label: 'Сколько пролито',
      field: 'value',
      align: 'right',
    },
  ]
}
