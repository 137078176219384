
import { defineComponent } from 'vue'
import axios from 'axios'
import { getConversionsColumns } from '../config/columns'
import {
  request,
  getTableProperties,
  Table,
  TableRequestProps,
} from '@/utils/request'
import ConversionEditDialog from '@/components/dialogs/ConversionEditDialog.vue'
import ConversionInfoDialog from '@/components/dialogs/ConversionInfoDialog.vue'
import ConversionHoldEditDialog from '@/components/dialogs/ConversionHoldEditDialog.vue'
import { LeadStatus } from '@/enums/LeadStatus'
import { RewardType } from '@/enums/RewardType'
import { exportToCsv } from '@/utils/export'
import { confirmDialog, formatValue, showErrorMessageSnackBar } from '@/utils'
import IconWithName from '@/components/icons/IconWithName.vue'
import { mapState } from 'vuex'
import { FormatMoney } from 'format-money-js'
import { Currency } from '@/enums/Currency'

export default defineComponent({
  name: 'ConversionsTable',
  props: ['filters', 'exportCsv', 'exportCsvAll'],
  components: {
    IconWithName,
    ConversionEditDialog,
    ConversionInfoDialog,
    ConversionHoldEditDialog,
  },
  mounted() {
    this.onRequest({ pagination: this.pagination })
  },
  computed: {
    ...mapState('authModule', ['user']),
  },
  data: function() {
    const moneyFormatter = new FormatMoney({
      decimals: 2,
      separator: ' ',
    })
    return {
      ...getTableProperties('datetime', 50),
      selected: [],
      loading: false,
      columns: getConversionsColumns(),
      showEditDialog: false,
      showInfoDialog: false,
      showHoldEditDialog: false,
      currentRow: null,
      leadStatuses: LeadStatus,
      rewardTypes: RewardType,
      moneyFormatter,
      tableAmount: {
        webUsdAmount: 0,
        advUsdAmount: 0,
        webEurAmount: 0,
        advEurAmount: 0,
        profitUsd: 0,
        profitEur: 0,
      },
    }
  },
  watch: {
    filters() {
      this.pagination.page = 1
      this.onRequest({ pagination: this.pagination })
    },
    exportCsv() {
      exportToCsv(this.rows, this.getColumnsForExport())
    },
    exportCsvAll() {
      const prevRows = [...this.rows]
      const prevPagination = { ...this.pagination }
      this.onRequest({pagination: { ...prevPagination, rowsPerPage: 0, page: 1 }})
        .then(() => {
          exportToCsv(this.rows, this.getColumnsForExport())
          this.rows = [...prevRows]
          this.pagination = { ...prevPagination }
        })
        .catch(err => {
          showErrorMessageSnackBar((err as any).response?.data?.errors?.message)
        })
    },
  },
  methods: {
    async onRequest(
      props: TableRequestProps | Omit<TableRequestProps, 'pagination'> = {},
    ) {
      const options = { pagination: this.pagination, ...props }
      this.columns = getConversionsColumns(this.filters.isDetailing)
      await request(options, this as Table, '/api/conversions')
      if (this.selected.length) {
        this.selected = this.rows.filter((item1: any) =>
          this.selected.some((item2: any) => item1.id === item2.id),
        )
      }
      this.tableAmount = this.getTableAmount()
    },
    getCurrencyFromRow(currency: number) {
      return ` ${Currency[currency]}`
    },
    formatMoney(value: number) {
      return this.moneyFormatter.from(
        Number(formatValue(Number(value), 2, true)),
        {
          decimals: 2,
          separator: ' ',
        },
      )
    },
    checkInfo(props: TableRequestProps) {
      this.currentRow = props.row as never
      this.showInfoDialog = !this.showInfoDialog
    },
    changeConversionHold(props: TableRequestProps) {
      this.currentRow = props?.row as never
      this.showHoldEditDialog = !this.showHoldEditDialog
    },
    changeEditDialog() {
      this.showEditDialog = !this.showEditDialog
    },
    async approveConversions(props: TableRequestProps) {
      confirmDialog('Апрув конверсий?', async () => {
        this.loading = true
        const data = this.selected.map((r: any) => ({
          id: r.id,
          status: r.status,
          tdsRedirectEventId: r.tdsRedirectEventId,
        }))
        try {
          await axios.put(`/api/conversions/approve`, data)
        } catch (e) {
          showErrorMessageSnackBar((e as any).response?.data?.errors?.message)
        }
        await this.onRequest(props)
        this.loading = false
      })
    },
    async rejectConversions(props: TableRequestProps) {
      confirmDialog('Реджект конверсий?', async () => {
        this.loading = true
        const data = this.selected.map((r: any) => ({
          id: r.id,
          status: r.status,
        }))
        try {
          await axios.put(`/api/conversions/reject`, data)
        } catch (e) {
          showErrorMessageSnackBar((e as any).response?.data?.errors?.message)
        }
        await this.onRequest(props)
        this.loading = false
      })
    },
    getTableAmount() {
      const usdRows = this.rows.filter((r: any) => r.currency === Currency.USD)
      const eurRows = this.rows.filter((r: any) => r.currency === Currency.EUR)
      const webUsdAmount = usdRows.reduce(
        (accumulator, currentRow: any) => accumulator + Number(currentRow.amount || 0),
        0,
      )
      const advUsdAmount = usdRows.reduce(
        (accumulator, currentRow: any) => accumulator + Number(currentRow.advertiserAmount || 0),
        0,
      )
      const webEurAmount = eurRows.reduce(
        (accumulator, currentRow: any) => accumulator + Number(currentRow.amount || 0),
        0,
      )
      const advEurAmount = eurRows.reduce(
        (accumulator, currentRow: any) => accumulator + Number(currentRow.advertiserAmount || 0),
        0,
      )
      return {
        webUsdAmount,
        advUsdAmount,
        webEurAmount,
        advEurAmount,
        profitUsd: advUsdAmount - webUsdAmount,
        profitEur: advEurAmount - webEurAmount,
      }
    },
    getColumnsForExport() {
      let columns = [...this.columns]
      columns.unshift({
        name: 'advertiserCurrencyName',
        label: 'Валюта',
        field: 'advertiserCurrencyName',
        align: 'right',
      })
      columns.unshift({
        name: 'advertiserAmount',
        label: 'Выплата рекла',
        field: 'advertiserAmount',
        align: 'right',
      })
      columns.unshift({
        name: 'currencyName',
        label: 'Валюта',
        field: 'currencyName',
        align: 'right',
      })
      columns.unshift({
        name: 'amount',
        label: 'Выплата вебу',
        field: 'amount',
        align: 'right',
      })
      if (this.user?.isSuperAdmin || this.user?.isFinanceManager) {
        columns.unshift({
          name: 'fantomAffiliateId',
          label: 'Fantom WEB ID',
          field: 'fantomAffiliateId',
          align: 'center',
        })
      }
      columns.unshift({
        name: 'affiliateId',
        label: 'WEB ID',
        field: 'affiliateId',
        align: 'center',
      })
      columns.unshift({
        name: 'advertiserId',
        label: 'ADV ID',
        field: 'advertiserId',
        align: 'center',
      })
      columns.unshift({
        name: 'offerId',
        label: 'Offer ID',
        field: 'offerId',
        align: 'center',
      })
      return columns
    },
  },
})
