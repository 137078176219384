
import { defineComponent } from 'vue'
import axios from 'axios'

export default defineComponent({
  name: 'Offers',
  async created() {
    this.availableOffers = (
      await axios.get('/api/offers/available/' + this.$route.params.id)
    ).data
  },
  data: function() {
    return {
      availableOffers: [],
    }
  },
})
