import router from '@/router'
import axios from 'axios'
import { ActionContext } from 'vuex'
import { AuthState, LoginData, RootModule, User } from '@/store/types'

const user = JSON.parse(localStorage.getItem('user') as string)
const initialState = user
  ? { status: { loggedIn: true }, user, error: false }
  : { status: { loggedIn: false }, user: null, error: false }

const authModule = {
  namespaced: true,
  state: initialState,
  actions: {
    async login(
      { commit }: ActionContext<AuthState, RootModule>,
      { email, password }: LoginData,
    ) {
      try {
        const { data } = await axios.post('/api/auth/login', {
          email,
          password,
        })
        const config = {
          headers: { Authorization: `Bearer ${data.accessToken}` },
        }
        const user = await axios.get('api/auth/profile', config)
        commit('loginSuccess', { ...user.data, ...data })
        await router.push('/statistics')
      } catch (err) {
        commit('loginFailure')
        throw err
      }
    },
    async logout({ commit }: ActionContext<AuthState, RootModule>) {
      await axios.post('/api/auth/logout')
      commit('logout')
    },
  },
  mutations: {
    loginSuccess(state: AuthState, user: User) {
      state.status = { loggedIn: true }
      state.user = user
      state.error = false
      axios.defaults.headers.Authorization = `Bearer ${user.accessToken}`
      localStorage.setItem('user', JSON.stringify(user))
    },
    loginFailure(state: AuthState) {
      state.status = { loggedIn: false }
      state.user = null
      axios.defaults.headers.Authorization = undefined
      state.error = true
    },
    logout(state: AuthState) {
      state.status = { loggedIn: false }
      state.user = null
      axios.defaults.headers.Authorization = undefined
      localStorage.removeItem('user')
      router.push('/login').catch(err => {
        throw err
      })
    },
  },
}

export default authModule
