
import axios from 'axios'
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import { showErrorMessageSnackBar } from '@/utils'
import { FieldOption, lookupByField } from '@/utils/lookups'

export default defineComponent({
  name: 'AddAffiliateTagsDialog',
  props: ['open', 'close', 'affiliateId', 'refresh', 'markerId'],
  watch: {
    async markerId() {
      this.loading = true
      if (this.markerId) {
        this.affiliateTag = (
          await axios.get(
            `/api/markers/${this.markerId}?affiliateId=${this.affiliateId}`,
          )
        ).data
      }
      this.loading = false
    },
  },
  async created() {
    this.markers = (await axios.get('/api/markers')).data
  },
  computed: {
    ...mapState('authModule', ['user']),
    showDialog(): boolean {
      return this.open
    },
  },
  data: function() {
    return {
      affiliateTag: { reason: null },
      loading: false,
      isSaving: false,
      errors: {},
      existedMarkerId: null,
      markers: [],
    }
  },
  methods: {
    addAffiliateTag() {
      this.isSaving = true
      const q = this.existedMarkerId
        ? { id: this.existedMarkerId, reason: this.affiliateTag.reason }
        : this.affiliateTag
      axios
        .post('/api/markers/', { ...q, affiliateId: Number(this.affiliateId) })
        .then(() => {
          this.onClose()
          this.$emit('refresh')
        })
        .catch(error => {
          this.errors = error.response.data.errors
          showErrorMessageSnackBar('Произошла ошибка при сохранении.')
        })
      this.isSaving = false
    },
    onClose() {
      this.affiliateTag = { reason: null }
      this.existedMarkerId = null
      this.errors = {}
      this.$emit('close')
    },
    async getOptionsByField(
      value: string,
      update: Function,
      field: FieldOption,
    ) {
      await lookupByField(this, value, update, field)
    },
  },
})
