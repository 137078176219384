
import { defineComponent } from 'vue'
import Filters from '@/components/filters/Filters.vue'
import ConsumersTable from './ConsumersTable.vue'
import { FiltersType, getDefaultFilters } from '@/utils/request'
export default defineComponent({
  name: 'ConsumersTab',
  components: { Filters, ConsumersTable },
  data: function() {
    return {
      exportCsv: false,
      filters: { ...getDefaultFilters(), isDetailing: false },
    }
  },
  methods: {
    onExport() {
      this.exportCsv = !this.exportCsv
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }

      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    changeAll(filters: FiltersType, isDetailing = false) {
      this.filters = { ...filters } as any
    },
    resetFilters() {
      this.filters = {
        ...getDefaultFilters(),
        groupBy: 'date',
        isDetailing: false,
      }
      this.$router.push({
        path: this.$route.fullPath?.replace('/', ''),
        query: {},
      })
    },
  },
})
