import getDefaultData from '@/pages/mobile-apps/config/getDefaultData'
import {
  Errors,
  MobileAppData,
  MobileAppState,
  RootModule,
} from '@/store/types'
import { ActionContext } from 'vuex'
import axios from 'axios'
import router from '@/router'

const mobileAppsModule = {
  state: getDefaultData(),
  mutations: {
    update(state: MobileAppState, value: MobileAppData): void {
      state.mobileApp = { ...state.mobileApp, ...value }
      state.errors = {}
    },
    reset(state: MobileAppState): void {
      const { mobileApp, errors } = getDefaultData()
      state.mobileApp = mobileApp
      state.errors = errors
    },
    setErrors(state: MobileAppState, value: Errors): void {
      state.errors = value
    },
  },
  actions: {
    async getData(
      { commit }: ActionContext<MobileAppState, RootModule>,
      mobileAppId: string,
    ) {
      try {
        const { data } = await axios.get('/api/apps/' + mobileAppId)
        commit('update', data)
        commit('setErrors', {})
      } catch (err) {
        await router.push('/mobile-apps/new')
        throw new Error(err as string)
      }
    },
  },
  namespaced: true,
}

export default mobileAppsModule
