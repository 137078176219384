import { Errors, AdminState, RootModule, AdminData } from '@/store/types'
import { ActionContext } from 'vuex'
import axios from 'axios'
import router from '@/router'
import { Currency } from '@/enums/Currency'

function getDefaultData(): AdminState {
  return {
    errors: {},
    admin: {
      newPassword: null,
      isTdsAdmin: false,
      isLimitedAccess: false,
      isLightLimitedAccess: false,
      hideFinances: false,
      isAdvertiserManager: false,
      isAffiliateManager: false,
      isAppsAdmin: false,
      isSuperAdmin: false,
      isFinanceManager: false,
      disableEmails: false,
      baseCurrency: Currency.USD,
      credentials: { email: null },
      contacts: {},
    },
  }
}

const adminsModule = {
  state: getDefaultData(),
  mutations: {
    update(state: AdminState, value: AdminData): void {
      state.admin = { ...state.admin, ...value }
      state.errors = {}
    },
    reset(state: AdminState): void {
      const { admin, errors } = getDefaultData()
      state.admin = admin
      state.errors = errors
    },
    setErrors(state: AdminState, value: Errors): void {
      state.errors = value
    },
  },
  actions: {
    async getData(
      { commit }: ActionContext<AdminState, RootModule>,
      adminId: string,
    ) {
      try {
        const { data } = await axios.get('/api/admins/' + adminId)
        commit('update', data)
        commit('setErrors', {})
      } catch (err) {
        await router.push('/admins/new')
        throw new Error(err as string)
      }
    },
  },
  namespaced: true,
}

export default adminsModule
