
import { mapState } from 'vuex'
import axios from 'axios'
import { defineComponent } from 'vue'
import Offers from './tabs/Offers.vue'
import { BalanceType } from '@/enums/BalanceType'
import { Currency } from '@/enums/Currency'
import CampaignsPage from '@/pages/campaigns/CampaignsPage.vue'
import AddAffiliateTagsDialog from '@/components/dialogs/AddAffiliateTagsDialog.vue'
import { getAffiliateBalances, loginAsAffiliate } from '@/utils/affiliate'
import { confirmDialog, formatDate, showErrorMessageSnackBar } from '@/utils'
import IconWithName from '@/components/icons/IconWithName.vue'
import AffiliateCommentDialog from '@/components/dialogs/AffiliateCommentDialog.vue'
import Referrals from '@/pages/affiliates/tabs/Referrals.vue'
import LoginLogs from '@/pages/affiliates/tabs/LoginLogs.vue'
import { Balance } from '@/store/types'
import PayoutPage from './tabs/PayoutsPage.vue'
import ManagerChangelog from '@/pages/affiliates/tabs/ManagerChangelog.vue'
import { PaymentSystem } from '@/enums/PaymentSystem'
import { PaymentSystemNetwork } from '@/enums/PaymentSystemNetwork'
import BalanceChangelog from '@/pages/affiliates/tabs/BalanceChangelog.vue'

export default defineComponent({
  name: 'AffiliateInfo',
  components: {
    BalanceChangelog,
    PayoutPage,
    ManagerChangelog,
    LoginLogs,
    Referrals,
    AffiliateCommentDialog,
    IconWithName,
    Offers,
    CampaignsPage,
    AddAffiliateTagsDialog,
  },
  computed: mapState('advertisersModule', ['advertiser', 'errors']),
  async created(): Promise<void> {
    await this.onRequest()
  },
  data: function() {
    return {
      loading: true,
      storageUrl: process.env.CDN_STORAGE_URL,
      affiliateTab: 'offers',
      showAddTagsDialog: false,
      affiliate: {
        credentials: {},
        contacts: { telegram: null },
        image: '',
        balances: [],
      },
      errors: {},
      showCommentDialog: false,
      balanceTypes: BalanceType,
      currencies: Currency,
      selectedMarkerId: null,
      paymentSystem: PaymentSystem,
      paymentSystemNetwork: PaymentSystemNetwork,
    }
  },
  methods: {
    async onRequest() {
      this.loading = true
      this.affiliate = (
        await axios.get('/api/affiliates/affiliate/' + this.$route.params.id)
      ).data
      this.loading = false
    },
    addMarker(markerId: number) {
      this.selectedMarkerId = markerId as any
      this.showAddTagsDialog = !this.showAddTagsDialog
    },
    async deleteMarker(affiliateId: number, markerId: number, name: string) {
      confirmDialog(
        `Вы действительно хотите удалить маркер ${name}? с аффилейта ID:${affiliateId}?`,
        async () => {
          await axios.post(`/api/markers/delete-from-aff`, {
            id: markerId,
            affiliateId,
          })
          await this.onRequest()
        },
      )
    },
    async deleteComment(id: number) {
      confirmDialog(
        'Вы действительно хотите удалить этот комментарий?',
        async () => {
          try {
            await axios.delete('/api/affiliates/comment/' + id)
            await this.onRequest()
          } catch (e) {
            showErrorMessageSnackBar(
              'Только суперадмины могут удалять комменты',
            )
          }
        },
      )
    },
    frmtDate(date: Date) {
      return formatDate(date)
    },
    onCloseDialog() {
      this.showAddTagsDialog = false
      this.selectedMarkerId = null
    },
    getBalances(balances: Balance[], isReferralsBalances: boolean) {
      return getAffiliateBalances(balances || [], isReferralsBalances)
    },
    async loginAffiliate(id: string) {
      await loginAsAffiliate(id)
    },
    format(val: string) {
      return formatDate(val)
    },
  },
})
