
import { getPushAnalyticsColumns } from './config/getColumns'
import { defineComponent } from 'vue'
import Filters from '@/components/filters/Filters.vue'
import {
  FiltersType,
  getDefaultFilters,
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import useQuasar from 'quasar/src/composables/use-quasar.js';

export default defineComponent({
  name: 'PushAnalytics',
  components: { Filters },
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  setup() {
    const $q = useQuasar()
    return {
      showInfoSnackbar() {
        $q.notify({
          type: 'info',
          message: `Подождите, идет загрузка. Это может занять некоторое время...`,
          position: 'top-right',
          timeout: 4000,
        })
      },
      showErrorSnackbar(err: any) {
        const error = new Error(err)
        $q.notify({
          type: 'negative',
          message: `Internal Server Error: ${error.message}`,
          position: 'top-right',
          timeout: 4000,
        })
      },
    }
  },
  data() {
    return {
      ...getTableProperties('createdAt'),
      filters: getDefaultFilters(),
      columns: getPushAnalyticsColumns(),
    }
  },
  watch: {
    async filters() {
      await this.onRequest({ pagination: this.pagination })
    },
  },
  methods: {
    async onRequest(props: TableRequestProps) {
      this.showInfoSnackbar()
      try {
        await request(props, this as Table, '/api/push-notifications/analytics')
      } catch (err) {
        this.showErrorSnackbar(err)
      }
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    resetFilters() {
      this.filters = getDefaultFilters()
    },
  },
})
