
import { defineComponent } from 'vue'
import axios from 'axios'
import { PushNotification, PushNotificationRule } from '@/store/types'
import { lookupByField } from '@/utils/lookups'
import { getDays, getPushNotificationEventTypes } from '@/utils'
import { Day } from '@/enums/Day'
import { unionBy, sortBy } from 'lodash'
import { PushNotificationEventType } from '@/enums/PushNotificationEventType'

export default defineComponent({
  name: 'PushGroup',
  async created() {
    const groupId = this.$route.params.id || ''
    this.languages = (await axios.get('/api/languages')).data
    for (const row of this.languages) {
      this.pushGroup.push({ languageId: (row as any).id } as never)
    }
    if (groupId && groupId !== 'new') {
      const group = (await axios.get(`/api/push-notifications/${groupId}`)).data
      this.pushGroup = sortBy(unionBy(group, this.pushGroup, 'languageId'), [
        'languageId',
      ])
      const data = this.pushGroup?.[0] as any
      this.rules = data?.individualRules || []
      this.images = {
        backgroundImage: data?.backgroundImage || '',
        previewImage: data?.previewImage || '',
      }
    }
    this.loading = false
  },
  data() {
    return {
      languages: [],
      pushGroup: [],
      images: {
        backgroundImage: '',
        previewImage: '',
      },
      backgroundImage: '',
      previewImage: '',
      loading: true,
      isSaving: false,
      errors: {},
      storageUrl: process.env.CDN_STORAGE_URL,
      rules: [],
      days: getDays(),
      types: getPushNotificationEventTypes(),
    }
  },
  methods: {
    addRule() {
      this.rules.push({
        days: [
          Day.Monday,
          Day.Tuesday,
          Day.Wednesday,
          Day.Thursday,
          Day.Friday,
          Day.Saturday,
          Day.Sunday,
        ],
        types: [
          PushNotificationEventType.Install,
          PushNotificationEventType.Registration,
          PushNotificationEventType.Deposit,
        ],
      } as never)
    },
    updateRules(rule: PushNotificationRule, index: number) {
      this.rules[index] = rule as never
    },
    deleteRule(ruleIndex: number) {
      const rules = this.rules.filter(
        (rule: PushNotificationRule, index: number) => index !== ruleIndex,
      )
      this.rules = [...rules]
    },
    async postFormData(data: string, field: string, url: string) {
      const form = new FormData()
      form.append(field, data)
      await axios.post(url, form)
    },
    onSubmit: async function() {
      this.isSaving = true
      const notifications = this.pushGroup
        .filter(
          (p: PushNotification) =>
            Object.keys(p).includes('title') ||
            Object.keys(p).includes('message'),
        )
        .map((p: PushNotification) => ({
          ...p,
          individualRules: this.rules.length ? this.rules : null,
        }))
      try {
        const response: any = await axios.post(
          '/api/push-notifications',
          { notifications },
          {
            params: {
              id:
                this.$route.params.id === 'new' ? null : this.$route.params.id,
            },
          },
        )
        this.setErrors({})

        const pushGroupId = response.data
        if (this.images.backgroundImage) {
          await this.postFormData(
            (this.images.backgroundImage as unknown) as string,
            'backgroundImage',
            `/api/push-notifications/${pushGroupId}/backgroundImage`,
          )
        }

        if (this.images.previewImage) {
          await this.postFormData(
            (this.images.previewImage as unknown) as string,
            'previewImage',
            `/api/push-notifications/${pushGroupId}/previewImage`,
          )
        }

        await this.$router.push('/push-notifications')
      } catch (error) {
        if ((error as any).response.status) {
          alert(
            'Не удалось сохранить пуш. Проверьте настройки и попробуйте еще раз.',
          )
        }
      } finally {
        this.isSaving = false
      }
    },
    updatePush(push: PushNotification, index: number) {
      this.pushGroup[index] = push as never
    },
    setErrors(errors: any) {
      this.errors = errors
    },
    onReset: function() {
      this.pushGroup = []
    },
    async getLanguages(value: string, update: Function) {
      await lookupByField(this, value, update, 'languages')
    },
    onChange(e: any, field: 'backgroundImage' | 'previewImage') {
      this.createFile(e.target.files[0], field)
    },
    createFile(file: File, field: 'backgroundImage' | 'previewImage') {
      if (!file.type.match('image.*')) {
        alert('Выберите картинку')
        return
      }
      const reader = new FileReader()
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const component = this
      reader.onload = function(e: any) {
        component[field] = e.target.result
      }
      reader.readAsDataURL(file)
      this.images[field] = (file as unknown) as string
    },
    removeFile(field: 'backgroundImage' | 'previewImage') {
      this[field] = ''
      this.images[field] = ''
    },
  },
})
