import '../styles/quasar.sass'
import '@quasar/extras/material-icons/material-icons.css'
import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';

export default {
  plugins: { Notify, Dialog },
  config: {
    notify: {
      /* look at QuasarConfOptions from the API card */
    },
  },
}
