
import { defineComponent } from 'vue'
import { mapMutations, mapState } from 'vuex'
import { AffiliateReward } from '@/store/types'
import store from '@/store'
import { lookupAffiliates, lookupGeos } from '@/utils/lookups'
import { RewardType } from '@/enums/RewardType'
import IconWithName from '@/components/icons/IconWithName.vue'

export default defineComponent({
  name: 'Affiliates',
  components: { IconWithName },
  props: ['defaultOptions'],
  created(): void {
    if (!store.state.mobileAppsModule.mobileApp.affiliateRewards.length) {
      this.update({
        affiliateRewards: [{ value: {}, type: RewardType.CPI }],
      })
    }
  },
  computed: {
    ...mapState('mobileAppsModule', ['mobileApp', 'errors']),
    affiliateRewards() {
      return store.state.mobileAppsModule.mobileApp.affiliateRewards
    },
  },
  data: function() {
    return {
      geos: [...(this.defaultOptions.geos || [])],
      affiliates: [...(this.defaultOptions.affiliates || [])],
    }
  },
  methods: {
    ...mapMutations('mobileAppsModule', ['update']),
    addRule() {
      this.update({
        affiliateRewards: [
          ...this.affiliateRewards,
          { value: {}, type: RewardType.CPI },
        ],
      })
    },
    deleteRule(rewardIndex: number) {
      const affiliateRewards = this.affiliateRewards.filter(
        (reward: AffiliateReward, index: number) => index !== rewardIndex,
      )
      this.update({ affiliateRewards })
    },
    updateField(reward: AffiliateReward, index: number) {
      const affiliateRewards = this.affiliateRewards
      affiliateRewards[index] = reward
      this.update({ affiliateRewards })
    },
    async getGeos(value: string, update: Function, reward: AffiliateReward) {
      const unavailableGeoIds = this.mobileApp.affiliateRewards
        .filter(
          (r: AffiliateReward) =>
            r.affiliateIds?.[0] !== reward.affiliateIds?.[0],
        )
        .map((r: AffiliateReward) => r.geoIds)
        .flat()
      await lookupGeos(this, value, update, [], unavailableGeoIds)
    },
    async getAffiliates(value: string, update: Function) {
      await lookupAffiliates(this, value, update)
    },
    clearFilter(selectedRef: string) {
      if (this.$refs[selectedRef] !== void 0) {
        ;(this.$refs[selectedRef] as any).updateInputValue('')
      }
    },
  },
})
