import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-negative" }
const _hoisted_3 = { class: "q-mb-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_center, { class: "q-ma-lg" }, {
      default: _withCtx(() => [
        _createElementVNode("b", null, "История обновлений капы #" + _toDisplayString(_ctx.leadLimitRow.id), 1)
      ]),
      _: 1
    }),
    (!_ctx.leadLimitRow.isActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("b", _hoisted_2, "Капа была сброшена/закрыта админом #" + _toDisplayString(_ctx.leadLimitRow.updatedById) + " в " + _toDisplayString(_ctx.frmtDate(_ctx.leadLimitRow.updatedAt)) + " и больше неактивна.", 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_q_table, {
        dense: "",
        rows: _ctx.rows,
        columns: _ctx.columns,
        separator: "cell",
        pagination: _ctx.pagination,
        "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
        loading: _ctx.loading,
        onRequest: _ctx.onRequest,
        "binary-state-sort": "",
        "column-sort-order": "da",
        "rows-per-page-options": [10, 20, 100, 0]
      }, {
        "body-cell-createdById": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_IconWithName, {
                id: props.row.createdById,
                name: props.row.credentialsEmail,
                size: "20px",
                color: "primary",
                icon: "manage_accounts",
                entity: "manager"
              }, null, 8, ["id", "name"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 1
      }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
    ])
  ], 64))
}