import { formatDate } from '@/utils'

export default function getColumns() {
  return [
    {
      name: 'createdAt',
      label: 'Дата',
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'affiliateEmail',
      label: 'Аффилейт',
      field: 'affiliateEmail',
      align: 'left',
    },
    {
      name: 'title',
      label: 'Тема',
      field: 'title',
      align: 'left',
    },
    {
      name: 'status',
      label: 'Статус',
      field: 'status',
      align: 'center',
    },
    { name: 'actions', label: '', field: 'actions', align: 'center' },
  ]
}
