
import { mapMutations, mapState } from 'vuex'
import { defineComponent } from 'vue'
import store from '@/store'
import { getRewardTypesByVertical } from '@/utils'
import { CommonReward, TrafficSource } from '@/store/types'
import { RewardType } from '@/enums/RewardType'
import { lookupGeos } from '@/utils/lookups'
import { payoutCurrencies } from '@/utils'

export default defineComponent({
  name: 'Targeting',
  props: ['defaultOptions'],
  created(): void {
    const commonRewards = store.state.offersModule.offer.commonRewards
    if (!commonRewards.length) {
      this.update({
        commonRewards: [
          ...commonRewards,
          { value: {}, stopGeo: false, withoutValue: false },
        ],
      })
    }
  },
  computed: {
    ...mapState('offersModule', ['errors', 'offer']),
    rewards(): CommonReward[] {
      const commonRewards = store.state.offersModule.offer.commonRewards
      if (!commonRewards.length) {
        return this.addReward()
      }

      return commonRewards
    },
  },
  data: function() {
    return {
      geos: [...(this.defaultOptions.geos || [])],
      rewardTypesEnum: RewardType,
      rewardTypes: getRewardTypesByVertical(
        store.state.offersModule.offer.vertical,
      ),
      currencies: payoutCurrencies,
      trafficSources:
        store.state.offersModule.offer.trafficSources.map(s => s.name) || [],
    }
  },
  methods: {
    ...mapMutations('offersModule', ['update']),
    addReward() {
      this.update({
        commonRewards: [
          ...(this.offer.commonRewards || []),
          { value: {}, stopGeo: false, withoutValue: false },
        ],
      })
      return this.offer.commonRewards
    },
    deleteReward(rewardIndex: number) {
      const commonRewards = this.rewards.filter(
        (reward: CommonReward, index: number) => index !== rewardIndex,
      )
      this.update({ commonRewards })
    },
    updateField(reward: CommonReward, index: number) {
      if (reward.type === RewardType.RevShare) {
        reward = { ...reward, value: { ...reward.value, amount: null } }
      }
      const commonRewards = this.rewards
      commonRewards[index] = reward
      this.update({ commonRewards })
    },
    async getGeos(value: string, update: Function) {
      await lookupGeos(this, value, update)
    },
    getTrafficSources(value: string, update: Function) {
      const trafficSources = this.trafficSources.filter((s: any) =>
        s.toLowerCase().includes(value.toLowerCase()),
      )
      update(() => {
        if (!value) {
          this.trafficSources = store.state.offersModule.offer.trafficSources.map(
            s => s.name,
          )
          return
        }
        this.trafficSources = trafficSources
      })
    },
    clearFilter(selectedRef: string) {
      if (this.$refs[selectedRef] !== void 0) {
        ;(this.$refs[selectedRef] as any).updateInputValue('')
      }
    },
    updateTrafficSource(reward: CommonReward, name: string, index: number) {
      const trafficSource = this.offer.trafficSources.find(
        (s: TrafficSource) => name === s.name,
      )
      this.updateField(
        {
          ...reward,
          trafficSource,
        },
        index,
      )
    },
  },
})
