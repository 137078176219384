
import { mapMutations, mapState } from 'vuex'
import { defineComponent } from 'vue'
import store from '@/store'
import { OfferTranslation } from '@/store/types'
import { lookupLanguages } from '@/utils/lookups'

export default defineComponent({
  name: 'Translations',
  props: ['defaultOptions'],
  computed: {
    ...mapState('offersModule', ['errors', 'offer']),
    translations(): OfferTranslation[] {
      const translations = store.state.offersModule.offer.translations
      if (!translations.length) {
        return this.addReward()
      }

      return translations
    },
  },
  data: function() {
    return {
      languages: [...(this.defaultOptions.languages || [])],
    }
  },
  methods: {
    ...mapMutations('offersModule', ['update']),
    addReward() {
      this.update({
        translations: [...(this.offer.translations || []), {}],
      })
      return this.offer.translations
    },
    deleteReward(i: number) {
      const translations = this.translations.filter(
        (reward: OfferTranslation, index: number) => index !== i,
      )
      this.update({ translations })
    },
    updateField(reward: OfferTranslation, index: number) {
      const translations = this.translations
      translations[index] = reward
      this.update({ translations })
    },
    async getLanguages(value: string, update: Function) {
      await lookupLanguages(this, value, update)
    },
    clearFilter(selectedRef: string) {
      if (this.$refs[selectedRef] !== void 0) {
        ;(this.$refs[selectedRef] as any).updateInputValue('')
      }
    },
  },
})
