
import { defineComponent } from 'vue'
import getColumns from './config/getColumns'
import Filters from '@/components/filters/AppsFilters.vue'
import {
  request,
  Table,
  TableRequestProps,
  getTableProperties,
  getGroupByField,
  Row,
  getDefaultFilters,
  getPagination,
} from '@/utils/request'
import { groupByOptions } from '@/pages/tds-statistics/config/groupByOptions'
import { getTotalValues } from '@/utils/getData'
import { FiltersType } from '@/utils/request'
import { PostbackEventType } from '@/enums/PostbackEventType'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { omitBy, isNull } from 'lodash'
import PieChart from '@/components/charts/PieChart.vue'
import LineChart from '@/components/charts/LineChart.vue'
import { getData } from '@/utils/getData'
import { getISOWeek } from 'date-fns'
import { formatValue } from '@/utils'
import { FormatMoney } from 'format-money-js'

export default defineComponent({
  name: 'Statistics',
  components: { LineChart, PieChart, Filters },
  created() {
    if (Object.keys(this.$route.params).length) {
      const paramsFilters = this.$route.params
      for (const [key, value] of Object.entries(paramsFilters)) {
        if (Number(value) || Number(value) === 0) {
          paramsFilters[key] = (Number(value) as unknown) as string
        }
      }
      this.filters = { ...this.filters, ...paramsFilters }
    } else {
      this.onRequestChartData()
      this.onRequest({ pagination: this.pagination })
    }
  },
  setup() {
    const $q = useQuasar()
    return {
      showErrorSnackbar(err: any) {
        const error = new Error(err)
        $q.notify({
          type: 'negative',
          message: `Internal Server Error: ${error.message}`,
          position: 'top-right',
          timeout: 4000,
        })
      },
    }
  },
  data: function() {
    const moneyFormatter = new FormatMoney({
      decimals: 2,
      separator: ' ',
    })
    return {
      moneyFormatter,
      columns: getColumns(),
      filters: getDefaultFilters(),
      ...getTableProperties('groupByField'),
      pagination: {
        ...getPagination('groupByField', 30),
        selectedColumns: ['installs', 'registrationCount', 'firstDepositCount'],
      },
      eventType: PostbackEventType,
      chartData: [],
      chartLoading: true,
      ...this.getCheckboxes(),
      installs: true,
      registrationCount: true,
      firstDepositCount: true,
    }
  },
  watch: {
    filters() {
      if (this.filters.groupBy !== 'date') {
        this.pagination.selectedColumns = this.pagination.selectedColumns.length
          ? [this.pagination.selectedColumns[0]]
          : []
        this.installs = false
        this.registrationCount = false
        this.firstDepositCount = false
        this.firstDepositSum = false
        this.firstDepositSumEur = false
        this.crReg = false
        this.crFtd = false
        this.crFtdReg = false
        this.installSpend = false
        if (this.pagination.selectedColumns.length) {
          this[this.pagination.selectedColumns[0]] = true
        }
      }
      this.pagination.page = 1
      this.onRequest({ pagination: this.pagination })
      this.onRequestChartData()
    },
  },
  methods: {
    getCurrencyUsd(): string {
      return ' USD'
    },
    getCurrencyEur(): string {
      return ' EUR'
    },
    getISOWeekName(val: string) {
      return (
        'week #' +
        getISOWeek(new Date(val)) +
        `  (${new Date(val).getFullYear()})`
      )
    },
    total(field: string, operation: 'sum' | 'avg'): number | string {
      return getTotalValues(this.rows, field, operation)
    },
    getGroupedField(field: 'id' | 'name' | 'field', row: Row) {
      return getGroupByField(field, row, groupByOptions, this.filters)
    },
    async onRequestChartData() {
      this.chartLoading = true
      const orderBy = {
        [String(this.filters?.groupBy)]: this.pagination.descending
          ? 'DESC'
          : 'ASC',
      }
      this.chartData = await getData(
        { filters: this.filters || {}, limit: 0, offset: 0, orderBy },
        '/api/statistics',
      )
      this.chartLoading = false
    },
    async onRequest(props: TableRequestProps) {
      try {
        await request(props, this as Table, '/api/statistics')
      } catch (err) {
        this.showErrorSnackbar(err)
      }
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    resetFilters() {
      this.pagination.page = 1
      this.filters = getDefaultFilters()
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    detailConversions(params: any) {
      const filters = omitBy(this.filters, isNull)
      this.$router.push({
        name: 'logs',
        params: {
          ...filters,
          filters: JSON.stringify(filters),
          ...params,
        },
      })
    },
    changeSelected(column: string) {
      if (this.pagination.selectedColumns.includes(column)) {
        this.pagination.selectedColumns = this.pagination.selectedColumns.filter(
          (c: string) => c !== column,
        )
      } else {
        this.pagination.selectedColumns = [
          ...this.pagination.selectedColumns,
          column,
        ]
      }
    },
    getCheckboxes() {
      return {
        installs: false,
        registrationCount: false,
        firstDepositCount: false,
        firstDepositSum: false,
        firstDepositSumEur: false,
        crReg: false,
        crFtd: false,
        crFtdReg: false,
        installSpend: false,
      }
    },
    resetColumns() {
      this.selectedColumns = []
      const checkboxes = this.getCheckboxes()
      for (const c of Object.keys(checkboxes)) {
        this[c] = false
      }
    },
    getGroupByArray() {
      return groupByOptions
    },
    formatMoney(value: number) {
      return this.moneyFormatter.from(
        Number(formatValue(Number(value), 2, true)),
      )
    },
  },
})
