import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Filters = _resolveComponent("Filters")!
  const _component_ReferralsTable = _resolveComponent("ReferralsTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Filters, {
      tab: "referral-stats",
      onReset: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('refresh'))),
      onExport: _ctx.onExport,
      onChange: _cache[1] || (_cache[1] = 
        (field, value) => {
          _ctx.$emit('change', field, value)
        }
      ),
      onChangeAll: _cache[2] || (_cache[2] = 
        (field, value) => {
          _ctx.$emit('changeAll', field, value)
        }
      ),
      "current-filters": _ctx.complexFilters
    }, null, 8, ["onExport", "current-filters"]),
    _createVNode(_component_ReferralsTable, {
      filters: _ctx.filters,
      exportCsv: _ctx.exportCsv,
      onDetail: _ctx.onDetail,
      onChangePagination: _ctx.changePagination,
      currentPagination: _ctx.pagination
    }, null, 8, ["filters", "exportCsv", "onDetail", "onChangePagination", "currentPagination"])
  ]))
}