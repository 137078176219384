import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-mx-md flex justify-start mobile-display-block" }
const _hoisted_2 = { class: "flex row items-center" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "q-ml-md" }
const _hoisted_5 = { class: "row items-center justify-center" }
const _hoisted_6 = { class: "flex column" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "row justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Filters = _resolveComponent("Filters")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, {
        class: "link-text",
        to: "/push-notifications/new"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_btn, {
            size: "md",
            label: "Добавить пуш",
            color: "primary",
            "no-caps": ""
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Filters, {
          tab: "push-groups",
          onReset: _ctx.resetFilters,
          onChange: _ctx.onChangeFilters,
          onChangeAll: _ctx.changeAll,
          "current-filters": _ctx.filters
        }, null, 8, ["onReset", "onChange", "onChangeAll", "current-filters"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_q_table, {
        dense: "",
        rows: _ctx.rows,
        columns: _ctx.columns,
        "row-key": "id",
        separator: "cell",
        pagination: _ctx.pagination,
        "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
        loading: _ctx.loading,
        onRequest: _ctx.onRequest,
        "binary-state-sort": ""
      }, {
        "body-cell-title": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", null, _toDisplayString(props.row.title), 1)
                ]),
                _createElementVNode("img", {
                  width: "40",
                  height: "40",
                  src: _ctx.storageUrl + '/' + props.row.previewImage,
                  alt: "",
                  class: "img q-ml-md"
                }, null, 8, _hoisted_7)
              ])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-languages": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.languages, (lang, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "row q-mr-sm q-pb-xs",
                    key: index
                  }, [
                    _createVNode(_component_q_btn, {
                      color: "primary",
                      size: "sm",
                      label: lang.code,
                      outline: ""
                    }, null, 8, ["label"])
                  ]))
                }), 128))
              ])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-isTurnedOn": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_q_checkbox, {
                modelValue: props.row.isTurnedOn,
                "onUpdate:modelValue": ($event: any) => ((props.row.isTurnedOn) = $event),
                onClick: ($event: any) => (_ctx.updatePush(props.row))
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onClick"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-actions": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                round: "",
                flat: "",
                color: "primary",
                onClick: ($event: any) => (
              _ctx.$router.push('/push-notifications/' + props.row.autoPushGroupId)
            ),
                icon: "edit",
                size: "12px",
                class: "q-mr-md"
              }, null, 8, ["onClick"]),
              _createVNode(_component_q_btn, {
                flat: "",
                round: "",
                icon: "delete",
                color: "primary",
                onClick: ($event: any) => (_ctx.deleteRow(props.row.autoPushGroupId)),
                size: "12px"
              }, null, 8, ["onClick"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 1
      }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
    ])
  ], 64))
}