
import { defineComponent } from 'vue'
import axios from 'axios'
import { showErrorMessageSnackBar, showSuccessSnackBar } from '@/utils'

export default defineComponent({
  name: 'LeadLimitEditPopup',
  props: ['leadLimitRow', 'refresh'],
  data() {
    return {
      maxValue: this.leadLimitRow.maxValue,
      loading: false,
    }
  },
  methods: {
    async save() {
      this.loading = true
      try {
        await axios.post('/api/lead-limits/update', {
          id: this.leadLimitRow.id,
          period: this.leadLimitRow.period,
          maxValue: Number(this.maxValue),
        })
        showSuccessSnackBar('Капа успешно обновлена')
      } catch (e) {
        showErrorMessageSnackBar(
          (e as any).response?.data?.errors?.message || 'Произошла ошибка :(',
        )
      }
      this.loading = false
      this.$emit('refresh')
    },
  },
})
