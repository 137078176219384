import { GroupByOptions } from '@/utils/request'

export const financeGroupByOptions: GroupByOptions = [
  {
    id: 'date',
    name: 'Дни',
    field: 'date',
  },
  {
    id: 'week',
    name: 'Недели',
    field: 'week',
  },
  {
    id: 'mobileAppId',
    name: 'Приложение',
    field: 'mobileAppName',
  },
  {
    id: 'affiliateId',
    name: 'Аффилейт',
    field: 'affiliateEmail',
  },
]
