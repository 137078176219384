
import { defineComponent } from 'vue'
import { mapState, mapMutations } from 'vuex'
import {
  lookupAffiliates,
  lookupGeos,
  lookupOffers,
  lookupTrafficSourcesByOffer,
} from '@/utils/lookups'
import IconWithName from '@/components/icons/IconWithName.vue'

export default defineComponent({
  name: 'GeneralInfo',
  components: { IconWithName },
  props: ['defaultOptions'],
  computed: {
    ...mapState('campaignsModule', ['campaign', 'errors']),
    ...mapState('authModule', ['user']),
  },
  data: function() {
    return {
      affiliates: [...(this.defaultOptions.affiliates || [])],
      offers: [...(this.defaultOptions.offers || [])],
      trafficSources: [...(this.defaultOptions.trafficSources || [])],
      geos: [...(this.defaultOptions.geos || [])],
    }
  },
  methods: {
    ...mapMutations('campaignsModule', ['update']),
    async getOffers(value: string, update: Function) {
      await lookupOffers(
        this,
        value,
        update,
        [],
        { affiliateId: this.campaign.affiliateId },
        true,
      )
    },
    async getGeos(value: string, update: Function) {
      await lookupGeos(this, value, update, [], [], {
        affiliateId: this.campaign.affiliateId,
        byRewards: true,
        offerId: this.campaign.offerId,
      })
    },
    async getTrafficSources(value: string, update: Function) {
      await lookupTrafficSourcesByOffer(this, value, update)
    },
    async getAffiliates(value: string, update: Function, extraParams = {}) {
      await lookupAffiliates(this, value, update, extraParams)
    },
  },
})
