
import { defineComponent } from 'vue'
import axios from 'axios'
import { enumToArray } from '@/utils'
import { AffiliateAccessStatus } from '@/enums/AffiliateAccessStatus'
import { lookupAffiliates, lookupMobileApps } from '@/utils/lookups'
import { getDefaultFilters, getTableProperties, Row } from '@/utils/request'
import { getColumns } from './getColumns'
import { request, Table, TableRequestProps } from '@/utils/request'
export default defineComponent({
  name: 'MobileAppAccess',
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  data: function() {
    return {
      ...getTableProperties('createdAt'),
      filters: { ...getDefaultFilters() },
      columns: getColumns(),
      errors: {},
      access: this.getEmptyAccess(),
      apps: [],
      affiliates: [],
      status: AffiliateAccessStatus,
      statuses: enumToArray(AffiliateAccessStatus),
    }
  },
  methods: {
    async onRequest(props: TableRequestProps) {
      await request(props, this as Table, '/api/mobile-apps-access')
    },
    async changeStatus(access: Row) {
      await axios.put('/api/mobile-apps-access', {
        status: access.status,
        mobileAppId: access.mobileAppId,
        affiliateId: access.affiliateId,
      })
      await this.onRequest({ pagination: this.pagination })
      this.access = this.getEmptyAccess()
    },
    getEmptyAccess() {
      return {
        mobileAppId: null,
        affiliateId: null,
        status: undefined,
      }
    },
    async getMobileApps(value: string, update: Function) {
      await lookupMobileApps(this, value, update)
    },
    async getAffiliates(value: string, update: Function) {
      await lookupAffiliates(this, value, update)
    },
  },
})
