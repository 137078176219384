
import { defineComponent } from 'vue'
import Installs from './Installs.vue'
import MobileAppLogs from './MobileAppLogs.vue'
import TdsPostbackLogs from './TdsPostbackLogs.vue'
import BlackListLogs from './BlackListLogs.vue'
import PostbackNoteDialog from '@/components/dialogs/PostbackNoteDialog.vue'
export default defineComponent({
  name: 'Logs',
  components: {
    Installs,
    MobileAppLogs,
    TdsPostbackLogs,
    BlackListLogs,
    PostbackNoteDialog,
  },
  data: function() {
    return {
      tab: this.$route.params.tab || 'installs',
      showPostbackNoteDialog: false,
    }
  },
  methods: {
    backToStats() {
      this.$router.push({
        name: 'statistics',
        params: {
          ...JSON.parse(this.$route.params.filters as any),
        },
      })
    },
  },
})
