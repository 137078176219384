import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-mx-lg" }
const _hoisted_2 = {
  class: "flex row items-center",
  style: {"width":"max-content"}
}
const _hoisted_3 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_table, {
      dense: "",
      rows: _ctx.rows,
      columns: _ctx.columns,
      "row-key": "id",
      separator: "cell",
      pagination: _ctx.pagination,
      "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
      loading: _ctx.loading,
      onRequest: _ctx.onRequest,
      "binary-state-sort": ""
    }, {
      "body-cell-geo": _withCtx((props) => [
        _createVNode(_component_q_td, { props: props }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                class: "q-mr-sm",
                height: "10",
                width: "15",
                alt: props.row.geoCode,
                src: 
                `http://purecatamphetamine.github.io/country-flag-icons/3x2/${props.row.geoCode}.svg`
              
              }, null, 8, _hoisted_3),
              _createTextVNode(" " + _toDisplayString(props.row.geoCode ? props.row.geo : '—'), 1)
            ])
          ]),
          _: 2
        }, 1032, ["props"])
      ]),
      _: 1
    }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
  ]))
}