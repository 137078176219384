
import { mapState, mapMutations } from 'vuex'
import { defineComponent } from 'vue'
import { Vertical } from '@/enums/Vertical'
import { OfferStatus } from '@/enums/OfferStatus'
import { enumToArray, showSuccessSnackBar } from '@/utils'
import store from '@/store'
import { RevShareHold } from '@/enums/RevShareHold'
import { lookupAdvertisers, lookupOfferCategories, lookupAdvertiserManagers } from '@/utils/lookups'
import { LeadLimitPeriod } from '@/enums/LeadLimitPeriod'
import EmojiPicker from 'vue3-emoji-picker'

export default defineComponent({
  name: 'OfferInfo',
  props: ['defaultOptions'],
  components: { EmojiPicker },
  computed: mapState('offersModule', ['offer', 'errors']),
  data: function() {
    return {
      foreColor: '#000000',
      highlight: '#ffff00aa',
      advertisers: [...(this.defaultOptions.advertisers || [])],
      advertiserManagers: [...(this.defaultOptions.advertiserManagers || [])],
      categories: [...(this.defaultOptions.offerCategories || [])],
      statuses: enumToArray(OfferStatus),
      verticals: enumToArray(Vertical),
      leadLimitPeriods: enumToArray(LeadLimitPeriod),
      verticalsEnum: Vertical,
      revShareHoldOptions: enumToArray(RevShareHold),
      storageUrl: process.env.CDN_STORAGE_URL,
      holdEnabled: !!store.state.offersModule.offer.defaultHoldInDays,
      leadLimitEnabled:
        store.state.offersModule.offer.defaultLeadLimitPerMonth !== null,
      informLeadLimitEnabled:
        store.state.offersModule.offer.defaultInformLeadLimit !== null,
    }
  },
  watch: {
    holdEnabled() {
      if (!this.holdEnabled) {
        this.update({ defaultHoldInDays: null })
      }
    },
    leadLimitEnabled() {
      if (!this.leadLimitEnabled) {
        this.update({
          defaultLeadLimitPerMonth: null,
          defaultLeadLimitPeriod: null,
        })
      } else {
        this.update({
          defaultLeadLimitPeriod: LeadLimitPeriod.Campaign,
        })
      }
    },
    informLeadLimitEnabled() {
      if (!this.informLeadLimitEnabled) {
        this.update({
          defaultInformLeadLimit: null,
          defaultInformLeadLimitPeriod: null,
        })
      } else {
        this.update({
          defaultInformLeadLimitPeriod: LeadLimitPeriod.Campaign,
        })
      }
    },
  },
  methods: {
    ...mapMutations('offersModule', ['update']),
    async getAdvertisers(value: string, update: Function) {
      await lookupAdvertisers(this, value, update)
    },
    async getAdvertiserManagers(value: string, update: Function) {
      await lookupAdvertiserManagers(this, value, update)
    },
    async getOfferCategories(value: string, update: Function) {
      await lookupOfferCategories(this, value, update, this.offer.categoryIds, {
        vertical: this.offer.vertical,
      })
    },
    clearFilter(selectedRef: string) {
      if (this.$refs[selectedRef] !== void 0) {
        ;(this.$refs[selectedRef] as any).updateInputValue('')
      }
    },
    color(cmd: any, name: string) {
      const edit: any = (this.$refs as any).editor
      ;(this.$refs as any).token.hide()
      edit.runCmd(cmd, name)
      edit.focus()
    },
    colorComments(cmd: any, name: string) {
      const edit: any = (this.$refs as any).editorComments
      ;(this.$refs as any).token.hide()
      edit.runCmd(cmd, name)
      edit.focus()
    },
    onSelectEmoji(emoji: any) {
      const element = document.createElement('textarea')
      document.body.appendChild(element)
      element.value = emoji.i
      element.select()
      document.execCommand('copy')
      document.body.removeChild(element)
      showSuccessSnackBar('Скопировано')
    },
  },
})
