import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "q-mx-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_table, {
      dense: "",
      rows: _ctx.rows,
      columns: _ctx.columns,
      "row-key": "id",
      separator: "cell",
      pagination: _ctx.pagination,
      "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
      loading: _ctx.loading,
      onRequest: _ctx.onRequest,
      "binary-state-sort": ""
    }, {
      "body-cell-prevManagers": _withCtx((props) => [
        _createVNode(_component_q_td, { props: props }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.prevManagerIds, (id, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                _createVNode(_component_IconWithName, {
                  id: id,
                  name: _ctx.getAdminEmail(id),
                  size: "20px",
                  color: "primary",
                  icon: "manage_accounts",
                  entity: "manager"
                }, null, 8, ["id", "name"])
              ]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["props"])
      ]),
      "body-cell-managers": _withCtx((props) => [
        _createVNode(_component_q_td, { props: props }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.managerIds, (id, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                _createVNode(_component_IconWithName, {
                  id: id,
                  name: _ctx.getAdminEmail(id),
                  size: "20px",
                  color: "primary",
                  icon: "manage_accounts",
                  entity: "manager"
                }, null, 8, ["id", "name"])
              ]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["props"])
      ]),
      "body-cell-createdBy": _withCtx((props) => [
        _createVNode(_component_q_td, { props: props }, {
          default: _withCtx(() => [
            _createVNode(_component_IconWithName, {
              id: props.row.createdBy.id,
              name: props.row.createdBy.credentials.email,
              size: "20px",
              color: "primary",
              icon: "manage_accounts",
              entity: "manager"
            }, null, 8, ["id", "name"])
          ]),
          _: 2
        }, 1032, ["props"])
      ]),
      _: 1
    }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
  ]))
}