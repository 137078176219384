import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "chart2",
  class: "flex justify-center items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner_cube = _resolveComponent("q-spinner-cube")!
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_q_spinner_cube, {
          key: 0,
          style: {"height":"413px"},
          color: "orange",
          size: "3.5em"
        }))
      : (_openBlock(), _createBlock(_component_apexchart, {
          key: 1,
          type: "pie",
          width: "680",
          height: "435",
          options: _ctx.chartOptions,
          series: _ctx.series
        }, null, 8, ["options", "series"]))
  ]))
}