import { formatDate } from '@/utils'

export function getColumns() {
  return [
    {
      name: 'createdAt',
      label: 'Дата',
      field: 'createdAt',
      align: 'center',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'affiliateEmail',
      label: 'Аффилейт',
      field: 'affiliateEmail',
      align: 'center',
      sortable: true,
    },
    {
      name: 'mobileAppName',
      label: 'Приложение',
      field: 'mobileAppName',
      align: 'center',
      sortable: true,
    },
    { name: 'actions', label: 'Действия', field: 'actions', align: 'center' },
  ]
}
