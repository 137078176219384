
import { defineComponent } from 'vue'
import useQuasar from 'quasar/src/composables/use-quasar.js';
export default defineComponent({
  name: 'AttributionLinkDialog',
  props: ['open', 'close', 'source', 'package'],
  computed: {
    showDialog(): boolean {
      return this.open
    },
  },
  setup() {
    const $q = useQuasar()
    return {
      showSuccessSnackbar() {
        $q.notify({
          type: 'positive',
          message: `Скопировано`,
          position: 'top-right',
          timeout: 2500,
        })
      },
    }
  },
  data: function() {
    return {
      ttClickLink: `https://app.appsflyer.com/PACKAGE?pid=bytedanceglobal_int&af_siteid=__CSITE__&c=__CAMPAIGN_NAME__&af_channel=__PLACEMENT__&af_c_id=__CAMPAIGN_ID__&af_adset=__AID_NAME__&af_adset_id=__AID__&af_ad=__CID_NAME__&af_ad_id=__CID__&af_ad_type=__CTYPE__&af_click_lookback=7d&clickid=__CALLBACK_PARAM__&advertising_id=__GAID__&idfa=__IDFA__&os=__OS__&af_ip=__IP__&af_ua=__UA__&af_lang=__SL__&redirect=false`,
      ttImpressionLink: `https://impression.appsflyer.com/PACKAGE?pid=bytedanceglobal_int&af_siteid=__CSITE__&c=__CAMPAIGN_NAME__&af_channel=__PLACEMENT__&af_c_id=__CAMPAIGN_ID__&af_adset=__AID_NAME__&af_adset_id=__AID__&af_ad=__CID_NAME__&af_ad_id=__CID__&af_ad_type=__CTYPE__&af_viewthrough_lookback=24h&clickid=__CALLBACK_PARAM__&advertising_id=__GAID__&idfa=__IDFA__&os=__OS__&af_ip=__IP__&af_ua=__UA__&af_lang=__SL__&redirect=false`,
      unityClickLink: `https://app.appsflyer.com/PACKAGE?pid=unityads_int&af_siteid={source_app_id}&c={campaign_name}&af_cost_model=cpi&af_cost_currency=USD&af_cost_value={cpi}&af_c_id={campaign_id}&af_ad={creative_pack}&af_ad_id={creative_pack_id}&af_adset={creative_pack}&af_channel={exchange}&af_click_lookback=7d&advertising_id={ifa}&gamer_id={gamer_id}&game_id={game_id}&redirect=false&af_ad_orientation={video_orientation}`,
      unityImpressionLink: `https://impression.appsflyer.com/PACKAGE?pid=unityads_int&af_siteid={source_app_id}&c={campaign_name}&af_cost_model=cpi&af_cost_currency=USD&af_cost_value={cpi}&af_c_id={campaign_id}&af_ad={creative_pack}&af_ad_id={creative_pack_id}&af_adset={creative_pack}&af_channel={exchange}&af_viewthrough_lookback=24h&advertising_id={ifa}&gamer_id={gamer_id}&game_id={game_id}&redirect=false&af_ad_orientation={video_orientation}`,
    }
  },
  methods: {
    getClickLink() {
      const url = this.source === 'tt' ? this.ttClickLink : this.unityClickLink
      return url.replace('/PACKAGE?', `/${this.package}?`)
    },
    getImpressionLink() {
      const url =
        this.source === 'tt' ? this.ttImpressionLink : this.unityImpressionLink
      return url.replace('/PACKAGE?', `/${this.package}?`)
    },
    copy(id: 'click_url' | 'impression_url') {
      const url = document.getElementById(id) as HTMLInputElement
      url.select()
      document.execCommand('copy')
      this.showSuccessSnackbar()
    },
  },
})
