import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-h6 q-ml-lg"
}
const _hoisted_2 = {
  key: 1,
  class: "text-h6 q-ml-lg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_GeneralInfo = _resolveComponent("GeneralInfo")!
  const _component_Promos = _resolveComponent("Promos")!
  const _component_Postbacks = _resolveComponent("Postbacks")!
  const _component_RedirectDomain = _resolveComponent("RedirectDomain")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDialog) = $event)),
    onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.$emit('close')), ["esc"]))
  }, {
    default: _withCtx(() => [
      (!_ctx.ready)
        ? (_openBlock(), _createBlock(_component_q_spinner, {
            key: 0,
            color: "primary",
            size: "3em",
            thickness: 3
          }))
        : (_openBlock(), _createBlock(_component_q_card, {
            key: 1,
            style: {"min-width":"350px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, { class: "row justify-between items-center" }, {
                default: _withCtx(() => [
                  (!_ctx.campaignId)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Новая кампания "))
                    : (_openBlock(), _createElementBlock("div", _hoisted_2, "Кампания #" + _toDisplayString(_ctx.campaign.id), 1)),
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_btn, {
                      "no-caps": "",
                      icon: "close",
                      flat: "",
                      rounded: "",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                    })
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_q_form, {
                onSubmit: _ctx.onSubmit,
                onReset: _ctx.onReset,
                class: "q-gutter-md q-mx-sm"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, { class: "q-py-none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_GeneralInfo, {
                        onSelect: _ctx.selectOffer,
                        defaultOptions: _ctx.defaultOptions
                      }, null, 8, ["onSelect", "defaultOptions"])
                    ]),
                    _: 1
                  }),
                  (_ctx.campaign.offerId)
                    ? (_openBlock(), _createBlock(_component_q_card_section, {
                        key: 0,
                        class: "q-py-none"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Promos, { defaultOptions: _ctx.defaultOptions }, null, 8, ["defaultOptions"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_q_card_section, { class: "q-py-none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Postbacks)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_q_card_section, { class: "q-py-none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_RedirectDomain, { defaultOptions: _ctx.defaultOptions }, null, 8, ["defaultOptions"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onSubmit", "onReset"]),
              _createVNode(_component_q_card_actions, {
                align: "center",
                class: "text-primary"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    disable: _ctx.loading,
                    loading: _ctx.loading,
                    "no-caps": "",
                    icon: "save",
                    color: "positive",
                    label: "Сохранить",
                    type: "submit",
                    onClick: _ctx.onSubmit
                  }, null, 8, ["disable", "loading", "onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}