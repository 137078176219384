import axios from 'axios'
import { ReadOptions, Filters } from '@/utils/request'
import { Row } from '@/utils/request'
import { formatValue } from '@/utils'

export async function getData(options: ReadOptions, url: string) {
  const params: ReadOptions = { ...options, filters: { ...options.filters } }
  for (const [key, value] of Object.entries(params.filters)) {
    if (value === null || value === undefined) {
      delete params.filters[key as keyof Filters]
    }
  }
  if (options.filters.dateFrom) {
    params.filters.dateFrom = options.filters.dateFrom
  }
  if (options.filters.dateTo) {
    params.filters.dateTo = options.filters.dateTo
  }
  if (options.filters.groupBy) {
    params.groupBy = [options.filters.groupBy]
  }
  if (options.orderBy) {
    params.orderBy = options.orderBy
  }
  if (options.limit) {
    params.limit = options.limit
  }
  if (options.offset) {
    params.offset = options.offset
  }

  return (await axios.get(url, { params })).data
}

export function getTotalValues(
  rows: Row[],
  field: string,
  operation: 'sum' | 'avg',
): number | string {
  let total = 0
  if (operation === 'sum') {
    for (const row of rows) {
      const val = Number(row[field])
      if (val) total += val
    }
  } else {
    let length = 0
    for (const row of rows) {
      const val = Number(row[field])
      if (val) {
        total += val
        length++
      }
    }
    if (length) total /= length
  }
  return formatValue(total) as number
}

export function regroupRowsData(
  rows: any,
  nestedRows: any,
  nestedChildRows = [],
  ref: any,
) {
  ref.rows = [...rows]
  const { sortBy, descending } = ref.pagination
  const orderByField =
    sortBy !== 'groupByField' ? sortBy : String(ref.filters?.groupBy)
  if (!nestedChildRows?.length) {
    for (const row of ref.rows) {
      const children = []
      const groupBy1Value = row[ref.filters.groupBy]
      for (const child of nestedRows) {
        if (
          child[ref.filters.groupBy] === groupBy1Value &&
          child[ref.filters.groupBy] !== null &&
          child[ref.filters.groupBy] !== undefined
        ) {
          children.push(child)
        }
      }
      row.children = children.sort((r1: any, r2: any) =>
        descending
          ? r2[orderByField] - r1[orderByField]
          : r1[orderByField] - r2[orderByField],
      )
    }
  } else {
    for (const row of ref.rows) {
      const children = []
      const groupBy1Value = row[ref.filters.groupBy]
      for (const child of nestedRows) {
        const nestedChildren: any = []
        const groupBy2Value = child[ref.filters.groupBy2]
        if (
          child[ref.filters.groupBy] === groupBy1Value &&
          child[ref.filters.groupBy] !== null &&
          child[ref.filters.groupBy] !== undefined
        ) {
          for (const child2 of nestedChildRows) {
            if (
              child2[ref.filters.groupBy] === groupBy1Value &&
              child2[ref.filters.groupBy] !== null &&
              child2[ref.filters.groupBy] !== undefined &&
              child2[ref.filters.groupBy2] === groupBy2Value &&
              child2[ref.filters.groupBy2] !== null &&
              child2[ref.filters.groupBy2] !== undefined
            ) {
              nestedChildren.push(child2)
            }
          }
          child.children = nestedChildren.sort((r1: any, r2: any) =>
            descending
              ? r2[orderByField] - r1[orderByField]
              : r1[orderByField] - r2[orderByField],
          )
          children.push(child)
        }
      }
      row.children = children.sort((r1: any, r2: any) =>
        descending
          ? r2[orderByField] - r1[orderByField]
          : r1[orderByField] - r2[orderByField],
      )
    }
  }
}
