import { formatDate } from '@/utils'

export default function getColumns() {
  return [
    { name: 'id', label: 'ID', field: 'id', align: 'left', sortable: true },
    {
      name: 'name',
      label: 'Название',
      field: 'name',
      align: 'left',
      sortable: true,
    },
    {
      name: 'createdAt',
      label: 'Дата добавления',
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'offersCount',
      label: 'Офферы',
      field: 'offersCount',
      align: 'right',
      classes: 'monospaced',
      sortable: true,
    },
    {
      name: 'regsCount',
      label: 'Регистрации',
      field: 'regsCount',
      align: 'right',
      classes: 'monospaced',
      sortable: true,
    },
    {
      name: 'depsCount',
      label: 'Депозиты',
      field: 'depsCount',
      align: 'right',
      classes: 'monospaced',
      sortable: true,
    },
    { name: 'actions', label: '', field: 'actions', align: 'center' },
  ]
}
