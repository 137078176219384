import { formatDate, isDefined } from '@/utils'
import { Currency } from '@/enums/Currency'
import { PayoutStatus } from '@/enums/PayoutStatus'
import { PaymentSystem } from '@/enums/PaymentSystem'
import { PaymentSystemNetwork } from '@/enums/PaymentSystemNetwork'

export function getPayoutColumns() {
  return [
    { name: 'id', label: 'ID', field: 'id', align: 'center' },
    {
      name: 'createdAt',
      label: 'Дата',
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'affiliateEmail',
      label: 'Аффилейт',
      field: 'affiliateEmail',
      align: 'left',
      sortable: true,
    },
    {
      name: 'payoutAmount',
      label: 'Сумма',
      field: 'payoutAmount',
      align: 'right',
      format: (val: string, row: any) => {
        return row.payoutAmount
      },
    },
    {
      name: 'payoutCurrency',
      label: 'Валюта',
      field: 'payoutCurrency',
      align: 'right',
      format: (val: string, row: any) => {
        return Currency[row.payoutCurrency]
      },
    },
    {
      name: 'paymentSystem',
      label: 'Платежная система',
      field: 'paymentSystem',
      align: 'left',
      format: (val: string, row: any) =>
        PaymentSystem[row.paymentSystem] +
        (PaymentSystemNetwork[row.paymentSystemNetwork]
          ? ` (${PaymentSystemNetwork[row.paymentSystemNetwork].toLowerCase()})`
          : ''),
    },
    {
      name: 'account',
      label: 'Номер кошелька',
      field: 'account',
      align: 'left',
    },
    {
      name: 'status',
      label: 'Статус',
      field: 'status',
      align: 'center',
      sortable: true,
      format: (val: string, row: any) => PayoutStatus[row.status],
    },
    {
      name: 'feePercent',
      label: 'Коммисия, %',
      field: 'feePercent',
      align: 'right',
      format: (val: string) => isDefined(val),
    },
    {
      name: 'paidValueAmount',
      label: 'К оплате',
      field: 'paidValueAmount',
      align: 'right',
      format: (val: string, row: any) => {
        return (
          (row.payoutAmount || 0).toFixed(2) +
          ' ' +
          Currency[row.payoutCurrency]
        )
      },
    },
    {
      name: 'paidValueCurrency',
      label: 'Валюта',
      field: 'paidValueCurrency',
      align: 'right',
      format: (val: string, row: any) => {
        return Currency[row.payoutCurrency]
      },
    },
    { name: 'actions', label: 'Действия', field: 'actions', align: 'center' },
    {
      name: 'updatedAt',
      label: 'Дата обновления',
      field: 'updatedAt',
      align: 'left',
      format: (val: string) => formatDate(val),
    },
    {
      name: 'comment',
      label: 'Комментарий (хеш транзакции)',
      field: 'comment',
      align: 'left',
      format: (val: string) => isDefined(val),
    },
    {
      name: 'rejectComment',
      label: 'Причина реджекта',
      field: 'rejectComment',
      align: 'left',
      format: (val: string) => isDefined(val),
    },
  ]
}
