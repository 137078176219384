
import { mapActions, mapMutations, mapState } from 'vuex'
import axios from 'axios'
import { defineComponent } from 'vue'
import { enumToArray } from '@/utils'
import { Currency } from '@/enums/Currency'

export default defineComponent({
  name: 'AdvertisersEdit',
  computed: mapState('advertisersModule', ['advertiser', 'errors']),
  async created() {
    if (!this.$route.params.id) {
      this.reset()
      this.loading = false
      return
    }

    await this.getData(this.$route.params.id)
    this.loading = false
  },
  data: function() {
    return {
      loading: true,
      currencies: enumToArray(Currency),
    }
  },
  methods: {
    ...mapActions('advertisersModule', ['getData']),
    ...mapMutations('advertisersModule', ['update', 'reset', 'setErrors']),
    onSubmit: function() {
      const method = !this.$route.params.id ? 'post' : 'put'
      axios[method]('/api/advertisers', this.advertiser, {
        params: { id: this.$route.params.id },
      })
        .then(() => {
          this.setErrors({})
          this.$router.push('/advertisers')
        })
        .catch(({ response }) => {
          this.setErrors(response.data.errors)
        })
    },
    onReset: function() {
      this.reset()
      this.$router.push('/advertisers')
    },
  },
})
