import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_page_container = _resolveComponent("q-page-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$route.path !== '/login' && _ctx.$route.path !== '/sign-up')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Header, {
            drawer: _ctx.drawer,
            onOpenMenu: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeDrawer(!_ctx.drawer)))
          }, null, 8, ["drawer"]),
          _createVNode(_component_Navigation, {
            drawer: _ctx.drawer,
            miniState: _ctx.miniState,
            onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.miniState = false)),
            onMouseout: _cache[2] || (_cache[2] = ($event: any) => (_ctx.miniState = true)),
            onOpenMenu: _ctx.changeDrawer
          }, null, 8, ["drawer", "miniState", "onOpenMenu"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_q_page_container, { style: {"padding":"20px 0 20px 8px !important"} }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    })
  ], 64))
}