
import { defineComponent } from 'vue'
import { mapMutations, mapState } from 'vuex'
import { AffiliateReward, TdsRule } from '@/store/types'
import store from '@/store'
import { lookupAffiliates, lookupGeos } from '@/utils/lookups'
import IconWithName from '@/components/icons/IconWithName.vue'

export default defineComponent({
  name: 'Rules',
  components: { IconWithName },
  props: ['defaultOptions'],
  created(): void {
    if (!store.state.mobileAppsModule.mobileApp.rules.length) {
      this.update({ rules: [{}] })
    }
  },
  computed: {
    ...mapState('mobileAppsModule', ['mobileApp', 'errors']),
    rules() {
      return store.state.mobileAppsModule.mobileApp.rules
    },
  },
  data: function() {
    return {
      geos: [...(this.defaultOptions.geos || [])],
      affiliates: [...(this.defaultOptions.affiliates || [])],
    }
  },
  methods: {
    ...mapMutations('mobileAppsModule', ['update']),
    addRule() {
      this.update({ rules: [...this.rules, {}] })
    },
    deleteRule(ruleIndex: number) {
      const rules = this.rules.filter(
        (rule: TdsRule, index: number) => index !== ruleIndex,
      )
      this.update({ rules })
    },
    getFullNaming(rule: TdsRule, index: number) {
      if (!rule.affiliateId) {
        return null
      }
      if (rule.naming) {
        return rule.naming
      }
      rule.naming =
        rule.affiliateId +
        '-' +
        Math.random()
          .toString(16)
          .substr(2, 8) +
        '_sub1_sub2_sub3_sub4_sub5_sub6_sub7'
      this.updateField(rule, index)
    },
    updateField(rule: TdsRule, index: number) {
      const rules = this.rules
      rules[index] = rule
      this.update({ rules })
    },
    async getGeos(value: string, update: Function, rule: TdsRule) {
      const availableGeoIds = this.mobileApp.affiliateRewards
        ?.filter(
          (r: AffiliateReward) => r.affiliateIds?.[0] === rule.affiliateId,
        )
        .map((r: AffiliateReward) => r.geoIds)
        .flat()
      if (!availableGeoIds?.length) {
        update(() => {
          this.geos = []
        })
        return
      }
      await lookupGeos(this, value, update, availableGeoIds)
    },
    async getAffiliates(value: string, update: Function) {
      await lookupAffiliates(this, value, update)
    },
    clearFilter(selectedRef: string, rule: TdsRule) {
      if (this.$refs[selectedRef] !== void 0) {
        ;(this.$refs[selectedRef] as any).updateInputValue('')
      }
      if (selectedRef.includes('affiliates') && rule.naming) {
        rule.naming =
          rule.affiliateId + rule.naming.substr(rule.naming.indexOf('-'))
        this.updateField(rule, Number(selectedRef.split('-')[1]))
      }
    },
  },
})
