
import { defineComponent } from 'vue'
import {
  confirmDialog,
  enumToArray,
  getRewardTypesByVertical,
  payoutCurrencies,
  showErrorMessageSnackBar,
  showSuccessSnackBar,
} from '@/utils'
import axios from 'axios'
import { LeadStatus } from '@/enums/LeadStatus'
import { lookupGeos } from '@/utils/lookups'
import { Vertical } from '@/enums/Vertical'
export default defineComponent({
  name: 'ConversionEditDialog',
  props: ['open', 'close', 'refresh', 'leads'],
  async created() {
    this.geos = (await axios.get('/api/geos')).data
  },
  computed: {
    showDialog(): boolean {
      return this.open
    },
  },
  data: function() {
    const changedLeads = this.leads.map((l: any) => ({
      id: l.id,
      geoId: l.geoId,
      status: l.statusRaw,
      type: l.typeRaw,
      amount: l.amount,
      currency: l.currency,
      advertiserAmount: l.advertiserAmount,
      advertiserCurrency: l.advertiserCurrency,
    }))
    return {
      isSaving: false,
      geos: [],
      lead: changedLeads[0],
      changedLeads,
      rewardTypes: getRewardTypesByVertical(Vertical.Gambling),
      statuses: enumToArray(LeadStatus),
      currencies: payoutCurrencies,
      errors: {},
    }
  },
  methods: {
    updateField(val: any) {
      this.lead = val
    },
    async getGeos(value: string, update: Function) {
      await lookupGeos(this, value, update)
    },
    save() {
      const data = this.changedLeads.map((l: any) => ({
        ...this.lead,
        id: l.id,
      }))
      confirmDialog(
        'Вы уверены что хотите изменить данные по выбранным конверсиям?',
        () => {
          this.isSaving = true
          axios
            .put('/api/conversions/edit', data)
            .then(() => {
              showSuccessSnackBar('Конверсия обновлена')
              this.errors = {}
              this.$emit('refresh')
              this.$emit('close')
            })
            .catch(error => {
              showErrorMessageSnackBar(error.response.data?.errors?.message)
            })
            .finally(() => {
              this.isSaving = false
            })
        },
      )
    },
  },
})
