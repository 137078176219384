import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "column" }
const _hoisted_2 = {
  key: 0,
  class: "q-mb-md q-mt-none"
}
const _hoisted_3 = { class: "flex col-md-1 col-2 self-center justify-center" }
const _hoisted_4 = {
  key: 1,
  class: "q-my-md"
}
const _hoisted_5 = { class: "flex col-md-1 col-2 self-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_vue_feather = _resolveComponent("vue-feather")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.landings.length)
      ? (_openBlock(), _createElementBlock("h5", _hoisted_2, " Лендинги "))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.landings, (promo, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "row q-mb-sm",
        key: index
      }, [
        _createVNode(_component_q_checkbox, {
          class: "col-2 col-md-1",
          modelValue: promo.isActive,
          "onUpdate:modelValue": ($event: any) => ((promo.isActive) = $event),
          onClick: ($event: any) => (_ctx.updatePromos(promo))
        }, null, 8, ["modelValue", "onUpdate:modelValue", "onClick"]),
        _createVNode(_component_q_input, {
          dense: "",
          class: "col-md-5 col-9 q-pb-none",
          readonly: "",
          outlined: "",
          modelValue: promo.name,
          "onUpdate:modelValue": ($event: any) => ((promo.name) = $event),
          "error-message": _ctx.errors?.targetPromoIds || _ctx.errors?.message,
          error: !!_ctx.errors?.targetPromoIds || !!_ctx.errors?.message
        }, null, 8, ["modelValue", "onUpdate:modelValue", "error-message", "error"]),
        _createVNode(_component_q_input, {
          dense: "",
          class: "col-md-3 col-7 self-baseline q-pb-none",
          readonly: "",
          outlined: "",
          modelValue: _ctx.markupTypes[promo.markupType],
          "onUpdate:modelValue": ($event: any) => ((_ctx.markupTypes[promo.markupType]) = $event)
        }, null, 8, ["modelValue", "onUpdate:modelValue"]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_vue_feather, {
            size: "20px",
            onClick: ($event: any) => (_ctx.landingRedirect(promo.url)),
            type: "external-link",
            class: "link"
          }, null, 8, ["onClick"])
        ])
      ]))
    }), 128)),
    (_ctx.preLandings.length)
      ? (_openBlock(), _createElementBlock("h5", _hoisted_4, "Преленды:"))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.preLandings, (promo, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "row q-mb-sm",
        key: index
      }, [
        _createVNode(_component_q_checkbox, {
          class: "col-md-1 col-2",
          modelValue: promo.isActive,
          "onUpdate:modelValue": ($event: any) => ((promo.isActive) = $event),
          onClick: ($event: any) => (_ctx.updatePromos(promo))
        }, null, 8, ["modelValue", "onUpdate:modelValue", "onClick"]),
        _createVNode(_component_q_input, {
          dense: "",
          class: "col-md-5 col-9 q-pb-none",
          outlined: "",
          readonly: "",
          modelValue: promo.name,
          "onUpdate:modelValue": ($event: any) => ((promo.name) = $event)
        }, null, 8, ["modelValue", "onUpdate:modelValue"]),
        _createVNode(_component_q_input, {
          dense: "",
          class: "col-md-3 col-7 self-baseline q-pb-none",
          readonly: "",
          outlined: "",
          modelValue: _ctx.markupTypes[promo.markupType],
          "onUpdate:modelValue": ($event: any) => ((_ctx.markupTypes[promo.markupType]) = $event)
        }, null, 8, ["modelValue", "onUpdate:modelValue"]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_vue_feather, {
            size: "20px",
            onClick: ($event: any) => (_ctx.landingRedirect(promo.url)),
            type: "external-link",
            class: "link"
          }, null, 8, ["onClick"])
        ])
      ]))
    }), 128))
  ]))
}